import { deleteStatementDocumentUnit } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from "aws-amplify/api";
const client = generateClient();

export const removeStatementDocumentUnit = async (statementUnitId) => {
  if (!statementUnitId) return;
  console.log('Removing statemnet document unit record:', statementUnitId);

  try {
    const response = await client.graphql({
      query: deleteStatementDocumentUnit,
      variables: { input: {id: statementUnitId} }
    });

    const result = response.data.deleteSpkStatementDocumentUnitTbl.id;
    return result;
  } catch (err) {
    console.error('Error in removeStatementDocumentUnit:', err);
    return 'Error: Unable to remove statement unit';
  }
};

removeStatementDocumentUnit.propTypes = {
  statementUnitId: PropTypes.string.isRequired, // The ID of the record to delete

};