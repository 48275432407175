import { fetchAllCreditCardTeamMemberMap } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from "aws-amplify/api";

const client = generateClient();

export const fetchAllCreditCardTeamMemberMaps = async (allsort) => {
  try {
    const allSortParam = (allsort==="sandbox") ? "sandbox" : "all";
    const gqlParams = {
      allSort: allSortParam || 'all',
    };

    // console.log('fetchAllCreditCardTeamMemberMaps gqlParams: ', gqlParams);

    const response = await client.graphql({
      query: fetchAllCreditCardTeamMemberMap,
      variables: gqlParams,
    });

    // console.log('fetchAllCreditCardTeamMemberMaps response: ', response);
    
    const results = response.data.allCreditCardTeamMemberMapId.items;
    return results;
  } catch (err) {
    return 'error fetchAllCreditCardTeamMemberMaps: ' + err;
  }
};

fetchAllCreditCardTeamMemberMaps.propTypes = {
  allsort: PropTypes.string,
};