import { useState, useEffect, useContext } from 'react';
import {
  Container,
  Box,
} from '@chakra-ui/react';
import { AppContext } from './AppContext.jsx'
import { useForm } from 'react-hook-form';
import { PropTypes } from 'prop-types';

// graphql components
import { searchDjtsByDivision } from './graphqlCompnents/DJT/searchDjtsByDivision.jsx';
import { getStatusIdByStatusCode } from './graphqlCompnents/DJT/getStatusIdByStatusCode.jsx';
import { fetchAllDivisions } from './graphqlCompnents/Billing/fetchAllDivisions.jsx';

// generic functions
import { isValidDate, formatDateFn } from './functions/dateTime.jsx'
import { isNumber } from './functions/number.jsx';

// components
import { SearchDailyJobTicketsByClientByDate } from './DailyJobTicket/SearchDailyJobTicketsByClientByDate.jsx'
import { ResultsTableDailyJobTicketsByClientByDate } from './DailyJobTicket/ResultsTableDailyJobTicketsByClientByDate.jsx'

// PDF
import { generatePdf } from './PDF/GeneratePDF'

// design components
import { ModuleBox } from './Structural/ModuleBox';
import { FormSelectSimple } from './Form/Select/FormSelectSimple';

const TableRowHeight = 40; // Assuming each table row is 50px high
// const TableHeaderHeight = 456; // Adjust accordingly if your table has a header
const TableHeaderHeight = 410;

export const DailyJobTicket = () => {
  const { store } = useContext(AppContext);

  const currentUserId = store?.userData?.id
  const supervisorMultiDivisions = store?.userData?.multiDivisions || [];
  const currentUserIsGlobalAdmin = store?.userData?.isGlobalAdmin||false
  const divisionHasOneClient = store?.divisionHasOneClient
  const doShowAllSupervisorsSwitch = store?.enableAllSupervisors
  const showDownloadPDFButton = store?.showDownloadPDF||false
  const [ clientSelected, setClientSelected ] = useState(null)
  const [ clientIdSelected, setClientIdSelected ] = useState('')
  const [ djtStartDate, setDjtStartDate ] = useState('')
  const [ djtEndDate, setDjtEndDate ] = useState('')
  const [ endDateDisabled, setEndDateDisabled ] = useState(true)
  const [ showAllResults, setShowAllResults ] = useState(false)
  const [ djtSearchResultsNextToken, setDjtSearchResultsNextToken ] = useState(null)
  const [currentDivisionId, setCurrentDivisionId] = useState(null);
  useEffect(() => {
    if (store?.userData?.divisionId) {
      setCurrentDivisionId(store.userData.divisionId);
    }
  }, [store?.userData?.divisionId]); // Run whenever store.userData.divisionId changes
  
  const [ loadMoreDisabled, setLoadMoreDisabled ] = useState(false)
  const [ sortedFilteredDjts, setSortedFilteredDjts ] = useState([])

  const { control, register, setValue, formState: { errors } } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {}
  });

  const [numberOfRows, setNumberOfRows] = useState(0);

  const [ divisions, setDivisions ] = useState([])
  const [ selectedDivision, setSelectedDivision ] = useState(null)
  useEffect(() => {
    // The spell to summon all divisions from the ether
    const loadDivisions = async () => {
      try {
        const divisions = await fetchAllDivisions() || [];
        // console.warn('----- divisions: ', divisions);
        setDivisions(divisions);
      } catch (error) {
        // In case the spell fizzles, you know why
        console.error('Failed to fetch divisions:', error);
      }
    };
  
    // And... poof! Let the magic happen.
    loadDivisions();
  }, []); // The empty array makes sure this happens once, right after the initial curtain lift
  
  // const [ hasSelectedDivision, setHasSelectedDivision ] = useState(false);
  const handleDivisionChanged = (option) => {
    setSelectedDivision(option)
    setCurrentDivisionId(option?.value)
    // setHasSelectedDivision(true)
  }

  useEffect(() => {
    if (divisions.length > 0 && currentUserIsGlobalAdmin && supervisorMultiDivisions?.length>0) {
      const defaultDivision = divisions.find(({ defaultQBClass }) => defaultQBClass === 'Oklahoma');
      if (defaultDivision) {
        setSelectedDivision({ label: defaultDivision.defaultQBClass, value: defaultDivision.id });
        handleDivisionChanged({ label: defaultDivision.defaultQBClass, value: defaultDivision.id });
        setCurrentDivisionId(defaultDivision.id);
        // setHasSelectedDivision(true);
      }
    }
  },[divisions, currentUserIsGlobalAdmin, supervisorMultiDivisions?.length])

  const updateTableRows = () => {
      const screenHeight = window.innerHeight;
      // Calculate the number of rows that can fit, subtract header height from screen height before division
      let rowsThatCanFit = Math.floor((screenHeight - TableHeaderHeight) / TableRowHeight);
      if (rowsThatCanFit > 8) {
        // rowsThatCanFit = 75;
        rowsThatCanFit = 99;
      }
      setNumberOfRows(rowsThatCanFit);
  };

  useEffect(() => {
      updateTableRows();
      window.addEventListener('resize', updateTableRows);
      return () => window.removeEventListener('resize', updateTableRows);
  }, []);

  // const searchLimit = 11
  const searchLimit = numberOfRows

  const onCustomerChanged = async (event) => {
    console.log('event: ', event)
    setLoadMoreDisabled(false)
    if (event===null) {
      // console.log('EVENT IS NULL')
      setClientSelected(null)
      setClientIdSelected(null)
      searchDjts(
        {
          djtStatusId: searchStatusTypeId, 
          divisionId: currentDivisionId, 
          djtClientId: '', 
          djtDate: djtStartDate, 
          djtEndDate: djtEndDate, 
          limit: searchLimit, 
          nextToken: null 
        }, showAllResults, true)
        .catch(console.error);
    } else {
      setClientSelected({label: event.label, value: event.value})
      setClientIdSelected(event.value)
      // now search djts for customer
      searchDjts(
        {
          djtStatusId: searchStatusTypeId, 
          divisionId: currentDivisionId, 
          djtClientId: event.value, 
          djtDate: djtStartDate, 
          djtEndDate: djtEndDate, 
          limit: searchLimit, 
          nextToken: null 
        }, showAllResults, true)
        .catch(console.error);
    }
  }

  const onStartDateChanged = async (date) => {
    console.log(' executing onStartDateChanged: ', date)
    setLoadMoreDisabled(false)
    if (date===null) {
      setEndDateDisabled(true)
      setDjtStartDate(null)
      searchDjts(
        {
          djtStatusId: searchStatusTypeId, 
          divisionId: currentDivisionId, 
          djtClientId: clientIdSelected, 
          djtDate: '', 
          djtEndDate: '', 
          limit: searchLimit, 
          nextToken: null 
        }, showAllResults, true)
        .catch(console.error);
    } else {
      const startDate = date
      if (isValidDate(date)) {
        setEndDateDisabled(false)
        setDjtStartDate(startDate)
        searchDjts(
          {
            djtStatusId: searchStatusTypeId, 
            divisionId: currentDivisionId, 
            djtClientId: clientIdSelected, 
            djtDate: startDate, 
            djtEndDate: djtEndDate, 
            limit: searchLimit, 
            nextToken: null 
          }, showAllResults, true)
          .catch(console.error);
      }
    }
  }

  const onEndDateChanged = async (date) => {
    setLoadMoreDisabled(false)
    if (date===null) {
      setDjtEndDate(null)
      searchDjts(
        {
          djtStatusId: searchStatusTypeId, 
          divisionId: currentDivisionId, 
          djtClientId: clientIdSelected, 
          djtDate: djtStartDate, 
          djtEndDate: '', 
          limit: searchLimit, 
          nextToken: null 
        }, showAllResults, true)
        .catch(console.error);
    } else {
      const endDate = date
      if (isValidDate(endDate)) {
        setEndDateDisabled(false)
        setDjtEndDate(endDate)
        searchDjts(
          {
            djtStatusId: searchStatusTypeId, 
            divisionId: currentDivisionId, 
            djtClientId: clientIdSelected, 
            djtDate: djtStartDate, 
            djtEndDate: endDate, 
            limit: searchLimit, 
            nextToken: null 
          }, showAllResults, true)
          .catch(console.error);
      }
    }
  }

  const [ pendingSubmissionStatusId, setPendingSubmissionStatusId ] = useState(null)  // drafts
  const [ pendingApprovalStatusId, setPendingApprovalStatusId ] = useState(null)      // submitted
  const [ invoicedQBStatusId, setinvoicedQBStatusId ] = useState(null)      // invoiced-quickbooks
  const [ invoicedOtherStatusId, setInvoicedOtherStatusId ] = useState(null)      // invoiced-other
  const [ savedDoNotBillStatusId, setSavedDoNotBillStatusId ] = useState(null)      // uninvoiced-donotbill
  const [ approvalRequiredStatusId, setApprovalRequiredStatusId ] = useState(null)    // approval issue
  const [ deletedStatusId, setDeletedStatusId ] = useState(null)                      // deleted
  const [ showSearchResutlsTable, setShowSearchResutlsTable ] = useState(true)
  const [ searchStatusTypeId, setSearchStatusTypeId] = useState(null)

  useEffect(() => {
    const fetchStatusIds = async () => {
      if (!savedDoNotBillStatusId) {
        const savedDoNotBillId = await getStatusIdByStatusCode('uninvoiced-donotbill')  // submitted
        setSavedDoNotBillStatusId(savedDoNotBillId)
      }
    }
    fetchStatusIds()
  },[savedDoNotBillStatusId])

  useEffect(() => {
    const fetchStatusIds = async () => {
      if (!pendingSubmissionStatusId) {
        const pendingSubmissionId = await getStatusIdByStatusCode('queued-pending-submission')  // drafts
        setPendingSubmissionStatusId(pendingSubmissionId)
      }
    }
    fetchStatusIds()
  },[pendingSubmissionStatusId])

  useEffect(() => {
    const fetchStatusIds = async () => {
      if (!pendingApprovalStatusId) {
        const pendingApprovalId = await getStatusIdByStatusCode('queued-pending-approval')      // submitted
        setPendingApprovalStatusId(pendingApprovalId)
      }
    }
    fetchStatusIds()
  },[pendingApprovalStatusId])

  useEffect(() => {
    const fetchStatusIds = async () => {
      if (!invoicedQBStatusId) {
        const invoicedQBId = await getStatusIdByStatusCode('invoiced-via-quickbooks')      // invoiced-quickbooks
        setinvoicedQBStatusId(invoicedQBId)
        // setStatusSavedSet(true)
      }
    }
    fetchStatusIds()
  },[invoicedQBStatusId])

  useEffect(() => {
    const fetchStatusIds = async () => {
      if (!invoicedOtherStatusId) {
        const invoicedOtherId = await getStatusIdByStatusCode('invoiced-via-other')      // invoiced-other
        setInvoicedOtherStatusId(invoicedOtherId)
        // setStatusSavedSet(true)
      }
    }
    fetchStatusIds()
  },[invoicedOtherStatusId])

  useEffect(() => {
    const fetchStatusIds = async () => {
      if (!approvalRequiredStatusId) {
        const pendingApprovalRequiredId = await getStatusIdByStatusCode('queued-unapproved-with-issue') // approval issue
        setApprovalRequiredStatusId(pendingApprovalRequiredId)
        // might need to enable something here to pass to ticket so only display one button and textarea for message to admin
      }
    }
    fetchStatusIds()
  },[approvalRequiredStatusId])

  useEffect(() => {
    const fetchStatusIds = async () => {
      if (!deletedStatusId) {
        const deletedId = await getStatusIdByStatusCode('deleted') // deleted
        setDeletedStatusId(deletedId)
        // might need to enable something here to pass to ticket so only display one button and textarea for message to admin
      }
    }
    fetchStatusIds()
  },[deletedStatusId])

  // should be initial load only - set to saved submissions
  useEffect(() => {
    if (!searchStatusTypeId && pendingSubmissionStatusId) {
      setSearchStatusTypeId(pendingSubmissionStatusId)
    }
  },[searchStatusTypeId, pendingSubmissionStatusId])

  const searchStatusId = [
    pendingSubmissionStatusId,  // 'queued-pending-submission', 7fd6f20e-9698-4646-ac68-beebab1d0d5b
    pendingApprovalStatusId,    // 'queued-pending-approval', 2a279c1e-110c-4223-a2bd-e411fc51f89e
    approvalRequiredStatusId,   // 'queued-unapproved-with-issue', 468eb65a-8471-47e9-98cb-3258570f71fa
    deletedStatusId,            // 'deleted', 15b03dbc-14bb-47fa-a240-44d0992afb2b
  ]

  const [ isReviewing, setIsReviewing ] = useState(false)
  const [ currentSelectedTab, setCurrentSelectedTab ] = useState(0)
  const handleTabChange = async (tab) => {
    setShowSearchResutlsTable(false)
    if (tab===2) {
      setIsReviewing(true)
    } else {
      setIsReviewing(false)
    }
    
    // clear results table and wait for new data.
    const status = searchStatusId[tab]
    setCurrentSelectedTab(tab)
    console.log('tab: ', tab);
    setSearchStatusTypeId(status)
    const searchProps = {
      djtStatusId: status,
      divisionId: currentDivisionId, 
      djtClientId: clientIdSelected, 
      djtDate: djtStartDate, 
      djtEndDate: djtEndDate, 
      limit: searchLimit, 
      nextToken: null,
    }
    if (tab===1) {
      searchProps.statusGroup = 'submitted-invoiced'
      searchProps.statusIds = `${pendingApprovalStatusId},${invoicedQBStatusId},${invoicedOtherStatusId},${savedDoNotBillStatusId}`
    }

    console.warn(' -----> searchProps: ', searchProps)
    await searchDjts(searchProps, showAllResults, true)
    setShowSearchResutlsTable(true)
  }

  const [ approvalRequiredCount, setApprovalRequiredCount ] = useState(0)
  const [ djtSearchProps, setDjtSearchProps ] = useState(null)
  const searchDjts = async (props, optShowAll, optClearAll) => {
    console.log('<----- searchProps: ', props)
    // all of the djts get merged into this array
    let filteredDjts = []

    // depending on the tab we are in, pass one of the following to the search function
    // pendingSubmissionStatusId
    // pendingApprovalStatusId
    let searchProps = null
    if (!props) {
      searchProps = {
        djtStatusId: searchStatusTypeId,
        divisionId: currentDivisionId, 
        djtClientId: clientIdSelected, 
        djtDate: djtStartDate, 
        djtEndDate: djtEndDate, 
        limit: searchLimit, 
        nextToken: null,
      }
      optClearAll=true
    } else {
      searchProps = props
    }

    // default to show only supers djts
    let doShowAllResults = false
    if (optShowAll===true) {
      doShowAllResults = true
    }
    if (optShowAll===false) {
      doShowAllResults = false
    }
    if (optShowAll===undefined) {
      doShowAllResults = showAllResults
    }

    // Either show all results or only supervisor submitted djts
    if (doShowAllResults) {
      searchProps.teamMemberId = null
    } else {
      searchProps.teamMemberId = currentUserId
    }

    setDjtSearchProps(searchProps)
    setShowAllResults(doShowAllResults)

    // we shouldn't run this every time.
    // but we need to get the count so we can flag the approvals tab with a notice
    const approvalRequiredPending = await searchDjtsByDivision({
      djtStatusId: approvalRequiredStatusId,
      divisionId: currentDivisionId, 
      djtClientId: "", 
      teamMemberId: currentUserId,
      djtDate: "", 
      djtEndDate: "", 
      limit: searchLimit, 
      nextToken: null,
    }) || []
    console.log('approvalRequiredPending: ', approvalRequiredPending)

    const approvalRequiredPendingCount = approvalRequiredPending?.items?.length
    if (isNumber(approvalRequiredPendingCount)) {
      setApprovalRequiredCount(approvalRequiredPendingCount)
    }
    
    // we need to merge the existing array in with the search results unless this is a new search
    let allDjtData = []
    console.warn (' __________ searchDjtsByDivision - searchProps: ', searchProps)
    const allNewDjtData = await searchDjtsByDivision(searchProps) || []
    console.warn ('allNewDjtData: ', allNewDjtData)
    // serchProps.djtStatusId = approvalRequiredStatusId
    // this doesn't even get called until we click the tab. Need to call this sooner.
    // if (allNewDjtData.data.items.length>0 && isReviewing===true) setApprovalRequiredCount(allNewDjtData.data.items.length)
    // we need to run the approval required query to get the count before the first tab is loaded
    
    // const allNewDjtDataNextToken = allNewDjtData?.nextToken
    const allNewDjtDataNextToken = allNewDjtData?.nextToken||null

    // if clearAll, then wipe previous data
    if (optClearAll===true || allNewDjtData?.items.length===0) {
      allDjtData = allNewDjtData?.items
      // console.log('allDjtData: ', allDjtData)
      allDjtData.nextToken = allNewDjtDataNextToken
      setSortedFilteredDjts(null)
    } else {
      if (sortedFilteredDjts!==undefined && sortedFilteredDjts!==null && sortedFilteredDjts?.length>0) {
        // clear out the waitingForParent flag
        for (const djt of sortedFilteredDjts) {
          djt.waitingForParent = false
        }

        const mergedArray = sortedFilteredDjts.concat(allNewDjtData.items)
        allDjtData = mergedArray
        allDjtData.nextToken = allNewDjtDataNextToken
      } else {
        allDjtData = allNewDjtData?.items
        allDjtData.nextToken = allNewDjtDataNextToken
      }
    }

    // filter allDjtData for parents or childless
    // these are coming in as most recent first
    const djtDataParentOrChildless = allDjtData?.filter( 
      djt => djt?.isDuplicate===false 
    )

    // since we want to display these as newest first, we need to flip this array
    // so as we filter it, we end up with newest first
    djtDataParentOrChildless.sort((a, b) => a.djtDate - b.djtDate)

    // console.log('djtDataParentOrChildless: ', djtDataParentOrChildless)
    const djtChildren = allDjtData?.filter(
      djt => djt?.isDuplicate===true
    )
    
    // iterate through non-duplicates
    djtDataParentOrChildless?.forEach( djt => {
      const thisDjtId = djt.id

      // this is NOT a child/duplicate, it is a parent -  merge into the all allray
      filteredDjts.push(djt)

      // now check if it has any children - some of these children may not have parents loaded yet.
      const childrenOfThisParentUnsorted = djtChildren?.filter(e => e.parentId===thisDjtId)

      // now we need to reverse the sorting on these. parents get sorted from most recent forward
      // children get sorted from oldest to newest forward
      const childrenOfThisParent = childrenOfThisParentUnsorted?.sort((a, b) => a.djtDate > b.djtDate)

      if (childrenOfThisParent?.length>0) {
        // this current djt has kids
        childrenOfThisParent.forEach( child => {
          filteredDjts.push(child)
          const indexOfRemovedChild = djtChildren.findIndex(object => {
            return object.id === child.id
          })
          djtChildren.splice(indexOfRemovedChild, 1)
        })
      }
    })

    // for each of the orphaned children, re-insert them in the correct order
    djtChildren.forEach( djtChild => {
      djtChild.waitingForParent = true

      // did we find somewhere to put it?
      let insertedDjt = false
      // if the orphaned child djtDate is <= filtered djt date && the 
      // filteredDjt is not a duplciate, insert
      for (const [djtIndex, filteredDjt] of filteredDjts.entries()) {
        
        if (djtChild.djtDate>=filteredDjt.djtDate && filteredDjt.isDuplicate!==true) {
          // console.log('THIS IS WHERE IT GETS INSERTED')
          filteredDjts.splice(djtIndex, 0, djtChild)
          insertedDjt = true
          break
        }
      }
      // if we made it here we have a straggler that didn't match any of the current
      if (insertedDjt===false) {
        const filteredDjtsInsertAt = filteredDjts.length+1
        filteredDjts.splice(filteredDjtsInsertAt, 0, djtChild)
      }
    })
    
    setLoadMoreDisabled(false)
    if (allDjtData?.nextToken===null || allDjtData?.length===0 || allDjtData?.length<searchLimit ) {
      setLoadMoreDisabled(true)
    }

    // This needs to be revisited
    setDjtSearchResultsNextToken(allDjtData?.nextToken)
    // let clearExistingResults = (optClearAll===true) ? true : false

    setSortedFilteredDjts(filteredDjts)

    // check if everything loaded and return loaded status
    return(true)
  }

  // initial search, hard coded for recent submissions, show pending approvals
  useEffect(() => {
    if (currentDivisionId && pendingSubmissionStatusId) {
      // setSortedFilteredDjts(null)
      setValue('searchDate', null)
      setValue('endDate', null)
      setValue('selectCustomer', null)
      // setLoadMoreDisabled(false)
      setLoadMoreDisabled(true)
      searchDjts(
        {
          djtStatusId: pendingSubmissionStatusId, 
          divisionId: currentDivisionId, 
          djtClientId: '', 
          djtDate: '', 
          djtEndDate: '', 
          limit: searchLimit, 
          nextToken: null 
        }, showAllResults, true)
        .catch(console.error
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDivisionId, pendingSubmissionStatusId])

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleLoadMore = () => {

    searchDjts({
      djtStatusId: searchStatusTypeId,
      divisionId: currentDivisionId, 
      djtClientId: clientIdSelected, 
      djtDate: djtStartDate, 
      djtEndDate: djtEndDate, 
      limit: searchLimit, 
      nextToken: djtSearchResultsNextToken 
    }, showAllResults).catch(console.error);
  }

  const handleShowAllToggle = async (showAll) => {
    // clear out the existing serach results

    // console.log(' <<<<<< showAll in load more:', showAll)
    // toggle the data getting sent down to results
    setShowAllResults(showAll)
    // console.log('djtSearchProps before: ', djtSearchProps)
    djtSearchProps.nextToken = null
    setLoadMoreDisabled(true)
    // console.log('djtSearchProps after: ', djtSearchProps)
    searchDjts(djtSearchProps, showAll, true)
  }

  const filteredDivisions = (isGlobalAdmin, supervisorMultiDivisions, divisions) => {
    // If user is a global admin, return all divisions
    if (isGlobalAdmin) return divisions;
  
    // If user is not a global admin, filter divisions by supervisorMultiDivisions
    if (supervisorMultiDivisions.length > 0) {
      return divisions.filter(division =>
        supervisorMultiDivisions.includes(division.id)
      );
    }
  
    // Otherwise, return an empty array (no access to divisions)
    return [];
  };
  
  // Example usage
  // const isGlobalAdmin = false; // Change to true to test global admin access
  // const result = filteredDivisions(isGlobalAdmin, supervisorMultiDivisions, divisions);
  
  // console.log(result);

  const DivisionsDropdown = ({ isGlobalAdmin, multiDivisions, divisions }) => {
    const options = filteredDivisions(isGlobalAdmin, multiDivisions, divisions);
  
    return (
      <FormSelectSimple
        key='select_division'
        register={register}
        control={control}
        errors={errors}
        isRequired={true}
        rules={{ required: "A job ticket division is required" }}
        fieldname="selectDivision"
        fieldlabel="Select Job Ticket Division"
        placeholder={'Select job ticket division or start typing'}
        optionsArray={Array.isArray(options) ? options.map(({ defaultQBClass, id }) => ({
          label: defaultQBClass,
          value: id,
        })) : []}
        onChange={(e) => handleDivisionChanged(e)}
        selectedoption={selectedDivision}
      />
    );
  };

  DivisionsDropdown.propTypes = {
    isGlobalAdmin: PropTypes.bool.isRequired,
    multiDivisions: PropTypes.arrayOf(PropTypes.string).isRequired,
    divisions: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  return (

    <Container as="form">

      <SearchDailyJobTicketsByClientByDate 
        control={control}
        register={register}
        endDateDisabled={endDateDisabled}
        onChange={(event, action) => onCustomerChanged(event, action)}
        startDate={startDate}
        startDateOnChange={(date) => {
          setStartDate(date);
          // onStartDateChanged(formatDateFn(date), "selected");
          console.info('GETTING DATE: ', date)
          onStartDateChanged(formatDateFn(date));
        }}
        endDate={endDate}
        endDateOnChange={(date) => {
          setEndDate(date);
          // onEndDateChanged(formatDateFn(date), "selected");
          onEndDateChanged(formatDateFn(date));
        }}
        clientSelected={clientSelected}
        djtStartDate={djtStartDate}
        djtEndDate={djtEndDate}
        clientIconOnClick={() => {
          setClientIdSelected(null);
          setClientSelected(null);
          const action = {action: "clear", removedValues: [clientSelected], name: "selectCustomer"};
          onCustomerChanged(null, action);
        }}
        startDateIconOnClick={() => {
          setStartDate(new Date())
          setDjtStartDate(null);
          onStartDateChanged(null);
        }}
        endDateIconOnClick={() => {
          setEndDate(new Date())
          setDjtEndDate(null);
          onEndDateChanged(null);
        }}
      />

      {(currentUserIsGlobalAdmin || supervisorMultiDivisions?.length>0) && <ModuleBox>
        <Box w={'50%'}>
          <DivisionsDropdown 
            isGlobalAdmin={currentUserIsGlobalAdmin}
            multiDivisions={supervisorMultiDivisions}
            divisions={divisions}
          />
        </Box>
      </ModuleBox>}

      <ResultsTableDailyJobTicketsByClientByDate
        djtSearchResults={sortedFilteredDjts}
        loadMoreOnClick={() => handleLoadMore()}
        loadMoreDisabled={loadMoreDisabled}
        handleRefresh={searchDjts}
        handleShowAllToggle={handleShowAllToggle}
        currentUserId={currentUserId}
        currentDivisionId={currentDivisionId}
        divisionHasOneClient={divisionHasOneClient}
        handleTabChange={handleTabChange}
        showSearchResutlsTable={showSearchResutlsTable}
        isReviewing={isReviewing}
        approvalRequiredCount={approvalRequiredCount}
        doShowAllSupervisorsSwitch={doShowAllSupervisorsSwitch}
        showDownloadPDFButton={showDownloadPDFButton}
        // handleGeneratePdf={handleGeneratePdf}
        handleGeneratePdf={generatePdf}
        currentTab={currentSelectedTab}
        // hasSelectedDivision={hasSelectedDivision}
        register={register}
        errors={errors}
      />

      <Box mt='50px'></Box>

    </Container>


  );
}