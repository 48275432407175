import { getDivisionIdByLoginEmail } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';

import { generateClient } from "aws-amplify/api";
const client = generateClient();

export const fetchTeamMemberDataByLoginEmail = async (email) => {
  try {
    const gqlParams = {
      loginEmail: email
    }

    console.log('fetchTeamMemberDataByLoginEmail gqlParams: ', gqlParams);

    const response = await client.graphql({
      query: getDivisionIdByLoginEmail,
      variables: gqlParams
    })

    console.log('fetchTeamMemberDataByLoginEmail response: ', response);
    
    const results = response.data.teamMemberByLoginEmail.items[0];
    return results;
  } catch (err) {
    return('fetchTeamMemberDataByLoginEmail error: ', [err]);
  }
}

fetchTeamMemberDataByLoginEmail.propTypes = {
  email: PropTypes.string.isRequired,
};
