/* eslint-disable react/prop-types */
import { useEffect, useState, useContext, Fragment } from 'react';
// import PropTypes from 'prop-types';
import { set, useForm } from 'react-hook-form';
import {
  Container,
  Box,
  Text,
  Flex,
  Spacer,
  Spinner,
  Center,
  Table,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
  HStack,
  useDisclosure,
  useBreakpointValue,
  TableContainer,
  Tabs, 
  TabList, 
  TabPanels, 
  Tab, 
  TabPanel,
  Tooltip,
  Grid,
  IconButton,
  Badge,
} from "@chakra-ui/react";

// globals
import { AppContext } from '../AppContext.jsx';

// Icons
import { AvailableIcons } from '../Icons/AvailableIcons.jsx';

// graphQL queries
import { fetchUploadedReceiptsByStatus } from '../graphqlCompnents/Statement/fetchUploadedReceiptsByStatus.jsx';
import { fetchTransactionsByStatus } from '../graphqlCompnents/Statement/fetchTransactionsByStatus.jsx';
import { fetchQuickBooksExpenseCategories } from '../graphqlCompnents/Statement/fetchQuickBooksExpenseCategories.jsx';
import { fetchAllQuickBooksVendorNamesIds } from '../graphqlCompnents/Statement/fetchAllQuickBooksVendorNamesIds.jsx';
import { fetchCapitalOneInstantNotificationsByStatus } from '../graphqlCompnents/Statement/fetchCapitalOneInstantNotificationsByStatus.jsx';
import { getExpenseCategories } from '../QuickBooks/getExpenseCategories.jsx';
import { fetchAllChartOfAccounts } from '../graphqlCompnents/Statement/fetchAllChartOfAccounts.jsx';
import { addOrUpdateChartOfAccounts } from '../graphqlCompnents/Statement/addOrUpdateChartOfAccounts.jsx';
import { addCreditCardTeamMemberMap } from '../graphqlCompnents/Statement/addCreditCardTeamMemberMap.jsx';
import { updateCreditCardTeamMember } from '../graphqlCompnents/Statement/updateCreditCardTeamMember.jsx';
import { fetchAllCreditCardTeamMemberMaps } from '../graphqlCompnents/Statement/fetchAllCreditCardTeamMemberMaps.jsx';
import { fetchAllTeamMembers } from '../graphqlCompnents/Statement/fetchAllTeamMembers.jsx';
import { fetchAccountsByTeamMember } from '../graphqlCompnents/Statement/fetchAccountsByTeamMember.jsx';
import { updateCreditCardTransactionReceipt } from '../graphqlCompnents/Statement/updateCreditCardTransactionReceipt.jsx';
import { updateTransaction } from '../graphqlCompnents/Statement/updateTransaction.jsx';
import { fetchTransactionsByDateRange } from '../graphqlCompnents/Statement/fetchTransactionsByDateRange.jsx';

// QUickBooks Auth
import { QuickBooksAuth } from '../QuickBooks/QuickBooksAuth.jsx';
import { QBLoginLogoutButtons } from '../QuickBooks/QBLoginLogoutButtons.jsx';
import { getChartOfAccounts } from '../QuickBooks/getChartOfAccounts.jsx';

// Lambdas
import { createQBVendor } from '../QuickBooks/createQBVendor.jsx';
import { getClasses } from '../QuickBooks/getClasses.jsx';
import { pushStatementToQuickBooks } from '../QuickBooks/pushStatementToQuickBooks.jsx';
// graphql
import { addQuickBooksVendor } from '../graphqlCompnents/Statement/addQuickBooksVendor.jsx';
import { addVendorMap } from '../graphqlCompnents/Statement/addVendorMap.jsx';
// components
import { syncQBVendors } from '../QuickBooks/syncQBVendors.jsx';

// structural components
import { ModuleBox } from '../Structural/ModuleBox.jsx';
import { ModuleDrawer } from '../Structural/ModuleDrawer.jsx';

// drawer components
import { EditTransaction } from './EditTransaction.jsx';
import { EditTransactionVendor } from './EditTransactionVendor.jsx';

// external components
// import { VendorMap } from './VendorMap.jsx';

// form components
import { TextInput } from '../Form/Input/TextInput.jsx';
import { FormSelectSimple } from '../Form/Select/FormSelectSimple.jsx';

// external components
import { StatementUploader } from './StatementUploader.jsx';
// Buttons
import { ButtonQuaternaryWithIcon } from '../Form/Button/ButtonQuaternaryWithIcon.jsx';
import { ButtonPrimaryPlain } from '../Form/Button/ButtonPrimaryPlain.jsx';
import { ButtonSecondaryPlain } from '../Form/Button/ButtonSecondaryPlain.jsx';

// external functions
import { normalizeMerchantName, findMatchesWithQbVendors } from '../functions/transactionMatching.jsx';
import { formatCurrency } from '../functions/currency.jsx';
import { spellOutDate } from '../functions/dateTime.jsx';
import { truncateString } from '../functions/strings.jsx';
import { toTitleCase } from '../functions/strings.jsx';

// Modals
import { ReceiptImageViewerModal } from '../Modal/ReceiptImageViewerModal.jsx';

import { getSignedUrlForFile } from '../custom-hooks/useSignedUrlForFile.jsx';

// SpkPlaidCreditCardTransactionTbl
// SpkStatementVendorMapTypeTbl
// SpkStatementQuickBooksVendorTbl
// SpkStatementVendorMapTbl

export const ReceiptTransactionManagement = () => {

  // grab global context values
  const { store } = useContext(AppContext);

  // console.info('STORE: ', store)
  const adminName = store?.userData?.name||'';
  // get the current environment sandbox || production
  const qbEnv = store?.qbEnvironment || 'sandbox';

  const currentUserIsGlobalAdmin = store?.userData?.isGlobalAdmin||false;

  const currentTeamMemberId = store?.userData?.id||'';

  const usersGroups = store?.userData?.groups||[];
  let userIsCreditCardManager = false;
  if (usersGroups.includes('credit_card_receipt_manager') || usersGroups.includes('credit_card_manager')) {
    userIsCreditCardManager = true;
  }
  

  // set the current users team member id
  // const currentTeamMemberId = store?.userData?.id

  // --- REACT-HOOK-FORM ---
  const { control, register, handleSubmit, getValues, setValue, setError, clearErrors, formState: { errors, isSubmitting } } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    // reValidateMode: 'onBlur',
    defaultValues: {}
  });
  
  const onError = (errors) => {
    console.log('Form errors:', errors);
  };

  const [ isPublishingToQuickBooks, setIsPublishingToQuickBooks ] = useState(false);

  // QUICKBOOKS
  const [ loadingQBClasses, setLoadingQBClasses] = useState(true);
  const [ qbClasses, setQBClasses ] = useState([]);
  const [tokenObj, setTokenObj] = useState(null);
  useEffect(() => {
    const storedToken = localStorage.getItem('accessToken');
    if (storedToken) {
      setTokenObj(JSON.parse(storedToken));  // Load token from local storage on component mount
    }
  }, []);

  const [ qbVendorsSynced, setQBVendorsSynced ] = useState(false);
  
  const updateTokenObj = (newToken) => {
    if (!newToken) return;
    console.warn('Setting token object:', newToken);
    newToken.accessToken = newToken.access_token;
    setTokenObj(newToken);

    const loadQBClasses = async (token, environment) => {
      if (token && environment) {
        setLoadingQBClasses(true);
        try {
          const qbClassesData = await getClasses(token, environment);
          setQBClasses(
            qbClassesData.map((qbclass) => ({
              value: qbclass.Id,
              label: qbclass.Name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase(),
            }))
          );
        } catch (error) {
          console.error('Error loading QB classes:', error);
        } finally {
          console.log('Finished loading QB classes');
          setLoadingQBClasses(false);
        }
      }
    };

    const handleSyncQuickBooksVendors = async (token, environment) => {
      if (token && environment) {
        setQBVendorsSynced(false)
        console.log('Sync QuickBooks Vendors')
        try {
          const props = {
            token: token, 
            environment: environment
          }
          // const response = await syncQBVendors(props)
          const response = syncQBVendors(props)
            .then(newVendors => {
              console.log(`Successfully synced ${newVendors.length} new vendors.`);
            })
            .catch(error => {
              console.error('Vendor sync failed:', error);
            });
          console.warn(' ----- syncQBVendors response: ', response)
          
        } catch (error) {
          console.error('Error syncing QB vendors:', error);
        } finally {
          console.log('Finished syncing QB vendors');
          setQBVendorsSynced(true)
        }
      }
    }

    if (newToken?.accessToken) {
      console.warn('loading qbClasses: ', newToken.accessToken)
      loadQBClasses(newToken.accessToken, qbEnv);
      handleSyncQuickBooksVendors(newToken.accessToken, qbEnv);
    }
  }

  // QUICKBOOKS

  // ----- EDIT TRANSACTION DRAWER -----
  const { isOpen: isEditTransactionOpen , onOpen: onEditTransactionOpen, onClose: editTransactionClose } = useDisclosure()
  // ----- EDIT TRANSACTION VENDOR DRAWER -----
  const { isOpen: isEditTransactionVendorOpen , onOpen: onEditTransactionVendorOpen, onClose: editTransactionVendorClose } = useDisclosure()
  // ----- GALLERY MODAL -----
  const { isOpen: isReceiptImageViewerModalOpen, onOpen: onReceiptImageViewerModalOpen, onClose: onReceiptImageViewerModalClose } = useDisclosure();

  const [ galleryImages, setGalleryImages ] = useState([]);
  const [ galleryTransaction, setGalleryTransaction ] = useState({});
  const handleOpenGallery = (imagesArray, transaction) => {
    console.warn('openGallery images: ', imagesArray);
    console.warn('openGallery transaction: ', transaction);
    setGalleryImages(imagesArray);  // Set gallery images first
    setGalleryTransaction(transaction);  // Set gallery transaction data
  };
  
  // useEffect to watch galleryImages and open modal only when it's set
  useEffect(() => {
    if (galleryImages.length > 0) {
      console.log('Gallery images set, opening modal...');
      onReceiptImageViewerModalOpen();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [galleryImages]); // Depend on galleryImages, so it triggers only when set

  const handleReceiptImageViewerModalClose = () => {
    setGalleryImages([]);  // Clear gallery images
    setGalleryTransaction({});  // Clear gallery transaction data
    onReceiptImageViewerModalClose();
  };

  const [ uploadedReceipts, setUploadedReceipts ] = useState([]);
  const [ uploadedUnmatchedReceipts, setUploadedUnmatchedReceipts ] = useState([]);
  const [ syncedTransactions, setSyncedTransactions ] = useState([]);
  const [matchResults, setMatchResults] = useState({
    matches: [],
    unmatchedFromArray1: [],
    unmatchedFromArray2: [],
  });

  const [ refreshIndex, setRefreshIndex ] = useState(0);
  const handleRefresh = () => {
    setMatchResults({
      matches: [],
      unmatchedFromArray1: [],
      unmatchedFromArray2: [],
    });
    
    setRefreshIndex(prev => prev + 1); // Increment to trigger useEffect
    setValue('searchInput', '')
  };

  const [ expenseCategories, setExpenseCategories ] = useState([])
  // const [ selectedExpenseCategory, setSelectedExpenseCategory ] = useState('')
  // const [ isIFTATracked, setIsIFTATracked ] = useState(false)
  // Function to get expense category based on qbId
  const getExpenseCategory = (qbId) => {
    // console.log('qbId: ', qbId)
    // console.log('expenseCategories: ', expenseCategories)
    // if (!expenseCategories.length) return '';
    // Find the expense that matches the given qbId
    const expense = expenseCategories.find((category) => category.value === qbId);

    // If no expense is found, return null or a default value
    if (!expense) return '';

    // Return the formatted object based on the found expense
    return {
      value: expense.value,
      label: expense.label,
      isIFTATracked: expense?.isIFTATracked === true ? true : false,
    };
  };

  const [qbVendors, setQBVendors] = useState([]);
  // const [loading, setLoading] = useState(false);
  // const [errorMsg, setErrorMsg] = useState(null);

  useEffect(() => {
    let isMounted = true; // Track if the component is still mounted

    const fetchQuickBooksVendors = async () => {
      try {
        // setLoading(true);
        // setErrorMsg(null); // Reset any previous error
        const vendors = await fetchAllQuickBooksVendorNamesIds({ startsWith: '', limit: 6000 });
        
        if (isMounted) {
          // Normalize the vendor names and add a `normalizedName` property
          const normalizedVendors = vendors?.items.map(vendor => ({
            ...vendor,
            normalizedName: normalizeMerchantName(vendor.qbDisplayName)
          }));

          // console.log('Normalized QuickBooks Vendors: ', normalizedVendors);
          setQBVendors(normalizedVendors || []);
        }
      } catch (err) {
        if (isMounted) {
          console.error('Failed to fetch QuickBooks vendors:', err);
          // setErrorMsg(err.message || 'Failed to fetch vendors');
        }
      } finally {
        if (isMounted) {
          // setLoading(false);
        }
      }
    };

    if (qbEnv) {
      fetchQuickBooksVendors();
    }

    // Cleanup function to set isMounted to false
    return () => {
      isMounted = false;
    };
  }, [qbEnv, refreshIndex]);

  const [ capitalOneTransactionsNeedingReceipt, setCapitalOneTransactionsNeedingReceipt ] = useState([])
  useEffect(() => {
    // Define the function to fetch and process transactions
    const fetchUploadedReceipts = async () => {
      const results = await fetchCapitalOneInstantNotificationsByStatus('needs-receipt', 750);

      // Filter out entries with purchaseDate before "2024-10-28"
      const cutoffDate = new Date("2024-11-01");
      const filteredResults = results.filter(item => {
        const purchaseDate = new Date(item.purchaseDate);
        return purchaseDate >= cutoffDate; // Keep entries on or after cutoff
      });

      console.log('fetchCapitalOneInstantNotificationsByStatus results: ', filteredResults);
      // Sort the filtered array by purchaseDate in descending order
      const sortedArray = filteredResults.sort((a, b) => {
        const dateA = new Date(a.lastFour);
        const dateB = new Date(b.lastFour);
        return dateB - dateA; // Descending order: latest date first
      });

      console.log('fetchCapitalOneInstantNotificationsByStatus results: ', sortedArray);
      setCapitalOneTransactionsNeedingReceipt(sortedArray);
    };

    // Trigger fetch only if qbEnv is true
    // qbEnv && fetchUploadedReceipts();
  }, [qbEnv, refreshIndex]);

  useEffect(() => {
    const fetchUploadedReceipts = async () => {
      try {
        const [readyResults, fixedIssueResults, hasIssueResults] = await Promise.all([
          fetchUploadedReceiptsByStatus({status: 'ready-for-approval', limit: 750}),
          fetchUploadedReceiptsByStatus({status: 'fixed-issue', limit: 100}),
          fetchUploadedReceiptsByStatus({status: 'has-issue', limit: 100}),
        ]);
        
        // const results = [...readyResults, ...issueResults];
        const results = [
          ...(readyResults || []), 
          ...(fixedIssueResults || []),
          ...(hasIssueResults || [])
        ];
        console.warn('fetchUploadedReceiptsByStatus results: ', results);
        
        // Iterate through results to get signed URLs for each item
        const resultsWithSignedUrls = await Promise.all(
          results?.map(async (item) => {
            const uploadedDocuments = item?.uploadedDocuments?.items || [];
            // Fetch signed URLs for each document in parallel
            const signedUrls = await Promise.all(
              uploadedDocuments.map(async (doc) => {
                const { identityId, key, bucket } = doc?.uploadedDocument || {};
                if (identityId && key && bucket) {
                  try {
                    const url =  await getSignedUrlForFile({
                      bucketName: bucket,
                      identityId: identityId,
                      fileName: key,
                      fileSecurity: 'protected',
                    });
                    return url;
                  } catch (error) {
                    console.error(`Error fetching signed URL for document ${key}:`, error);
                    return null; // Skip if the URL fetch fails
                  }
                }
                return null; // Skip if any value is missing
              })
            );
  
            // Attach signed URLs to the current item, filtering out any null results
            return {
              ...item,
              signedUrls: signedUrls.filter(Boolean),
            };
          })
        );
        console.info('resultsWithSignedUrls: ', resultsWithSignedUrls);
  
        // Store processed data with signed URLs in state
        setUploadedReceipts(resultsWithSignedUrls);
      } catch (error) {
        console.error('Error fetching uploaded receipts or signed URLs:', error);
      }
    };
  
    if (currentUserIsGlobalAdmin) {
      fetchUploadedReceipts();
    }
  }, [currentUserIsGlobalAdmin, refreshIndex]);

  useEffect(() => {
    const fetchUploadedUnmatchedReceipts = async () => {
      try {
        const [preemptiveResults, withnotificationResuts] = await Promise.all([
          fetchUploadedReceiptsByStatus({status: 'preemptive', limit: 750}),
          fetchUploadedReceiptsByStatus({status: 'with-notification', limit: 750}),
        ]);
        
        // const results = [...readyResults, ...issueResults];
        const results = [
          ...(preemptiveResults || []), 
          ...(withnotificationResuts || [])
        ];
        console.warn(' +++++ fetchUploadedUnmatchedReceiptsByStatus: ', results);
        
        // Iterate through results to get signed URLs for each item
        const resultsWithSignedUrls = await Promise.all(
          results?.map(async (item) => {
            const uploadedDocuments = item?.uploadedDocuments?.items || [];
            // Fetch signed URLs for each document in parallel
            const signedUrls = await Promise.all(
              uploadedDocuments.map(async (doc) => {
                const { identityId, key, bucket } = doc?.uploadedDocument || {};
                if (identityId && key && bucket) {
                  try {
                    const url =  await getSignedUrlForFile({
                      bucketName: bucket,
                      identityId: identityId,
                      fileName: key,
                      fileSecurity: 'protected',
                    });
                    return url;
                  } catch (error) {
                    console.error(`Error fetching signed URL for document ${key}:`, error);
                    return null; // Skip if the URL fetch fails
                  }
                }
                return null; // Skip if any value is missing
              })
            );
  
            // Attach signed URLs to the current item, filtering out any null results
            return {
              ...item,
              signedUrls: signedUrls.filter(Boolean),
            };
          })
        );
        console.info('resultsWithSignedUrls: ', resultsWithSignedUrls);
  
        // Store processed data with signed URLs in state
        setUploadedUnmatchedReceipts(resultsWithSignedUrls);
      } catch (error) {
        console.error('Error fetching uploaded receipts or signed URLs:', error);
      }
    };
  
    if (currentUserIsGlobalAdmin) {
      fetchUploadedUnmatchedReceipts();
    }
  }, [currentUserIsGlobalAdmin, refreshIndex]);


  const [ transactionsByCard, setTransactionsByCard ] = useState([]);
  const [ mappedTeamMembers, setMappedTeamMembers ] = useState([]);

  {/* Pending Transactions */}
  useEffect(() => {

    const transactionDataByCardNumber = (transactions, mappedMembers = []) => {
      // Create a lookup map for quick matching of card numbers to team members
      const memberMap = Array.isArray(mappedMembers)
        ? mappedMembers.reduce((acc, member) => {
            if (member.teamMember) { // Ensure teamMember is not null
              acc[member.cardLastFour] = {
                firstName: member.teamMember.firstName || '',
                lastName: member.teamMember.lastName || '',
                goesBy: member.teamMember.goesBy || '',
              };
            }
            return acc;
          }, {})
        : {};
    
      // Group transactions by their accountNumber (card number)
      const groupedTransactions = transactions.reduce((acc, transaction) => {
        const accountNumber = transaction.accountNumber;
    
        if (accountNumber) {
          if (!acc[accountNumber]) {
            acc[accountNumber] = {
              accountNumber,
              count: 0,
              transactions: [],
              teamMemberName: 'Unknown Team Member', // Default
            };
    
            // Add team member info if available
            const teamMember = memberMap[accountNumber];
            if (teamMember) {
              acc[accountNumber].teamMemberName = teamMember.goesBy
                ? `${teamMember.goesBy} ${teamMember.lastName}`
                : `${teamMember.firstName} ${teamMember.lastName}`;
            }
          }
    
          // Add transaction to the grouped list and increment the count
          acc[accountNumber].transactions.push(transaction);
          acc[accountNumber].count++;
        }
    
        return acc;
      }, {});
    
      // Convert the grouped object into an array sorted by count (descending)
      return Object.values(groupedTransactions).sort((a, b) => b.count - a.count);
    };

    const enrichTransactionsWithTeamMember = (transactions, mappedMembers = []) => {
      // Create a lookup map for quick matching
      const memberMap = Array.isArray(mappedMembers)
        ? mappedMembers.reduce((acc, member) => {
            if (member.teamMember) { // Only process non-null teamMember
              acc[member.cardLastFour] = {
                firstName: member.teamMember.firstName || '',
                lastName: member.teamMember.lastName || '',
                goesBy: member.teamMember.goesBy || ''
              };
            }
            return acc;
          }, {})
        : {};
    
      // Enrich transactions with matching team member details
      return transactions.map(transaction => {
        const teamMember = memberMap[transaction.accountNumber];
        return {
          ...transaction,
          teamMember: teamMember || null,
        };
      });
    };

    const fetchSyncedTransactions = async () => {
      try {
        const results = await fetchTransactionsByStatus('pending_match', 750);
        console.info(' ##### fetchSyncedTransactions results: ', results);
    
        // Enrich transactions with teamMember details
        const enrichedTransactions = enrichTransactionsWithTeamMember(results, mappedTeamMembers || []);
        console.log(' ***** Enriched Transactions: ', enrichedTransactions);
    
        setSyncedTransactions(enrichedTransactions);
    
        // Get transactions grouped by card with detailed rows
        const resultsByCard = transactionDataByCardNumber(enrichedTransactions, mappedTeamMembers || []);
        console.warn(' ***** resultsByCard: ', resultsByCard);
    
        setTransactionsByCard(resultsByCard); // Update state with the grouped results
      } catch (error) {
        console.error('Error fetching synced transactions:', error);
      }
    };

    // Only fetch if conditions are met
    if ((currentUserIsGlobalAdmin || userIsCreditCardManager) && mappedTeamMembers?.length > 0) {
      fetchSyncedTransactions();
    }
    console.log('mappedTeamMembers: ', mappedTeamMembers);
  }, [currentUserIsGlobalAdmin, userIsCreditCardManager, refreshIndex, mappedTeamMembers]);

  useEffect(() => {
    if (uploadedReceipts.length && syncedTransactions.length && expenseCategories.length && qbVendors.length && refreshIndex) {
      const config = {
        amountField1: 'capitalOneInstantNotification.purchaseAmount',
        amountField2: 'amount',
        accountField1: 'capitalOneInstantNotification.lastFour',
        accountField2: 'accountNumber',
        dateField1: 'capitalOneInstantNotification.purchaseDate',
        dateField2: 'date',
        merchantField1: 'capitalOneInstantNotification.purchaseMerchant',
        merchantField2: 'merchantEntityName',

        amountWeight: 15,               // Reduced to lower the impact of amount matching
        amountTolerancePercentage: 20,  // Allow for tipping up to 20% of the amount (only applied on categoryIds for restaurants)
        accountWeight: 25,              // Keep accountWeight high for strict matching
        dateWeight: 10,                 // Slightly lower to emphasize merchant over date
        dateProximity: 3,               // Date proximity as 3 days for vendor to batch transactions
        merchantWeight: 50,             // Significantly increased to prioritize merchant similarity

        minimumScoreThreshold: 5,      // Keep minimum threshold low to allow for more matches

        // Separate similarity configurations for merchant and vendor matching
        minimumMerchantSimilarity: 0.8,  // Keep this high for merchantEntityName matching
        minimumQbVendorSimilarity: 0.6,  // New value for qbVendor matching to loosen it up

        // Array of category IDs representing Plaid categories for 
        // [ { "S" : "Food and Drink" }, { "S" : "Restaurants" } ] 13005000
        // [ { "S" : "Food and Drink" }, { "S" : "Bar" } ] 13001000
        // [ { "S" : "Travel" }, { "S" : "Lodging" }, { "S" : "Hotels and Motels" } ] 22012003
        // Find category for hotel and lodging.
        categoryIds: ["13005000", "13001000"], 
      };

      // Find matches using the utility function and update state
      const result = findMatchesWithQbVendors(uploadedReceipts, syncedTransactions, qbVendors, config);
      console.log('magic results: ', result);
      // const result = findMatches(uploadedReceipts, syncedTransactions, config);
      // console.log('magic results: ', result);
      setMatchResults(result);
    }
    console.warn(' ***** uploadedReceipts: ', uploadedReceipts);
    console.warn(' ***** syncedTransactions: ', syncedTransactions);
    console.warn(' ***** expenseCategories: ', expenseCategories);
    console.warn(' ***** qbVendors: ', qbVendors);
  }, [uploadedReceipts, syncedTransactions, expenseCategories, qbVendors, refreshIndex]);

  const handleScroll = (e) => {
    // if (!nextToken) return;
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight) {
      // console.log('reached the bottom of the scrollbox: ', nextToken);
      // setNextNextToken(nextToken)
    }
  };

  // const showResultsSpinner = false;
  // const [ currentTab, setCurrentTab ] = useState(0)
  // const [ currentTabName, setCurrentTabName ] = useState('Inbox')
  // const [ previousTabName, setPreviousTabName ] = useState('Inbox')
  // const handleTabChange = async (tab) => {
    
  //   // setCurrentTab(tab)
  //   let currentTabNames = ['Ready', 'Chart of Accounts']
  //   // setCurrentTabName(currentTabNames[tab])

  //   if (currentTabNames[tab] !== previousTabName) {
  //     setPreviousTabName(currentTabNames[tab])
  //   }
  // }

  const searchHeaderTextClass = useBreakpointValue({
    sm: 'dark-sfpro-heading-2',
    md: 'dark-sfpro-heading-2',
    lg: 'dark-sfpro-heading-1',
    xl: 'dark-sfpro-heading-1',
    '2xl': 'dark-sfpro-heading-1'
  })

  const [ selectedExpenseCategoryOption, setSelectedExpenseCategoryOption ] = useState('');
  const updateSelectedExpenseCategory = (event, index) => {
    console.warn('event: ', event)
    console.warn('index: ', index)
    // setIsIFTATracked(event?.isIFTATracked===true ? true : false)
    setSelectedExpenseCategoryOption(event||'');
    setValue('selectExpenseCategory', event.value||'')
    clearErrors('selectExpenseCategory')
  }

  const [ currentEditTransaction, setCurrentEditTransaction ] = useState(null)
  const [ currentEditTransactionIndex, setCurrentEditTransactionIndex ] = useState(null)
  const handleOpenEditTransaction = (transaction, index) => {
    // console.log('transaction: ', transaction)
    // console.log('index: ', index)
    setCurrentEditTransaction(transaction)
    setCurrentEditTransactionIndex(index)
    // console.log('currentEditTransaction token: ', tokenObj)
  }

  useEffect(() => {
    if (currentEditTransaction && tokenObj) {
      onEditTransactionOpen();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[currentEditTransaction, tokenObj])

  const onEditTransactionClose = () => {
    resetMatches();
    setCurrentEditTransaction(null);
    // handleRefresh()
    editTransactionClose();
  }

  const generateProcessedRow = (match, index, isLoggedInQB) => {
    // Pre-process each value
    let transactionType = 'instant';
    if (match.capitalOneInstantNotification) {
      // console.log('has instant notification data:', match);
    }
    if (match.plaidCreditCardTransactionId) {
      transactionType = 'direct';
      // console.log('has transaction id:', match);
    }
    // console.log('match: ', match);

    let hasUnit = false;
    if (match.iftaFuelTaxTrackId || match.unitId || match.units?.items?.length > 0) {
      hasUnit = true;
    }

    const matchedDate = spellOutDate(match.matchedItem.date, 'apple-ny');
    const merchantEntityName = match.matchedItem.merchantEntityName

    // Is this needed any longer?
    const matchedStore = match?.capitalOneInstantNotification?.purchaseMerchant||match.matchedItem.store||'Unknown';
    const merchantName = truncateString(matchedStore, 19);

    const receiptNotes = match?.addedNotes||'';
    const matchedMerchantName = truncateString(match.matchedItem.merchantEntityName,19);
    // console.warn('match?.qbVendor?.qbDisplayName: ', match?.qbVendor?.qbDisplayName)

    // When qbSupplierMatched is false manual merging of QuickBooks supplier is needed.
    // console.warn(' ----- match?.qbVendor?.qbDisplayName: ', match?.qbVendor?.qbDisplayName);
    // console.warn(' ----- match?.qbVendor: ', match?.qbVendor);
    // console.warn('merchantEntityName: ', merchantEntityName)
    let qbSupplierMatched = false;
    let qbSupplierName = '';
    if (match?.qbVendor && match?.qbVendor?.qbDisplayName?.toLowerCase() === merchantEntityName?.toLowerCase()) {
      qbSupplierMatched = true;
      qbSupplierName = merchantEntityName;
      // console.warn(' ----- MATCHED! : ', qbSupplierName);
    } else {
      qbSupplierName = merchantEntityName;
      // console.warn(' ----- NOT MATCHED BUT SETTING! : ', qbSupplierName);
    }

    // console.warn('qbSupplierMatched: ', qbSupplierMatched)
    match.qbSupplierMatched = qbSupplierMatched;
    match.qbSupplierName = qbSupplierName;
    
    const qbSupplierNameTruncated = truncateString(qbSupplierName, 19);
    const receiptDivision = match.division.prettyname||'';
    const signedUrls = match.signedUrls||[];
    

    const receiptHasIssue = match?.hasIssue||false;
    let infoIconColor = 'var(--success-green)';
    if (receiptHasIssue) {
      if (match?.matchStatus === 'fixed-issue') {
        infoIconColor = 'var(--success-green)';
      } else {
        infoIconColor = 'var(--error-red)';
      }
    }
    const receiptIssue = match?.issue||'';
    // const receiptStatus = match?.status||'';
    // console.warn('match: ', match)
    const receiptIssueFormatted = receiptIssue.split('::cr::').map((line, index) => (
      <Box key={index}>
        {line}
        {index < receiptIssue.split('::cr::').length - 1 && <br />}
      </Box>
    ))
    const purchaseAmount = formatCurrency(match?.capitalOneInstantNotification?.purchaseAmount); // Format amount
    const matchedAmount = formatCurrency(match.matchedItem.amount); // Assume formatCurrency is a helper function
    const displayAmount = (purchaseAmount===matchedAmount) ? purchaseAmount : <>{purchaseAmount}<br/>{matchedAmount}</>;

    const lastFour = match?.capitalOneInstantNotification?.lastFour||match.matchedItem.accountNumber||'';
    const cardHolder = mappedTeamMembers?.find(record => record.cardLastFour === lastFour);
    // console.log('cardHolder: ', cardHolder)
    let firstName = '';
    let lastName = '';
    let cardHolderName = ''
    let division = ''
    if (cardHolder?.teamMember) {
      division = cardHolder?.teamMember?.division?.prettyname;
      const name = cardHolder?.teamMember;
      firstName = name.goesBy||name.firstName||'';
      lastName = name.lastName||'';
      cardHolderName = `${firstName} ${lastName}`
    } else {
      cardHolderName = `${lastFour}`;
      // console.log('cardHolder?.teamMember: ', cardHolder)
    }

    const cardHolderNameTruncated = truncateString(cardHolderName, 10);
    const expenseCategoryId = match.expenseCategoryId||'';
    const expenseCategoryOption = getExpenseCategory(expenseCategoryId)||'';
    const expenseCategoryName = truncateString(expenseCategoryOption?.label,19)||'Unknown Expense';
    // const qbVendorMatchScore = match.qbVendorMatchScore.toFixed(4);
    // const plaidMerchantMatchScore = match.matchingScore.toFixed(4);

    // const qbSupplierTooltipLabel = <>Supplier: {qbSupplierName}<br />Score: {qbVendorMatchScore}</>;
    const qbSupplierTooltipLabel = <>Supplier: {qbSupplierName}</>;

    // const qbMerchantTooltipLabel = <>Merchant Name: {matchedMerchantName}<br />Merchant Store: {merchantName}<br />Score: {plaidMerchantMatchScore}</>;
    const qbMerchantTooltipLabel = <>Merchant Name: {matchedMerchantName}</>;
    const cardHolderTooltipLabel = <>User: {cardHolderName}<br />Card: {lastFour}<br />Division: {division}</>;

    const purchaseValue = match?.capitalOneInstantNotification?.purchaseAmount||match.matchedItem.amount;
    const matchedValue = match.matchedItem.amount;
    const difference = Math.abs(purchaseValue - matchedValue);
    const higherAmount = Math.max(purchaseValue, matchedValue);
    const tipPercentage = (difference / higherAmount) * 100;
    const amountTooltipLabel = <>
      Receipt Amount: {purchaseAmount}<br />
      Billed Amount: {matchedAmount}<br />
      {(difference!==0) && `Difference: $${difference.toFixed(2)}`}
      {(difference!==0) && <br />}
      {(difference!==0) && `Tip Percentage: ${tipPercentage.toFixed(2)}%`}
      </>;
    const expenseCategoryDivisionTooltipLabel = <>Expense Category: {expenseCategoryOption?.label}<br />Division: {receiptDivision}</>;

    // Return the entire row element
    let rowBackgroundColor = 'transparent';
    // if (match?.matchStatus === 'fixed-issue') {
    //   rowBackgroundColor = '#115611';
    // }
    // if (match?.matchStatus === 'has-issue') {
    //   rowBackgroundColor = 'var(--dark-gold-tertiary)';
    // }
    return (
      <Fragment key={'transaction-frag_1_'+index}>
        <Tr key={`transaction-row-${index}`}
          _hover={{ 
            // bg: (!qbSupplierMatched) ? '#4B3400' : 'var(--table-row-hover)', 
            cursor: 'pointer' 
          }} // Conditionally set hover bg to gold if qbSupplierName is 'Unknown'
          // published
          bg={rowBackgroundColor} // Change background based on condition
          onClick={() => {
            handleOpenEditTransaction(match, index)
          }}>
          <Td verticalAlign="top" py="10px">
            {/* Matched date on top-left */}
            <Text as="span" className="dark-sfpro-text-2">{matchedDate}</Text>

            {/* Flex wrapper for alignment */}
            <Flex mt="8px" align="center" gap="12px">
              {/* IconButton */}
              {(signedUrls?.length > 0) && (
                <IconButton
                  backgroundColor={'transparent'}
                  h="24px"
                  w="24px"
                  variant="iconOnlyTertiaryTransparent"
                  icon={<AvailableIcons boxSize="20px" iconRef="image"/>}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent event bubbling
                    handleOpenGallery(signedUrls, {date: match.matchedItem.date, 
                      supplier: qbSupplierName, amount: matchedAmount});
                  }}
                />
              )}

              {/* ReceiptNotes icon */}
              {receiptNotes && (
                <Tooltip label={receiptNotes} placement="top" hasArrow>
                  <Box display="inline-flex" alignItems="center">
                    <AvailableIcons boxSize="20px" iconRef="notes" />
                  </Box>
                </Tooltip>
              )}
              {hasUnit && (
                <Tooltip label="Has Unit" placement="top" hasArrow>
                  <Box display="inline-flex" alignItems="center">
                    <AvailableIcons boxSize="20px" iconRef="iconUnits" />
                  </Box>
                </Tooltip>
              )}
              {receiptHasIssue && (
                <Tooltip label={receiptIssueFormatted} placement="top" hasArrow>
                  <Box display="inline-flex" alignItems="center">
                    <AvailableIcons boxSize="20px" iconRef="info" color={infoIconColor}/>
                  </Box>
                </Tooltip>
              )}
            </Flex>
          </Td>
          <Td verticalAlign={'top'} py={'10px'}>
            {(!qbSupplierMatched) ? (
              <Tooltip px={'10px'} label={qbSupplierTooltipLabel} placement="top" hasArrow>
                <Badge variant="solid" borderRadius={'6px'} marginRight={'10px'} paddingTop={'5px'} paddingBottom={'5px'} colorScheme={'green'} textAlign="center" display="inline-block" minWidth="60px" textTransform="none">
                  {qbSupplierNameTruncated}
                </Badge>
              </Tooltip>
            ) : (
              <Tooltip label={qbSupplierTooltipLabel} placement="top" hasArrow>
                <Text as="span" className='dark-sfpro-text-2'>{qbSupplierNameTruncated}</Text>
              </Tooltip>)
            }
          </Td>
          <Td verticalAlign={'top'} py={'10px'}>
            <Tooltip label={qbMerchantTooltipLabel} placement="top" hasArrow>
              <Text as="span" className='dark-sfpro-text-2'>{matchedMerchantName}<br />{toTitleCase(merchantName)}</Text>
            </Tooltip>
          </Td>
          <Td verticalAlign={'top'} py={'10px'}>
            <Tooltip label={expenseCategoryDivisionTooltipLabel} placement="top" hasArrow>
              <Text as="span" className='dark-sfpro-text-2'>{expenseCategoryName}<br />{receiptDivision}</Text>
            </Tooltip>
          </Td>
          <Td verticalAlign={'top'} align='right' py={'10px'}>
            <Tooltip label={amountTooltipLabel} placement="top" hasArrow>
              <Text as="span" className='dark-sfpro-text-2'>{displayAmount}</Text>
            </Tooltip>
          </Td>
          <Td verticalAlign={'top'} py={'10px'}>
            <Tooltip label={cardHolderTooltipLabel} placement="top" hasArrow>
              <Text as="span" className='dark-sfpro-text-2'>{cardHolderNameTruncated}</Text>
            </Tooltip>
          </Td>
          <Td verticalAlign={'middle'}>
            {/* <ButtonSecondaryPlain 
              width="120px"
              type="button"
              name='test'
              value={isPublishingToQuickBooks ? 'Publishing...' : 'Publish'}
              isDisabled={
                isPublishingToQuickBooks===true 
                || !isLoggedInQB 
                || expenseCategoryOption===''
                || (match?.matchStatus === 'has-issue')
              }
              // isDisabled={true}
              onClick={(e) => {
                e.stopPropagation();  // Stop event from bubbling to <Tr>
                handleSubmit(() => {
                  console.log('publishing');
                  handleRowPublish(match)
                }, onError)();
              }}
            /> */}
          </Td>
        </Tr>
        <Tr key={`divider-row-${index}`}><Td colSpan={7} borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr>
      </Fragment>
    );
  };
  
  // CHART OF ACCOUNTS
  const [ dynamodbChartOfAccounts, setDynamoDBChartOfAccounts ] = useState([])
  

  const fetchAndProcessChartOfAccounts = async () => {
    
    // fetch local chart of accounts and store in state
    let allSort = (qbEnv === 'sandbox') ? 'sandbox' : 'all';
    // console.warn('qbEnv: ', qbEnv)
    // console.warn('allSort: ', allSort)
    const ddbChartOfAccounts = await fetchAllChartOfAccounts(allSort)
    setDynamoDBChartOfAccounts(ddbChartOfAccounts)

    const quickBooksChartOfAccounts = await getChartOfAccounts(tokenObj?.accessToken, qbEnv)
    console.log('quickBooksChartOfAccounts: ', quickBooksChartOfAccounts)
    if (quickBooksChartOfAccounts?.code==="3200") {
      alert("QuickBooks Token Expired. Relogin to QuickBooks");
      return;
    }
    const { creates, updates } = compareDatasets(ddbChartOfAccounts, quickBooksChartOfAccounts?.Account);

    if (creates.length > 0 || updates.length > 0) {
      await updateDynamoDBChartOfAccounts(creates, updates);
    }
  }

  // EXPENSE CATEGORIES
  const [ dynamodbExpenseCategories, setDynamoDBExpenseCategories ] = useState([])

  const syncExpenseCategories = async () => {
    const quickBooksExpenseCategories = await getExpenseCategories(tokenObj?.accessToken, qbEnv)
    console.log('quickBooksExpenseCategories: ', quickBooksExpenseCategories)

    const { creates, updates } = compareExpenseCategoryDatasets(dynamodbExpenseCategories, quickBooksExpenseCategories?.Account);

    if (creates.length > 0 || updates.length > 0) {
      await updateDynamoDBExpenseCategories(creates, updates);
    }
  }

  useEffect(() => {
    const fetchExpenseCategories = async () => {
      const results = await fetchQuickBooksExpenseCategories(qbEnv);
      console.warn(' ----- fetchQuickBooksExpenseCategories results: ', results);
      setDynamoDBExpenseCategories(results);
      
      const categories = results.map(expense => ({
        value: expense.qbId,
        label: expense.appName,
        isIFTATracked: (expense?.isIFTATracked===true) ? true : false,
      }));
      setExpenseCategories(categories);
    }

    fetchExpenseCategories();
  },[qbEnv, refreshIndex])

  const compareExpenseCategoryDatasets = (dynamoDBData, quickBooksData) => {
    const updates = [];
    const creates = [];
  
    quickBooksData.forEach(qbItem => {
      const match = dynamoDBData.find(dynamoItem => dynamoItem.qbId === qbItem.Id);
  
      if (!match) {
        creates.push(qbItem);
      // } else if (!areObjectsEqual(match, qbItem)) {
      } else {
        updates.push(qbItem);
      }
    });
  
    return { creates, updates };
  };
  
  const updateDynamoDBExpenseCategories = async (creates, updates) => {
    for (const item of creates) {
      const input = {
        qbId: item.Id,
        allSort: (qbEnv === 'sandbox') ? 'sandbox' : 'all',
        acctNum: item.AcctNum,


        qbName: item.Name,
        qbFullyQualifiedName: item.FullyQualifiedName,
        qbActive: item.Active.toString(),
        qbParentId: item.ParentRef?.value||'',
        qbParentName: item.ParentRef?.name||'',
        qbLevel: item.Level.toString(),
        qbSubAccount: item.SubAccount.toString(),
        qbSpecialAccountType: item.SpecialAccountType||'',
        
      };

      try {
        const result = await addOrUpdateExpenseCategory(input);
        console.log('DynamoDB created:', result)
      } catch (error) {
        console.error('Error creating DynamoDB:', error);
      }
    }
    for (const item of updates) {
      const input = {
        qbId: item.Id,
        qbName: item.Name,
        qbFullyQualifiedName: item.FullyQualifiedName,
        qbActive: item.Active.toString(),
        qbParentId: item.ParentRef?.value||'',
        qbParentName: item.ParentRef?.name||'',
        qbLevel: item.Level.toString(),
        qbSubAccount: item.SubAccount.toString(),
        qbSpecialAccountType: item.SpecialAccountType||'',
        allSort: (qbEnv === 'sandbox') ? 'sandbox' : 'all',
      };

      try {
        const result = await addOrUpdateExpenseCategory(input);
        console.log('DynamoDB updated:', result)
      }
      catch (error) {
        console.error('Error updating DynamoDB:', error);
      }
    }
  };
        
  const updateDynamoDBChartOfAccounts = async (creates, updates) => {
    for (const item of creates) {
      const input = {
        qbChartOfAccountsId: item.Id,
        qbChartOfAccountsName: item.Name,
        qbChartOfAccountsFullyQualifiedName: item.FullyQualifiedName,
        qbChartOfAccountsActive: item.Active.toString(),
        allSort: (qbEnv === 'sandbox') ? 'sandbox' : 'all',
      };
  
      try {
        // const result = await addChartOfAccounts(input);
        const result = await addOrUpdateChartOfAccounts(input);
        console.log('DynamoDB created:', result)
      } catch (error) {
        console.error('Error creating DynamoDB:', error);
      }
    }
    for (const item of updates) {
      const input = {
        qbChartOfAccountsId: item.Id,
        qbChartOfAccountsName: item.Name,
        qbChartOfAccountsFullyQualifiedName: item.FullyQualifiedName,
        qbChartOfAccountsActive: item.Active.toString(),
        allSort: (qbEnv === 'sandbox') ? 'sandbox' : 'all',
      };
  
      try {
        // await updateChartOfAccounts(input);
        const result = await addOrUpdateChartOfAccounts(input);
        console.log('DynamoDB updated:', result)
      } catch (error) {
        console.error('Error updating DynamoDB:', error);
      }
    }
  };
  
  const compareDatasets = (dynamoDBData, quickBooksData) => {
    const updates = [];
    const creates = [];
  
    quickBooksData.forEach(qbItem => {
      const match = dynamoDBData.find(dynamoItem => dynamoItem.qbChartOfAccountsId === qbItem.Id);
  
      if (!match) {
        creates.push(qbItem);
      } else if (!areObjectsEqual(match, qbItem)) {
        updates.push(qbItem);
      }
    });
  
    return { creates, updates };
  };
  
  const areObjectsEqual = (dynamoItem, qbItem) => {
    return (
      dynamoItem.qbChartOfAccountsId === qbItem.Id &&
      dynamoItem.qbChartOfAccountsName === qbItem.Name &&
      dynamoItem.qbChartOfAccountsFullyQualifiedName === qbItem.FullyQualifiedName &&
      dynamoItem.qbChartOfAccountsActive === qbItem.Active.toString()
    );
  };

  const removeTeamMemberMapEntry = (qbChartOfAccountsId) => {
    setValue(`selectTeamMember_${qbChartOfAccountsId}`, {label: '', id: ''})
    setSelectedTeamMemberMap((prev) => {
      const updatedMap = { ...prev };
      delete updatedMap[qbChartOfAccountsId];
      return updatedMap;
    });
  };

  const [ selectedTeamMemberMap, setSelectedTeamMemberMap ] = useState([])

  const updateSelectedTeamMemberMap = (
    qbChartOfAccountsId, 
    selectedTeamMemberId, 
  ) => {
    setSelectedTeamMemberMap((prev) => ({
      ...prev,
      [qbChartOfAccountsId]: selectedTeamMemberId
    }));
  }

  const updateTeamMemberMap = async (accountId) => {
    console.log('accountId: ', accountId)
    const isExistingMapping = mappedTeamMembers.find(mapping => mapping.qbChartOfAccountsId === accountId);
    console.log('mappedTeamMembers: ', mappedTeamMembers)
    console.log('isExistingMapping: ', isExistingMapping)
    let allSort = (qbEnv === 'sandbox') ? 'sandbox' : 'all';
    const input = {
      qbChartOfAccountsId: accountId,
      teamMemberId: selectedTeamMemberMap[accountId],
      isActive: true,
      isDefault: true,
      allSort: allSort,
    };
    if (isExistingMapping) {
      // update ddb then refresh
      try {
        await updateCreditCardTeamMember(input);
        fetchMappedTeamMembers();
      } catch (error) {
        console.error('Error updating DynamoDB:', error);
      }
    } else {
      // create new mapping
      try {
        await addCreditCardTeamMemberMap(input);
        fetchMappedTeamMembers();
      } catch (error) {
        console.error('Error creating DynamoDB:', error);
      }
    }
  }

  const [ allTeamMemberNames, setAllTeamMemberNames ] = useState([])
  const fetchAllTeamMemberNames = async () => {
    const fetchedTeamMembers = await fetchAllTeamMembers();
    console.log('fetchedTeamMembers: ', fetchedTeamMembers)
    const teamMembers = [{ id: '00000', firstName: 'Select Team Member', goesBy: '', lastName: '' }, ...fetchedTeamMembers];
    setAllTeamMemberNames(teamMembers);
    return teamMembers;
  };

  
  useEffect(() => {
    if (currentUserIsGlobalAdmin || userIsCreditCardManager) {
      const fetchData = async () => {
        const teamMembers = await fetchAllTeamMemberNames();
        console.log('teamMembers: ', teamMembers)
        const mappedMembers = await fetchMappedTeamMembers();
        console.log('mappedMembers: ', mappedMembers)
        setMappedTeamMembers(mappedMembers);
        const chartOfAccounts = await fetchDynamoDbChartOfAccounts(mappedMembers);
        console.log('chartOfAccounts: ', chartOfAccounts)
      };

      fetchData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserIsGlobalAdmin, userIsCreditCardManager]);

  const fetchMappedTeamMembers = async (tmId) => {
    if (currentUserIsGlobalAdmin || userIsCreditCardManager) {
      const mappedTeamMembers = await fetchAllCreditCardTeamMemberMaps(qbEnv);
      setMappedTeamMembers(mappedTeamMembers);
      return mappedTeamMembers;
    } else {
      const mappedTeamMembers = await fetchAccountsByTeamMember(tmId);
      setMappedTeamMembers(mappedTeamMembers);
      return mappedTeamMembers;
    }
  };

  useEffect(() => {
    const fetchTeamMemberMap = async (environment) => {
      const mappedTeamMembers = await fetchAllCreditCardTeamMemberMaps(environment);
      // console.log('mappedTeamMembers: ', mappedTeamMembers)
      setMappedTeamMembers(mappedTeamMembers);
    };
    if (qbEnv) {
      fetchTeamMemberMap(qbEnv);
    }
  },[qbEnv])

  const fetchDynamoDbChartOfAccounts = async (mappedTeamMembers) => {
    let allSort = (qbEnv === 'sandbox') ? 'sandbox' : 'all';
    const ddbChartOfAccounts = await fetchAllChartOfAccounts(allSort);
    // console.warn('ddbChartOfAccounts: ', ddbChartOfAccounts);

    // Initialize the selectedTeamMemberMap with existing mappings
    const initialMap = {};
    ddbChartOfAccounts.forEach(account => {
      initialMap[account.qbChartOfAccountsId] = '';
    });
    mappedTeamMembers.forEach(mapping => {
      initialMap[mapping.qbChartOfAccountsId] = mapping.teamMemberId;
    });

    setSelectedTeamMemberMap(initialMap);
    setDynamoDBChartOfAccounts(ddbChartOfAccounts);
    return ddbChartOfAccounts;
  };

  const generateChartOfAccountsRows = (accounts, mappedtms) => {
    
    // console.log('accounts: ', accounts)
    // console.log('mappedtms: ', mappedtms)
    if (!accounts || !mappedtms) return;
    // return accounts?.map((item, index) => {
    return accounts?.map((item, index) => {
      const mappedMember = mappedtms.find(mapping => mapping.qbChartOfAccountsId === item.qbChartOfAccountsId);

      let selectedTeamMember = '';
      if (mappedMember && selectedTeamMemberMap[item?.qbChartOfAccountsId] && selectedTeamMemberMap[item?.qbChartOfAccountsId] !== mappedMember?.teamMemberId) {
        const matchedTeamMember = allTeamMemberNames.find(tm => tm.id === selectedTeamMemberMap[item?.qbChartOfAccountsId]);
        const matchedGoesBy = (matchedTeamMember?.goesBy) ? `"${matchedTeamMember?.goesBy}"` : '';
        const matchedName = `${matchedTeamMember?.firstName} ${matchedGoesBy} ${matchedTeamMember?.lastName}`;
        selectedTeamMember = {label: matchedName, value: `${matchedTeamMember?.id}`};
      } else if (mappedMember?.teamMember) {
        const mappedGoesBy = (mappedMember?.teamMember?.goesBy) ? `"${mappedMember?.teamMember?.goesBy}"` : '';
        const mappedName = `${mappedMember?.teamMember?.firstName} ${mappedGoesBy} ${mappedMember?.teamMember?.lastName}`;
        selectedTeamMember = {label: mappedName, value: mappedMember?.teamMemberId};
      }
      

      // let showButtonCell = false;
      const teamMemberIsMapped = (selectedTeamMemberMap[item?.qbChartOfAccountsId]?.length>0) ? true : false
      const selectedTeamMemberCOAId = selectedTeamMemberMap[item?.qbChartOfAccountsId]
      const mappedTeamMemberCOAId = mappedMember?.qbChartOfAccountsId
      const accountCOAId = item?.qbChartOfAccountsId
      const mappedTeamMemberId = mappedMember?.teamMemberId

      // console.log('selectedTeamMember: ', selectedTeamMember)

      return (
        <Fragment key={`frag_${index}`}>
          <Tr key={`tr_${index}`}>
            {/* <Td>
              <Box>
                <FormSwitch
                  register={register}
                  control={control}
                  size={'md'}
                  fieldname={`accountActive_${item.qbChartOfAccountsId}`}  // unique field name
                  isChecked={isAccountActive}
                  onChange={(e) => {
                    setValue(`accountActive_${item.qbChartOfAccountsId}`, !isAccountActive);
                    setAccountActiveState(prev => ({
                      ...prev,
                      [item.qbChartOfAccountsId]: !isAccountActive
                    }));
                  }}
                />
              </Box>
            </Td> */}
            {/* <Td>{item?.qbChartOfAccountsId}</Td> */}
            {/* <Td>{item?.qbChartOfAccountsActive}</Td> */}
            <Td>{item?.qbChartOfAccountsName}</Td>
            <Td>
              <Box>
                <FormSelectSimple
                  key={`teamMember_${accountCOAId}`}
                  register={register}
                  control={control}
                  errors={errors}
                  isRequired={true}
                  fieldName={`selectTeamMember_${accountCOAId}`}
                  placeholder={'Select Team Member'}
                  optionsArray={allTeamMemberNames?.map((tm, index) => ({
                    key: index,
                    value: tm.id,
                    label: tm.goesBy?.length>0 
                      ? `${tm.firstName} "${tm.goesBy}" ${tm.lastName}` 
                      : `${tm.firstName} ${tm.lastName}`
                  }))}
                  onChange={(e) => updateSelectedTeamMemberMap(accountCOAId, e?.value, mappedTeamMemberCOAId)}
                  selectedoption={selectedTeamMember}
                />
              </Box>
            </Td>
            <Td>
              {(teamMemberIsMapped) && 
                <Box display={'flex'} gap={'5px'}>
                  {((selectedTeamMemberCOAId 
                      && mappedTeamMemberCOAId !== accountCOAId) 
                      || mappedTeamMemberId !== selectedTeamMemberMap[accountCOAId])
                    && 
                    <ButtonPrimaryPlain 
                      type="button"
                      name='updateTeamMemberMap'
                      value={isSubmitting ? 'Updating...' : 'Update'}
                      isDisabled={isSubmitting===true}
                      onClick={handleSubmit(() => {
                        updateTeamMemberMap(accountCOAId);
                      }, onError)}
                    />
                  }
                  {((selectedTeamMemberCOAId 
                      && mappedTeamMemberCOAId !== accountCOAId) 
                      || mappedTeamMemberId !== selectedTeamMemberMap[accountCOAId])
                    && <ButtonPrimaryPlain 
                    type="button"
                    name='resetTeamMemberMap'
                    value={'Cancel'}
                    isDisabled={isSubmitting===true}
                    onClick={handleSubmit(() =>{
                      removeTeamMemberMapEntry(accountCOAId);
                    })}
                  />}
                </Box>
              }
            </Td>
          </Tr>
          <Tr><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
        </Fragment>
      );
    });
  }
  // CHART OF ACCOUNTS

  // QuickBooks Classes
  useEffect(() => {
    const loadQBClasses = async (token, environment) => {
      if (token && environment) {
        setLoadingQBClasses(true);
        try {
          const qbClassesData = await getClasses(token, environment);
          // console.log('qbClassesData: ', qbClassesData)
          setQBClasses(
            qbClassesData.map((qbclass) => ({
              value: qbclass.Id,
              label: qbclass.Name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase(),
            }))
          );
        } catch (error) {
          console.error('Error loading QB classes:', error);
        } finally {
          // console.log('SHOULD BE SEEING THIS ON EACH LOAD: ', qbClasses)
          setLoadingQBClasses(false);
        }
      }
    };

    if (tokenObj?.accessToken) {
      console.warn('loading qbClasses: ', tokenObj.accessToken)
      loadQBClasses(tokenObj.accessToken, qbEnv);
    }
  }, [tokenObj?.accessToken, qbEnv]);

  const updateSelectedQBClass = (divisionName, qbClassOptions) => {
    // This really needs a mapping table created that can be synced with the QB classes
    let divisionNameNormalized = divisionName.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
    // this is a hack to normalize the division names to match the QB classes
    if (divisionNameNormalized === 'overheadadministration') {
      divisionNameNormalized = 'overheadadmin';
    }
    // division options
    // Blue Island
    // Citgo
    // ExxonMobile
    // Memphis
    // Oklahoma
    // OKC Environmental
    // OKC Maintenance
    // Overhead/Admin

    // {Id: "508930", Name: "300 S. Jernigan Blvd/rental", SubClass: false, FullyQualifiedName: "300 S. Jernigan Blvd/rental", Active: true, domain: "QBO", …}
    // {Id: "508931", Name: "Blue Island", SubClass: false, FullyQualifiedName: "Blue Island", Active: true, domain: "QBO", …}
    // {Id: "508932", Name: "Citgo", SubClass: false, FullyQualifiedName: "Citgo", Active: true, domain: "QBO", …}
    // {Id: "508933", Name: "Exxon/Mobil", SubClass: false, FullyQualifiedName: "Exxon/Mobil", Active: true, domain: "QBO", …}
    // {Id: "508934", Name: "Memphis", SubClass: false, FullyQualifiedName: "Memphis", Active: true, domain: "QBO", …}
    // {Id: "508935", Name: "OKC-Environmental", SubClass: false, FullyQualifiedName: "OKC-Environmental", Active: true, domain: "QBO", …}
    // {Id: "508936", Name: "OKC-Industrial", SubClass: false, FullyQualifiedName: "OKC-Industrial", Active: true, domain: "QBO", …}
    // {Id: "508937", Name: "OKC-Maintenance", SubClass: false, FullyQualifiedName: "OKC-Maintenance", Active: true, domain: "QBO", …}
    // {Id: "5000000000000344554", Name: "Oklahoma", SubClass: false, FullyQualifiedName: "Oklahoma", Active: true, domain: "QBO", …}
    // {Id: "508938", Name: "Overhead/Admin", SubClass: false, FullyQualifiedName: "Overhead/Admin", Active: true, domain: "QBO", …}

    // console.log('qbClassOptions: ', qbClassOptions)
    const matchedClass = qbClassOptions.find(option => option.label === divisionNameNormalized);
    // console.log('matchedClass: ', matchedClass)
    return matchedClass;
    // setSelectedQBClass(matchedClass);
  }

  // Creating QuickBooks Purchase
  // purchaseObject, filename, key, identityid, token, environment
  const pushStatementToQB = async (purchaseObj) => {

    console.log('purchaseObj: ', purchaseObj)
    const uploadRedults = await pushStatementToQuickBooks(purchaseObj)
    console.warn('uploadResults: ', uploadRedults)
    return uploadRedults;
  }

  //onSubmit={handleSubmit(updateReceipt)}
  const handlePublish = async (transaction) => {
    console.warn('handlePublish transaction: ', transaction)
    

    setIsPublishingToQuickBooks(true);

    if (transaction.hasIssue) {
      const results = await updateReceipt(transaction);
      console.log('updateReceipt results: ', results);
    } else {

      // need to handle for just updating record because it has an issue

      // looking for plaidCreditCardTransactionId
      console.warn('transaction: ', transaction)

      // console.log('transactionId: ', transactionId)
      const isSupplierMatched = transaction.qbSupplierMatched;
      console.warn('isSupplierMatched: ', isSupplierMatched)

      const divisionName = (transaction?.selectDivision?.label) ? transaction?.selectDivision?.label : transaction?.division?.prettyname||transaction?.divisionName||null;
      // console.warn('divisionName: ', divisionName)
      
      const qbClassesData = await getClasses(tokenObj.accessToken, qbEnv);
      const qbClasses = qbClassesData.map((qbclass) => ({
        value: qbclass.Id,
        label: qbclass.Name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase(),
      }))
            
      // console.warn('qbClasses: ', qbClasses)
      let qbClass = '';
      if (divisionName) {
        qbClass = updateSelectedQBClass(divisionName, qbClasses);
      }
      // console.warn('qbClass: ', qbClass);

      // console.warn('mappedTeamMembers: ', mappedTeamMembers)
      const tmIdCardNumber = transaction.tmIdCardNumber.split('#');
      const qbAccount = mappedTeamMembers.find(
        record => 
          record.cardLastFour === tmIdCardNumber[1] && record.teamMemberId === tmIdCardNumber[0]
      );
      
      const accountRefId = qbAccount?.qbChartOfAccountsId;
      // console.log('accountRef: ', accountRefId);
      // return;
      // setIsPublishing(true);
    
      try {

        let qbVendorId = null;
        // let qbVendorName = null;
        
        if (isSupplierMatched) {
          qbVendorId = transaction.qbVendorId;
          console.warn('qbVendorId: ', qbVendorId)
          // qbVendorName = transaction.qbVendor.qbDisplayName;
        } else {
          let newQBVendorId = null;
          let newQBVendorName = null;
          // is there ever a time when Plaid passes back an inknown merchant name?
          newQBVendorName = transaction.merchantName;
          console.warn('newQBVendorName: ', newQBVendorName)
          const results = await createQBVendor(tokenObj?.accessToken, newQBVendorName, qbEnv);
      
          console.log('add vendor results: ', results);
          console.log('add vendor results: ', results?.vendorId);
        
          if (results.error) {
            // Handle the error accordingly
            if (results.error === 'Vendor already exists') {
              // Handle the specific case where the vendor already exists
              console.log('Vendor already exists. Please choose a different name or use the existing vendor.');
              // Display an error message to the user
              alert('Vendor already exists. Please choose a different name or use the existing vendor.');
              return;
            } else {
              console.error('Error in form submission:', results.error);
              return;
            }
          } else {
            if (results.vendorId) newQBVendorId = results.vendorId;
            console.log('results (need vendor id): ', results)
          }

          // if the vendor is new, we need to create the record in QB Vendor table
          if (newQBVendorId && newQBVendorName) {
            // run the add vendor to qb vendor table and update the modal
            console.log('New Vendor ID:', newQBVendorId);
            const params = {
              id: newQBVendorId,
              name: newQBVendorName,
            };
            const newQBVendorResults = await addQuickBooksVendor(params);
            console.log('New QB Vendor Results:', newQBVendorResults);
            console.log('Creating mapping entry');
            // run the add vendor to map and update the modal
            const addVendorMapResults = await addVendorMap({
              vendorName: newQBVendorName, 
              vendorTypeKey: 'receipt', 
              qbVendorId: newQBVendorId
            });
            console.log('Add Vendor Map Results:', addVendorMapResults);

            qbVendorId = newQBVendorId;
            // qbVendorName = newQBVendorName;
          } else {
            console.error('Error creating new vendor:', results);
            return;
          }
        }

        // UNITS - Do we need to add some text about the IFTS amd units to the description like they used to do it?

        // update the status of the transaction to published
        const updateTransactionParams = {
          id: transaction.plaidCreditCardTransactionId,
          status: 'published',
          approvedById: currentTeamMemberId,
        }
        const updateTransactionresults = await updateTransaction(updateTransactionParams)
        console.warn('updateTransactionresults: ', updateTransactionresults)

        // prepare for updating database record for the transaction
        const props = {
          // matchStatus: transaction.matchStatus,
          matchStatus: transaction.matchStatus,
          id: transaction.id,  // The current transaction ID
          // vendorId: qbVendorId,  // Vendor selected in the form or existing one
          issue: transaction?.issue||'',
          expenseCategoryId: transaction.selectExpenseCategory,
        };
        //clientId: transaction?.selectClient||transaction?.clientId,
        if (transaction?.selectClient||transaction?.clientId) {
          props.clientId = transaction?.selectClient||transaction?.clientId;
        }

        props.hasIssue = transaction?.hasIssue 
        ? (transaction?.hasIssue === 'true' || transaction?.hasIssue === true)  // Convert 'true' (string) or true (boolean) to true
        : false;  // Default to false if formData.hasIssue does not exist
    
        

        // create the purchase in QuickBooks.
        const purchaseParams = {
          files: transaction.uploadedDocumentsJSON,
          token: tokenObj?.accessToken,
          identityId: transaction.identityKey,
          qbenv: qbEnv,
          accountId: transaction.accountNumber,
          accountRefId: accountRefId,
          vendorId: qbVendorId,
          txnAmount: transaction.amount,
          expensCatId: transaction.selectExpenseCategory,
          classId: qbClass?.value,
          memo: transaction.addedNotes,
          txnDate: transaction.transactionDate,
        }

        if (transaction.clientQbId) {
          purchaseParams.customerId = transaction.clientQbId;
        }
    
        console.log('purchaseObj: ', purchaseParams)
        
        const qbPurchaseId = await pushStatementToQB(purchaseParams);
        console.log('qbResults: ', qbPurchaseId);
        
        props.quickbooksExpenseId = qbPurchaseId;

        // use qbResults to update the transaction record in the database with the id
        // Call the updateReceipt function, passing the necessary data
        const results = await updateReceipt(props);
        console.log('updateReceipt results: ', results);

    
      } catch (error) {
        console.error('Error publishing transaction:', error);
        // setIsPublishing(false);
      }
    }

    setIsPublishingToQuickBooks(false);
    handleRefresh();
    onEditTransactionClose();
  };

  // When publish button is clicked from within a row
  const handleRowPublish = async (transaction) => {
    setIsPublishingToQuickBooks(true);
    console.log('transaction: ', transaction)
    
    // const dateYYYYMMDD = transaction?.createdAt.split('T')[0];
    const transactionDate = transaction?.matchedItem?.date||'';
    const identityKey = transaction?.uploadedDocuments?.items[0]?.uploadedDocument?.identityId; // Extract the identity key
    const files = transaction?.uploadedDocuments?.items?.map((item) => {
      const key = item.uploadedDocument.key; // Extract the key
      const fileName = key.split('/').pop(); // Extract the file name from the key
      return { fileKey: key, fileName };
    });
    const filesParameter = JSON.stringify(files);
    
    const updatedTransaction = {
      id: transaction?.id,
      plaidCreditCardTransactionId: transaction?.plaidCreditCardTransactionId,
      // transactionDate: dateYYYYMMDD,
      transactionDate: transactionDate,
      identityKey: identityKey,
      uploadedDocumentsJSON: filesParameter,
      merchantName: transaction?.matchedItem?.merchantEntityName||'UNKNOWN',
      accountNumber: transaction?.matchedItem?.accountNumber,
      amount: transaction?.matchedItem?.amount||'',
      qbVendorId: transaction?.qbVendor?.qbId||'',
      transactionAmount: transaction?.matchedItem?.amount||'',
      addedNotes: transaction?.addedNotes||'',
      tmId: transaction?.teamMemberId,
      cardNumber: transaction?.matchedItem?.accountNumber,
      tmIdCardNumber: `${transaction?.teamMemberId}#${transaction?.matchedItem?.accountNumber}`,
      qbSupplierMatched: transaction?.qbSupplierMatched,
      selectExpenseCategory: transaction?.expenseCategoryId,
      divisionName: transaction?.division?.prettyname,
      selectClient: transaction?.clientId,
      matchStatus: "published", // only editing the transactiuon can set the matchStatus to has issues,
    }

    // You can directly call handlePublish with predefined data
    // const formData = {
    //   selectExpenseCategory: transaction.expenseCategoryId,
    //   selectQuickBooksVendor: transaction.qbVendor?.qbId,
    // };
    await handlePublish(updatedTransaction);  // Call handlePublish with row transaction data
  };

  const updateReceipt = async (props) => {
    try {
        const response = await updateCreditCardTransactionReceipt(props);
        return response;
    } catch (error) {
      console.error('Error updating receipt:', error);
    }
  };

  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });

  const onSortSubmittedReceipts = (field) => {
    let direction = 'asc';
    if (sortConfig.key === field && sortConfig.direction === 'asc') {
      direction = 'desc'; // Toggle to descending if already sorted ascending
    }
  
    setSortConfig({ key: field, direction });

    // Sorting logic based on field and direction
    const sortedData = [...matchResults.matches].sort((a, b) => {
      let valA, valB;
  
      // Custom sorting logic for different fields
      if (field === 'card') {
        // valA = a.capitalOneInstantNotification.lastFour;
        // valB = b.capitalOneInstantNotification.lastFour;
        valA = a.matchedItem.accountNumber;
        valB = b.matchedItem.accountNumber;
      } else if (field === 'supplier') {
        valA = a.qbVendor?.qbDisplayName;
        valB = b.qbVendor?.qbDisplayName;
      } else if (field === 'expense-category') {
        valA = a?.expenseCategoryId;
        valB = b?.expenseCategoryId;
      } else if (field === 'merchant') {
        valA = a.matchedItem.merchantEntityName;
        valB = b.matchedItem.merchantEntityName;
      } else if (field === 'date') {
        valA = new Date(a.matchedItem.date);
        valB = new Date(b.matchedItem.date);
      } else if (field === 'amount') {
        // Remove $ and commas, then parse as float for numeric comparison
        const parseAmount = (amount) => {
          // Handle cases where amount is a string (e.g., "$10.91")
          if (typeof amount === 'string') {
            return parseFloat(amount.replace(/[^0-9.-]+/g, ''));
          }
          return amount; // If already a number, return as is
        };
        valA = parseAmount(a.capitalOneInstantNotification.purchaseAmount);
        valB = parseAmount(b.capitalOneInstantNotification.purchaseAmount);
      } else {
        valA = a[field];
        valB = b[field];
      }
  
      // Compare values based on the direction
      if (direction === 'asc') {
        return valA > valB ? 1 : valA < valB ? -1 : 0;
      } else {
        return valA < valB ? 1 : valA > valB ? -1 : 0;
      }
    });

    console.log('sortedData: ', sortedData)
  
    // Update only the matches array in the state while preserving other arrays
    setMatchResults((prevResults) => ({
      ...prevResults,  // Preserve the other arrays (unmatchedFromArray1, unmatchedFromArray2)
      matches: sortedData,  // Update only the matches array with sorted data
    }));
  };

  const [ currentEditTransactionVendor, setCurrentEditTransactionVendor ] = useState(null)
  const [ currentEditTransactionVendorIndex, setCurrentEditTransactionVendorIndex ] = useState(null)
  const handleOpenEditTransactionVendor = (transaction, index) => {
    // console.log('handleOpenEditTransactionVendor: ', transaction);
    setCurrentEditTransactionVendor(transaction);
    setCurrentEditTransactionVendorIndex(index);
  }

  useEffect(() => {
    currentEditTransactionVendor && onEditTransactionVendorOpen();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[currentEditTransactionVendor])

  const onEditTransactionVendorClose = () => {
    setCurrentEditTransactionVendor(null)
    // handleRefresh()
    editTransactionVendorClose()
  }

  // const handleOpenGallery = (imagesArray) => {
  //   console.log('openGallery: ', imagesArray)
  //   setGalleryImages(imagesArray)
  // }
  // useEffect(() => {
  //   if (galleryImages.length && galleryImages.length>0) {
  //     console.log('about to open gallery')
  //     onReceiptImageViewerModalOpen();
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[galleryImages])
    
  // const [ manuallyMappedVendor, setManuallyMappedVendor ] = useState({});
  // const [ isNewQBVendor, setIsNewQBVendor ] = useState(false);
  // const [ newQBVendorName, setNewQBVendorName ] = useState('');
  // const [ newVendorId, setNewVendorId ] = useState('');
  const mapQBVendorToStatementVendor = (vendorObj, index) => {

    console.log('vendorObj: ', vendorObj)
    console.log('index: ', index)

    // setManuallyMappedVendor(vendorObj);

    if (!vendorObj?.id && vendorObj?.name) {
      // setNewQBVendorName(vendorObj.name);
      // setIsNewQBVendor(true);
    }
  };

  // function to filter results based on searchterm
  const [ showSearchReset, setShowSearchReset ] = useState(false);
  const filterMatchedData = (e) => {
    // console.log('filterMatchedData: ', e.target.value)
    const searchTerm = e.target.value;
    const searchTermLength = searchTerm.length;

    console.log('searchTerm: ', searchTerm)
    console.log('searchTermLength: ', searchTermLength)
    
    if (searchTermLength > 0) {
      setShowSearchReset(true);
    }
    const filteredResults = matchResults.matches.filter((match) => {
      const merchantName = match?.matchedItem?.merchantEntityName.toLowerCase();
      const supplierName = match?.qbVendor?.qbDisplayName.toLowerCase()||'';
      const amount = match?.capitalOneInstantNotification?.purchaseAmount.toString();
      const card = match?.capitalOneInstantNotification?.lastFour.toString();
      const searchLower = searchTerm.toLowerCase();
      
      return (
        merchantName?.includes(searchLower) ||
        supplierName?.includes(searchLower) ||
        amount?.includes(searchLower) ||
        card?.includes(searchLower)
      );
    });

    console.log('filteredResults: ', filteredResults)
    setMatchResults((prevResults) => ({
      ...prevResults,
      matches: filteredResults,
    }));
  }

  const resetMatches = () => {
    setValue('searchInput', '')
    setRefreshIndex(prev => prev + 1)
    setShowSearchReset(false);
  }

  useEffect(() => {
    resetMatches();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const renderPendingReceipts = (transactionsByCard) => {
    // Group transactions by cardholder and calculate the total amount for each cardholder
    const groupedTransactions = transactionsByCard.reduce((acc, cardData) => {
      const cardNumber = cardData.accountNumber;
      const cardHolderName = cardData.teamMemberName || `Card ${cardNumber}`;
  
      // Calculate the total amount for this cardholder
      const totalAmount = cardData.transactions.reduce((sum, transaction) => {
        return sum + parseFloat(transaction.amount || 0);
      }, 0);
  
      // Add the cardholder's data to the accumulator
      acc.push({
        cardNumber,
        cardHolderName,
        transactions: cardData.transactions,
        totalAmount,
      });
  
      return acc;
    }, []);
  
    return (
      <TableContainer>
        <Box
          height="600px"
          overflowY="scroll"
          border="1px solid"
          borderColor="var(--dark-component-border)"
          borderRadius="6px"
          p={2}
          onScroll={handleScroll}
        >
          <Table variant="compact" size="compact">
            <Thead>
              <Tr>
                <Th>
                  <Text as="span" textStyle="dark-heading-4">Card</Text>
                </Th>
                <Th>
                  <Text as="span" textStyle="dark-heading-4">Team Member</Text>
                </Th>
                <Th>
                  <Text as="span" textStyle="dark-heading-4">Pending Count</Text>
                </Th>
                <Th>
                  <Text as="span" textStyle="dark-heading-4">Total Amount</Text>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {groupedTransactions.map((group, groupIndex) => {
                // Round the total amount to 2 decimal places
                const totalAmountRounded = parseFloat(group.totalAmount.toFixed(2));
  
                return (
                  <Fragment key={`group-${groupIndex}`}>
                    {/* Render each transaction for the cardholder */}
                    {group.transactions.map((transaction, index) => (
                      <Tr key={`transaction-${groupIndex}-${index}`}>
                        <Td>{transaction.accountNumber}</Td>
                        <Td>{group.cardHolderName}</Td>
                        <Td>{transaction.amount ? `$${parseFloat(transaction.amount).toFixed(2)}` : 'N/A'}</Td>
                        <Td>{transaction.date || 'N/A'}</Td>
                      </Tr>
                    ))}
                    {/* Summary row for the cardholder */}
                    <Tr key={`summary-${groupIndex}`}>
                      <Td colSpan={2}>
                        <Text as="span" className="dark-sfpro-text-2">
                          <strong>Total for {group.cardHolderName}</strong>
                        </Text>
                      </Td>
                      <Td colSpan={2} align="right">
                        <Text as="span" className="dark-sfpro-text-2">
                          <strong>{totalAmountRounded}</strong>
                        </Text>
                      </Td>
                    </Tr>
                    {/* Divider between cardholder groups */}
                    <Tr key={`divider-${groupIndex}`}>
                      <Td colSpan={4} borderBottom="1px solid var(--dark-module-divider)"></Td>
                    </Tr>
                  </Fragment>
                );
              })}
            </Tbody>
          </Table>
        </Box>
      </TableContainer>
    );
  };

  const handleFileParsed = (jsonObject) => {
    console.log("Parsed JSON Object:", jsonObject);
    // Do something with the parsed JSON
  };

  

  return (
    <>
      <QuickBooksAuth 
        qbAppId='ManageExpenses'
        qbEnv={qbEnv}
        // onTokenChange={setTokenObj}
        onTokenChange={(e) => updateTokenObj(e)}
        >
        {({ isLoggedInQB, loginQB, logoutQB }) => (
          <>
            <Container 
              // maxW="1900" 
              mb={'50px'} as="form" onSubmit={handleSubmit(updateReceipt, onError)}>
                
              <QBLoginLogoutButtons 
                isLoggedInQB={isLoggedInQB}
                loginQB={loginQB}
                logoutQB={logoutQB}
              />

              {isLoggedInQB && <ModuleBox>
                <Box>
                  <Text as="span" textStyle="heading-1">Search Receipts</Text>
                </Box>
                <Box mt={'10px'}>
                  <Text>Search visible results by amount, vendor.</Text>
                </Box>
                <Box w={'40%'} my={'25px'}>
                  <Grid templateColumns="1fr auto" gap={4} alignItems="end">
                    <TextInput
                      register={register}
                      errors={errors}
                      fieldname="searchInput"
                      fieldlabel="Search"
                      prettyname="Search"
                      placeholder="Enter search term"
                      onChange={filterMatchedData}
                      height={'40px'}
                    />

                    {showSearchReset && (
                      <ButtonPrimaryPlain
                        type="button"
                        onClick={resetMatches}
                        height={'40px'}
                        name="resetMatches"
                        value="Reset"
                        px={6}  // Add padding for button
                      />
                    )}
                  </Grid>
                </Box>

                {/* <Box>
                <Text as="span" className='dark-sfpro-text-2' color={qbVendorsSynced ? 'var(--success-green)' : 'var(--error-red)'}>{qbVendorsSynced ? 'QuickBooks Vendors Synced' : 'Syncing QuickBooks Vendors'}</Text>
                
                {qbClasses ? 'QuickBooks Classes Loaded' : 'Loading QuickBooks Classes'}
                </Box> */}
                
              </ModuleBox>}

              {isLoggedInQB && <ModuleBox>
                <Tabs 
                  borderColor='var(--dark-module-divider)' 
                  variant='enclosed' 
                  colorScheme='spikedarktabs' 
                  // onChange={(index) => handleTabChange(index)}
                  >
                  <TabList>
                    <Tab><Text>Ready ({matchResults?.matches?.length})</Text></Tab>
                    <Tab><Text>Reconciliation (IN PROOGRESS)</Text></Tab>
                    {/* <Tab><Text>Pending Match ({matchResults?.unmatchedFromArray1?.length})</Text></Tab> */}
                    {/* <Tab><Text>Issues</Text></Tab> */}
                    {/* <Tab><Text>Posted ({syncedTransactions?.length})</Text></Tab> */}
                    <Tab><Text>Chart of Accounts</Text></Tab>
                    {/* <Tab><Text>Expense Categories</Text></Tab> */}
                  </TabList>
                  <TabPanels>

                    {/* Ready */}
                    <TabPanel>
                      <TableContainer>
                        <HStack paddingBottom={'10px'}>
                          <Box w={'100%'}>
                            <Text className={searchHeaderTextClass}>Ready</Text>
                          </Box>
                          <Box>
                            <ButtonQuaternaryWithIcon
                              name='refresh'
                              leftIcon='refresh'
                              iconsize='22px'
                              isDisabled={!isLoggedInQB}
                              onClick={() => { handleRefresh(); }}
                              value='Refresh page'
                            />
                          </Box>
                        </HStack>
                          <Box
                            height={'600px'}
                            overflowY={isEditTransactionVendorOpen||isEditTransactionOpen ? "hidden" : "scroll"} // Control Y overflow
                            overflowX={isEditTransactionVendorOpen||isEditTransactionOpen ? "hidden" : "auto"} // Control X overflow
                            borderRadius="6px"
                          >
                          <Table variant={'compact'} size={'compact'}>
                            <Thead>
                              <Tr>
                                {/* Date Header */}
                                <Th verticalAlign={'top'}>
                                  <Text
                                    as="span"
                                    textStyle='dark-heading-4'
                                    cursor="pointer"
                                    onClick={() => onSortSubmittedReceipts('date')}
                                  >Date</Text>
                                </Th>
                                {/* QB Supplier Header */}
                                <Th verticalAlign={'top'}>
                                  <Text
                                    as="span"
                                    textStyle='dark-heading-4'
                                    cursor="pointer"
                                    onClick={() => onSortSubmittedReceipts('supplier')}
                                  >QuickBooks Supplier</Text>
                                </Th>
                                {/* Merchant Name Header */}
                                <Th verticalAlign={'top'}>
                                  <Text
                                    as="span"
                                    textStyle='dark-heading-4'
                                    cursor="pointer"
                                    onClick={() => onSortSubmittedReceipts('merchant')}
                                  >Merchant Name<br />Merchant Store</Text>
                                </Th>
                                {/* Expense Category Header */}
                                <Th verticalAlign={'top'}>
                                  <Text
                                    as="span"
                                    textStyle='dark-heading-4'
                                    cursor="pointer"
                                    onClick={() => onSortSubmittedReceipts('expense-category')}
                                  >Expense Category</Text>
                                </Th>

                                {/* Amount Header */}
                                <Th verticalAlign={'top'}>
                                  <Text
                                    as="span"
                                    textStyle='dark-heading-4'
                                    cursor="pointer"
                                    onClick={() => onSortSubmittedReceipts('amount')}
                                  >Amount</Text>
                                </Th>
                                {/* Card Header */}
                                <Th verticalAlign={'top'}>
                                  <Text
                                    as="span"
                                    textStyle='dark-heading-4'
                                    cursor="pointer"
                                    onClick={() => onSortSubmittedReceipts('card')}
                                  >User</Text>
                                </Th>
                                <Th></Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {/* {!matchResults.matches.length || !qbClasses?.length>0 && <Tr><Td colSpan={'6'} py={'25px'} borderBottom={'1px solid var(--dark-module-divider)'}><Center><Spinner color='var(--progress-bar-primary)' /></Center></Td></Tr>} */}
                              {/* {loadingQBClasses && <Tr><Td colSpan={'6'} py={'25px'} borderBottom={'1px solid var(--dark-module-divider)'}><Center><Spinner color='var(--progress-bar-primary)' /></Center></Td></Tr>} */}
                              {/* {!qbClasses?.length>0 && <Tr><Td colSpan={'6'} py={'25px'} borderBottom={'1px solid var(--dark-module-divider)'}><Center><Spinner color='var(--progress-bar-primary)' /></Center></Td></Tr>} */}
                            
                              {matchResults?.matches?.map((match, index) => (
                                generateProcessedRow(match, index, isLoggedInQB)
                              ))}

                              {/* Does qbClasses really need to be populated before showing the row? */}
                              {/* {!matchResults.matches.length && <Tr><Td colSpan={'6'} py={'25px'} borderBottom={'1px solid var(--dark-module-divider)'}><Center><Spinner color='var(--progress-bar-primary)' /></Center></Td></Tr>}
                              
                              {matchResults?.matches?.map((match, index) => (
                                generateProcessedRow(match, index, isLoggedInQB)
                              ))} */}

                            </Tbody>
                          </Table>
                        </Box>
                      </TableContainer>
                    </TabPanel>

                    {/* Pending Receipts */}
                    {/* <TabPanel>
                      <TableContainer>
                        <Text className={searchHeaderTextClass}></Text>
                        <Box
                          height="600px"
                          overflowY="scroll"
                          border="1px solid"
                          borderColor="var(--dark-component-border)"
                          borderRadius="6px"
                          p={2}
                          onScroll={handleScroll}
                        >
                          <Table variant="compact" size="compact">
                            <Thead>
                              <Tr>
                                <Th>
                                  <Text as="span" textStyle="dark-heading-4">Card</Text>
                                </Th>
                                <Th>
                                  <Text as="span" textStyle="dark-heading-4">Team Member</Text>
                                </Th>
                                <Th>
                                  <Text as="span" textStyle="dark-heading-4">Pending Count</Text>
                                </Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {transactionsByCard?.map((cardData, cardIndex) => (
                                <Fragment key={cardIndex}>
                                  <Tr>
                                    <Td>{cardData?.accountNumber}</Td>
                                    <Td>{cardData?.teamMemberName}</Td>
                                    <Td>{cardData?.count}</Td>
                                  </Tr>

                                  {cardData?.transactions?.map((transaction, transactionIndex) => (
                                    <Tr
                                      key={`${cardIndex}-${transactionIndex}`}
                                      className="nested-transaction-row"
                                    >
                                      <Td pl={8}>{transaction?.merchantEntityName || 'Unknown Merchant'}</Td>
                                      <Td>{transaction?.amount ? `$${parseFloat(transaction.amount).toFixed(2)}` : 'N/A'}</Td>
                                      <Td>{transaction?.date || 'N/A'}</Td>
                                    </Tr>
                                  ))}

                                  <Tr>
                                    <Td colSpan={3} borderBottom="1px solid var(--dark-module-divider)"></Td>
                                  </Tr>
                                </Fragment>
                              ))}
                            </Tbody>
                          </Table>
                        </Box>
                      </TableContainer>
                    </TabPanel> */}
                    <TabPanel>
                    <div>
                      <h1>Upload Billing Statement File</h1>
                      <StatementUploader onFileParsed={handleFileParsed} />
                    </div>
                      {/* {renderPendingReceipts(transactionsByCard)} */}
                    </TabPanel>

                    {/* Pending Match */}
                    {/* <TabPanel>
                      <TableContainer>
                        <Text className={searchHeaderTextClass}></Text>
                        <Box
                          height={'600px'}
                          // width={width||'100%'}
                          overflowY="scroll"
                          border="1px solid"
                          borderColor="var(-dark-component-border)"
                          borderRadius="6px"
                          p={2}
                          onScroll={handleScroll}
                        >
                          <Text as="span" textStyle='dark-heading-3'>
                            Submitted receipts pending a match to a posted transaction. <br />
                            Transaction may not have posted yet or is missed by the matching algorithm. <br />
                            Need a way to match to trasnaction manually.</Text>
                          <Table variant={'compact'} size={'compact'} >
                            <Thead>
                              <Tr>
                                <Th><Text as="span" textStyle='dark-heading-4'></Text>Date</Th>
                                <Th><Text as="span" textStyle='dark-heading-4'></Text>Merchant</Th>
                                <Th><Text as="span" textStyle='dark-heading-4'></Text>Expense Category</Th>
                                <Th><Text as="span" textStyle='dark-heading-4'></Text>Amount</Th>
                                <Th><Text as="span" textStyle='dark-heading-4'></Text>Card</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {matchResults?.unmatchedFromArray1?.map((unamtched, index) => (
                                // console.log('receipt: ', receipt),
                                <Tr key={index}>
                                  <Td>{unamtched?.capitalOneInstantNotification?.purchaseDate}</Td>
                                  <Td>{unamtched?.capitalOneInstantNotification?.purchaseMerchant}</Td>
                                  <Td>{unamtched?.expenseCategoryId}</Td>
                                  <Td>{unamtched?.capitalOneInstantNotification?.purchaseAmount}</Td>
                                  <Td>{unamtched?.capitalOneInstantNotification?.lastFour}</Td>
                                </Tr>
                              ))}
                              
                              <Tr><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
                            </Tbody>
                          </Table>
                        </Box>
                      </TableContainer>
                    </TabPanel> */}

                    {/* Issues */}
                    {/* <TabPanel>Issues</TabPanel> */}

                    {/* Posted */}
                    {/* <TabPanel>
                      <TableContainer>
                        <Text className={searchHeaderTextClass}></Text>
                        <Box
                          height={'600px'}
                          // width={width||'100%'}
                          overflowY="scroll"
                          border="1px solid"
                          borderColor="var(-dark-component-border)"
                          borderRadius="6px"
                          p={2}
                          onScroll={handleScroll}
                        >
                          <Text as="span" textStyle='dark-heading-3'>Posted transactions that are unmatched and have no submitted receipts. <br />
                          These fall outside of the matching filter and any of these older than 5 days should probably be flagged.<br />
                          {syncedTransactions.length}</Text>
                          <Table variant={'compact'} size={'compact'} >
                            <Thead>
                              <Tr>
                                <Th><Text as="span" textStyle='dark-heading-4'></Text>Posted Date</Th>
                                <Th><Text as="span" textStyle='dark-heading-4'></Text>Plaid Merchant</Th>
                                <Th><Text as="span" textStyle='dark-heading-4'></Text>Plaid Store</Th>
                                <Th><Text as="span" textStyle='dark-heading-4'></Text>Amount</Th>
                                <Th><Text as="span" textStyle='dark-heading-4'></Text>Card</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                            {syncedTransactions?.map((transaction, index) => (
                              // console.log('transaction: ', transaction),
                              <Tr key={index}>
                                <Td>{transaction.date}</Td>
                                <Td>{transaction.merchantEntityName}</Td>
                                <Td>{transaction.store}</Td>
                                <Td>{transaction.amount}</Td>
                                <Td>{transaction.accountNumber}</Td>
                              </Tr>
                            ))}

                              
                              <Tr><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
                            </Tbody>
                          </Table>
                        </Box>
                      </TableContainer>
                    </TabPanel> */}

                    {/* Chart of Accounts */}
                    <TabPanel>
                      <>
                        <Box>
                          <Flex alignItems="center" justifyContent="space-between" pb={'25px'}>
                            <Box>
                              <Text className={searchHeaderTextClass}>Sync Accounts</Text>
                            </Box>
                            <Spacer />
                            <Box>
                              <ButtonQuaternaryWithIcon 
                                name='closeDrawer'
                                iconsize='26px'
                                leftIcon='close'
                                value='Get QuickBooks Accounts'
                                onClick={fetchAndProcessChartOfAccounts}
                              />
                            </Box>
                          </Flex>
                          <Box>
                            <TableContainer>
                              <HStack mb='15px'>
                                <Text className={searchHeaderTextClass}>QuickBooks Accounts - Team Members</Text>
                                <Spacer/>
                              </HStack>
                              <Table variant={'compact'} size={'compact'} >
                                <Tbody>
                                  {(dynamodbChartOfAccounts?.length===0) && <Tr><Td py='6px' colSpan={4}><Center>No results found</Center></Td></Tr>}
                                  
                                  {/* {dynamodbChartOfAccounts && generateChartOfAccountsRows(dynamodbChartOfAccounts, mappedTeamMembers)} */}
                                  {generateChartOfAccountsRows(dynamodbChartOfAccounts, mappedTeamMembers)}
                                  
                                  <Tr><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
                                </Tbody> 
                              </Table>
                            </TableContainer>
                          </Box>
                        </Box>
                      </>
                    </TabPanel>

                    {/* Expense Categories */}
                    {/* <TabPanel>
                      <>
                        <Box>
                          <Flex alignItems="center" justifyContent="space-between" pb={'25px'}>
                            <Box>
                              <Text className={searchHeaderTextClass}>Sync Expense Categories</Text>
                            </Box>
                            <Spacer />
                            <Box>
                              <ButtonQuaternaryWithIcon 
                                name='closeDrawer'
                                iconsize='26px'
                                leftIcon='close'
                                value='Sync Expense Categories'
                                onClick={syncExpenseCategories}
                              />
                            </Box>
                          </Flex>
                          <Box>
                            <TableContainer>
                              <HStack mb='15px'>
                                <Text className={searchHeaderTextClass}>QuickBooks Expense Categories</Text>
                                <Spacer/>
                              </HStack>
                              <Table variant={'compact'} size={'compact'} >
                                <Tbody>
                                  {(dynamodbExpenseCategories?.length===0) && <Tr><Td py='6px' colSpan={4}><Center>No results found</Center></Td></Tr>}
                                  
                                  {dynamodbChartOfAccounts && generateChartOfAccountsRows(dynamodbChartOfAccounts, mappedTeamMembers)}
                                  
                                  <Tr><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
                                </Tbody> 
                              </Table>
                            </TableContainer>
                          </Box>
                        </Box>
                      </>
                    </TabPanel> */}

                  </TabPanels>
                </Tabs>
              </ModuleBox>}
            </Container>

            <ModuleDrawer
              onClose={onEditTransactionClose}
              isOpen={isEditTransactionOpen}
              bodyContent={
                <EditTransaction 
                  handleMapVendor={mapQBVendorToStatementVendor} 
                  transaction={currentEditTransaction} 
                  onClose={onEditTransactionClose} 
                  environment={qbEnv}
                  expenseCategories={expenseCategories}
                  selectedExpenseCategory={selectedExpenseCategoryOption}
                  qbVendors={qbVendors}
                  token={tokenObj?.accessToken||tokenObj?.access_token}
                  register={register}
                  control={control}
                  errors={errors}
                  setError={setError}
                  clearErrors={clearErrors}
                  setValue={setValue}
                  getValues={getValues}
                  updateExpenseCategory={updateSelectedExpenseCategory}
                  rowIndex={currentEditTransactionIndex}
                  updateReceipt={handlePublish}
                  handleSubmit={handleSubmit}
                  adminName={adminName}
                  isPublishing={isPublishingToQuickBooks}
                />}
              size={'full'}
            />

            <ModuleDrawer
              onClose={onEditTransactionVendorClose}
              isOpen={isEditTransactionVendorOpen}
              bodyContent={<EditTransactionVendor 
                  transaction={currentEditTransactionVendor} 
                  onClose={onEditTransactionVendorClose} 
                  environment={qbEnv}
                  qbVendors={qbVendors}
                  mapVendor={mapQBVendorToStatementVendor}
                  token={tokenObj?.accessToken||tokenObj?.access_token}
                  register={register}
                  control={control}
                  errors={errors}
                  setError={setError}
                  clearErrors={clearErrors}
                  setValue={setValue}
                  rowIndex={currentEditTransactionVendorIndex}/>
              }
              size={'full'}
            />

            {(galleryImages?.length>0) && <ReceiptImageViewerModal
              isModalOpen={isReceiptImageViewerModalOpen}   // Corrected prop name to match ReceiptImageViewerModal expectations
              onModalClose={handleReceiptImageViewerModalClose}
              images={galleryImages}
              transaction={galleryTransaction}
            />}
          </>
        )}
      </QuickBooksAuth>
    </>
  )
}


// show transactions with uploaded receipts and their matching transactions from plaid. (fetch both and amtch on lastFour, vendorName, purchaseAmount, purchaseDate)
// for each transaction:
//    - show the transaction date.
//    - show the vendor name.
//    - show the receipt image.
//    - show the transaction amount.
//    - show the transaction category.
//    - show the transaction account.
// show tooltip with details for a quick view of the transaction.
// show approve/deny buttons for each transaction.
// show edit button for each transaction.
// approving a transaction will mark it as approved, upload it to quickbooks (match vendor to merchant?) and will be visible in the approved transactions tab.
// when a merchant does not exist, it gets created in quickbooks

// vendor dropdown
// tabs

// qb vendor dropdown and auto-match using mapped vendor table
// match to vendors, when a merchant does not exist, it gets created in quickbooks
// button to push to QB (approve or deny)
// get receipt image
// mutable detail view
// handle if uploaded receipt is invoice to be paid? table has refund, invoice, receipt
// tabs for ready, denied, pending
// X    no_match_found to pending_match
// plaidSyncTransactions on timer or trigger
// onTabChange does not reload, add refresh button

// X    reset transactions
// X    resync transactions
// verify cursor is there and no duplciate transactions

// getChartOfAccounts calls the Lambda to query quickbooks, follow up on this.

// the goal is to clear out credit card transactions with uploaded receipts and push them to quickbooks.
// all transactions
// all receipts uploaded
// what is left

// assign credit cards to users, need to fetch chartOfAccounts and assign to users

// No modal, drawer opens instantly
// add supplier column with auto-match to vendor
// Tooltip with specs and team member name

// Create table row alert styles:

// dark-table-row-alert
// color: #3E2B00

// dark-table-row-alert-hover
// color: #4B3400