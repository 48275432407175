/* eslint-disable react/prop-types */
import { useEffect, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Container,
  Box,
  HStack,
  Text,
  Flex,
  Spacer,
  useDisclosure,
  List,
  ListItem,
} from "@chakra-ui/react";

// uuid
import { v4 as uuidv4 } from 'uuid';

// modals
import { CenteredSubmitWithProgressModal } from '../Modal/CenteredSubmitWithProgressModal.jsx';
import { delay } from '../functions/generic.jsx';

// buttons
import { ButtonPrimaryPlain } from '../Form/Button/ButtonPrimaryPlain.jsx';
import { ButtonQuaternaryWithIcon } from '../Form/Button/ButtonQuaternaryWithIcon.jsx';
import { ButtonSecondaryPlain } from '../Form/Button/ButtonSecondaryPlain.jsx';
import { ButtonSenaryWithIconOnly } from '../Form/Button/ButtonSenaryWithIconOnly.jsx';

// generic functions
import { todaysDateYMD } from '../functions/dateTime.jsx';

// graphql
import { fetchPrimaryUnitCodes } from '../graphqlCompnents/Unit/fetchPrimaryUnitCodes.jsx';
import { fetchUnitTypes } from '../graphqlCompnents/Unit/fetchUnitTypes.jsx';
import { fetchUnitSubTypes } from '../graphqlCompnents/Unit/fetchUnitSubTypes.jsx';
import { fetchAuxiliaryUnits } from '../graphqlCompnents/Unit/fetchAuxiliaryUnits.jsx';
import { fetchDivisionOptions } from '../graphqlCompnents/Unit/fetchDivisionOptions.jsx';
import { addPrimaryUnit } from '../graphqlCompnents/Unit/addPrimaryUnit.jsx';
import { addPrimaryUnitAuxiliaryUnits } from '../graphqlCompnents/Unit/addPrimaryUnitAuxiliaryUnits.jsx';
import { addPrimaryUnitStatus } from '../graphqlCompnents/Unit/addPrimaryUnitStatus.jsx';
import { addDJTBillableRate } from '../graphqlCompnents/Unit/addDJTBillableRate.jsx';

// structural components
import { ModuleBoxDrawer } from '../Structural/ModuleBoxDrawer.jsx';

// form components
import { TextInput } from '../Form/Input/TextInput.jsx';
import { FormSelectSimple } from '../Form/Select/FormSelectSimple.jsx';
import { FormSwitch } from '../Form/Switch/FormSwitch.jsx';

// globals
import { AppContext } from '../AppContext.jsx';

// custom
import { AvailableIcons } from '../Icons/AvailableIcons.jsx';

export const CreateUnit = (props) => {

  const { 
    onClose 
  } = props

  // grab global context values
  const { store } = useContext(AppContext);

  // set the current users team member id
  const currentTeamMemberId = store?.userData?.id
  // console.warn(' ----- currentTeamMemberId: ', currentTeamMemberId)

  // --- REACT-HOOK-FORM ---
  const { 
    control, 
    register, 
    handleSubmit, 
    clearErrors,
    setValue, 
    formState: { 
      errors, 
    }} = useForm({
      mode: 'onSubmit',
      reValidateMode: 'onBlur',
      defaultValues: {},
  });

  const onError = (errors, e) => console.log(errors, e);

  const { isOpen: isSubmitModalOpen , onOpen: onSubmitModalOpen, onClose: onSubmitModalClose } = useDisclosure()
  const [ submitModalContent, setSubmitModalContent ] = useState(null)
  const [ submitModalCloseDisabled, setSubmitModalCloseDisabled ] = useState(true)
  const [ submissionProgress, setSubmissionProgress ] = useState(0)
  async function handleSubmitModalClosed() {
    onSubmitModalClose()
    await delay(500)
    onClose()
  }

  // const [ selectedActionButton, setSelectedActionButton ] = useState(null)
  // const [ submitButtonText, setSubmitButtonText ] = useState(null)

  const [ isActive, setIsActive ] = useState(true)
  const [ isIrpiftaRegistered, setIsIrpiftaRegistered ] = useState(false)
  const [ isInspectedDaily, setIsInspectedDaily ] = useState(true)
  const [ requiresDriver, setRequiresDriver ] = useState(false)
  const [ requiresOperator, setRequiresOperator ] = useState(false)

  // const [ unitOptions, setUnitOptions ] = useState([])
  const [ unitCodes, setUnitCodes ] = useState([])
  // const [ existingCodes, setExistingCodes ] = useState([])
  useEffect(() => {
    const fetchUnitOptions = async () => {
      try {
        const response = await fetchPrimaryUnitCodes();

        const codes = response.map(unit => ({
          id: unit.id,
          code: unit.code
        }))
        setUnitCodes(codes);

      } catch (error) {
        console.error('Failed to fetch unit codes:', error);
      }
    };

    fetchUnitOptions();
  }, []);

  const [ auxiliaryUnitOptions, setAuxiliaryUnitOptions ] = useState([])
  let auxiliaryUnitSelectedOption = null;
  const [ selectedAuxiliaryUnits, setSelectedAuxiliaryUnits ] = useState([])  // id, name
  useEffect(() => {
    const listAuxiliaryUnits = async () => {
      try {
        const response = await fetchAuxiliaryUnits();
        // console.log('fetchAuxiliaryUnits response: ', response);
        // setAuxiliaryUnits(response);

        const options = response.map(unit => ({
          value: unit.id,
          label: unit.name,
        }))
        setAuxiliaryUnitOptions(options);

      } catch (error) {
        console.error('Failed to fetch auxiliary units:', error);
      }
    };

    listAuxiliaryUnits();
  }, []);

  const [ unitSubTypes, setUnitSubTypes ] = useState([])
  const [ unitSubTypeOptions, setUnitSubTypeOptions ] = useState([])
  const [ unitSubTypeSelectedOption, setUnitSubTypeSelectedOption ] = useState(null)
  useEffect(() => {
    const listUnitSubTypes = async () => {
      try {
        const response = await fetchUnitSubTypes();
        console.log('fetchUnitSubTypes response: ', response);
        setUnitSubTypes(response);

      } catch (error) {
        console.error('Failed to fetch unit sub-types:', error);
      }
    };

    listUnitSubTypes();
  }, []);


  // const [ unitTypes, setUnitTypes ] = useState([])
  const [ unitTypeOptions, setUnitTypeOptions ] = useState([])
  const [ unitTypeSelectedOption, setUnitTypeSelectedOption ] = useState(null)
  useEffect(() => {
    const listUnitTypes = async () => {
      try {
        const response = await fetchUnitTypes();
        // console.log('response: ', response);
        // setUnitTypes(response);

        const options = response.map(unit => ({
          value: unit.id,
          label: unit.name,
        }))
        setUnitTypeOptions(options);
        
      } catch (error) {
        console.error('Failed to fetch unit types:', error);
      }
    };
    
    listUnitTypes();
  }, []);

  const [ divisionOptions, setDivisionOptions ] = useState([])
  const [ divisionSelectedOption, setDivisionSelectedOption ] = useState(null)
  useEffect(() => {
    const fetchDivisions = async () => {
      try {
        const response = await fetchDivisionOptions();
        // console.log('fetchDivisionOptions: ', response);
        const options = response.map(division => ({
          value: division.id,
          label: division.prettyname,
        }))
        setDivisionOptions(options);

      } catch (error) {
        console.error('Failed to fetch divisions:', error);
      }
    };

    fetchDivisions();
  }, []);

  const inspectionStatuses = store?.inspectionStatuses
  // console.warn(' ----- inspectionStatuses: ', inspectionStatuses)
  const inspectionInitialRequired = inspectionStatuses?.filter((inspectionStatus) => {
    return inspectionStatus.statusQuery === 'initial_inspection'
  })

  // onChange handlers

  const handleDivisionChanged = (event) => {
    const selectedOption = divisionOptions.find(division => division.value === event.value);
    setDivisionSelectedOption(selectedOption);
    setValue('selectDivision', selectedOption.value);
    clearErrors('selectDivision');
  }

  //priceCode: {rateTypeId: "shift", price: "450.00"}
  const handleUnitTypeChanged = (event) => {
    const selectedOption = unitTypeOptions.find(unit => unit.value === event.value);
    setUnitTypeSelectedOption(selectedOption);

    const subTypeOptions = unitSubTypes
      .filter(unit => unit.typeId === event.value)
      .map(unit => ({
          value: unit.id,
          label: unit.name,
          rateTypeId: unit?.priceCode?.rateTypeId,
          price: unit?.priceCode?.price,
      }));
      console.log('unitTypeId: ', event.value)
    console.log('subTypeOptions: ', subTypeOptions)
    setUnitSubTypeOptions(subTypeOptions);
    setValue('selectUnitType', selectedOption.value);
    clearErrors('selectUnitType');
  }

  const handleUnitSubTypeChanged = (event) => {
    const selectedOption = unitSubTypeOptions.find(unit => unit.value === event.value);
    setUnitSubTypeSelectedOption(selectedOption);
    setValue('selectUnitSubType', selectedOption.value)
    clearErrors('selectUnitSubType');
  }

  const handleAuxiliaryUnitChanged = (event) => {
    const selectedOption = auxiliaryUnitOptions.find(unit => unit.value === event.value);
    // setAuxiliaryUnitSelectedOption(selectedOption);
    console.log('auxiliaryUnitOptions: ', auxiliaryUnitOptions)

    const updateSelectedAuxiliaryUnits = (selectedOption) => {
      const newAuxiliaryUnit = {
        id: selectedOption.value,
        name: selectedOption.label
      }
      setSelectedAuxiliaryUnits([...selectedAuxiliaryUnits, newAuxiliaryUnit]);

      // Remove the selected option from the auxiliary unit options
      const remainingOptions = auxiliaryUnitOptions.filter(unit => unit.value !== selectedOption.value);
      setAuxiliaryUnitOptions(remainingOptions);
    }

    if (selectedOption) {
      updateSelectedAuxiliaryUnits(selectedOption);
    }
  }

  const handleDeleteAuxiliaryUnit = (unitId) => {
    // Find the unit to be deleted to add it back to options
    const unitToDelete = selectedAuxiliaryUnits.find(item => item.id === unitId);
  
    // Filter out the deleted unit from selected auxiliary units
    const updatedAuxiliaryUnits = selectedAuxiliaryUnits.filter(item => item.id !== unitId);
    setSelectedAuxiliaryUnits(updatedAuxiliaryUnits);
  
    // If the unit to delete exists, add it back to the options and sort them
    if (unitToDelete) {
      setAuxiliaryUnitOptions(prevOptions => {
        const newOptions = [...prevOptions, { value: unitToDelete.id, label: unitToDelete.name }];
        return newOptions.sort((a, b) => a.label.localeCompare(b.label));
      });
    }
  };

  // const [unitIdValue, setUnitIdValue] = useState('');
  const [filteredCodes, setFilteredCodes] = useState([]);
  // const [ unitIdHasError, setUnitIdHasError ] = useState(false)
  let unitIdHasError = false;
  useEffect(() => {
    if (unitIdHasError===false) {
      clearErrors('code')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitIdHasError])

  // const unitCodeRegexPattern = /^[0-9A-Z-_]+$/;
  const unitCodeRegexPattern = /^[0-9]+$/;
  const unitCodeRegexMessage="Unit Id is required and must only contain numbers."
  const handleUnitIdChange = (event) => {
    // let hasUniqueValueError = false
    // let hasRegexError = false
    const value = event.target.value;
    if (value) {
      // setUnitIdValue(value);
      setValue('code', value);
      // console.warn(' ----- unitCodes: ', unitCodes)
      const matchedCodes = unitCodes.filter(unit => unit?.code?.includes(value));
      // console.log('matchedCodes: ', matchedCodes)
      setFilteredCodes(matchedCodes);
    } else {
      setFilteredCodes([]);
    }
    // // Check if the code exists
    // if (existingCodes.includes(value)) {
    //   setError('code', {
    //     type: 'manual',
    //     message: 'Unit ID already exists',
    //   });
    //   hasUniqueValueError = true;
    //   setUnitIdHasError(true)
    // } else {
    //   hasUniqueValueError = false;
    //   setUnitIdHasError(false)
    // }

    // if (!unitCodeRegexPattern.test(value)) {
    //   setError('code', {
    //     type: 'manual',
    //     message: unitCodeRegexMessage,
    //   });
    //   hasRegexError = true
    // } else {
    //   hasRegexError = false
    // }

    // if (!hasUniqueValueError && !hasRegexError) {
    //   clearErrors('code');
    // }
  };

  const handleUnitIdBlur = () => {
    setFilteredCodes([]); // Clear filtered codes list
  }

  const handleKeyDown = (event) => {
    // Check if the key pressed is 'Enter'
    if (event.key === 'Enter' && event.target.type !== 'textarea') {
      event.preventDefault();  // Prevent form submission
    }
  };

  const formSubmit = async (values, event) => {
    // reference AddTeamMember.js for Cognito Add User functionality
    const generateSortableCode = (code) => {
      // Use a regular expression to extract leading digits.
      const matches = code.match(/^\d+/);

      if (!matches) {
        console.error('No leading numeric value found.');
        return code;
      }

      const numberPart = parseInt(matches[0], 10);

      // Convert the number to a string and pad with zeros to ensure it's always 4 digits.
      const sortableCode = numberPart.toString().padStart(4, '0');
      return sortableCode;
    }

    event.preventDefault();
    console.warn(' ----- formSubmit values: ', values)

    // centered modal
    onSubmitModalOpen()
    setSubmitModalContent('Creating new unit...')
    setSubmissionProgress(0)
    setSubmitModalCloseDisabled(true)

    const unitCodeSortable = generateSortableCode(values.code)
    if (!unitCodeSortable) {
      setSubmitModalContent('Failed to generate sortable code. Check the unit code.');
      setSubmitModalCloseDisabled(false);
      return; // Stop execution if code is invalid
    }

    // add this units values using addPrimaryUnit
    const newUnit = {
      allSort: "all",
      code: values?.code,
      codeSortable: unitCodeSortable,
      color: values?.color,
      isActive: isActive,
      isInspectedDaily: isInspectedDaily,
      make: values?.make,
      model: values?.model,
      requiresDriver: requiresDriver,
      requiresOperator: requiresOperator,
      subTypeId: values?.selectUnitSubType,
      typeId: values?.selectUnitType,
      serial: values?.serial,
      irpiftaRegistered: isIrpiftaRegistered,
      tag: values?.tag,
      vin: values?.vin,
      year: values?.year,
      odometer: values?.odometer,
    }

    console.log('newUnit: ', newUnit)

    try {
      const primaryUnitId = await addPrimaryUnit(newUnit);
      console.log('primaryUnitId: ', primaryUnitId);

      setSubmitModalContent('Adding auxiliary units...');
      setSubmissionProgress(25);

      await Promise.all(selectedAuxiliaryUnits?.map(async (unit) => {
        const newAuxiliaryUnit = {
          spkPrimaryUnitTblID: primaryUnitId,
          spkAuxillaryUnitTblID: unit.id,
        };
        console.log('newAuxiliaryUnit: ', newAuxiliaryUnit);
        const response = await addPrimaryUnitAuxiliaryUnits(newAuxiliaryUnit);
        console.log('response: ', response);
      }));

      setSubmitModalContent('Updating unit status...');
      setSubmissionProgress(50);

      const newUnitStatus = {
          allSort: "all",
          code: values.code,
          unitId: primaryUnitId,
          statusId: inspectionInitialRequired[0].id,
          divisionId: values.selectDivision,
          inService: true,
          createdOn: todaysDateYMD(),
      };
      console.log('newUnitStatus: ', newUnitStatus);
      const unitStatusId = await addPrimaryUnitStatus(newUnitStatus);
      console.log('unitStatusId: ', unitStatusId);

      setSubmitModalContent('Setting billable rate...');
      setSubmissionProgress(75);

      // shift, hour, day
      console.info('unitSubTypeSelectedOption: ', unitSubTypeSelectedOption)
      const shiftRate = (unitSubTypeSelectedOption.rateTypeId==='shift') ? unitSubTypeSelectedOption.price : '';
      const hourlyRate = (unitSubTypeSelectedOption.rateTypeId==='hour') ? unitSubTypeSelectedOption.price : '';
      const dayRate = (unitSubTypeSelectedOption.rateTypeId==='day') ? unitSubTypeSelectedOption.price : '';
      const newDJTBillableRate = {
          unitCode: values.code,
          unitId: primaryUnitId,
          hourlyRate: hourlyRate,
          shiftRate: shiftRate,
          dayRate: dayRate,
      };
      console.log('newDJTBillableRate: ', newDJTBillableRate);
      const DJTBillableRateId = await addDJTBillableRate(newDJTBillableRate);
      console.log('DJTBillableRateId: ', DJTBillableRateId);

      setSubmitModalCloseDisabled(false);
      setSubmissionProgress(100);
    } catch (error) {
        console.error('Error during form submission:', error);
        setSubmitModalContent(`Error: ${error.message}`);
        setSubmitModalCloseDisabled(false);
        setSubmissionProgress(0);
    }

    // // Create primary unit and get id, use id to create auxiliary units
    // const primaryUnitId = await addPrimaryUnit(newUnit)
    // console.log('primaryUnitId: ', primaryUnitId)

    // setSubmitModalContent('Adding auxiliary units...')
    // setSubmissionProgress(25)

    // // create auxiliary units
    // selectedAuxiliaryUnits.forEach(async (unit) => {
    //   const newAuxiliaryUnit = {
    //     // primaryUnitId: primaryUnit.id,
    //     primaryUnitId: primaryUnitId,
    //     auxiliaryUnitId: unit.id,
    //   }
    //   console.log('newAuxiliaryUnit: ', newAuxiliaryUnit)
    //   const response = await addPrimaryUnitAuxiliaryUnits(newAuxiliaryUnit)
    //   console.log('response: ', response)
    // })

    // setSubmitModalContent('Updating unit status...')
    // setSubmissionProgress(50)

    // const newUnitStatus = {
    //   allSort: "all",
    //   code: values.code,
    //   unitId: primaryUnitId,
    //   statusId: inspectionInitialRequired[0].id,
    //   divisionId: values.selectDivision,
    //   inService: true,
    //   createdOn: todaysDateYMD(),
    // }
    // console.log('newUnitStatus: ', newUnitStatus)
    // // Create unit status
    // const unitStatusId = await addPrimaryUnitStatus(newUnitStatus)
    // console.log('unitStatusId: ', unitStatusId)

    // // unitCode
    // // unitId
    // // hourlyRate
    // // shiftRate
    // // dayRate
    // // Make sure that a record gets created in SpkDJTPrimaryUnitBillableRateTbl using the data from the subType pricing

    // setSubmitModalContent('Setting billable rate...')
    // setSubmissionProgress(75)

    // const newDJTBillableRate = {
    //   unitCode: values.code,
    //   unitId: primaryUnitId,
    //   hourlyRate: unitSubTypeSelectedOption.priceCode.hourlyRate,
    //   shiftRate: unitSubTypeSelectedOption.priceCode.shiftRate,
    //   dayRate: unitSubTypeSelectedOption.priceCode.dayRate,
    // }
    // console.log('newDJTBillableRate: ', newDJTBillableRate)
    // // Create DJT Billable Rate
    // const DJTBillableRateId = await addDJTBillableRate(newDJTBillableRate)
    // console.log('DJTBillableRateId: ', DJTBillableRateId)

    // setSubmitModalCloseDisabled(false)
    // setSubmissionProgress(100)
      

  }

  return (
    <>
    {/* <Box 
      w={{ 
        base: '1275px', //< 400 screen width
        sm: '1275px', // total width of phone screen
        md: '1275px', // ipad 7th gen target - 810 when with enu nav
        lg: '1275px', 
        xl: '1400px',
        '2xl': '1600px'
      }}
      borderRadius='6px'
      p='25px'
      // mt='25px'
      // ml='25px'
      bg='var(--dark-module-bg)'>
        <UploadUnitFiles 
          documentType={'photo'}
          headerText={'Upload Unit Images'}
        />
    </Box> */}

    <Container as="form" maxW="1600" mb={12} onKeyDown={handleKeyDown} onSubmit={handleSubmit(formSubmit, onError)}>

      <ModuleBoxDrawer mt='25px'>
        <Flex>
          <Box>
            <Text as="span" textStyle='heading-1'>Create New Unit</Text>
          </Box>
          <Spacer />
          <Box>
            <ButtonQuaternaryWithIcon 
              name='closeDrawer'
              iconsize='26px'
              leftIcon='close'
              value='Cancel'
              onClick={onClose}
            />
          </Box>
        </Flex>

        <Box w={'40%'} my={'25px'}>
        <Box>
            <Text as="span" textStyle='label-2'>Unit ID (Example: 123 or 91)</Text>
          </Box>
          <Box display="flex" alignItems="top" pt={'5px'}>
            <AvailableIcons color={'var(--error-red)'} boxSize={'25px'} iconRef={'alerttriangle'} />
            <Text pl={'15px'} as="span" textStyle='text-2-grey-2'>
              Unit IDs are permanent and cannot be changed once created. Please double-check that the ID you enter is correct. Existing unit IDs are shown below as you type.
            </Text>
          </Box>
          <TextInput
            register={register}
            errors={errors}
            fieldname="code"
            // fieldlabel="Unit ID (Example: 123 or 91)"
            prettyname="Unit ID"
            placeholder="Enter unit id"
            // fieldvalue={unitIdValue||''}
            fieldvalue={''}
            onChange={handleUnitIdChange}
            onBlur={handleUnitIdBlur}
            isRequired={true}
            // regexPattern={/^[0-9a-zA-Z-\s]+$/}
            regexPattern={unitCodeRegexPattern}
            regexMessage={unitCodeRegexMessage}
          />
          {filteredCodes.length > 0 && (
            <List spacing={2} mt={2} borderRadius="md" bg={'var(--dark-menu-background)'} maxH="200px" overflowY="scroll">
              {filteredCodes.map(unit => (
                <ListItem key={unit.id} p={2} _hover={{ bg: "gray.100" }}>
                  {unit.code}
                </ListItem>
              ))}
            </List>
          )}
        </Box>

        <Box w={'40%'} my={'25px'}>
          <TextInput
            register={register}
            errors={errors}
            fieldname="make"
            fieldlabel="Make"
            prettyname="Make"
            placeholder="Enter unit make"
          />
        </Box>

        <Box w={'40%'} my={'25px'}>
          <TextInput
            register={register}
            errors={errors}
            fieldname="model"
            fieldlabel="Model"
            prettyname="Model"
            placeholder="Enter unit model"
          />
        </Box>

        <Box w={'40%'} my={'25px'}>
          <TextInput
            register={register}
            errors={errors}
            fieldname="color"
            fieldlabel="Color"
            prettyname="Color"
            placeholder="Enter unit color"
          />
        </Box>

        <Box w={'40%'} my={'25px'}>
          <TextInput
            register={register}
            errors={errors}
            fieldname="year"
            fieldlabel="Year"
            prettyname="Year"
            placeholder="Enter unit year"
          />
        </Box>

        <Box w={'40%'} my={'25px'}>
          <TextInput
            register={register}
            errors={errors}
            fieldname="tag"
            fieldlabel="Tag"
            prettyname="Tag"
            isRequired={true}
            placeholder="Enter unit tag"
          />
        </Box>

        <Box w={'40%'} my={'25px'}>
          <TextInput
            register={register}
            errors={errors}
            fieldname="vin"
            fieldlabel="VIN"
            prettyname="VIN"
            isRequired={true}
            placeholder="Enter unit vin"
          />
        </Box>

        <Box w={'40%'} my={'25px'}>
          <TextInput
            register={register}
            errors={errors}
            fieldname="odometer"
            fieldlabel="Odometer"
            prettyname="Odometer"
            isRequired={false}
            placeholder="Enter unit odometer"
          />
        </Box>

        <Box w={'40%'} my={'25px'}>
          <TextInput
            register={register}
            errors={errors}
            fieldname="serial"
            fieldlabel="Serial No."
            prettyname="Serial No."
            placeholder="Enter unit serial number"
          />
        </Box>

        <Box w={'40%'} my={'25px'}>
          <FormSelectSimple
            register={register}
            control={control}
            errors={errors}
            fieldname="selectDivision"
            fieldlabel="Initial Unit Division"
            isRequired={true}
            rules={{ required: "Division is required" }}
            placeholder="Select unit initial division location"
            optionsArray={divisionOptions||''}
            onChange={(e) => handleDivisionChanged(e)}
            selectedoption={divisionSelectedOption||''}
          />
        </Box>

        <Box w={'40%'} my={'25px'}>
          <FormSelectSimple
            register={register}
            control={control}
            errors={errors}
            fieldname="selectUnitType"
            fieldlabel="Unit Type"
            isRequired={true}
            rules={{ required: "Unit type is required" }}
            placeholder="Select unit type"
            optionsArray={unitTypeOptions||''}
            onChange={(e) => handleUnitTypeChanged(e)}
            selectedoption={unitTypeSelectedOption||''}
          />
        </Box>

        <Box w={'40%'} my={'25px'}>
          <FormSelectSimple
            register={register}
            control={control}
            errors={errors}
            fieldname="selectUnitSubType"
            fieldlabel="Unit Sub-type"
            isRequired={true}
            rules={{ required: "Unit sub-type is required" }}
            placeholder="Select unit sub-type"
            optionsArray={unitSubTypeOptions||''}
            onChange={(e) => handleUnitSubTypeChanged(e)}
            selectedoption={unitSubTypeSelectedOption||''}
            isDisabled={!unitTypeSelectedOption}
          />
        </Box>

        <Box w={'40%'} my={'25px'}>
          <FormSelectSimple
            register={register}
            control={control}
            errors={errors}
            fieldname="selectAuxiliaryUnits"
            fieldlabel="Auxiliary Units"
            placeholder="Select auxiliary units to add to this unit"
            optionsArray={auxiliaryUnitOptions||''}
            onChange={(e) => handleAuxiliaryUnitChanged(e)}
            selectedoption={auxiliaryUnitSelectedOption||''}
          />
        </Box>
        <Box w={'40%'} my={'25px'}>
          <Box>
            <List spacing={'10px'}>
              {selectedAuxiliaryUnits.map((unit, index) => (
                <ListItem key={unit.id} style={{ listStyleType: "none" }}>
                  <ButtonSenaryWithIconOnly
                    mr='5px'
                    key={'aux_unit_list_button_'+index}
                    name={'aux_unit_list_button_'+index}
                    icon='delete'
                    iconsize='22px'
                    onClick={() => handleDeleteAuxiliaryUnit(unit.id)}
                    // onClick={() => {
                    //   const updatedAuxiliaryUnits = selectedAuxiliaryUnits.filter((item) => item.id !== unit.id);
                    //   setSelectedAuxiliaryUnits(updatedAuxiliaryUnits);
                    // }}
                  />
                  <Text as="span" textStyle='dark-sfpro-text-1'>{unit.name}</Text>
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>

        <Box w={'40%'} my={'25px'}>
          <Flex>
            <Box>
              <Text as="span" textStyle='label-1'>Unit is active</Text><br/>
            </Box>
            <Spacer />
            <Box>
              <FormSwitch
                register={register}
                control={control}
                fieldname="isActive"
                isChecked={isActive}
                onChange={() => {
                  setValue('isActive', !isActive)
                  setIsActive(!isActive)
                }}
              />
            </Box>
          </Flex>
        </Box>

        <Box w={'40%'} my={'25px'}>
          <Flex>
            <Box>
              <Text as="span" textStyle='label-1'>Unit is registered IRP/IFTA</Text><br/>
            </Box>
            <Spacer />
            <Box>
              <FormSwitch
                register={register}
                control={control}
                fieldname="isIrpiftaRegistered"
                isChecked={isIrpiftaRegistered}
                onChange={() => {
                  setValue('isIrpiftaRegistered', !isIrpiftaRegistered)
                  setIsIrpiftaRegistered(!isIrpiftaRegistered)
                }}
              />
            </Box>
          </Flex>
        </Box>

        <Box w={'40%'} my={'25px'}>
          <Flex>
            <Box>
              <Text as="span" textStyle='label-1'>Unit is inspected daily</Text><br/>
            </Box>
            <Spacer />
            <Box>
              <FormSwitch
                register={register}
                control={control}
                fieldname="isInspectedDaily"
                isChecked={isInspectedDaily}
                onChange={() => {
                  setValue('isInspectedDaily', !isInspectedDaily)
                  setIsInspectedDaily(!isInspectedDaily)
                }}
              />
            </Box>
          </Flex>
        </Box>

        <Box w={'40%'} my={'25px'}>
          <Flex>
            <Box>
              <Text as="span" textStyle='label-1'>Unit requires driver</Text><br/>
            </Box>
            <Spacer />
            <Box>
              <FormSwitch
                register={register}
                control={control}
                fieldname="requiresDriver"
                isChecked={requiresDriver}
                onChange={() => {
                  setValue('requiresDriver', !requiresDriver)
                  setRequiresDriver(!requiresDriver)
                }}
              />
            </Box>
          </Flex>
        </Box>

        <Box w={'40%'} my={'25px'}>
          <Flex>
            <Box>
              <Text as="span" textStyle='label-1'>Unit requires operator</Text><br/>
            </Box>
            <Spacer />
            <Box>
              <FormSwitch
                register={register}
                control={control}
                fieldname="requiresOperator"
                isChecked={requiresOperator}
                onChange={() => {
                  setValue('requiresOperator', !requiresOperator)
                  setRequiresOperator(!requiresOperator)
                }}
              />
            </Box>
          </Flex>
        </Box>

        <HStack my={'25px'} key={uuidv4()}>
          <Box w={'40%'}>
            <ButtonSecondaryPlain 
              width={'100%'}
              name='cancel'
              value='Cancel'
              onClick={onClose}
              // onClick={() => {
              //   setSelectedActionButton(null)
              // }}
              // onClick={() =>  handleInvoiceReset()}
              // isDisabled={!isFormLoaded}
            />  
          </Box>

          <Box w={'60%'}>
            <ButtonPrimaryPlain
              type="submit"
              onClick={handleSubmit(formSubmit, onError)}
              width={'100%'}
              name='submit'
              value={'Create New Unit'}
              // isDisabled={!isFormLoaded || djtFields?.length===0 || (attachableObjects?.length!==djtFields?.length)}
            />
          </Box>
        </HStack>
      </ModuleBoxDrawer>


    </Container>
    <CenteredSubmitWithProgressModal
      isModalOpen={isSubmitModalOpen}
      onModalClose={onSubmitModalClose}
      content={submitModalContent}
      closeButtonDisabled={submitModalCloseDisabled}
      handleSubmitModalClosed={handleSubmitModalClosed}
      progress={submissionProgress}
      successMessage={'Unit has been created.'}
      closeButtonVisible={true}
    />
    </>
  )
}
