import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useOAuth2 } from "@tasoskakour/react-use-oauth2";

export const QuickBooksAuth = ({ qbAppId = 'Invoices', qbEnv = 'sandbox', children, onTokenChange }) => {
  const [isLoggedInQB, setIsLoggedInQB] = useState(false);
  const [authCheckInterval, setAuthCheckInterval] = useState(null);

  let clientId = '';
  let appUrl = '';

  if (qbAppId === 'Invoices') {
    clientId = qbEnv === 'production'
      ? 'ABP8PJqGvfaI3tNtXuvKC9iwnL9ZUIDyN8plAbDrvihUbeF697'
      : 'ABrc9XINDdYVQiBR51Buxkl84gNwrX8mHLi4zfwYhfPTPgHvhL';
    appUrl = 'ywr5n26siri25kupsa7noxc5hu0xdiwk';
  } else if (qbAppId === 'ManageExpenses') {
    clientId = qbEnv === 'production'
      ? 'ABCZV9104pvuddVgnTpQQMPBLOhTw9ZdxJ6PUh2OZa9VIACOmc'
      : 'ABr4PG9kkm2kV0F9dBiLyCpcJoUMjO24byqykNIN6sOSFBLiuV';
    appUrl = 'ivwnuafbelpqvtxjz7hdispzim0uarwx';
  }

  
  // if (qbAppId === 'Invoices') {
  //   clientId = qbEnv === 'production'
  //     ? 'ABP8PJqGvfaI3tNtXuvKC9iwnL9ZUIDyN8plAbDrvihUbeF697'
  //     : 'ABrc9XINDdYVQiBR51Buxkl84gNwrX8mHLi4zfwYhfPTPgHvhL';
  //   appUrl = 'ywr5n26siri25kupsa7noxc5hu0xdiwk';
  // }

  // if (qbAppId === 'ManageExpenses') {
  //   clientId = qbEnv === 'production'
  //     ? 'ABCZV9104pvuddVgnTpQQMPBLOhTw9ZdxJ6PUh2OZa9VIACOmc'
  //     : 'ABr4PG9kkm2kV0F9dBiLyCpcJoUMjO24byqykNIN6sOSFBLiuV';
  //   appUrl = 'ivwnuafbelpqvtxjz7hdispzim0uarwx';
  // }

  const redirectUri = `${document.location.origin}/callback`;

  const handleError = (error) => {
    console.error("QuickBooks OAuth Error:", error.message);
    if (error.message.includes("Popup was closed")) {
      console.warn("Authentication was cancelled.");
    } else {
      console.warn("Authentication failed. Please try again.");
    }
  };

  const handleTokenSuccess = (payload) => {
    if (payload?.error) {
      setIsLoggedInQB(false);
      console.error("QB OAuth2 Error", payload.error);
    } else {
      try {
        const accessTokenObject = {
          accessToken: payload.access_token,
          expirationTime: new Date().getTime() + Number(payload.expires_in) * 1000,
        };
        localStorage.setItem('accessToken', JSON.stringify(accessTokenObject));
        localStorage.setItem('refreshToken', payload.refresh_token);
        setIsLoggedInQB(true);
        onTokenChange(payload);
      } catch (error) {
        console.error("Error storing tokens:", error);
      }
    }
  };

  const {
    data,
    loading,
    getAuth,
    logout,
  } = useOAuth2({
    authorizeUrl: "https://appcenter.intuit.com/connect/oauth2",
    clientId: `${clientId}`,
    redirectUri: redirectUri,
    scope: "com.intuit.quickbooks.accounting",
    responseType: "code",
    exchangeCodeForTokenQueryFn: async (callbackParameters) => {
      const response = await fetch(`https://${appUrl}.lambda-url.us-east-1.on.aws?qbenv=${qbEnv}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...callbackParameters,
          qbenv: qbEnv,
          client_id: clientId,
          redirect_uri: redirectUri,
        }),
      });
      if (!response.ok) throw new Error('Failed to exchange code for token');
      return response.json();
    },
    onSuccess: (payload) => handleTokenSuccess(payload),
    onError: handleError,
  });

  useEffect(() => {
    const restoreSession = async () => {
      const isLoggedIn = await checkLoginStatus();
      setIsLoggedInQB(isLoggedIn);
    };

    // Call restore session on mount
    restoreSession();

    // Set up interval for token refresh
    const interval = setInterval(async () => {
      const storedToken = localStorage.getItem('accessToken');
      if (storedToken) {
        const { expirationTime } = JSON.parse(storedToken);
        if (new Date().getTime() > expirationTime - 60000) { // Refresh 1 minute before expiry
          await refreshAuthToken(localStorage.getItem('refreshToken'));
        }
      }
    }, 5 * 60 * 1000); // Every 5 minutes

    setAuthCheckInterval(interval);

    return () => clearInterval(interval); // Cleanup
  }, [qbEnv]);

  

  

  const refreshAuthToken = async (refreshToken) => {
    if (!refreshToken) {
      console.warn("No refresh token available.");
      return false;
    }

    try {
      const response = await fetch(`https://${appUrl}.lambda-url.us-east-1.on.aws/refresh`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          refresh_token: refreshToken,
          qbenv: qbEnv,
          client_id: clientId,
          redirect_uri: redirectUri,
        }),
      });

      if (!response.ok) throw new Error('Failed to refresh token');

      const tokenData = await response.json();
      localStorage.setItem('accessToken', JSON.stringify({
        accessToken: tokenData.access_token,
        expirationTime: new Date().getTime() + Number(tokenData.expires_in) * 1000,
      }));
      localStorage.setItem('refreshToken', tokenData.refresh_token);

      setIsLoggedInQB(true);
      return true;
    } catch (error) {
      console.error("Token refresh failed:", error);
      setIsLoggedInQB(false);
      return false;
    }
  };

  const checkLoginStatus = async () => {
    const storedToken = localStorage.getItem('accessToken');
    if (!storedToken) return false;

    const { expirationTime } = JSON.parse(storedToken);
    if (new Date().getTime() > expirationTime) {
      console.warn("Token expired. Attempting to refresh.");
      return await refreshAuthToken(localStorage.getItem('refreshToken'));
    }

    return true;
  };

  const loginQB = () => getAuth();

  const logoutQB = () => {
    console.log("Logging out...");
    logout();
    setIsLoggedInQB(false);
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    onTokenChange(null);
  };

  if (loading) return <div>Loading...</div>;

  return children({
    isLoggedInQB,
    loginQB,
    logoutQB,
    tokenObj: data,
  });
};

QuickBooksAuth.propTypes = {
  qbAppId: PropTypes.oneOf(['Invoices', 'ManageExpenses']),
  qbEnv: PropTypes.oneOf(['production', 'sandbox']).isRequired,
  children: PropTypes.func.isRequired,
  onTokenChange: PropTypes.func.isRequired,
};

export default QuickBooksAuth;