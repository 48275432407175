import { getSpkCapitalOneInstantNotification } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from "aws-amplify/api";
const client = generateClient();

export const fetchQuickTransaction = async (id) => {
  try {
    if (!id) return ('error fetchQuickTransaction: id is required');
    
    const gqlParams = { id: id };
    const response = await client.graphql({
      query: getSpkCapitalOneInstantNotification,
      variables: gqlParams
    });
console.info('fetchQuickTransaction response', response);
    const results = response.data.getSpkCapitalOneInstantNotificationTbl;
    return results;
    
  } catch (err) {
    return ('error fetchQuickTransaction: ', [err]);
  }
};

fetchQuickTransaction.propTypes = {
  id: PropTypes.string.isRequired,
};