import { listStatementDocumentUnitsByTransactionId } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const fetchUnitsByTransactionId = async (transactionId) => {
  try {
    let gqlParams = {
      transactionId: transactionId,
    };

    console.log('gqlParams: ', gqlParams);

    const response = await client.graphql({
      query: listStatementDocumentUnitsByTransactionId,
      variables: gqlParams,
    });

    console.log('response: ', response);

    const results = response.data.statementDocumentUnitByTransactionId.items;
    return results;
    
  } catch (err) {
    return('error fetchUnitsByTransactionId: ', [err]);
  }
};

fetchUnitsByTransactionId.propTypes = {
  transactionId: PropTypes.string.isRequired,
};