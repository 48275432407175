import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Papa from "papaparse";

import { fetchTransactionsByDateRange } from '../graphqlCompnents/Statement/fetchTransactionsByDateRange.jsx';


// NOW NEED TO HANDLE FOR:
// 1. CREDIT/DEBIT
// 2. TRANSACTIONS WITH RECEIPTS



// Helper function to group and sort transactions
const groupAndSortTransactions = (transactions) => {
  const grouped = transactions.reduce((acc, transaction) => {
    const cardNo = transaction["Card No."];
    if (!acc[cardNo]) acc[cardNo] = [];
    acc[cardNo].push(transaction);
    return acc;
  }, {});

  Object.keys(grouped).forEach((cardNo) => {
    grouped[cardNo].sort((a, b) =>
      new Date(a["Posted Date"]) - new Date(b["Posted Date"])
    );
  });

  return grouped;
};

// Helper function to calculate group totals
const calculateGroupTotals = (group) => {
  let debitTotal = 0;
  let creditTotal = 0;

  group.forEach((transaction) => {
    debitTotal += parseFloat(transaction.Debit || 0);
    creditTotal += parseFloat(transaction.Credit || 0);
  });

  return { debitTotal, creditTotal };
};

export const StatementUploader = () => {
  const [error, setError] = useState(null);
  const [parsedData, setParsedData] = useState(null); // Store parsed JSON here
  const [ transactionsByDateRange, setTransactionsByDateRange ] = useState([]);
  const [ transactionStartDate, setTransactionStartDate ] = useState('');
  const [ transactionEndDate, setTransactionEndDate ] = useState('');
  const [processedData, setProcessedData] = useState(null); // Final data with match info

  // useEffect(() => {
  //   const fetchTransactions = async () => {
  //     try {
  //       const publishedTransactions = await fetchTransactionsByDateRange("published", transactionStartDate, transactionEndDate, 1500);
  //       const pendingTransactions = await fetchTransactionsByDateRange("pending_match", transactionStartDate, transactionEndDate, 1500);
        
  //       const mergedTransactions = {
  //         items: [...publishedTransactions, ...pendingTransactions],
  //         nextToken: null,
  //         __typename: "ModelSpkPlaidCreditCardTransactionTblConnection"
  //       };
        
  //       console.log('Merged transactions:', mergedTransactions);
  //       setTransactionsByDateRange(mergedTransactions);
  //     } catch (error) {
  //       console.error('Error fetching transactions:', error);
  //     }
  //   };
  
  //   if (transactionStartDate && transactionEndDate) {
  //     fetchTransactions();
  //   }
  // }, [parsedData, transactionStartDate, transactionEndDate]);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const publishedTransactions = await fetchTransactionsByDateRange("published", transactionStartDate, transactionEndDate, 1500);
        const pendingTransactions = await fetchTransactionsByDateRange("pending_match", transactionStartDate, transactionEndDate, 1500);

        const mergedTransactions = [...publishedTransactions, ...pendingTransactions];
        console.log('Merged transactions:', mergedTransactions);

        setTransactionsByDateRange(mergedTransactions); // Just store the fetched data here
      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    };

    if (transactionStartDate && transactionEndDate) {
      fetchTransactions();
    }
  }, [transactionStartDate, transactionEndDate]);

  const normalizeAmount = (amount) => {
    // Convert to float and ensure two decimal places
    return parseFloat(amount || 0).toFixed(2);
  };

  const findMatchingTransactions = (uploadedTransactions, mergedTransactions) => {
    console.log('Uploaded transactions:', uploadedTransactions);
    console.log('Merged transactions:', mergedTransactions);
  
    // Map to quickly look up merged transactions
    const mergedLookup = mergedTransactions.reduce((acc, transaction) => {
      const normalizedAmount = normalizeAmount(transaction.amount); // Normalize the amount
      const key = `${transaction.accountNumber}-${normalizedAmount}-${transaction.date}`; // Matching criteria
      // console.log('A Key:', key);
      acc[key] = transaction; // Store the full transaction object to access 'store'
      return acc;
    }, {});

    console.log('Merged lookup:', mergedLookup);
  
    // Check for matches in uploaded data
    return uploadedTransactions.map((transaction) => {
      const normalizedAmount = normalizeAmount(transaction.Debit || transaction.Credit); // Normalize the amount
      const key = `${transaction["Card No."]}-${normalizedAmount}-${transaction["Posted Date"]}`; // Matching criteria
      // console.log('B Key:', key);
  
      // Find a match in the lookup
      const match = mergedLookup[key];
      return {
        ...transaction,
        isMatch: !!match, // Set isMatch to true if a match is found
        store: match ? match.store : null, // Add the store field from the matched transaction
        status: match ? match.status : null, // Add the status field from the matched transaction
        quickbooksExpenseId: match ? match.capitalOneCreditCardTransactionReceipt?.quickbooksExpenseId : null, // Add the quickbooksExpenseId
        transactionType: match ? match.capitalOneCreditCardTransactionReceipt?.transactionType : null, // Add the transactionType
        matchStatus: match ? match.capitalOneCreditCardTransactionReceipt?.matchStatus : null, // Add the matchStatus
      };
    });
  };

  useEffect(() => {
    if (parsedData && transactionsByDateRange.length > 0) {
      // Find matching transactions
      const matchedData = findMatchingTransactions(parsedData, transactionsByDateRange);
      console.log('Matched data:', matchedData);
      setProcessedData(matchedData); // Update the processed data with match info
    }
  }, [parsedData, transactionsByDateRange]); // Run only when these dependencies change

  const handleFileUpload = (event) => {
    setError(null); // Reset error state
    const file = event.target.files[0]; // Get the selected file

    if (!file) {
      setError("No file selected.");
      return;
    }

    // Validate that the uploaded file is a CSV file
    if (!file.type.includes("csv")) {
      setError("Please upload a valid CSV file.");
      return;
    }

    // Use PapaParse to parse the CSV file
    Papa.parse(file, {
      header: true, // Convert rows into JSON objects based on CSV headers
      skipEmptyLines: true, // Skip empty lines in the CSV
      complete: (results) => {
        if (results.errors.length) {
          setError("Failed to parse the CSV file. Please check the format.");
        } else {
          const data = results.data;

          // Find the earliest and latest `Posted Date`
          const postedDates = data.map((row) => new Date(row["Posted Date"]));
          const earliestDate = new Date(Math.min(...postedDates));
          const latestDate = new Date(Math.max(...postedDates));

          // Save parsed data and date ranges to state
          setParsedData(data);
          setTransactionStartDate(earliestDate.toISOString().split("T")[0]); // Save as YYYY-MM-DD
          setTransactionEndDate(latestDate.toISOString().split("T")[0]); // Save as YYYY-MM-DD
        }
      },
      error: (err) => {
        setError(`Error parsing CSV: ${err.message}`);
      },
    });
  };

  return (
    <div>
      <label>
        <input
          type="file"
          accept=".csv" // Limit to CSV files
          onChange={handleFileUpload}
          style={{ display: "none" }} // Hide the native input
        />
        <button
          type="button"
          onClick={() => document.querySelector("input[type=file]").click()}
        >
          Upload CSV
        </button>
      </label>

      {error && <p style={{ color: "red" }}>{error}</p>}

      {/* Display date range */}
      {transactionStartDate && transactionEndDate && (
        <div style={{ marginTop: "20px" }}>
          <p><strong>Earliest Posted Date:</strong> {transactionStartDate}</p>
          <p><strong>Latest Posted Date:</strong> {transactionEndDate}</p>
        </div>
      )}

      {/* Display grouped and sorted data as a table */}
      {parsedData && (
        <table border="1" style={{ width: "100%", marginTop: "20px", textAlign: "left" }}>
          <thead>
            <tr>
              <th>Stmt.<br/>Card No.</th>
              <th>Stmt.<br/>Trans. Date</th>
              <th>Stmt.<br/>Posted Date</th>
              <th>Stmt.<br/>Description</th>
              <th>Trans.<br/>Store</th>
              <th>Trans.<br/>Type</th>
              <th>Stmt.<br/>Debit</th>
              <th>Stmt.<br/>Credit</th>
              <th>Status</th>
              <th>Match Status</th>
            </tr>
          </thead>
          <tbody>
            {/* Ensure processedData is used here */}
            {processedData &&
              Object.entries(groupAndSortTransactions(processedData)).map(
                ([cardNo, transactions]) => {
                  const { debitTotal, creditTotal } = calculateGroupTotals(transactions);

                  return (
                    <React.Fragment key={cardNo}>
                      {/* Render transactions for the card */}
                      {transactions.map((transaction, index) => (
                        <tr
                          key={index}
                          style={{
                            // backgroundColor: transaction.isMatch ? "#aaaaaa" : "transparent",
                            color: transaction.isMatch ? "var(--success-green)" : "var(--error-red)",
                          }}
                        >
                          <td>{transaction["Card No."]}</td>
                          <td>{transaction["Transaction Date"]}</td>
                          <td>{transaction["Posted Date"]}</td>
                          <td>{transaction.Description}</td>
                          <td>{transaction.store}</td>
                          <td>{transaction?.transactionType}</td>
                          <td>{transaction.Debit || "0.00"}</td>
                          <td>{transaction.Credit || "0.00"}</td>
                          <td>{transaction.status==="pending_match" && "ERP Only"} {transaction.status==="published" && "QuickBooks"}</td>
                          <td>{transaction.matchStatus || "no receipt"}</td>
                        </tr>
                      ))}

                      {/* Subtotal row */}
                      <tr style={{ fontWeight: "bold" }}>
                        <td colSpan="2">Subtotal for Card {cardNo}</td>
                        <td></td><td></td><td></td><td></td>
                        <td>{debitTotal.toFixed(2)}</td>
                        <td>{creditTotal.toFixed(2)}</td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr><td colSpan="10">&nbsp;</td></tr>
                    </React.Fragment>
                  );
                }
              )}
          </tbody>
        </table>
      )}
    </div>
  );
};

// PropTypes for validation
StatementUploader.propTypes = {
  onFileParsed: PropTypes.func,
};