const MainFooter = () => {
  // Combine major version and commit hash for the full version
  const version = `${import.meta.env.VITE_MAJOR_VERSION}.${import.meta.env.VITE_COMMIT_HASH}`;

  return (
    <footer className="footer">
      <p>Version: {version}</p>
    </footer>
  );
};

export default MainFooter;