import { useState, useEffect, useContext } from 'react';
import {
  Box,
  // Button,
  Flex,
  Image,
  Text,
  Container,
  useDisclosure,
  Center,
  Stack,
  IconButton,
} from "@chakra-ui/react";

import { AvailableIcons } from '../Icons/AvailableIcons.jsx';

// import confetti from 'canvas-confetti';

// import { GlobalWorkerOptions } from 'pdfjs-dist/build/pdf';
// import * as pdfjsLib from 'pdfjs-dist';

import PropTypes from 'prop-types';

// uuid
// import { v4 as uuid } from 'uuid';

// react-hook-form
import { useForm } from 'react-hook-form';

// form components
import { TextInput } from '../Form/Input/TextInput.jsx';
import { FormSelectSimple } from '../Form/Select/FormSelectSimple.jsx';
import { TextareaInput } from '../Form/Input/TextareaInput.jsx';
// import { TextareaInputV2 } from '../Form/Input/TextareaInputV2.jsx';
import { FormSelectMulti } from '../Form/Select/FormSelectMulti.jsx';
import { stateOptions } from '../Form/Select/stateOptions.jsx';

// form buttons
import { ButtonPrimaryPlain } from '../Form/Button/ButtonPrimaryPlain.jsx';
import { ButtonQuaternaryPlain } from '../Form/Button/ButtonQuaternaryPlain.jsx';

import { ResponsiveCancelCloseButton } from '../Form/Button/ResponsiveCancelCloseButton.jsx';

// graphql and external components
import { fetchDivisionOptions } from '../graphqlCompnents/Statement/fetchDivisionOptions.jsx';
import { fetchContactCompaniesByDivision } from '../graphqlCompnents/DJT/fetchContactCompaniesByDivision.jsx';
import { fetchAllUnits } from '../graphqlCompnents/Unit/fetchAllUnits.jsx';
import { fetchQuickBooksExpenseCategories } from '../graphqlCompnents/Statement/fetchQuickBooksExpenseCategories.jsx';
import { fetchClientsForAllDivisions } from '../graphqlCompnents/DJT/fetchClientsForAllDivisions.jsx';
import { updateCreditCardTransactionReceipt } from '../graphqlCompnents/Statement/updateCreditCardTransactionReceipt.jsx';
import { updateTransaction } from '../graphqlCompnents/Statement/updateTransaction.jsx';
import { addIFTAFuelTracking } from '../graphqlCompnents/Statement/addIFTAFuelTracking.jsx';
import { updateIFTAFuelTracking } from '../graphqlCompnents/Statement/updateIFTAFuelTracking.jsx';
import { fetchUnitsByTransactionId } from '../graphqlCompnents/Statement/fetchUnitsByTransactionId.jsx';
import { removeStatementDocumentUnit } from '../graphqlCompnents/Statement/removeStatementDocumentUnit.jsx';
import { addStatementDocumentUnit } from '../graphqlCompnents/Statement/addStatementDocumentUnit.jsx';

// generic funcitons
// import { stripToNumbersAndDecimal } from '../functions/number.jsx';
import { spellOutDate } from '../functions/dateTime.jsx';

// globals
import { AppContext } from '../AppContext.jsx';

// modal
import { CenteredSingleButtonConfirmationModal } from '../Modal/CenteredSingleButtonConfirmationModal.jsx';
import { delay } from '../functions/generic.jsx';
// import { del } from 'aws-amplify/api';

// pdf preview
import { GlobalWorkerOptions } from 'pdfjs-dist/build/pdf';
import * as pdfjsLib from 'pdfjs-dist';

export const AttachReceipt = (props) => {  

  const {
    uploadedReceipts,
    transaction,
    // selectedActionType,
    screenWidth,
    onClose
  } = props;

  // console.warn('transaction props: ', props);

  useEffect(() => {
    // Set the workerSrc to the file in the public folder
    GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs';
  }, []);

  const hasInstantNotification = transaction?.hasInstantNotification||false;
  const instantNotificationId = hasInstantNotification ? transaction?.id : null;
  const creditCardTransactionId = hasInstantNotification ? null : transaction?.id;
  // console.log('hasInstantNotification: ', hasInstantNotification)
  // console.log('instantNotificationId: ', instantNotificationId)
  // console.log('creditCardTransactionId: ', creditCardTransactionId)
  // console.log('attachReceiptParams: ', transaction)
  const plaidCreditCardTransactionId = transaction.id||null;


  const transactionId = transaction?.id || ''; // SpkPlaidCreditCardTransactionTbl id
  const transactionHasInstantNotification = transaction?.hasInstantNotification===true ? true : false;

  // grab global context values
  const { store } = useContext(AppContext);

  const userIsMobile = store?.userIsMobile;

  // get the current environment sandbox || production
  const qbEnv = store?.qbEnvironment || 'sandbox'

  // set the current users team member id
  const currentTeamMemberId = store?.userData?.id

  // current users default division
  // const usersDivisionId = store?.userData?.divisionId

  // current user groups
  // const userGroups = store?.userData?.groups

  // const isSupervisor = userGroups?.includes('supervisor')

  // modal
  const { isOpen: isConfirmModalOpen , onOpen: onConfirmModalOpen, onClose: onConfirmModalClose } = useDisclosure()
  const [ confirmationModalContent, setConfirmationModalContent ] = useState(null);
  const [ confirmationModalHeaderText, setConfirmationModalHeaderText ] = useState(null);
  const [ showConfirmationModal, setShowConfirmationModal ] = useState(false);
  // const [ isAlreadyUploaded, setIsAlreadyUploaded ] = useState(null);
  // const [ isEditMode, setIsEditMode ] = useState(false);
  // const [ uploadingNewReceipt, setUploadingNewReceipt ] = useState(false);
  const [ currentReceipt, setCurrentReceipt ] = useState(null);
  // const currentReceiptRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDataReady, setIsDataReady] = useState(false);
  const [originalSavedUnits, setOriginalSavedUnits] = useState([]);
  const [originalIFTATaxTrackingId, setOriginalIFTATaxTrackingId] = useState(null);

  async function handleConfirmModalClosed() {
    // either redirect to user receipts or close the modal
    onConfirmModalClose()
    await delay(500)
    handleClose();
  }

  const [ previewImage, setPreviewImage ] = useState('');
  const [ signedUrl, setSignedUrl ] = useState('');

  useEffect(() => {
    console.log('currentReceipt: ', currentReceipt);
    if (currentReceipt?.signedUrls?.length > 0) {
      setSignedUrl(currentReceipt.signedUrls[0]);
    } else if (currentReceipt?.url) {
      setSignedUrl(currentReceipt.url);
    }
  }, [currentReceipt]);

  useEffect(() => {
    // generatePreview function
    const generatePreview = async (signedURL) => {
      function extractFileTypeFromUrl(url) {
        try {
          // Extract the portion of the URL before the query parameters
          const urlWithoutParams = url.split('?')[0];
          
          // Extract the file extension using regex or by splitting on "."
          const fileType = urlWithoutParams.split('.').pop();
          
          // Validate that the file type is meaningful (e.g., not empty)
          if (fileType && fileType.length > 0) {
            return fileType;
          } else {
            throw new Error("File type could not be determined");
          }
        } catch (error) {
          console.error("Error extracting file type:", error.message);
          return null;
        }
      }
      const fileType = extractFileTypeFromUrl(signedURL);

      if (fileType === 'pdf') {
        const pdfPreview = await generatePdfPreview(signedURL);
        console.log('pdfPreview: ', pdfPreview);
        setPreviewImage(pdfPreview);  // Set the preview in the
        return 
      } else if (['jpg', 'jpeg', 'png'].includes(fileType.toLowerCase())) {
        const imagePreview = signedURL;
        console.log('imagePreview: ', imagePreview);
        setPreviewImage(imagePreview);  // Set the preview in the
        return
      } else {
        throw new Error('Unsupported file type for preview generation');
      }
    };
    
    // generatePdfPreview function
    const generatePdfPreview = async (pdfUrl) => {
      const pdf = await pdfjsLib.getDocument(pdfUrl).promise;
      const page = await pdf.getPage(1);
    
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const viewport = page.getViewport({ scale: 1.0 });
    
      canvas.height = viewport.height;
      canvas.width = viewport.width;
    
      await page.render({ canvasContext: context, viewport }).promise;
      
      return canvas.toDataURL();  // Return the preview as a data URL
    };
    // Generate a preview for the file (either an image or a PDF)
    (signedUrl?.length>1) && generatePreview(signedUrl);  // Generate preview
  },[signedUrl])

  // const canvasRef = useRef(null);
  // const myConfetti = useRef(null); // Store confetti instance in a ref

  // const defaults = {
  //   spread: 360,
  //   ticks: 50,
  //   gravity: 0,
  //   decay: 0.94,
  //   startVelocity: 30,
  //   colors: ['#FFE400', '#FFBD00', '#E89400', '#FFCA6C', '#FDFFB8']
  // };

  // useEffect(() => {
  //   if (canvasRef.current && !myConfetti.current) {
  //     myConfetti.current = confetti.create(canvasRef.current, {
  //       resize: true,
  //       useWorker: true
  //     });
  //   }
  // }, [canvasRef.current]);

  // const shoot = () => {
  //   if (myConfetti.current) {
  //     // Star confetti
  //     myConfetti.current({
  //       ...defaults,
  //       particleCount: 40,
  //       scalar: 1.2,
  //       shapes: ['star']
  //     });

  //     // Small circle confetti for contrast
  //     myConfetti.current({
  //       ...defaults,
  //       particleCount: 10,
  //       scalar: 0.75,
  //       shapes: ['circle']
  //     });
  //   }
  // };

  // const triggerConfetti = () => {
  //   shoot(); // First shot
  //   setTimeout(shoot, 100); // Second shot
  //   setTimeout(shoot, 200); // Third shot
  // };

  // useEffect(() => {
  //   // Set the workerSrc to the file in the public folder
  //   GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs';
  // }, []);

  useEffect(() => {
    if (showConfirmationModal && confirmationModalContent && confirmationModalHeaderText) {
      // triggerConfetti();
      onConfirmModalOpen();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[showConfirmationModal, confirmationModalContent, confirmationModalHeaderText])

  // --- REACT-HOOK-FORM ---
  const { 
    control, 
    register, 
    reset,
    handleSubmit, 
    clearErrors,
    setValue,
    formState: { 
      isSubmitting,
      errors
    }} = useForm({
      mode: 'onSubmit',
      reValidateMode: 'onBlur',
      // reValidateMode: 'onChange',
      defaultValues: {
        units: [],
        odometer: '',
        gallons: '',
        addedNotes: '',
        selectExpenseCategory: '',
        selectDivision: '',
        selectClient: '',
        selectState: '',
        selectOTRUnit: '',
      },
  });
  
  const onError = (errors, e) => console.log(errors, e);

  const [selectedExpenseCategory, setSelectedExpenseCategory] = useState('');
  const [selectedDivision, setSelectedDivision] = useState('');
  const [selectedClient, setSelectedClient] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [selectedIFTAUnitOption, setSelectedIFTAUnitOption] = useState('');
  const [clientOptions, setClientOptions] = useState([]);
  const [allClientOptions, setAllClientOptions] = useState([]);

  const [scale, setScale] = useState(1); // Track zoom level
  
  const handleZoom = (zoomIn) => {
    setScale((prevScale) => {
      const newScale = zoomIn ? prevScale + 0.2 : prevScale - 0.2;
      return Math.max(1, Math.min(newScale, 3)); // Limit scale between 1x and 3x
    });
  };

  const resetZoom = () => {
    setScale(1); // Reset zoom level to default
  };

  // fetch all expense categories shared by all users
  const [ expenseCategories, setExpenseCategories ] = useState([])
  useEffect(() => {
    const fetchAllExpenseCategories = async () => {
      try {
        const response = await fetchQuickBooksExpenseCategories(qbEnv);
        const categories = response.map(expense => ({
          value: expense.qbId,
          label: expense.appName,
          isIFTATracked: (expense?.isIFTATracked===true) ? true : false,
        }));

        setExpenseCategories(categories);
      } catch (error) {
        console.error('Failed to fetch expense categories:', error);
      }
    };

    fetchAllExpenseCategories();
  }, [qbEnv]);

  // fetch all divisions shared by all users
  const [ divisionOptions, setDivisionOptions ] = useState([])
  useEffect(() => {
    const fetchDivisions = async () => {
      try {
        const responseAll = await fetchDivisionOptions('all');
        const optionsAll = responseAll.map(division => ({
          value: division.id,
          label: division.prettyname,
        }));
  
        // Fetch divisions for 'special'
        const responseSpecial = await fetchDivisionOptions('special');
        const optionsSpecial = responseSpecial.map(division => ({
          value: division.id,
          label: division.prettyname,
        }));
  
        // Combine and sort options
        const combinedOptions = [...optionsAll, ...optionsSpecial].sort((a, b) => 
          a.label.localeCompare(b.label)
        );
        setDivisionOptions(combinedOptions);
  
      } catch (error) {
        console.error('Failed to fetch divisions:', error);
      }
    };
  
    fetchDivisions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fetch and set all units shared by all users, set IFTA unit options at the same time
  const [ unitOptions, setUnitOptions] = useState([])
  const [ iftaUnitOptions, setIFTAUnitOptions ] = useState([])
  useEffect(() => {
    const fetchAndSetUnits = async () => {
      try {
        const units = await fetchAllUnits();
        // console.log('allUnits: ', units)
        units.sort((a, b) => a.codeSortable.localeCompare(b.codeSortable));

        // create IFTA unit options
        const iftaUnits = units.filter(unit => unit.irpiftaRegistered === true && unit.isActive === true);
        // console.log('iftaUnits: ', iftaUnits)
        const iftaUnitOptions = iftaUnits.map(unit => ({
          value: unit.id,
          label: `${unit.code} - ${unit.type.name} / ${unit.subType.name}`
        }));

        setIFTAUnitOptions(iftaUnitOptions)

        // reduce down to active units
        const activeUnits = units.filter(unit => unit.isActive === true);
        // Create unitOptions
        const options = activeUnits.map(unit => ({
          value: unit.id,
          label: `${unit.code} - ${unit.type.name} / ${unit.subType.name}`
        }));
        // console.warn('unitOptions: ', options)
        setUnitOptions(options)

      } catch (error) {
        console.error('Failed to fetch units:', error);
      }
    };

    fetchAndSetUnits();
  }, []); 

  // handle form submit
  const onSubmit = async (data) => {
    console.warn('Submitting receipt date:', data);
    console.warn('currentReceipt: ', currentReceipt);

    console.warn('transactionId: ', transactionId);
    console.warn('transactionHasInstantNotification: ', transactionHasInstantNotification);
    console.warn('receiptId: ', currentReceipt.id);
    const currentReceiptId = currentReceipt.id;
    const isIFTATracking = !!currentReceipt.iftaFuelTaxTrack?.id;
    console.warn('isIFTATracking: ', isIFTATracking);

    console.warn('hasInstantNotification: ', hasInstantNotification)
    console.warn('instantNotificationId: ', instantNotificationId)
    console.warn('creditCardTransactionId: ', creditCardTransactionId)
    console.warn('attachReceiptParams: ', transaction)
    console.warn('submitted units: ', data?.units)
    console.warn('originalUnits: ', originalSavedUnits)
    console.warn('originalIFTATaxTrackingId: ', originalIFTATaxTrackingId)

    // Units to Add: In submittedUnits but not in originalUnits
    const unitsToAdd = data?.units.filter((unit) => !originalSavedUnits.includes(unit));

    // Units to Remove: In originalUnits but not in submittedUnits
    const unitsToRemove = originalSavedUnits.filter((unit) => !data?.units.includes(unit));

    console.log("Units to Add:", unitsToAdd);
    console.log("Units to Remove:", unitsToRemove);

    // if the original receipt had units and has now changed, we need to remove the old units
    if (unitsToRemove.length>0) {
      //get the current units and their id so we can use that to delete the units
      const currentUnits = await fetchUnitsByTransactionId(currentReceiptId);
      console.log('currentUnits: ', currentUnits);
      const recordIdsOfUnitsToRemove = currentUnits.filter(unit => unitsToRemove.includes(unit.unitId)).map(unit => unit.id);
      console.log('recordIdsOfUnitsToRemove: ', recordIdsOfUnitsToRemove);
      // loop through the recordIdsOfUnitsToRemove to remove and console.log out id
      recordIdsOfUnitsToRemove.forEach(async (id) => {
        console.log('id: ', id);
        const deleteResults = await removeStatementDocumentUnit(id);
        console.log('deleteResults: ', deleteResults);
      });
    }

    if (unitsToAdd.length>0) {
      // Create an array of promises for inserting unit records if this is NOT ifta
      const unitPromises = unitsToAdd.map(unit => {
        const unitParams = {
          unitId: unit,
          transactionId: currentReceiptId,
        };
        return addStatementDocumentUnit(unitParams);
      });

      // Wait for all unit insertions to complete
      await Promise.all(unitPromises);
    }

    const statementParams = {
      id: currentReceiptId,
      allSort: "all",
      isReceiptUploaded: true,
      addedNotes: data?.addedNotes,
      divisionId: data?.selectDivision,
      ...(data?.selectClient && { clientId: data.selectClient }),
      teamMemberId: currentTeamMemberId,
      ...(data?.selectExpenseCategory && { expenseCategoryId: data.selectExpenseCategory }),
      matchStatus: "ready-for-approval",
    };

    if (hasInstantNotification) {
      statementParams.capitalOneInstantNotificationId = instantNotificationId;
    }

    if (plaidCreditCardTransactionId) {
      statementParams.plaidCreditCardTransactionId = creditCardTransactionId;
    }

    // if the original did not have ifta tracking and the new one does, we need to add the ifta tracking
    // BUT we also need to be able to update the ifta tracking if it changes
    //updateIFTAFuelTracking
    if (isIFTATracking) {

      const iftaTrackingJSON = {
        allSort: 'all',
        transactionId: currentReceiptId,
        unitId: data?.selectOTRUnit,
        teamMemberId: currentTeamMemberId,
        stateTwoChar: data?.selectState,
        gallons: data?.gallons,
        odometer: data?.odometer,
      }
      console.warn('iftaTrackingJSON: ', iftaTrackingJSON)

      let iftaFuelTaxTrackId = null
      if (originalIFTATaxTrackingId) {
        // update
        iftaTrackingJSON.id = originalIFTATaxTrackingId;
        iftaFuelTaxTrackId = await updateIFTAFuelTracking(iftaTrackingJSON)
      } else {
        // add new
        iftaFuelTaxTrackId = await addIFTAFuelTracking(iftaTrackingJSON)
        // console.log('iftaFuelTaxTrackId: ', iftaFuelTaxTrackId)
        statementParams.iftaFuelTaxTrackId = iftaFuelTaxTrackId
      }
    }

    // update updateCapitalOneReceipt with any changes to the lower portion of the form
    // and use the uploadedReceipts[currentIndex] to get the current receipt

    if (creditCardTransactionId) {
      console.warn(' about to update transaction creditCardTransactionId: ', creditCardTransactionId)
      const params = {
        id: creditCardTransactionId,
        capitalOneCreditCardTransactionReceiptId: currentReceiptId,
      }
      if (instantNotificationId) {
        params.capitalOneInstantNotificationId = instantNotificationId
      }
      console.warn('params: ', params)
      const updateTransactionresults = await updateTransaction(params);
      console.warn('updateTransactionresults: ', updateTransactionresults);
    }
    console.warn(' SHOULD HAVE CORRECT MATCHSTATUS statementParams: ', statementParams)
    const updateReceiptResultId = await updateCreditCardTransactionReceipt(statementParams);
    console.log('updateReceiptResultId: ', updateReceiptResultId);
    
    handleClose();
  };

  const handleClose = () => {
    console.warn('Closing modal...');
    clearErrors();
    onClose();
  };

  useEffect(() => {
    // All clients
    const fetchAllClients = async () => {
      try {
        const response = await fetchClientsForAllDivisions();
        const clientOptions = response.map(client => ({
          value: client.value,
          label: client.label,
          divisionId: client.divisionId,
        }));

        // Add the ' -- None --' option at the beginning
        clientOptions.unshift({ value: '', label: ' - None -' });
      
        setAllClientOptions(clientOptions);
      } catch (error) {
        console.error('Failed to fetch clients:', error);
      }
    };

    fetchAllClients();

  },[])

  // Check if all required data is loaded
  useEffect(() => {
    const checkDataReady = () => {
      // Basic required data
      const hasBasicData = !!(
        uploadedReceipts?.length > 0 &&
        expenseCategories?.length > 0 &&
        divisionOptions?.length > 0 &&
        unitOptions?.length > 0 &&
        currentReceipt?.id
      );

      if (!hasBasicData) return false;

      // Check if IFTA data is properly loaded when needed
      const isIFTAReceipt = !!currentReceipt?.iftaFuelTaxTrack?.id;
      if (isIFTAReceipt) {
        const hasIFTAData = !!(
          currentReceipt.iftaFuelTaxTrack.unitId &&
          currentReceipt.iftaFuelTaxTrack.stateTwoChar &&
          currentReceipt.iftaFuelTaxTrack.odometer &&
          currentReceipt.iftaFuelTaxTrack.gallons &&
          iftaUnitOptions?.length > 0
        );
        if (!hasIFTAData) return false;
      }

      return true;
    };

    const isReady = checkDataReady();
    setIsDataReady(isReady);
  }, [
    uploadedReceipts,
    expenseCategories,
    divisionOptions,
    iftaUnitOptions,
    unitOptions,
    currentReceipt
  ]);

  useEffect(() => {
    if (currentReceipt) {
      console.info('currentReceipt: ', currentReceipt);

      // Expense Category
      if (expenseCategories) {
        console.info('expenseCategories: ', expenseCategories);
        const expense = expenseCategories.find(
          (category) => category.value === currentReceipt.expenseCategoryId
        );

        console.info('expense: ', expense);
        
        if (expense) {
          let isIFTATracked = expense?.isIFTATracked === true;
          const expenseCategoryValue = {
            value: expense.value,
            label: expense.label,
            isIFTATracked: isIFTATracked,
          };
          // setIsIFTATracked(isIFTATracked);
          setSelectedExpenseCategory(expenseCategoryValue);
          setValue('selectExpenseCategory', expense.value);
        } else {
          setSelectedExpenseCategory('');
        }
      }
      
      // Added notes
      // setExpenseNotes(currentReceipt.addedNotes || '');
      setValue('addedNotes', currentReceipt.addedNotes || '');

      // Division
      if (divisionOptions) {
        const division = divisionOptions.find(
          option => option.value === currentReceipt.divisionId
        );
        setSelectedDivision(division || '');
        setValue('selectDivision', currentReceipt.divisionId);
      }
      
      // Clients by division
      const fetchClients = async (divisionId) => {
        if (divisionId) {
          try {
            const response = await fetchContactCompaniesByDivision(divisionId);
            const clientOptions = response.map(client => ({
              value: client.value,
              label: client.label,
            }));
    
            // Add the ' -- None --' option at the beginning
            clientOptions.unshift({ value: '', label: ' - None -' });
          
            setClientOptions(clientOptions);
          } catch (error) {
            console.error('Failed to fetch clients:', error);
          }
        }
      };

      fetchClients(currentReceipt.divisionId);

      // Client
      if (clientOptions) {
        const client = clientOptions.find((client) => client.value === currentReceipt.clientId);
        setSelectedClient(client || '');
        setValue('selectClient', currentReceipt.clientId);
      }

      const isIFTATracked = !!currentReceipt.iftaFuelTaxTrack?.id;
      console.warn('isIFTATracked: ', isIFTATracked);
      if (isIFTATracked) {
        setOriginalIFTATaxTrackingId(currentReceipt.iftaFuelTaxTrack.id);
      } else {
        setOriginalIFTATaxTrackingId(null);
      }

      // IFTA fields if present
      if (isIFTATracked && iftaUnitOptions && stateOptions) {
        // IFTA Unit
        const iftaUnit = iftaUnitOptions.find(
          unit => unit.value === currentReceipt.iftaFuelTaxTrack.unitId
        );
        setSelectedIFTAUnitOption(iftaUnit || '');
        setValue('selectOTRUnit', currentReceipt.iftaFuelTaxTrack.unitId);
        clearErrors('selectOTRUnit');

        // State
        const state = stateOptions.find(
          state => state.value === currentReceipt.iftaFuelTaxTrack.stateTwoChar?.toLowerCase()
        );
        setSelectedState(state || '');
        setValue('selectState', currentReceipt.iftaFuelTaxTrack.stateTwoChar?.toLowerCase());
        clearErrors('selectState');
      } else {
        setSelectedIFTAUnitOption('');
        setValue('selectOTRUnit', '');
        setSelectedState('');
        setValue('selectState', '');
      }

      // Regular units if present
      if (unitOptions) {
        if (currentReceipt.units?.items) {

          const savedUnits = currentReceipt.units?.items.map((item) => item.unitId);
          setOriginalSavedUnits(savedUnits);

          const units = currentReceipt.units.items.map(unit => {
            const matchedOption = unitOptions.find(option => option.value === unit.unitId);
            return matchedOption || null;
          }).filter(option => option !== null);
          setSelectedUnits(units);
          setValue('units', units.map(unit => unit.value));
          clearErrors('units');
        }
      } else {
        setSelectedUnits([]);
        setValue('units', []);
      }
      
      // IFTA Gallons and Odometer
      if (isIFTATracked) {
        const odometer = currentReceipt.iftaFuelTaxTrack.odometer || '';
        console.warn('odometer: ', odometer);
        // setOdometer(odometer);
        setValue('odometer', odometer);
        clearErrors('odometer');

        const gallons = currentReceipt.iftaFuelTaxTrack.gallons || '';
        console.warn('gallons: ', gallons);
        // setGallons(gallons);
        setValue('gallons', gallons);
        clearErrors('gallons');
      } else {
        // setOdometer('');
        setValue('odometer', '');
        // setGallons('');
        setValue('gallons', '');
      }

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ currentIndex, currentReceipt, expenseCategories, 
    divisionOptions, iftaUnitOptions, unitOptions, setValue ]);

  useEffect(() => {
    if (uploadedReceipts && uploadedReceipts.length > 0) {
      setCurrentReceipt(uploadedReceipts[currentIndex]);
      setIsDataReady(false); // Reset loading state when receipt changes
    }
  }, [uploadedReceipts, currentIndex]);

  const ReceiptViewer = (props) => {
    const { 
      uploadedReceipts=[]||'',
      expenseCategories=[],
      divisionOptions=[],
      clientOptions=[],
      iftaUnitOptions=[],
      unitOptions=[],
      stateOptions=[],
      // currentReceipt={},
      selectedExpenseCategory={}||'',
      selectedIFTAUnitOption={}||'',
      selectedUnits=[],
      isSubmitting=false,
      setValue,
      // fetchAllClients,
    } = props

    useEffect(() => {
      // Clear IFTA-related fields when expense category changes
      if (!selectedExpenseCategory?.isIFTATracked) {
        setValue('gallons', '');
        setValue('odometer', '');
        setValue('selectOTRUnit', '');
        setValue('selectState', '');
      }
    }, [selectedExpenseCategory, setValue]);

    if (!uploadedReceipts || uploadedReceipts.length === 0) {
      return <Text>No receipts to display.</Text>;
    }

    // if (!expenseCategories.length > 0) { return (<Text>Loading expense categories...</Text>); }
    // if (!divisionOptions.length > 0) { return (<Text>Loading divisions...</Text>); }
    // if (!stateOptions.length > 0) { return (<Text>Loading states...</Text>); }
    // if (!unitOptions.length > 0) { return (<Text>Loading units...</Text>); }
    // if (!currentReceipt) { return <Text>Loading receipt...</Text>; }

    const isIFTATracked = (selectedExpenseCategory?.isIFTATracked === true) ? true : false

    const handleNotesChange = (e) => {
      setValue('addedNotes', e.target.value);
    };

    const handleOdometerChange = (e) => {
      setValue('odometer', e.target.value);
    };

    const handleGallonsChange = (e) => {
      setValue('gallons', e.target.value);
    };

    const handleExpenseCategoryChange = (e) => {
      const iftaTracked = (e?.isIFTATracked === true) ? true : false
      if (iftaTracked) {
        setValue('units', []);
      }
      // setIsIFTATracked(iftaTracked);
      setSelectedExpenseCategory(e);
      setValue('selectExpenseCategory', e.value);
      // clearErrors('selectExpenseCategory')
    };

    const updateSelectedState = (e) => {
      setSelectedState(e)
      setValue('selectState', e.value)
      clearErrors('selectState')
    }

    const handleGallonsKeyPress = (e) => {
      // Regular expression for allowed characters (digits and period)
      const allowedChars = /^[0-9.]$/;
      
      if (!allowedChars.test(e.key)) {
        e.preventDefault();
      }
    }

    const handleOdometerKeyPress = (e) => {
      // Regular expression for allowed characters (digits and period)
      const allowedChars = /^[0-9.]$/;
      
      if (!allowedChars.test(e.key)) {
        e.preventDefault();
      }
    }

    const handleDivisionChange = (event) => {
      const divisionId = event.value;
      setSelectedDivision(event);
      setValue('selectDivision', divisionId);
      clearErrors('selectDivision');

      const filteredClients = allClientOptions.filter(
        (client) => client.divisionId === divisionId
      );

      setClientOptions(filteredClients)
      setValue('selectClient', filteredClients);
      setSelectedClient('');
      setValue('selectClient', '');
    };

    const handleClientChange = (event) => {
      setSelectedClient(event);
      setValue('selectClient', event.value);
    };

    const handleIFTAUnitChange = (e) => {
      setSelectedIFTAUnitOption(e);
      setValue('selectOTRUnit', e.value)
      clearErrors('selectOTRUnit')
    };

    const handleUnitsChange = (event, action) => {
      if (action?.action === 'select-option') {
        const addedOption = { value: action?.option?.value, label: action?.option?.label, key: action?.option?.value };
        setSelectedUnits([...selectedUnits, addedOption]);
        setValue('units', [...selectedUnits, addedOption].map(unit => unit.value));
      }
      if (action?.action === 'remove-value') {
        const removedOption = { value: action?.removedValue?.value, label: action?.removedValue?.label, key: action?.removedValue?.value };
        const newUnits = selectedUnits.filter((unit) => unit.value !== removedOption.value);
        setSelectedUnits(newUnits);
        setValue('units', newUnits.map(unit => unit.value));
      }
    };

    const handleNavigation = (direction) => {
      if (direction === "prev") {
        setCurrentIndex((prev) => (prev === 0 ? uploadedReceipts.length - 1 : prev - 1));
      } else if (direction === "next") {
        setCurrentIndex((prev) => (prev === uploadedReceipts.length - 1 ? 0 : prev + 1));
      }
    };
  
    // New reset function that uses navigation to reset state
    const resetCurrentReceipt = () => {

      // expense categories
      const expense = expenseCategories.find(
        category => category.value === uploadedReceipts[currentIndex]?.expenseCategoryId
      );
      setSelectedExpenseCategory(expense || '');
      setValue('selectExpenseCategory', expense.value);

      // division
      const division = divisionOptions.find(
        option => option.value === uploadedReceipts[currentIndex]?.divisionId
      );
      setSelectedDivision(division || '');
      setValue('selectDivision', division.value);

      // client
      if (uploadedReceipts[currentIndex].clientId) {
        const filteredClients = allClientOptions.filter(
          (client) => client.divisionId === division.value
        );
  
        setClientOptions(filteredClients)
        setValue('selectClient', filteredClients);

        const client = filteredClients.find(
          client => client.value === uploadedReceipts[currentIndex].clientId
        );
        setSelectedClient(client || '');
        setValue('selectClient', client.value);
      }
      

      // units
      if (uploadedReceipts[currentIndex]?.units?.items) {
        const units = uploadedReceipts[currentIndex]?.units.items.map(unit => {
          const matchedOption = unitOptions.find(option => option.value === unit.unitId);
          return matchedOption || null;
        }).filter(option => option !== null);
        setSelectedUnits(units);
        setValue('units', units.map(unit => unit.value));
      }

      // added notes
      setValue('addedNotes', uploadedReceipts[currentIndex]?.addedNotes || '');

      // IFTA fields
      if (uploadedReceipts[currentIndex]?.iftaFuelTaxTrack) {
        setValue('gallons', uploadedReceipts[currentIndex]?.iftaFuelTaxTrack?.gallons || '');
        setValue('odometer', uploadedReceipts[currentIndex]?.iftaFuelTaxTrack?.odometer || '');

        const state = stateOptions.find(
          state => state.value === uploadedReceipts[currentIndex]?.iftaFuelTaxTrack.stateTwoChar?.toLowerCase()
        );
        setSelectedState(state || '');
        setValue('selectState', state.value);

        const iftaUnit = iftaUnitOptions.find(
          unit => unit.value === uploadedReceipts[currentIndex]?.iftaFuelTaxTrack.unitId
        );
        setSelectedIFTAUnitOption(iftaUnit || '');
        setValue('selectOTRUnit', iftaUnit.value);
      }
    };

    if (!uploadedReceipts || uploadedReceipts.length === 0) {
      return <Text>No receipts to display.</Text>;
    }
  
    const numberOfReceipts = uploadedReceipts.length;
    const uploadedDate = uploadedReceipts[currentIndex].createdAt.split('T')[0];
    const formattedDate = spellOutDate(uploadedDate, 'apple-fy')
    const imageUrl = uploadedReceipts[currentIndex].signedUrls?.[0] || ""; // Get the first image URL or fallback to an empty string

    return (
      <Center pt={'25px'}>
        <Stack 
          key={currentIndex} 
          width="100%" 
          maxW="600px" 
          textAlign="center"
        >
          {/* Receipt image */}
          {imageUrl ? (
            <Box 
              mt="25px" 
              // width="100%" 
              // maxW="800px" 
              mx="auto" 
              position="relative">
              {/* Scrollable Zoomable Box */}
              <Box
                overflow="auto"
                border="1px solid #ccc"
                // width="100%"
                // maxW="800px" // Set maxWidth to 800px
                // height="100%" // Adjust height as needed
                // maxHeight={screenWidth > 800 ? "800px" : "600px"} // Limit height on smaller screens
                mx="auto" // Center the box horizontally
                position="relative"
                bg="gray.50"
              >
                <Box
                  style={{
                    transform: `scale(${scale})`,
                    transformOrigin: "top left",
                    display: "inline-block",
                  }}
                  width={`${100 * scale}%`}
                >
                  {/* <Image
                    src={previewImage}
                    alt="Uploaded Receipt"
                    borderRadius="md"
                    maxH="400px"
                    objectFit="contain"
                    mx="auto"
                  /> */}
                  <Image
                    src={previewImage}
                    alt="Uploaded Receipt"
                    // draggable="false"
                    // width="100%"
                    // height="auto"
                    objectFit="contain"
                    mx="auto"
                  />
                </Box>
              </Box>

              {/* Floating Zoom Buttons at the Top-Right */}
              <Flex
                position="absolute"
                // top="5px"
                // right="5px"
                top="10px"
                right="10px"
                flexDirection="column"
                gap="5px"
                // bg="var(dark-module-bg)" // Set the background color
                bg={'var(--dark-module-bg)'} // Set the background color
                color={'var(--dark-gold-primary)'}
                borderRadius="md"
                paddingX="5px"
                paddingY="10px"
                zIndex="10"
                // boxShadow="lg" // Optional: Add shadow for a floating effect
              >
                <IconButton
                  aria-label="Zoom In"
                  icon={<AvailableIcons boxSize={'25px'} iconRef={'plus'} color={'var(--dark-gold-primary)'} />}
                  onClick={() => handleZoom(true)}
                  isDisabled={scale === 3}
                  color="dark-gold-primary" // Set icon color
                  bg="transparent" // Background transparent to match dark-module-bg
                  _hover={{ bg: "rgba(255, 255, 255, 0.1)" }} // Slight hover effect
                  _active={{ bg: "rgba(255, 255, 255, 0.2)" }} // Active effect
                  _disabled={{ color: "gray.500" }} // Disabled state styling
                  size="lg" // Adjust button size to match the design
                />
                <IconButton
                  aria-label="Zoom Out"
                  icon={<AvailableIcons boxSize={'25px'} iconRef={'minus'}  />}
                  onClick={() => handleZoom(false)}
                  isDisabled={scale === 1}
                  color="dark-gold-primary"
                  bg="transparent"
                  _hover={{ bg: "rgba(255, 255, 255, 0.1)" }}
                  _active={{ bg: "rgba(255, 255, 255, 0.2)" }}
                  _disabled={{ color: "gray.500" }}
                  size="lg"
                />
                <IconButton
                  aria-label="Reset Zoom"
                  icon={<AvailableIcons boxSize={'25px'} iconRef={'revert'} color={'var(--dark-gold-primary)'} />}
                  onClick={resetZoom}
                  color="dark-gold-primary"
                  bg="transparent"
                  _hover={{ bg: "rgba(255, 255, 255, 0.1)" }}
                  _active={{ bg: "rgba(255, 255, 255, 0.2)" }}
                  size="lg"
                />
              </Flex>
            </Box>
            // <Image
            //   src={previewImage}
            //   alt="Receipt"
            //   borderRadius="md"
            //   maxH="400px"
            //   objectFit="contain"
            //   mx="auto"
            // />
          ) : (
            <Box><Text py={10}>Image not available.</Text></Box>
          )}
  
          {/* Receipt Details with navigation */}
          <Box
            paddingTop={'25px'}
            position="relative" // This sets up the parent container for absolute positioning
            display="flex"
            alignItems="center"
            justifyContent="center"
            // px={4} // Optional padding for spacing
          >
            {/* Previous Button */}
            <IconButton
              position="absolute"
              left="0" // Aligns the button to the far left
              variant="iconOnlyOctonary"
              icon={<AvailableIcons boxSize={"35px"} iconRef={"select-previous"} />}
              onClick={() => {
                handleNavigation("prev");
              }}
              size={'lg'}
              isDisabled={uploadedReceipts?.length === 1}
            />

            {/* Receipt Date */}
            <Box textAlign="center" flex="1">
              <Text
                as="span"
                color={"var(--dark-text-grey-1)"}
                textStyle="text-2"
                display="block"
                mb="6px"
              >
                Receipt Image Date ({currentIndex + 1} of {numberOfReceipts})
              </Text>
              <Text
                as="span"
                color={"var(--dark-text-grey-1)"}
                textStyle="heading-3"
                display="block"
                fontWeight="bold"
                mb="6px"
              >
                {formattedDate}
              </Text>
            </Box>

            {/* Next Button */}
            <IconButton
              position="absolute"
              right="0" // Aligns the button to the far right
              variant="iconOnlyOctonary"
              icon={<AvailableIcons boxSize={"35px"} iconRef={"select-next"} />}
              onClick={() => {
                handleNavigation("next");
              }}
              size={'lg'}
              isDisabled={uploadedReceipts?.length === 1}
            />
          </Box>

          {/* Expense Category */}
          <Box pt={'25px'} textAlign="left" width="100%">
            <FormSelectSimple
              register={register}
              control={control}
              errors={errors}
              isRequired={true}
              rules={{ required: "Expense Category is required" }}
              // onChange={e => updateSelectedExpenseCategory(e)}
              // selectedoption={selectedExpenseCategory || ''}
              onChange={handleExpenseCategoryChange}
              selectedoption={selectedExpenseCategory}
              optionsArray={expenseCategories}
              fieldname="selectExpenseCategory"
              prettyname="Expense Category"
              fieldlabel="Expense Category"
              placeholder={'Select expense category'}
              issearchable={false}
            />
          </Box>

          {/* Expense Notes */}
          <Box pt={'25px'} textAlign="left" width="100%">
            <TextareaInput
              register={register}
              errors={errors}
              fieldname="addedNotes"
              fieldlabel="Expense Notes"
              prettyname="Expense Notes"
              placeholder="Enter any notes about this expense"
              onChange={handleNotesChange}
              rows={3}
            />
            {/* <TextareaInputV2
              register={register}
              errors={errors}
              fieldname="addedNotes"
              fieldlabel="Expense Notes"
              prettyname="Expense Notes"
              placeholder="Enter any notes about this expense"
              // fieldvalue={addedNotes}
              // fieldvalue={notes}
              fieldvalue={expenseNotes}
              // defaultvalue={addedNotes}
              // onChange={(e) => setAddedNotes(e.target.value)} // Update state on change
              onChange={handleNotesChange}
            /> */}
          </Box>

          {/* Division */}
          <Box pt={'25px'} textAlign="left" width="100%">
            <FormSelectSimple
              key='Division'
              register={register}
              control={control}
              errors={errors}
              isRequired={true}
              rules={{ required: "Division is required" }}
              fieldname="selectDivision"
              fieldlabel="Division"
              placeholder={'Select division'}
              optionsArray={divisionOptions?.map((division, index) => ({
                key: index,
                value: division.value,
                label: division.label
              }))}
              onChange={handleDivisionChange}
              selectedoption={selectedDivision||''}
              issearchable={false}
            />
          </Box>

          {/* Clients */}
          <Box pt={'25px'} textAlign="left" width="100%">
            <FormSelectSimple
              key='Client'
              register={register}
              control={control}
              errors={errors}
              isRequired={true}
              fieldname="selectClient"
              fieldlabel="Client"
              placeholder={'Select client'}
              optionsArray={clientOptions}
              onChange={handleClientChange}
              selectedoption={selectedClient||''}
              issearchable={false}
            />
          </Box>

          {/* Units/IFTA */}
          {isIFTATracked ? (<>
            {/* IFTA Unit */}
            <Box pt={'25px'} textAlign="left" width="100%">
              <FormSelectSimple
                register={register}
                control={control}
                errors={errors}
                isRequired={true}
                rules={{ required: "OTR Unit is required" }}
                onChange={(e) => handleIFTAUnitChange(e)}
                selectedoption={selectedIFTAUnitOption}
                optionsArray={iftaUnitOptions}
                fieldname="selectOTRUnit"
                placeholder={'Select OTR unit'}
                fieldlabel="OTR Unit"
                issearchable={false}
              />
            </Box>

            {/* IFTA State */}
            <Box pt={'25px'} textAlign="left" width="100%">
              <FormSelectSimple
                register={register}
                control={control}
                errors={errors}
                isRequired={true}
                rules={{ required: "State is required" }}
                onChange={e => updateSelectedState(e)}
                selectedoption={selectedState||''}
                optionsArray={stateOptions}
                fieldname='selectState'
                prettyname='State'
                fieldlabel="State"
                placeholder={'Select state'}
              />
            </Box>

            {/* IFTA Odometer */}
            <Box pt={'25px'} textAlign="left" width="100%">
              <TextInput
                register={register}
                errors={errors}
                // fieldtype="tel"
                fieldtype="number" step="any"
                fieldname="odometer"
                fieldlabel="Odometer"
                prettyname="Odometer"
                placeholder="Enter odometer miles"
                // fieldvalue={iftaOdometer}
                // fieldvalue={odometer}
                onChange={handleOdometerChange}
                onKeyPress={handleOdometerKeyPress}
                isRequired
              />
            </Box>

            {/* IFTA Gallons */}
            <Box pt={'25px'} textAlign="left" width="100%">
              <TextInput
                register={register}
                errors={errors}
                fieldtype="number"
                fieldname="gallons"
                fieldlabel="Gallons"
                prettyname="Gallons"
                placeholder="Enter gallons purchased"
                // fieldvalue={iftaGallons}
                // fieldvalue={gallons}
                onChange={handleGallonsChange}
                onKeyPress={handleGallonsKeyPress}
                isRequired
              />
            </Box>

            </>
          ) : 
            <Box pt={'25px'} textAlign="left" width="100%">
              {/* Non-IFTA, Multiple Units */}
              <FormSelectMulti
                register={register}
                control={control}
                errors={errors}
                onChange={(event, action) => handleUnitsChange(event, action)}
                selectedoption={selectedUnits||[]}
                optionsArray={unitOptions}
                fieldname="units"
                placeholder={'Select units if applicable'}
                fieldlabel="Units"
                issearchable={false}
                isMulti
              />
            </Box>
          }

          <Box pt={'25px'} textAlign="left" width="100%">
            <Flex gap={4} justifyContent="center" width="100%">
              {/* <Button 
                onClick={resetCurrentReceipt}
                isDisabled={isSubmitting}
                colorScheme="red" 
                width="100%"
                >Reset</Button> */}
              <ButtonQuaternaryPlain
                type="button"
                // onClick={handleSubmit(formSubmit, onError)}
                onClick={resetCurrentReceipt} 
                width="100%"
                name="reset"
                value={'Reset'}
                isDisabled={isSubmitting}
              />
              <ButtonPrimaryPlain
                type="submit"
                onClick={handleSubmit(onSubmit, onError)}
                width="100%"
                name="submit"
                value={isSubmitting ? "Attaching..." : "Attach"}
                isDisabled={selectedExpenseCategory==='' || isSubmitting}
              />
              
            </Flex>
          </Box>

        </Stack>
      </Center>
    );
  };

  // Prop Validation
  ReceiptViewer.propTypes = {
    uploadedReceipts: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          // url: PropTypes.string.isRequired,
          // description: PropTypes.string,
        })
      ),
    ]),
    expenseCategories: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
        isIFTATracked: PropTypes.bool,
      })
    ).isRequired,
    divisionOptions: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        name: PropTypes.string,
      })
    ).isRequired,
    clientOptions: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        name: PropTypes.string,
      })
    ).isRequired,
    iftaUnitOptions: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      })
    ),
    unitOptions: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
        irpiftaRegistered: PropTypes.bool,
      })
    ).isRequired,
    stateOptions: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      })
    ).isRequired,
    currentReceipt: PropTypes.shape({
      id: PropTypes.string.isRequired,
      signedUrls: PropTypes.arrayOf(PropTypes.string),
      createdAt: PropTypes.string,
      expenseCategoryId: PropTypes.string.isRequired,
      addedNotes: PropTypes.string,
      divisionId: PropTypes.string.isRequired,
      clientId: PropTypes.string,
      iftaFuelTaxTrackId: PropTypes.string,
      unitId: PropTypes.string,
      iftaFuelTaxTrack: PropTypes.shape({
        unitId: PropTypes.string.isRequired,
        stateTwoChar: PropTypes.string.isRequired,
        odometer: PropTypes.string.isRequired,
        gallons: PropTypes.string.isRequired,
      }),
      units: PropTypes.shape({
        items: PropTypes.arrayOf(
          PropTypes.shape({
            unitId: PropTypes.string.isRequired,
          })
        ),
      }),
    }),
    selectedExpenseCategory: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        isIFTATracked: PropTypes.bool,
      }),
    ]),
    selectedIFTAUnitOption: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      }),
    ]),
    selectedUnits: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      })
    ),
    setValue: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool,
    // fetchAllClients: PropTypes.func,
  };

  return (
    <>
      <Container as="form" 
        // onSubmit={handleSubmit(formSubmit, onError)}
        >
        <>
          <Flex
            direction="column"
            maxW="800px"
            mx="auto"
            color="white"
            minH="100vh"
          >
            <Flex direction="column" align="center" position="relative">
              {/* Close button positioned at the top right */}
              <Box position="absolute" top="0" right="0">
                <ResponsiveCancelCloseButton 
                  iconSize={['22px', '26px']}
                  isMobile={userIsMobile}
                  value="Cancel"
                  onClick={handleClose} />
              </Box>

              {/* Center-aligned text */}
              {/* <Box textAlign="center"> */}
              <Box textAlign=";eft">
                {/* <Text
                  as="span"
                  color={'var(--dark-text-grey-1)'}
                  textStyle="text-2"
                  display="block"
                  mb="6px"
                >
                  Attach a transaction to a saved receipt
                </Text> */}
                <Text
                  as="span"
                  color={'var(--dark-text-grey-1)'}
                  textStyle="text-2"
                  display="block"
                  mb="6px"
                >
                  Transaction Details
                </Text>
                <Text
                  as="span"
                  color={'var(--dark-text-grey-1)'}
                  textStyle="heading-4"
                  display="block"
                  fontWeight="bold"
                  mb="6px"
                >
                  {transaction.name}
                </Text>
                <Text
                  as="span"
                  color={'var(--dark-text-grey-1)'}
                  textStyle="text-2"
                  display="block"
                  mb="4px"
                >
                  {transaction.date}
                </Text>
                <Text
                  as="span"
                  color={'var(--dark-text-grey-1)'}
                  textStyle="text-2"
                  display="block"
                >
                  ${transaction.amount}
                </Text>
              </Box>
            </Flex>

            {!isDataReady ? (
              <Center pt={'25px'}>
                <Stack width="100%" maxW="600px" textAlign="center">
                  <Text color="var(--dark-text-grey-1)" textStyle="text-2">
                    Loading saved receipts data...
                  </Text>
                </Stack>
              </Center>
            ) : (
              <ReceiptViewer 
                key={currentReceipt.id} // Force new instance when receipt changes
                uploadedReceipts={uploadedReceipts}
                expenseCategories={expenseCategories}
                divisionOptions={divisionOptions}
                clientOptions={clientOptions}
                iftaUnitOptions={iftaUnitOptions}
                unitOptions={unitOptions}
                stateOptions={stateOptions}
                currentReceipt={currentReceipt}
                selectedExpenseCategory={selectedExpenseCategory}
                selectedIFTAUnitOption={selectedIFTAUnitOption}
                selectedUnits={selectedUnits}
                setValue={setValue}
                isSubmitting={isSubmitting}
              />
            )}

            {/* <ReceiptViewer 
              uploadedReceipts={uploadedReceipts}
              expenseCategories={expenseCategories}
              divisionOptions={divisionOptions}
              clientOptions={clientOptions}
              iftaUnitOptions={iftaUnitOptions}
              unitOptions={unitOptions}
              stateOptions={stateOptions}
              currentReceipt={currentReceipt}
              selectedExpenseCategory={selectedExpenseCategory}
              selectedIFTAUnitOption={selectedIFTAUnitOption}
              selectedUnits={selectedUnits}
              setValue={setValue}
              isSubmitting={isSubmitting}
              // fetchAllClients={fetchAllClients}
            /> */}
                
            </Flex>
            <Box h={'300px'}></Box>
          </>

      </Container>

      <CenteredSingleButtonConfirmationModal
        isModalOpen={isConfirmModalOpen}
        onModalClose={onConfirmModalClose}
        headerIcon='outlinecheckcircle'
        headerIconColor='var(--success-green)'
        headerText={confirmationModalHeaderText}
        bodyContent={confirmationModalContent}
        closeButtonDisabled={false}
        handleSubmitModalClosed={handleConfirmModalClosed}
      />
    </>
  );
};

AttachReceipt.propTypes = {
  uploadedReceipts: PropTypes.array,
  transaction: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    amount: PropTypes.string,
    date: PropTypes.string,
    hasInstantNotification: PropTypes.bool,
  }),
  screenWidth: PropTypes.number,
  selectedActionType: PropTypes.string,
  onClose: PropTypes.func,
};