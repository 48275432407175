import { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Stack,
  Text,
  // Input,
  // Select,
  // Textarea,
  // Button,
  Image,
  Container,
  // HStack,
  Spacer,
} from '@chakra-ui/react';
import PropTypes from "prop-types"

import { ModuleBoxDrawer } from '../Structural/ModuleBoxDrawer.jsx';
// import { Divider } from '../Divider.jsx';

import { getSignedURLFromS3File } from '../graphqlCompnents/AWS/S3/getSignedURLFromS3File.jsx';
import { fetchUploadedDocument } from '../graphqlCompnents/AWS/S3/fetchUploadedDocument.jsx';

// import { fetchTeamMemberByAccountNumber } from '../graphqlCompnents/Statement/fetchTeamMemberByAccountNumber.jsx';

// import { spellOutDate } from '../functions/dateTime.jsx';
// import { formatCurrency } from '../functions/currency.jsx';

// import { FormSelectSimple } from '../Form/Select/FormSelectSimple.jsx';
// import { TextareaInput } from '../Form/Input/TextareaInput.jsx';

// import { ButtonSecondaryPlain } from '../Form/Button/ButtonSecondaryPlain.jsx';
// import { ButtonPrimaryPlain } from '../Form/Button/ButtonPrimaryPlain.jsx';
import { ButtonQuaternaryWithIcon } from '../Form/Button/ButtonQuaternaryWithIcon.jsx';

import { VendorMap } from './VendorMap.jsx';

export const EditTransactionVendor = (props) => {

  const {
    transaction={},
    onClose=()=>{},
    environment="sandbox",
    qbVendors=[],
    mapVendor=()=>{},
    token="",
    register=()=>{},
    control={},
    errors={},
    setError=()=>{},
    clearErrors=()=>{},
    setValue=()=>{},
    rowIndex='',
  } = props;

  console.log('EditTransactionVendor props: ', props)

  // console.log('transaction: ', transaction)
  // const allSort = (environment==="sandbox") ? "sandbox" : "all";

  const handleKeyDown = (event) => {
    // Check if the key pressed is 'Enter'
    if (event.key === 'Enter' && event.target.type !== 'textarea') {
      event.preventDefault();  // Prevent form submission
    }
  };

  const formSubmit = async (data) => {
    console.log(data);
  }

  const [ showHasIssueForm, setShowHasIssueForm ] = useState(false);
  const handleReceiptHasIssue = () => {
    console.log('Receipt has issue');
    setShowHasIssueForm(prevState => !prevState);
  }

  const updateSelectedQuickBooksVendor = (event) => {
    console.log('event: ', event)
    setSelectedQuickBooksVendorOption(event);
    setValue('selectQuickBooksVendor', event.value)
    clearErrors('selectQuickBooksVendor')
  }

  const onEditTransactionVendorClose = () => {
    setCurrentEditTransaction(null)
    // handleRefresh()
    editTransactionVendorClose()
  }

  const [ receiptSignedUrl, setReceiptSignedUrl ] = useState(null);
  useEffect(() => {
    (transaction?.signedUrls?.length>0) && setReceiptSignedUrl(transaction.signedUrls[0]);
  },[transaction])
  // useEffect(() => {
  //   const fetchSignedUrl = async (documentId) => {
  //     try {
  //       const document = await fetchUploadedDocument(documentId);
  //       // console.log('Fetched document: ', document);
        
  //       // Check for valid document structure
  //       if (!document?.bucket || !document?.key) {
  //         throw new Error(`Invalid document structure: ${JSON.stringify(document)}`);
  //       }
  
  //       const signedURL = await getSignedURLFromS3File(document);
  //       // console.log('Generated Signed URL: ', signedURL);
  //       setReceiptSignedUrl(signedURL);
  //     } catch (error) {
  //       console.error('Error in fetching or generating signed URL:', error);
  //     }
  //   };
  
  //   if (transaction?.uploadedDocumentId) {
  //     fetchSignedUrl(transaction.uploadedDocumentId);
  //   }
  // }, [transaction]);

  return (
    <>
    <Container as="form" maxW="1600" mb={12} onKeyDown={handleKeyDown}>
      
      <ModuleBoxDrawer>
        <Flex direction="column" maxW="1200px" mx="auto">
        
          <Flex >
            <Box>
              <Text as="span" textStyle='heading-1'>QuickBooks Supplier Not Found</Text>
            </Box>
            <Spacer />
            <Box>
              <ButtonQuaternaryWithIcon 
                name='closeDrawer'
                iconsize='26px'
                leftIcon='close'
                value='Cancel'
                onClick={onClose}
              />
            </Box>
          </Flex>

          {/* Layout with Receipt Image and Form */}
          <Grid templateColumns="repeat(2, 1fr)" gap={12}>

            {/* Left Section: Form */}
            <GridItem w={'520px'}>
              <Stack spacing={6}>
                <Box>
                  <Stack spacing="5px">
                    {/* <Text className='dark-sfpro-text-2'>QuickBooks Supplier: Unknown</Text> */}
                    <Text className='dark-sfpro-text-2'>Merchant Name: {transaction?.matchedItem?.merchantEntityName}</Text>
                    {/* <Text className='dark-sfpro-text-2'>Merchant Store: {transaction?.capitalOneInstantNotification?.purchaseMerchant}</Text> */}

                    <Box >
                      <VendorMap 
                        handleMapVendor={mapVendor}
                        vendorName={transaction?.matchedItem?.merchantEntityName}
                        vendors={qbVendors} 
                        environment={environment}
                        token={token}
                        control={control}
                        register={register}
                        errors={errors}
                        setError={setError}
                        clearErrors={clearErrors}
                        setValue={setValue}
                        rowIndex={rowIndex}
                      />
                    </Box>                    
                  </Stack>
                </Box>


              </Stack>
            </GridItem>

            {/* Right Section: Image Preview */}
            <GridItem>
              <Box 
                // borderWidth="1px" 
                // borderRadius="lg" 
                // overflow="hidden" 
                mb={4}
                >
                {/* Replace with your image path or a placeholder */}
                <Image src={receiptSignedUrl||''} alt="Receipt Preview" />
              </Box>
            </GridItem>

          </Grid>
        </Flex>
      </ModuleBoxDrawer>
    </Container>
    </>
  )
}

EditTransactionVendor.propTypes = {
  transaction: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  environment: PropTypes.string.isRequired,
  qbVendors: PropTypes.array.isRequired,
  mapVendor: PropTypes.func.isRequired,
  token: PropTypes.string,
  register: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  setError: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  rowIndex: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
}
