import { useEffect, useState, useContext } from 'react';
import { PropTypes } from 'prop-types';
import { useForm } from 'react-hook-form';
import {
  Container,
  Box,
  Text,
  Flex,
  Spacer,
  Stack,
  VStack,
  useDisclosure,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Badge,
  Grid,
  Image,
} from "@chakra-ui/react";

// custom components
// import { useSignedUrl } from './useSignedUrlComponent.jsx';
import { getSignedUrlForFile } from '../custom-hooks/useSignedUrlForFile.jsx';

// date-fns
import { spellOutDate } from '../functions/dateTime.jsx';
// import { formatCurrencyNumeric } from '../functions/currency.jsx';

// globals
import { AppContext } from '../AppContext.jsx';

// structural components
import { ModuleBox } from '../Structural/ModuleBox.jsx';
import { ModuleDrawer } from '../Structural/ModuleDrawer.jsx';

// custom components
import { AvailableIcons } from '../Icons/AvailableIcons.jsx';
import { Divider } from '../Divider.jsx';

// buttons
import { ButtonQuaternaryWithIcon } from '../Form/Button/ButtonQuaternaryWithIcon.jsx';

// form components
import { FormSwitch } from '../Form/Switch/FormSwitch.jsx';

// graphql components
import { fetchAllUnits } from '../graphqlCompnents/Unit/fetchAllUnits.jsx';

// default images
import ImagePendingSmall from "/images/unit-image-pending-small.png"

// spike forms
import { CreateUnit } from './CreateUnit.jsx';
import { ViewUnit } from './ViewUnit.jsx';
import { EditUnit } from './EditUnit.jsx';
import { EditUnitImages } from './EditUnitImages.jsx';
import { UnitDocuments } from './UnitDocuments.jsx';

export const Units = () => {

  // grab global context values
  const { store } = useContext(AppContext);

  const unitDocumentTypes = store?.unitDocumentTypes

  // set the current users team member id
  const currentTeamMemberId = store?.userData?.id
  // console.warn(' ----- currentTeamMemberId: ', currentTeamMemberId)

  const { control, register, setValue } = useForm({
      mode: 'onChange',
      reValidateMode: 'onBlur',
      defaultValues: {},
  });

  const [ selectedUnitId, setSelectedUnitId ] = useState(null)
  const { isOpen: isCreateUnitOpen , onOpen: onCreateUnitOpen, onClose: onCreateClose } = useDisclosure()
  const { isOpen: isEditUnitOpen , onOpen: onEditUnitOpen, onClose: onEditClose } = useDisclosure()
  const { isOpen: isEditUnitImagesOpen , onOpen: onEditUnitImagesOpen, onClose: onEditImagesClose } = useDisclosure()
  const { isOpen: isViewUnitOpen , onOpen: onViewUnitOpen, onClose: onViewClose } = useDisclosure()
  const { isOpen: isUnitDocumentsOpen , onOpen: onUnitDocumentsOpen, onClose: onDocumentsClose } = useDisclosure()

  const onViewUnitClose = () => {
    handleRefresh()
    onViewClose()
  }

  const onEditUnitClose = () => {
    handleRefresh()
    onEditClose()
  }

  const onEditUnitImagesClose = () => {
    handleRefresh()
    onEditImagesClose()
  }

  const onCreateUnitClose = () => {
    handleRefresh()
    onCreateClose()
  }

  const onUnitDocumentsClose = () => {
    handleRefresh()
    onDocumentsClose()
  }

  const [ showInactive, setShowInactive ] = useState(false)
  useEffect(() => {
    if (showInactive) {
      setGroupedUnits(allGroupedUnits)
    } else {
      const activeUnits = {}
      for (const division in allGroupedUnits) {
        activeUnits[division] = allGroupedUnits[division].filter(unit => unit.isActive)
      }
      setGroupedUnits(activeUnits)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ showInactive ])

  // const [smallImageTypeId, setSmallImageTypeId] = useState(null);
  useEffect(() => {
    const findTypeIdByName = (name) => unitDocumentTypes?.find(type => type?.name === name)?.id;

    if (unitDocumentTypes) {
      const smallTypeId = findTypeIdByName('unit-photo-small');
      // const mediumTypeId = findTypeIdByName('unit-photo-medium');
      // const largeTypeId = findTypeIdByName('unit-photo-large');

      if (smallTypeId) {
        // console.info('Set Small Image Type ID: ', smallTypeId);
        // setSmallImageTypeId(smallTypeId);
      }
    }
  }, [unitDocumentTypes]);

  const [ refreshIndex, setRefreshIndex ] = useState(0);
  const handleRefresh = () => {
    setRefreshIndex(prev => prev + 1); // Increment to trigger useEffect
  };

  const [allGroupedUnits, setAllGroupedUnits] = useState({});  // State to store members grouped by division
  const [groupedUnits, setGroupedUnits] = useState({});  // State to store members grouped by division
  const [ allUnits, setAllUnits ] = useState(null)
  const [ unitImages, setUnitImages ] = useState(null)
  // useEffect(() => {
  //   if (allUnits) {
  //     (async () => {
  //       // console.log('allUnits: ', allUnits);
  //       const unitImagesData = await Promise.all(
  //         allUnits.map(async (unit) => {
  //           const code = unit.code;
  //           const key = unit?.unitSmallImage?.uploadedDocument?.key||null;
  //           const bucket = unit?.unitSmallImage?.uploadedDocument?.bucket||null;
  //           const identityId = unit?.unitSmallImage?.uploadedDocument?.identityId||null;
  //           if (key && identityId && bucket) {

  //             const signedUrl = await getUrl({
  //               // path: "album/2024/1.jpg",
  //               path: `protected/${identityId}/${key}`,
  //               options: {
  //                 // specify a target bucket using name assigned in Amplify Backend
  //                 bucket: bucket,
  //                 // ensure object exists before getting url
  //                 validateObjectExistence: true, 
  //                 // url expiration time in seconds.
  //                 expiresIn: 300,
  //                 // whether to use accelerate endpoint
  //                 useAccelerateEndpoint: true,
  //               }
  //             });

  //             return {
  //               unitCode: code,
  //               signedUrl: signedUrl.url,
  //             };
  //           } else {
  //             return {
  //               unitCode: code,
  //               signedUrl: null,
  //             };
  //           }
  //         })
  //       );
  //       setUnitImages(unitImagesData);
  //     })();
  //   }
  // }, [allUnits]);

  useEffect(() => {
    if (allUnits) {
      (async () => {
        const unitImagesData = await Promise.all(
          allUnits.map(async (unit) => {
            const code = unit.code;
            const key = unit?.unitSmallImage?.uploadedDocument?.key || null;
            const bucket = unit?.unitSmallImage?.uploadedDocument?.bucket || null;
            const identityId = unit?.unitSmallImage?.uploadedDocument?.identityId || null;
  
            if (key && identityId && bucket) {
              // Use the useSignedUrl hook to generate the signed URL for the file
              const signedUrl = await getSignedUrlForFile({
                bucketName: bucket,
                identityId: identityId,
                fileName: key,
                fileSecurity: 'protected', // Optional if you want to make this dynamic
              });
  
              return {
                unitCode: code,
                signedUrl: signedUrl || null,
              };
            } else {
              return {
                unitCode: code,
                signedUrl: null,
              };
            }
          })
        );
  
        // Set the unit images once all URLs are fetched
        setUnitImages(unitImagesData);
      })();
    }
  }, [allUnits]);

  const getSignedUrlForUnitCode = (unitCode) => {
    if (!unitCode) return
    if (unitImages) {
      const unitImage = unitImages.find((image) => image?.unitCode === unitCode);
      return unitImage ? unitImage.signedUrl : null;
    }
    return null;
  };

  useEffect(() => {
    const fetchAndSetUnits = async () => {
      try {
        const units = await fetchAllUnits();
        // console.warn('units: ', units)
        setAllUnits(units);

        // Group units by 'prettyName' of their division
        const unitsByDivision = units.reduce((acc, unit) => {
          const divisionName = unit?.unitStatus?.division?.prettyname||'No Division';
          if (!acc[divisionName]) {
            acc[divisionName] = [];
          }
          acc[divisionName].push(unit);
          return acc;
        }, {});

        // Sort each group by isActive, codeSortable
        for (const division in unitsByDivision) {
          unitsByDivision[division].sort((a, b) => {
            // Sort by isActive first (false < true)
            // if (a.isActive !== b.isActive) {
            //   return a.isActive ? -1 : 1;
            // }
            // Finally by codeSortable
            return a.codeSortable.localeCompare(b.codeSortable);
          });
        }

        // console.info('unitsByDivision: ', unitsByDivision)

        setAllGroupedUnits(unitsByDivision);  // Store active and inactive grouped members in state
        
        const activeUnits = {}
        for (const division in unitsByDivision) {
          activeUnits[division] = unitsByDivision[division].filter(unit => unit.isActive)
        }
        setGroupedUnits(activeUnits)
        // console.info('groupedUnits: ', activeUnits)

        // console.log(' ----- Fetched new units.')

      } catch (error) {
        console.error('Failed to fetch units:', error);
      }
    };

    fetchAndSetUnits();

  }, [refreshIndex]); 

  const [ selectedimageId, setSelectedImageId ] = useState(null)
  const [ openDrawerType, setOpenDrawerType ] = useState(null)

  const handleOpenDrawer = (type, id, imageId) => {
    if (type==='new') {
      onCreateUnitOpen()
    }

    if (id) {
      setSelectedUnitId(id)
      if (type==='view') {
        setOpenDrawerType('view')
      }
  
      if (type==='edit') {
        setOpenDrawerType('edit')
      }
  
      if (type==='documents') {
        setOpenDrawerType('documents')
      }

      if (type==='images' && imageId) {
        setOpenDrawerType('images')
        setSelectedImageId(imageId)
      }
    }
  }

  useEffect(() => {

    if (openDrawerType==='images' && selectedUnitId && selectedimageId) {
      setOpenDrawerType(null)
      onEditUnitImagesOpen()
    }

    if (openDrawerType && selectedUnitId) {
      if (openDrawerType==='view') {
        setOpenDrawerType(null)
        onViewUnitOpen()
      }
      if (openDrawerType==='edit') {
        setOpenDrawerType(null)
        onEditUnitOpen()
      }
      if (openDrawerType==='documents') {
        setOpenDrawerType(null)
        onUnitDocumentsOpen()
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[openDrawerType, selectedUnitId, selectedimageId])

  // Function to determine the appropriate rate description based on the available data
  // const determineRateDescription = (rates) => {
  //   if (!rates) {
  //     // console.error('Rates object is undefined or null.');
  //     return "Rate information unavailable";
  //   }

  //   const { hourlyRate, shiftRate, dayRate } = rates;
  //   if (hourlyRate) {
  //     return `Hourly ${formatCurrencyNumeric(hourlyRate)}`;
  //   } else if (shiftRate) {
  //     return `Shift ${formatCurrencyNumeric(shiftRate)}`;
  //   } else if (dayRate) {
  //     return `Daily ${formatCurrencyNumeric(dayRate)}`;
  //   } else {
  //     return "No rate set";
  //   }
  // };

  // const priceCodeObjectToString = (obj) => {
  //   if (!obj) return 'No price code';
  //   const rateType = obj?.rateType?.description?.replace("/","")
  //   const price = obj?.price;
  //   const rate = `${formatCurrencyNumeric(price)} / ${rateType}`;
  //   return rate;
  // }

  const DivisionUnitCard = ({ unit }) => {
    // console.log('unit:  ', unit)
    //bucketName, identityId, fileName
    // const { signedUrl } = useSignedUrl({
    //   bucketName: 'prod-spike-unit-images',
    //   identityId: 'us-east-1:d37f7f78-7359-c61a-c653-202748435af9', 
    //   fileName: 'a5dc91e2-c23a-4d67-94e2-2ee2d44ef768.jpg',
    //   fileSecurity: 'protected', // Optional
    // });
    return (
      <Box 
        w={'100%'}
        // borderWidth="1px" 
        borderRadius="6px" 
        overflow="hidden" 
        p={5} 
        m={2} 
        // color={'#353638'}
        backgroundColor={'var(--dark-menu-background)'}>
        <Box>
          
        {/* <FileListComponent bucketName={'prod-spike-unit-images'} identityId={'us-east-1:d37f7f78-7359-c61a-c653-202748435af9'} fileName={'a5dc91e2-c23a-4d67-94e2-2ee2d44ef768.jpg'}/> */}
        {/* <SignedUrlComponent bucketName={'prod-spike-unit-images'} identityId={'us-east-1:d37f7f78-7359-c61a-c653-202748435af9'} fileName={'a5dc91e2-c23a-4d67-94e2-2ee2d44ef768.jpg'}/> */}
        </Box>
        <Box display="flex" alignItems="top">
          <VStack>
            <Box w={'172px'} pr={'10px'}>
              <Image 
                w={'172px'} h={'172px'}
                src={getSignedUrlForUnitCode(unit.code)||ImagePendingSmall} 
                borderRadius='12px'
                alt='Unit Image'
                border={'1px solid var(--dark-unit-image-border)'}
                // border={'3px solid green'}
              />
            </Box>
            <Spacer/>
            <Box>
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label='Options'
                  icon={<AvailableIcons boxSize={'22px'} iconRef={'more'} />} 
                  variant='cardEllipses' 
                  color={'var(--dark-gold-primary)'}
                  backgroundColor={'var(--dark-menu-background)'}
                  w={'60px'}
                  iconsize='18px'
                  height='28px'
                  mb='3px'
                  // isDisabled={(divisionName==='ExxonMobil' || divisionName==='Citgo') ? true : false}
                  // isDisabled={!tokenObj || !isFormLoaded || isMenuDisabled || disabledToolbars[djt?.id] || !isLoggedInQB}
                />
                <MenuList>
                  <MenuItem 
                    onClick={() => handleOpenDrawer('view', `${unit.id}`, '')}
                    icon={<AvailableIcons boxSize={'22px'} iconRef={'view'} />} >
                    View Unit
                  </MenuItem>
                  <MenuItem 
                    onClick={() => handleOpenDrawer('edit', `${unit.id}`, '')}
                    icon={<AvailableIcons boxSize={'22px'} iconRef={'edit'} />} >
                    Edit Unit
                  </MenuItem>
                  <MenuItem 
                    onClick={() => handleOpenDrawer('images', `${unit.id}`, `${unit.unitSmallImageId}`)}
                    icon={<AvailableIcons boxSize={'22px'} iconRef={'edit'} />} >
                    Edit Images
                  </MenuItem>
                  {/* <MenuItem 
                    onClick={() => handleOpenDrawer('documents', `${unit.id}`)}
                    icon={<AvailableIcons boxSize={'22px'} iconRef={'edit'} />} >
                    Documents
                  </MenuItem> */}
                </MenuList>
              </Menu>
            </Box>
          </VStack>
          <Box>
            <Stack direction={['row', 'column']} w={'100%'} spacing='5px' ml="10px">
              <Box display="flex" alignItems="center" paddingBottom={'15px'}>
                <Text pr={'10px'} as="span" textStyle='heading-3'>Unit ID: {unit?.code}</Text>
                <Badge colorScheme={unit.isActive ? "green" : "red"}>{unit.isActive ? 'Active' : 'Inactive'}</Badge>
              </Box>
              <Box><Text as="span" textStyle='text-2'>Type: {unit?.type?.name}</Text></Box>
              <Box><Text as="span" textStyle='text-2'>Subtype: {unit?.subType?.name}</Text></Box>
              <Box><Text as="span" textStyle='text-2'>Status: TBD</Text></Box>
              {/* <Box><Text as="span" textStyle='text-2'>Last Updated: {spellOutDate(unit?.unitStatus?.updatedAt.split('T')[0])}</Text></Box> */}
              <Box><Text as="span" textStyle='text-2'>Inspected: {spellOutDate(unit?.unitStatus?.lastInspected?.split('T')[0])||'Unknown'}</Text></Box>
              <Box><Text as="span" textStyle='text-2'>Location: {unit?.unitStatus?.division?.prettyname||'Unknown'}</Text></Box>
              {/* <Box><Text as="span" textStyle='text-2'>Status: {unit?.unitStatus?.status?.status||'Unknown'}</Text></Box> */}
              {/* <Box><Text as="span" textStyle='text-2'>Rate: {determineRateDescription(unit?.rates)}</Text></Box> */}
              {/* <Box><Text as="span" textStyle='text-2'>Rate: {priceCodeObjectToString(unit?.subType?.priceCode)}</Text></Box> */}
              <Box><Text as="span" textStyle='text-2'>IFTA/IRP Registered: {unit?.irpiftaRegistered===true ? 'Yes' : 'No'}</Text></Box>
            </Stack>
          </Box>
        </Box>
      </Box>
    );
  };

  DivisionUnitCard.propTypes = {
    unit: PropTypes.shape({
      id: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      unitSmallImageId: PropTypes.string,
      type: PropTypes.shape({
        name: PropTypes.string,
      }),
      subType: PropTypes.shape({
        name: PropTypes.string,
        priceCode: PropTypes.object,
      }),
      unitStatus: PropTypes.shape({
        updatedAt: PropTypes.string,
        lastInspected: PropTypes.string,
        status: PropTypes.shape({
          status: PropTypes.string,
          updatedAt: PropTypes.string,
        }),
        division: PropTypes.shape({
          prettyname: PropTypes.string,
        })
      }),
      irpiftaRegistered: PropTypes.bool,
      isActive: PropTypes.bool,
    }).isRequired,
  };

  const UnitLayout = ({ divisionData }) => {
    return (
      <Box>
        {Object.entries(divisionData).map(([divisionName, units]) => (
          // (divisionName!=='ExxonMobil' && divisionName!=='Citgo') && 
          <Box key={divisionName}>
            <Box key={divisionName} mb={8}>
              <Box display="flex" alignItems="baseline" mb={4}>
                <Text as="span" textStyle='heading-1' pr={'5px'}>{divisionName}</Text>
                {/* <AvailableIcons boxSize={'22px'} iconRef={'iconTeamMembers'} color={'var(--dark-gold-primary)'}/> */}
                <Text as="span" textStyle='dark–text–grey–3' pl={'5px'}>{units.length} Units</Text>
              </Box>
                <Grid templateColumns="repeat(auto-fill, minmax(370px, 1fr))" gap={6} >
                  {units.map(unit => (
                    <DivisionUnitCard key={unit.id} unit={unit} divisionName={divisionName} />
                  ))}
                </Grid>
            </Box>
            <Divider mt='25px' mb='25px'/>
          </Box>
        ))}
      </Box>
    );
  };

  UnitLayout.propTypes = {
    divisionData: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]),
  }

  return (
    <>
      <Container as="form" maxW="1600" mb={12}>
        <ModuleBox>
          <Flex>
            <Box>
              <Text as="span" textStyle='heading-1'>View Units</Text>
            </Box>
            <Spacer />
            <Box>
              <ButtonQuaternaryWithIcon
                name='new'
                leftIcon='new'
                iconsize='22px'
                // isDisabled={buttonsDisabled}
                onClick={() => handleOpenDrawer('new', null)}
                value='Create New Unit'
                mr={'25px'}
              />
            </Box>
          </Flex>
          <Box w={'300px'} >
            <Flex>
              <Box>
                <Text as="span" textStyle='label-1'>Show Inactive Units</Text><br/>
              </Box>
              <Spacer />
              <Box>
                <FormSwitch
                  register={register}
                  control={control}
                  fieldname="showInactive"
                  isChecked={showInactive}
                  onChange={() => {
                    setValue('showInactive', !showInactive)
                    setShowInactive(!showInactive)
                    // setSelectedTCUser('')
                  }}
                />
              </Box>
            </Flex>
          </Box>
        </ModuleBox>

        <ModuleBox mt='25px'>
          <Box>
            <UnitLayout divisionData={groupedUnits} />
          </Box>
        </ModuleBox>

      </Container>

      <ModuleDrawer
        onClose={onCreateUnitClose}
        isOpen={isCreateUnitOpen}
        bodyContent={<CreateUnit srcAction={'new'} adminId={currentTeamMemberId} onClose={onCreateUnitClose}/>}
        size={'full'}
      />
      <ModuleDrawer
        onClose={onEditUnitClose}
        isOpen={isViewUnitOpen}
        bodyContent={<ViewUnit unitId={selectedUnitId} onClose={onViewUnitClose}/>}
        size={'full'}
      />
      <ModuleDrawer
        onClose={onEditUnitClose}
        isOpen={isEditUnitOpen}
        bodyContent={<EditUnit unitId={selectedUnitId} onClose={onEditUnitClose}/>}
        size={'full'}
      />
      <ModuleDrawer
        onClose={onEditUnitImagesClose}
        isOpen={isEditUnitImagesOpen}
        bodyContent={<EditUnitImages unitId={selectedUnitId} imageId={selectedimageId} onClose={onEditUnitImagesClose}/>}
        size={'full'}
      />
      <ModuleDrawer
        onClose={onUnitDocumentsClose}
        isOpen={isUnitDocumentsOpen}
        bodyContent={<UnitDocuments unitId={selectedUnitId} onClose={onUnitDocumentsClose}/>}
        size={'full'}
      />
    </>
  );
}