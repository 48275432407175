// chakra ui
import { Icon } from '@chakra-ui/react'
import PropTypes from 'prop-types';

// import { CiImageOn } from "react-icons/ci";
// 3rd party icons
import { 
  LiaFileInvoiceDollarSolid
 } from "react-icons/lia";
 import { 
  GrRevert
 } from "react-icons/gr";
import { 
  BiAtom, 
  BiPlusCircle, 
  BiExport,
  BiTransfer,
  BiDollar,
  // BiArrowBack,
  // BiDollarCircle,
  // BiChevronLeft,
  // BiChevronRight,
  // BiChevronDown,
  // BiChevronUp,
  // BiCheckboxChecked,
} from "react-icons/bi";
import { 
  LuClipboardCopy,
  // LuCircleChevronRight,
  // LuCircleChevronLeft,
 } from "react-icons/lu";
import { CgMoreO } from "react-icons/cg";
import { FiClock } from "react-icons/fi";
import { 
  BsCheckSquare,
  BsListCheck,
  BsBriefcase,
  BsFillRecordFill,
  BsArchive,
  BsGear,
  BsImage,
} from "react-icons/bs";
import { 
  AiOutlineEye, 
  // AiOutlinePlusCircle, 
  AiOutlineCloseCircle, 
  AiOutlinePlus, 
  AiOutlineMinus,
  AiOutlineCheck,
  AiOutlineMail,
  AiFillCheckCircle,
  // AiOutlineDashboard,
  AiOutlineDollarCircle,
  AiOutlineTeam,
  AiOutlineFileText,
  AiOutlineArrowRight,
  AiOutlinePoweroff,
  // AiFillCloseCircle,
  AiOutlineUpload,
  // AiOutlineCheckCircle,
  AiOutlinePhone,
  AiOutlineSearch,
  AiOutlineHome,
} from "react-icons/ai";
import { 
  IoDuplicateOutline, 
  IoNotificationsOutline,
  IoTrashOutline,
  IoReceiptOutline,
  IoRibbonOutline,
  IoRefreshSharp,
  IoChevronForwardCircleOutline,
  IoChevronBackCircleOutline,
  IoInformationCircleOutline,
} from "react-icons/io5";
import { 
  ImClipboard,
 } from "react-icons/im";
import { 
  MdOutlineAddCircleOutline,
  MdRestore,
  // MdSync,
  MdCancel,
  MdOutlineFormatListBulleted,
  MdOutlinePersonOff,
  MdCheckBoxOutlineBlank,
  MdCheckBox,

} from "react-icons/md";
import { 
  HiOutlineChevronDown,
  HiOutlineChevronUp,
  // HiOutlineChevronLeft,
  // HiOutlineChevronRight,
  HiOutlineDocument,
} from "react-icons/hi";
import { 
  FiChevronLeft,
  FiChevronRight,
  FiAlertTriangle,
  FiEdit,
  FiCheckCircle,
  FiThumbsUp,
  FiDownload,
} from "react-icons/fi";
import { 
  RiContactsBookLine,
  RiFileTransferLine,
  RiFileUploadLine,
} from "react-icons/ri";
import { 
SlLocationPin,
} from "react-icons/sl";
import { 
  TfiTruck,
 } from "react-icons/tfi";
import {
  FaHamburger,
  FaArrowRight,
  // FaLink,
} from "react-icons/fa";
import {
  TbCapture,
} from 'react-icons/tb'
import { 
  HiMiniLink 
} from "react-icons/hi2";

export const AvailableIcons = ({ iconRef, boxSize='22px', color, bgcolor, ...rest }) => {

  let icons = {
    'placeholdericon': BiAtom,
    'tasks': MdOutlineAddCircleOutline,
    'notifications': IoNotificationsOutline,
    'add': BiPlusCircle,
    'edit': FiEdit,
    'new': BiPlusCircle,
    'view': AiOutlineEye,
    'duplicate': IoDuplicateOutline,
    'export': BiExport,
    'convert': RiFileTransferLine,
    'download': FiDownload,
    'archive': BsArchive,
    'close': AiOutlineCloseCircle,
    'plus': AiOutlinePlus,
    'minus': AiOutlineMinus,
    'check': AiOutlineCheck,
    'next': FiChevronRight,
    'previous': FiChevronLeft,
    'alerttriangle': FiAlertTriangle,
    'delete': IoTrashOutline,
    'accordianexpandlg': HiOutlineChevronDown,
    'accordiancollapselg': HiOutlineChevronUp,
    'checkbox': BsCheckSquare,
    'checkboxon': MdCheckBox,
    'checkboxoff': MdCheckBoxOutlineBlank,
    'email': AiOutlineMail,
    'fillcheckcircle': AiFillCheckCircle,
    'outlinecheckcircle': FiCheckCircle,
    'default': BiAtom,
    'iconDashboard': AiOutlineHome,
    'iconAr': AiOutlineDollarCircle,
    'iconDailyJobTicket': HiOutlineDocument,
    'iconEstimator': BiDollar,
    'iconTasks': BsListCheck,
    'iconStatements': IoReceiptOutline,
    'iconReceiptUpload': IoReceiptOutline,
    'iconTransfers': BiTransfer,
    'iconUnits': TfiTruck,
    'iconDailyInspections': ImClipboard,
    'iconCredentials': IoRibbonOutline,
    'iconTeamMembers': AiOutlineTeam,
    'iconContractsAndBids': AiOutlineFileText,
    'iconReview': AiOutlineArrowRight,
    'iconDivisions': SlLocationPin,
    'iconCareers': BsBriefcase,
    'iconContacts': RiContactsBookLine,
    'restore': MdRestore,
    'fillcircle': BsFillRecordFill,
    'hamburger': FaHamburger,
    'signout': AiOutlinePoweroff,
    'thumbsup': FiThumbsUp,
    'clock': FiClock,
    'more': CgMoreO,
    'upload': AiOutlineUpload,
    'reject': FiAlertTriangle,
    'alert': FiAlertTriangle,
    'nav-sync': HiMiniLink,
    'refresh': IoRefreshSharp,
    'queue': MdOutlineFormatListBulleted,
    'arrowright': FaArrowRight,
    'gear': BsGear,
    'tmoff': MdOutlinePersonOff,
    'phone': AiOutlinePhone,
    'capture': TbCapture,
    'uploadFile': RiFileUploadLine,
    'searchReset': MdCancel,
    'searchMagnify': AiOutlineSearch,
    'accountsPayable': LiaFileInvoiceDollarSolid,
    'image': BsImage,
    'clipboard-copy': LuClipboardCopy,
    'select-next': IoChevronForwardCircleOutline,
    'select-previous': IoChevronBackCircleOutline,
    'iconReports': HiOutlineDocument,
    'notes': HiOutlineDocument,
    'info': IoInformationCircleOutline,
    'revert': GrRevert,
  }

  return <Icon color={color} bgColor={bgcolor} boxSize={boxSize} as={icons[iconRef] || icons['default']} {...rest}/>

}

AvailableIcons.propTypes = {
  iconRef: PropTypes.string.isRequired,
  boxSize: PropTypes.string,
  color: PropTypes.string,
  bgcolor: PropTypes.string,
};