/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import { Box, VStack, Text, HStack } from '@chakra-ui/react';

// form components
import { TextInput } from '../Form/Input/TextInput';
import { ButtonPrimaryPlain } from '../Form/Button/ButtonPrimaryPlain';

export const QuickBooksSupplierScrollableBox = (props) => {

  const { 
    items, 
    width, 
    height, 
    handleSelectedObject, 
    handleLoadMore, 
    register, 
    errors, 
    setError, 
    clearErrors,
    suggestedMerchantName,
    showCreateSupplier=true,
    setValue,
  } = props
  
  

  const [selectedObject, setSelectedObject] = useState(null);

  const [merchantName, setMerchantName] = useState('')
  useEffect(() => {
    if (suggestedMerchantName) {
      setMerchantName(suggestedMerchantName);
      setValue('newVendorName', suggestedMerchantName)
      console.log('suggestedMerchantName: ', suggestedMerchantName)
    }

  },[suggestedMerchantName, selectedObject])

  const handleClick = (item) => {
    setSelectedObject(item);
  };

  const saveSelection = () => {
    handleSelectedObject(selectedObject)
  }

  const resetSelection = () => {
    setSelectedObject(null)
  }

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight) {
      // console.log('reached the bottom of the scrollbox');
      handleLoadMore()
    }
  };

  const handleNewVendorNameChange = (e) => {
    const vendor = e.target.value
    console.log('vendor: ', vendor)
    if (vendor.length>=3) {
      clearErrors('newVendorName')
      console.log('newVendorName: ', e.target.value)
      setSelectedObject({
        id: '', 
        name: e.target.value, 
        value: ''
      })
    } else {
      setError('newVendorName', {
        type: 'manual',
        message: 'Vendor name must be at least 3 characters long'
      })
    }
  }

  return (
    <Box width={width}>
      <Box>
        <Box
          height={height||'285px'}
          // width={width||'100%'}
          width={'100%'}
          overflowY="scroll"
          border="1px solid"
          borderColor="var(-dark-component-border)"
          borderRadius="6px"
          p={2}
          onScroll={handleScroll}
          // bg="var(--dark-button-grey-1)"
          sx={{
            '::-webkit-scrollbar': {
              width: '12px',
            },
            '::-webkit-scrollbar-thumb': {
              background: 'var(--dark-button-grey-4)',
              borderRadius: '10px',
            },
            '::-webkit-scrollbar-track': {
              background: 'var(--dark-button-grey-1)',
            },
          }}
        >
          <VStack spacing={2}>
            {items?.map((item, index) => (
              <Box
                key={'box_'+index+'_'+item?.id}
                w="100%"
                p={2}
                bg={selectedObject?.id === item.id ? 'var(--dark-button-grey-4)' : 'var(--dark-button-grey-1)'}
                borderRadius="6px"
                cursor="pointer"
                onClick={() => handleClick(item)}
                _hover={{ bg: 'var(--dark-button-grey-4)' }}
              >
                {/* <Text textStyle='text-1'>{item.id} : {item.name} - {item.value}</Text> */}
                <Text textStyle='text-1'>{item.name}</Text>
              </Box>
            ))}
          </VStack>
        </Box>
      </Box>

      

      <Box>
        {selectedObject?.id ? <>
          <Box paddingY={'25px'}>
            <Text as="span" textStyle='heading-2'>3. If this is the matching QuickBooks Supplier for this receipt, click &quot;Set Receipt Supplier&quot;..</Text>
          </Box>

          <Box 
            width={width||'100%'} 
          >
            <HStack spacing={3}>
              <ButtonPrimaryPlain
                name="saveSelection"
                value="Set Receipt Supplier"
                onClick={saveSelection} />
              <ButtonPrimaryPlain
                name="resetSelection"
                value="Reset"
                onClick={resetSelection} />
              <Text fontSize="lg">{selectedObject.name}</Text>
            </HStack>
            
            
          </Box></>
        : 
          <>
            {showCreateSupplier && <>
              <Box paddingY={'25px'}>
                <Text as="span" textStyle='heading-2'>3. If there is no matching existing QuickBooks Supplier for this receipt, click &quot;Create New Supplier&quot; to add this new Supplier to QuickBooks.</Text>
              </Box>

              <Box spacing={'25px'}>
                <TextInput
                  register={register}
                  errors={errors}
                  fieldtype="text"
                  fieldname="newVendorName"
                  fieldlabel="Create new QuickBooks supplier"
                  prettyname="New Vendor Name"
                  // fieldvalue={suggestedMerchantName}
                  fieldvalue={merchantName}
                  // isDisabled={suggestedMerchantName}
                  placeholder="Enter new QuickBooks supplier name"
                  // onChange={handleNewVendorNameChange}
                />
              </Box>
              <Box paddingTop={'25px'}>
                <ButtonPrimaryPlain
                  name="saveSelection"
                  value="Create New Supplier"
                  onClick={saveSelection} />
              </Box></>
            }
          </>
        }
      </Box>
    </Box>
  );
};