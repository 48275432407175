import { fetchCapitalOneInstantNotificationByLastFour } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const fetchInstantNotificationsByLastFour = async (lastFour, isSubmitted = false, limit = 250) => {
  try {
    let gqlFilter = (isSubmitted===false) ? { ne: true } : { eq: isSubmitted } 
    const gqlParams = {
      lastFour,
      filter: { isSubmitted: gqlFilter},
      and: {isMatched: {eq: false}},
      limit,
    };

    // console.info('GraphQL Params:', gqlParams);

    const response = await client.graphql({
      query: fetchCapitalOneInstantNotificationByLastFour,
      variables: gqlParams,
    });

    console.info('GraphQL Response:', response);

    // Ensure response is as expected
    if (!response || !response.data || !response.data.capitalOneInstantNotificationByLastFour) {
      console.warn(`No data returned for card ending in ${lastFour}`);
      return null; // Return null if the response structure is unexpected
    }

    const results = response.data.capitalOneInstantNotificationByLastFour.items;

    // If results is not an array or is empty, return null
    if (!Array.isArray(results) || results.length === 0) {
      console.warn(`No transactions found for card ending in ${lastFour}`);
      return null;
    }

    return results; // Return the items array if data is present
  } catch (err) {
    console.error('Error in fetchInstantNotificationsByLastFour:', err);
    return null; // Return null on error
  }
};

fetchInstantNotificationsByLastFour.propTypes = {
  lastFour: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  limit: PropTypes.number,
};