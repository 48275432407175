import { fetchPlaidCreditCardTransactionByStatus } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const fetchTransactionsByStatus = async (status, limit = 150) => {
  try {

    // const gqlParams = {
    //   date: { ge: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0] },
    //   status: status,
    //   sortDirection: "DESC",
    //   limit,
    // };

    // Define the minimum start date
    const minStartDate = new Date("2025-01-02"); // Minimum allowed date
    const calculatedStartDate = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000); // 30 days ago

    // Compare and select the later date
    const startDate = calculatedStartDate < minStartDate ? minStartDate : calculatedStartDate;

    // Format the date as YYYY-MM-DD
    const formattedStartDate = startDate.toISOString().split("T")[0];

    const gqlParams = {
      date: { ge: formattedStartDate }, // Use the calculated or minimum start date
      status: status,
      sortDirection: "DESC",
      limit,
    };
    
    // console.info('GraphQL Params:', gqlParams);

    const response = await client.graphql({
      query: fetchPlaidCreditCardTransactionByStatus,
      variables: gqlParams,
    });

    // console.info('GraphQL Response:', response);

    // Ensure response is as expected
    if (!response || !response.data || !response.data.plaidCreditCardTransactionByStatus) {
      console.warn(`No data returned for status ${status}`);
      return null; // Return null if the response structure is unexpected
    }

    const results = response.data.plaidCreditCardTransactionByStatus.items;

    // If results is not an array or is empty, return null
    if (!Array.isArray(results) || results.length === 0) {
      console.warn(`No transactions found for status ${status}`);
      return null;
    }

    return results; // Return the items array if data is present
  } catch (err) {
    console.error('Error in fetchTransactionsByStatus:', err);
    return null; // Return null on error
  }
};

fetchTransactionsByStatus.propTypes = {
  status: PropTypes.string.isRequired,
  limit: PropTypes.number,
};