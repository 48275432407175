import { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Stack,
  Text,
  useDisclosure,
  Image,
  Container,
  // HStack,
  Spacer,
  Modal, 
  ModalOverlay, 
  ModalContent, 
  ModalBody, 
  Input,
} from '@chakra-ui/react';

import PropTypes from "prop-types"

import { ModuleBoxDrawer } from '../Structural/ModuleBoxDrawer.jsx';
import { Divider } from '../Divider.jsx';

import { getSignedURLFromS3File } from '../graphqlCompnents/AWS/S3/getSignedURLFromS3File.jsx';
import { fetchUploadedDocument } from '../graphqlCompnents/AWS/S3/fetchUploadedDocument.jsx';
import { fetchUploadedReceipts } from '../graphqlCompnents/AWS/S3/fetchUploadedReceipts.jsx';
import { fetchDivisionOptions } from '../graphqlCompnents/Statement/fetchDivisionOptions.jsx';
import { fetchTeamMemberByAccountNumber } from '../graphqlCompnents/Statement/fetchTeamMemberByAccountNumber.jsx';
import { fetchClientsForAllDivisions } from '../graphqlCompnents/DJT/fetchClientsForAllDivisions.jsx';
import { fetchContactCompaniesByDivision } from '../graphqlCompnents/DJT/fetchContactCompaniesByDivision.jsx';

import { spellOutDate } from '../functions/dateTime.jsx';
import { formatCurrency } from '../functions/currency.jsx';

import { FormSelectSimple } from '../Form/Select/FormSelectSimple.jsx';
import { TextareaInput } from '../Form/Input/TextareaInput.jsx';
import { TextInput } from '../Form/Input/TextInput.jsx';

import { ButtonSecondaryPlain } from '../Form/Button/ButtonSecondaryPlain.jsx';
import { ButtonPrimaryPlain } from '../Form/Button/ButtonPrimaryPlain.jsx';
import { ButtonQuaternaryWithIcon } from '../Form/Button/ButtonQuaternaryWithIcon.jsx';

// structural components
// import { ModuleDrawer } from '../Structural/ModuleDrawer.jsx';
// CenteredStatusProgressModal
// drawer components
// import { EditTransactionVendor } from './EditTransactionVendor.jsx';
import { VendorMap } from './VendorMap.jsx';

import { stateOptions } from '../Form/Select/stateOptions.jsx';

import { GlobalWorkerOptions } from 'pdfjs-dist/build/pdf';
import * as pdfjsLib from 'pdfjs-dist';


export const EditTransaction = (props) => {

  const {
    transaction={},
    onClose=()=>{},
    environment="sandbox",
    expenseCategories=[],
    selectedExpenseCategory={},
    qbVendors=[],
    handleMapVendor=()=>{},
    token='',
    register=()=>{},
    control={},
    errors={},
    setError=()=>{},
    clearErrors=()=>{},
    setValue=()=>{},
    getValues=()=>{},
    updateExpenseCategory=()=>{},
    rowIndex='',
    updateReceipt=()=>{},
    handleSubmit=()=>{},
    adminName = '',
  } = props;

  console.log('EditTransaction props: ', transaction);

  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure(); // For controlling modal state
  const [currentImage, setCurrentImage] = useState(null); // To hold the image URL to display

  const handleImageClick = (imageUrl) => {
    setCurrentImage(imageUrl);  // Set the clicked image URL
    onModalOpen();  // Open the modal to show the image
  };

  const [ isSubmitting, setIsSubmitting ] = useState(false);
  
  // console.log('transaction: ', transaction?.capitalOneInstantNotification?.status);
  // console.log('qbVendors: ', qbVendors);

  const allSort = (environment==="sandbox") ? "sandbox" : "all";

  // ----- EDIT TRANSACTION DRAWER -----
  // const { isOpen: isEditTransactionVendorOpen , onOpen: onEditTransactionVendorOpen, onClose: editTransactionVendorClose } = useDisclosure()


  const issueIsResolved = transaction?.matchStatus === 'fixed-issue';
  const [ isUpdating, setIsUpdating ] = useState(false);
  // const [ isPublishing, setIsPublishing ] = useState(false);
  
  useEffect(() => {
    // Set the workerSrc to the file in the public folder
    GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs';
    setValue('publish', 'true');
    setValue('matchStatus', 'published');
    setValue('id', transaction?.id);
    console.log('selectedExpenseCategory: ', selectedExpenseCategory)
  }, []);

  const [ expenseCategoryLocked, setExpenseCategoryLocked ] = useState(false);
  useEffect(() => {
    const getExpenseCategory = (id) => {
      // if (!expenseCategories.length) return '';
      // Find the expense that matches the given qbId
      const expense = expenseCategories.find((category) => category.value === id);
  
      // If no expense is found, return null or a default value
      if (!expense) return '';
  
      // Return the formatted object based on the found expense
      const expenseCategory = {
        value: expense.value,
        label: expense.label,
        isIFTATracked: expense?.isIFTATracked === true ? true : false,
      };

      if (expenseCategory.isIFTATracked) {
        setExpenseCategoryLocked(true);
      }
      
      // pass the data off to the update function
      updateExpenseCategory(expenseCategory, rowIndex)
    };

    (transaction?.expenseCategoryId && expenseCategories.length) && getExpenseCategory(transaction?.expenseCategoryId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[expenseCategories, transaction])

  const [ teamMemberName, setTeamMemberName ] = useState(null);
  useEffect(() => {
    const fetchTeamMember = async () => {
      try {
        const results = await fetchTeamMemberByAccountNumber(transaction?.matchedItem?.accountNumber);
        let teamMember = {};
        if (results?.length>1) {
          teamMember = results.find((result) => result?.allSort === allSort);
        } else {
          teamMember = results[0]
        }
        
        const teamMemberDivision = teamMember?.teamMember?.division?.prettyname;
        let firstName = '';
        let lastName = '';
        if (teamMember) {
          const name = teamMember?.teamMember;
          firstName = name.goesBy||name.firstName||'';
          lastName = name.lastName||'';
        }
        setTeamMemberName(`${firstName} ${lastName}-${teamMemberDivision}`);
      } catch (error) {
        console.error('Error in fetching team member:', error);
      }
    };
  
    if (transaction?.matchedItem?.accountNumber?.length===4) {
      fetchTeamMember();
    }
  },[allSort, environment, transaction])

  // const [ quickBooksVendorOptions, setQuickBooksVendorOptions ] = useState([]);
  // useEffect(() => {
  //   const options = qbVendors.map(vendor => ({
  //     value: vendor.qbId,         // Assigning qbId as the value
  //     label: vendor.qbDisplayName // Assigning qbDisplayName as the label
  //   }));
  //   setQuickBooksVendorOptions(options);
  // },[qbVendors])

  // const [ selectedQuickBooksVendorOption, setSelectedQuickBooksVendorOption ] = useState(null);
  // useEffect(() => {
  //   const getQuickBooksVendor = (id) => {
  //     // if (!qbVendors.length) return '';
  //     // Find the vendor that matches the given qbId
  //     const vendor = qbVendors.find((vendor) => vendor.qbId === id);
  //     // console.log('id: ', id);

  //     // If no vendor is found, return null or a default value
  //     if (!vendor) return '';

  //     // Return the formatted object based on the found vendor
  //     const vendorOption = {
  //       value: vendor.qbId,
  //       label: vendor.qbDisplayName,
  //     };
  //     // console.log('vendorOption: ', vendorOption);
  //     setSelectedQuickBooksVendorOption(vendorOption);
  //   };

  //   (transaction?.qbVendor?.qbId && qbVendors.length) && getQuickBooksVendor(transaction?.qbVendor?.qbId);
  // },[qbVendors, transaction])

  const [ receiptSignedUrl, setReceiptSignedUrl ] = useState(null);
  useEffect(() => {
    const fetchSignedUrl = async (documentId) => {
      try {
        const document = await fetchUploadedDocument(documentId);
        // console.log('Fetched document: ', document);
        
        // Check for valid document structure
        if (!document?.bucket || !document?.key) {
          throw new Error(`Invalid document structure: ${JSON.stringify(document)}`);
        }
  
        const signedURL = await getSignedURLFromS3File(document);
        // console.log('Generated Signed URL: ', signedURL);
        setReceiptSignedUrl(signedURL);
      } catch (error) {
        console.error('Error in fetching or generating signed URL:', error);
      }
    };
  
    if (transaction?.uploadedDocumentId) {
      fetchSignedUrl(transaction.uploadedDocumentId);
    }
  }, [transaction]);

  const [ previewImages, setPreviewImages ] = useState([]);
  const [ receiptSignedUrls, setReceiptSignedUrls ] = useState([]);
  useEffect(() => {
    const fetchReceipts = async () => {
      try {
        const receipts = await fetchUploadedReceipts(transaction?.id);
        console.log('Fetched receipts:', receipts);
  
        // Check if receipts is an array
        if (!Array.isArray(receipts)) {
          throw new Error('Invalid receipts format.');
        }
  
        // Fetch all signed URLs and generate previews for each receipt
        const signedUrls = await Promise.all(
          receipts.map(async (receipt, index) => {
            // console.log(`Receipt ${index + 1}:`, receipt);
  
            const uploadedDocument = receipt?.uploadedDocument;
  
            // Check for valid document structure
            if (!uploadedDocument?.bucket || !uploadedDocument?.key) {
              throw new Error(`Invalid document structure: ${JSON.stringify(receipt)}`);
            }
  
            // Fetch the signed URL using the `uploadedDocument` details
            const signedURL = await getSignedURLFromS3File({
              bucket: uploadedDocument.bucket,
              key: uploadedDocument.key,
              identityId: uploadedDocument.identityId
            });
  
            // Generate a preview for the file (either an image or a PDF)
            const preview = await generatePreview(signedURL, uploadedDocument.key);  // Generate preview
  
            // Return both the signed URL and its corresponding preview
            return { signedURL, preview };
          })
        );
  
        // Separate signed URLs and previews into their own arrays
        const urls = signedUrls.map(item => item.signedURL);
        const previews = signedUrls.map(item => item.preview);
  
        // Set the state with both signed URLs and previews
        setReceiptSignedUrls(urls);
        setPreviewImages(previews);  // use this to display previews
  
      } catch (error) {
        console.error('Error in fetching receipts:', error);
      }
    };
  
    if (transaction) {
      fetchReceipts();
    }
  
  }, [transaction]);
  
  // generatePreview function
  const generatePreview = async (signedURL, key) => {
    const fileType = key?.split('.').pop();  // Extract the file type from the file key
  
    if (fileType === 'pdf') {
      return await generatePdfPreview(signedURL);
    } else if (['jpg', 'jpeg', 'png'].includes(fileType.toLowerCase())) {
      return signedURL;  // For image files, the signed URL can directly be used as the preview
    } else {
      throw new Error('Unsupported file type for preview generation');
    }
  };
  
  // generatePdfPreview function
  const generatePdfPreview = async (pdfUrl) => {
    const pdf = await pdfjsLib.getDocument(pdfUrl).promise;
    const page = await pdf.getPage(1);
  
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const viewport = page.getViewport({ scale: 1.0 });
  
    canvas.height = viewport.height;
    canvas.width = viewport.width;
  
    await page.render({ canvasContext: context, viewport }).promise;
    
    return canvas.toDataURL();  // Return the preview as a data URL
  };

  const handleKeyDown = (event) => {
    // Check if the key pressed is 'Enter'
    if (event.key === 'Enter' && event.target.type !== 'textarea') {
      event.preventDefault();  // Prevent form submission
    }
  };

  const [ showHasIssueForm, setShowHasIssueForm ] = useState(false);
  const handleReceiptHasIssue = () => {
    console.log('Receipt has issue');
    setShowHasIssueForm(prevState => !prevState);
  }

  const getStateOptions = (stateAbbreviation) => {
    const state = stateOptions.find(option => option.value === stateAbbreviation);
    return state.label;
  }

  // const updateSelectedQuickBooksVendor = (event) => {
  //   console.log('event: ', event)
  //   setSelectedQuickBooksVendorOption(event);
  //   setValue('selectQuickBooksVendor', event.value)
  //   clearErrors('selectQuickBooksVendor')
  // }

  const [ currentEditTransaction, setCurrentEditTransaction ] = useState(null)
  // const handleOpenEditTransactionVendor = (transaction) => {
  //   console.log('transaction: ', transaction)
  //   setCurrentEditTransaction(transaction)
  // }

  // useEffect(() => {
  //   currentEditTransaction && onEditTransactionVendorOpen();
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[currentEditTransaction])

  // const onEditTransactionVendorClose = () => {
  //   setCurrentEditTransaction(null)
  //   // handleRefresh()
  //   editTransactionVendorClose()
  // }

  const [ showEditSupplier, setShowEditSupplier ] = useState(false);
  // function to toggle the showEditSupplier off and on
  // const toggleShowEditSupplier = () => {
  //   setShowEditSupplier(prevState => !prevState);
  // }

  const [ receiptIssue, setReceiptIssue ] = useState('');
  const [ receiptIssueLog, setReceiptIssueLog ] = useState(transaction?.issue||'');
  
  const handleUpdateReceiptIssue = (issue) => {
    // add admin name to the issue test with a colon and a hard return
    const issues = receiptIssueLog + adminName + ': ' + issue + '::cr::';
    setReceiptIssue(issues);
  }

  const handleReturnReceiptToCardholder = async () => {
    setIsUpdating(true);
    const props = {
      matchStatus: 'has-issue',
      id: transaction?.id,
      hasIssue: true,
      issue: receiptIssue,
      expenseCategoryId: selectedExpenseCategory?.value,
    }
    console.log('props: ', props);
    await updateReceipt(props);
    handleOnClose();
  }

  const handleOnClose = () => {
    setShowHasIssueForm(false);
    setShowEditSupplier(false);
    setReceiptIssue('');
    setValue('receiptIssue', '');
    clearErrors('receiptIssue');
    setCurrentEditTransaction(null);
    // setSelectedQuickBooksVendorOption(null);
    // setSelectedExpenseCategoryOption(null);
    setTeamMemberName(null);
    setReceiptSignedUrl(null);
    setPreviewImages([]);
    setReceiptSignedUrls([]);
    setCurrentImage(null);
    setIsUpdating(false);
    onClose();
  }

  const [ selectedDivision, setSelectedDivision ] = useState('');
  const [ divisionOptions, setDivisionOptions ] = useState([])
  const [ allClientOptions, setAllClientOptions ] = useState([]);
  const [ clientOptions, setClientOptions ] = useState([]);
  const [ selectedClient, setSelectedClient ] = useState('');
  const [ divisionIsLocked, setDivisionIsLocked ] = useState(false);
  const [ unitsAttached, setUnitsAttached ] = useState([]);
  const [ clientQbId, setClientQbId ] = useState('');

  useEffect(() => {
    if (allClientOptions?.length>0) {
      let clientId = transaction.clientId||"";
      console.warn('clientId: ', clientId);
      const client = allClientOptions.find(
        option => option.value === clientId
      );
      console.warn('client: ', client);
      setSelectedClient(client || '');
      setValue('selectClient', clientId);
      setDivisionIsLocked(true);
      setClientQbId(client.qbId);
      setValue('clientQbId', client.qbId);
    }
  },[transaction, allClientOptions, setValue])

  const handleClientChange = (event) => {
    const match = allClientOptions.find(
      option => option.value === event.value
    );
    // console.warn('match: ', match?.qbId||'');
    setClientQbId(match?.qbId||'');
    setValue('clientQbId', match?.qbId||'');
    setSelectedClient(event);
    setValue('selectClient', event.value);
  };

  useEffect(() => {
    // All clients
    const fetchAllClients = async () => {
      try {
        const response = await fetchClientsForAllDivisions();
        const clientOptions = response.map(client => ({
          value: client.value,
          label: client.label,
          divisionId: client.divisionId,
          qbId: client.qbId,
        }));

        // Add the ' -- None --' option at the beginning
        clientOptions.unshift({ value: '', label: ' - None -' });

        console.warn('clientOptions: ', clientOptions);
      
        setAllClientOptions(clientOptions);
      } catch (error) {
        console.error('Failed to fetch clients:', error);
      }
    };

    fetchAllClients();

  },[])

  // fetch all divisions shared by all users
  useEffect(() => {
    const fetchDivisions = async () => {
      try {
        const responseAll = await fetchDivisionOptions('all');
        const optionsAll = responseAll.map(division => ({
          value: division.id,
          label: division.prettyname,
        }));
  
        // Fetch divisions for 'special'
        const responseSpecial = await fetchDivisionOptions('special');
        const optionsSpecial = responseSpecial.map(division => ({
          value: division.id,
          label: division.prettyname,
        }));
  
        // Combine and sort options
        const combinedOptions = [...optionsAll, ...optionsSpecial].sort((a, b) => 
          a.label.localeCompare(b.label)
        );
        setDivisionOptions(combinedOptions);
  
      } catch (error) {
        console.error('Failed to fetch divisions:', error);
      }
    };
  
    fetchDivisions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDivisionChange = (event) => {
    const divisionId = event.value;
    setSelectedDivision(event);
    // setValue('selectDivision', divisionId);
    setValue('selectDivision', event);
    clearErrors('selectDivision');

    const filteredClients = allClientOptions.filter(
      (client) => client.divisionId === divisionId
    );

    setClientOptions(filteredClients)
    setValue('selectClient', filteredClients);
    setSelectedClient('');
    setValue('selectClient', '');
  };

  useEffect(() => {
    if (transaction?.units?.items?.length>0) {
      const units = transaction.units.items.map((unit) => {
        console.log('unit: ', unit);
        return {
          // unitId: unit?.unitId,
          // unitCode: unit?.code,
          // unitType: unit?.type?.name,
          // unitSubType: unit.subType?.name,
          unitName: `${unit?.unit?.code} - ${unit?.unit?.type?.name}/${unit?.unit?.subType?.name}`,
        };
      });
      console.log('units: ', units);
      setUnitsAttached(units);
    }
  },[transaction])

  useEffect(() => {
    // Division
    if (divisionOptions) {
      const division = divisionOptions.find(
        option => option.value === transaction.divisionId
      );
      setSelectedDivision(division || '');
      // setValue('selectDivision', transaction.divisionId);
      setValue('selectDivision', division);
    }

    // Clients by division
    const fetchClients = async (divisionId) => {
      if (divisionId) {
        try {
          const response = await fetchContactCompaniesByDivision(divisionId);
          const clientOptions = response.map(client => ({
            value: client.value,
            label: client.label,
          }));

          // Add the ' -- None --' option at the beginning
          clientOptions.unshift({ value: '', label: ' - None -' });
        
          setClientOptions(clientOptions);
        } catch (error) {
          console.error('Failed to fetch clients:', error);
        }
      }
    };

    fetchClients(transaction.divisionId);

  }, [transaction, divisionOptions, setValue])

  useEffect(() => {
    if (transaction) {
      const dateYYYYMMDD = transaction?.createdAt.split('T')[0];
      (dateYYYYMMDD) && setValue('transactionDate', dateYYYYMMDD||'');
      // console.log('transactionDate: ', dateYYYYMMDD);
      
      const identityKey = transaction?.uploadedDocuments?.items[0]?.uploadedDocument?.identityId; // Extract the identity key
      setValue('identityKey', identityKey||'');
      // console.log('identityKey: ', identityKey);

      const files = transaction?.uploadedDocuments?.items?.map((item) => {
        const key = item.uploadedDocument.key; // Extract the key
        const fileName = key.split('/').pop(); // Extract the file name from the key
        return { fileKey: key, fileName };
      });
      // console.log('files: ', files||'');
      const filesParameter = JSON.stringify(files);
      // console.log('filesParameter: ', filesParameter);
      setValue('uploadedDocumentsJSON', filesParameter||'');

      setValue('merchantName', transaction?.matchedItem?.merchantEntityName||'UNKNOWN');
      // console.log('merchantName: ', transaction?.matchedItem?.merchantEntityName||'UNKNOWN');
      setValue('accountNumber', transaction?.matchedItem?.accountNumbe||'');
      // console.log('accountNumber: ', transaction?.matchedItem?.accountNumber);
      setValue('amount', transaction?.matchedItem?.amount||'');
      // console.log('amount: ', transaction?.matchedItem?.amount);
      setValue('qbVendorId', transaction?.qbVendor?.qbId||'');
      // console.log('qbVendorId: ', transaction?.qbVendor?.qbId);
      setValue('transactionAmount', transaction?.matchedItem?.amount||'');
      // console.log('transactionAmount: ', transaction?.matchedItem?.amount);
      setValue('addedNotes', transaction?.addedNotes||'');
      // console.log('addedNotes: ', transaction?.addedNotes);
      const tmId = transaction?.teamMemberId;
      // console.log('tmId: ', tmId||'');
      const cardNumber = transaction?.matchedItem?.accountNumber;
      // console.log('cardNumber: ', cardNumber||'');
      const tmIdCardNumber = `${tmId}#${cardNumber}`;
      // console.log('tmIdCardNumber: ', tmIdCardNumber);
      setValue('tmIdCardNumber', tmIdCardNumber||'');
      // console.log('qbSupplierMatched: ', transaction?.qbSupplierMatched);
      setValue('qbSupplierMatched', transaction?.qbSupplierMatched||false);
      // console.log('qbSupplierMatched: ', transaction?.qbSupplierMatched);
      setValue('plaidCreditCardTransactionId', transaction?.plaidCreditCardTransactionId||'');
    }
  }, [transaction, setValue]); // Include setValue in dependency array

  return (
    <>
    {/* <Container as="form" maxW="1600" mb={12} onKeyDown={handleKeyDown} onSubmit={handleSubmit(updateReceipt)}> */}
    <Container as="form" maxW="1600" mb={12} onKeyDown={handleKeyDown} onSubmit={handleSubmit(async (data) => {
      setIsSubmitting(true);
      try {
        await updateReceipt(data);
      } finally {
        setIsSubmitting(false);
      }
    })}>
      <ModuleBoxDrawer>
        <Flex direction="column" maxW="1200px" mx="auto">
          <Flex>
            <Box>
              <Text as="span" textStyle='heading-1'>Edit Receipt</Text>
            </Box>
            <Spacer />
            <Box>
              <ButtonQuaternaryWithIcon 
                name='closeDrawer'
                iconsize='26px'
                leftIcon='close'
                value='Cancel'
                onClick={handleOnClose}
              />
            </Box>
          </Flex>

          <fieldset disabled={transaction.hasIssue && !issueIsResolved}>

            {/* Layout with Receipt Image and Form */}
            <Grid templateColumns="repeat(2, 1fr)" gap={12}>
              
              <GridItem>
                <Flex wrap="wrap" mt={4}>
                  {previewImages.map((preview, index) => {
                    const fileType = receiptSignedUrls[index]?.split('.').pop().toLowerCase(); // Get file type from the URL

                    return (
                      <Box key={index} m={2}>
                        {/* Conditionally handle image and PDF */}
                        {fileType === 'pdf' ? (
                          // PDF file: download when clicked
                          <a href={receiptSignedUrls[index]} download={`Receipt_${index + 1}`}>
                            <Image src={preview} alt={`PDF Preview ${index + 1}`} width="200px" cursor="pointer" />
                          </a>
                        ) : (
                          // Image file: open in modal when clicked
                          <Image
                            src={preview}
                            alt={`Image Preview ${index + 1}`}
                            // width="200px"
                            // width="400px"
                            maxW={'500px'}
                            cursor="pointer"
                            onClick={() => handleImageClick(receiptSignedUrls[index])}  // Open modal
                          />
                        )}
                      </Box>
                    );
                  })}
                </Flex>
              </GridItem>

              {/* Right Section: Form */}
              <GridItem w={'520px'}>
                <Stack spacing={6}>
                  <Box>
                    <Text as="span" textStyle='heading-3'>
                      Item Details
                    </Text>
                    <Stack spacing="5px" mt={'25px'}>
                      
                      {/* {transaction?.hasIssue && !issueIsResolved && <Text className='dark-sfpro-text-2' color='red'>Receipt has issue. {transaction?.issue}</Text>}
                      {issueIsResolved && <Text className='dark-sfpro-text-2' color='green'>Issue resolved. {transaction?.issue}</Text>} */}
                      
                      <Text className='dark-sfpro-text-2'>Date: {spellOutDate(transaction?.matchedItem?.date, 'apple-ny')}</Text>
                      <Text className='dark-sfpro-text-2'>QuickBooks Supplier: {(transaction?.qbSupplierMatched) ? transaction?.qbSupplierName : `NEW ${transaction?.qbSupplierName}`} </Text>
                      <Text className='dark-sfpro-text-2'>Merchant: {transaction?.matchedItem?.merchantEntityName}</Text>
                      <Text className='dark-sfpro-text-2'>Amount: {formatCurrency(transaction?.matchedItem?.amount)}</Text>
                      <Text className='dark-sfpro-text-2'>Notes: {transaction?.addedNotes}</Text>
                      <Text className='dark-sfpro-text-2'>Card Number: {transaction?.matchedItem?.accountNumber}</Text>
                      <Text className='dark-sfpro-text-2'>Card Holder: {teamMemberName}</Text>
                      {(unitsAttached?.length>0) && <Text className='dark-sfpro-text-2'>Units: {unitsAttached.map((unit, index) => (
                        <Box key={index}>
                          {unit.unitName}
                        </Box>
                      ))}</Text>}
                      {expenseCategoryLocked && <>
                        <Text className='dark-sfpro-text-2'>State: {getStateOptions(transaction?.iftaFuelTaxTrack?.stateTwoChar)||''}</Text>
                        <Text className='dark-sfpro-text-2'>Odometer: {transaction?.iftaFuelTaxTrack?.odometer}</Text>
                        <Text className='dark-sfpro-text-2'>Gallons: {transaction?.iftaFuelTaxTrack?.gallons}</Text>
                        <Text className='dark-sfpro-text-2'></Text>
                      </>}

                      {expenseCategoryLocked && <>
                        <Text className='dark-sfpro-text-2'>Expense Category is locked due to IFTA tracking. Any changes to Expense Category will need to be made by the card holder.</Text>
                      </>}
                    
                      {/* {divisionIsLocked && <>
                        <Text className='dark-sfpro-text-2'>Division is locked due to client being selected. Any changes to Division will need to be made by the card holder.</Text>
                      </>} */}
                    
                      <Box mt={'25px'} w={'60%'}>
                        <FormSelectSimple
                          register={register}
                          control={control}
                          errors={errors}
                          isRequired={true}
                          rules={{ required: "Expense Category is required" }}
                          onChange={e => {
                            console.log('e: ', e);
                            updateExpenseCategory(e, rowIndex);
                          }}
                          selectedoption={selectedExpenseCategory||''}
                          optionsArray={expenseCategories}
                          fieldname='selectExpenseCategory'
                          prettyname='Expense Category'
                          fieldlabel="Expense Category"
                          placeholder={'Select expense category'}
                          issearchable={true}
                          isDisabled={expenseCategoryLocked}
                        />
                      </Box>

                      <Box mt={'25px'} w={'60%'}>
                        <FormSelectSimple
                          key='Division'
                          register={register}
                          control={control}
                          errors={errors}
                          isRequired={true}
                          rules={{ required: "Division is required" }}
                          fieldname="selectDivision"
                          fieldlabel="Division"
                          placeholder={'Select division'}
                          optionsArray={divisionOptions?.map((division, index) => ({
                            key: index,
                            value: division.value,
                            label: division.label
                          }))}
                          onChange={handleDivisionChange}
                          selectedoption={selectedDivision||''}
                          issearchable={false}
                          // isDisabled={divisionIsLocked}
                        />
                      </Box>

                      <Box mt={'25px'} w={'60%'}>
                        <FormSelectSimple
                          key='Client'
                          register={register}
                          control={control}
                          errors={errors}
                          // isRequired={true}
                          fieldname="selectClient"
                          fieldlabel="Client"
                          placeholder={'Select client'}
                          optionsArray={clientOptions}
                          onChange={handleClientChange}
                          selectedoption={selectedClient||''}
                          issearchable={false}
                        />
                      </Box>

                      {transaction?.hasIssue && <Box paddingTop={'25px'} >
                        {/* <Text as="span" textStyle="label-2" display="block" mt="4px" marginRight={'10px'}>Reason for return: </Text> */}
                        <Text
                          // paddingLeft="25px"
                          as="span"
                          textStyle="label-2"
                          display="block"
                          mt="4px"
                        >
                          {transaction?.issue.split('::cr::').map((line, index) => (
                            <Box key={index}>
                              {line}
                              {index < transaction?.issue.split('::cr::').length - 1 && <br />}
                            </Box>
                          ))}
                        </Text>
                      </Box>}
                    
                      <Box mt={'25px'} w={'100%'}>
                        <ButtonSecondaryPlain
                          type='button'
                          width={'245px'}
                          name='issue'
                          value='Issue with this receipt'
                          onClick={() => {
                            handleReceiptHasIssue();
                          }}
                        />                        
                      </Box>

                      {showEditSupplier && <Box >
                        <VendorMap 
                          handleMapVendor={handleMapVendor}
                          vendorName={transaction?.matchedItem?.merchantEntityName}
                          vendors={qbVendors} 
                          environment={environment}
                          token={token}
                          control={control}
                          register={register}
                          errors={errors}
                          setError={setError}
                          clearErrors={clearErrors}
                          setValue={setValue}
                          rowIndex={rowIndex}
                          showCreateSupplier={false}
                        />
                      </Box>}

                      <Divider my={'25px'} />

                      {(!showHasIssueForm && !showEditSupplier) && <Box>
                        <ButtonPrimaryPlain
                          type='submit'
                          width={'245px'}
                          name='publish-edited'
                          // value='Publish'
                          value={isSubmitting ? 'Publishing...' : 'Publish'}
                          isDisabled={showHasIssueForm||isSubmitting}
                          // isDisabled={true}
                        />
                      </Box>}

                      {showHasIssueForm && <Box mt={'25px'} bg={'var(--dark-menu-background)'}>
                        <Box w={'90%'} pl={'25px'} pt={'25px'}>
                          <TextareaInput
                            fieldname="receiptIssue"
                            prettyname="Receipt Issue"
                            register={register}
                            errors={errors}
                            placeholder="Enter receipt issue"
                            isRequired={true}
                            isReadOnly={false}
                            rows={2}
                            onChange={(e) => {
                              handleUpdateReceiptIssue(e.target.value);
                            }}
                          />
                        </Box>
                        <Box my={'25px'} w="100%" display="flex" justifyContent="center" alignItems="center">
                          <ButtonSecondaryPlain
                            width="245px"
                            type="button"
                            name="issue"
                            value="Return receipt to card holder"
                            onClick={() => {
                              handleReturnReceiptToCardholder();
                            }}
                          />
                        </Box>
                      </Box>}
                      
                    </Stack>
                  </Box>


                </Stack>
              </GridItem>
            </Grid>
          </fieldset>
        </Flex>
      </ModuleBoxDrawer>
    </Container>

    {/* <ModuleDrawer
      onClose={onEditTransactionVendorClose}
      isOpen={isEditTransactionVendorOpen}
      bodyContent={
        <EditTransactionVendor 
          transaction={currentEditTransaction} 
          onClose={onEditTransactionVendorClose} 
          environment={environment}
          qbVendors={qbVendors}
          setValue={setValue}
        />}
      size={'full'}
    /> */}

    {/* Modal to show the image */}
    <Modal isOpen={isModalOpen} onClose={onModalClose} size={'full'}>
      <ModalOverlay />
      <ModalContent bg="black" position="relative">  {/* Set the background color to black */}
        
        {/* Custom close button */}
        <Box position="absolute" top="25px" right="25px">
          <ButtonQuaternaryWithIcon 
            name='closeModal'
            iconsize='26px'
            leftIcon='close'
            value='Close'
            onClick={onModalClose}
          />
        </Box>
        
        <ModalBody p="25px" pt="50px">  {/* Reduced top padding */}
          <Box bg="black" p="25px" borderRadius="md" mt="50px"> {/* Added margin-top to push image further down */}
            {currentImage && (
              <Image src={currentImage} alt="Receipt Image" width="100%" />
            )}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
    </>
  )
}

EditTransaction.propTypes = {
  transaction: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  environment: PropTypes.string.isRequired,
  expenseCategories: PropTypes.array.isRequired,
  selectedExpenseCategory: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  qbVendors: PropTypes.array.isRequired,
  handleMapVendor: PropTypes.func.isRequired,
  token: PropTypes.string,
  register: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  setError: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  updateExpenseCategory: PropTypes.func.isRequired,
  rowIndex: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  updateReceipt: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  adminName: PropTypes.string,
}