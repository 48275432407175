import { createStatementVendorMap } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const addVendorMap = async (props) => {
  if (!props) return;

  try {
    console.log('addVendorMap props: ', props);

    const response = await client.graphql({
      query: createStatementVendorMap,
      variables: { input: props },
    });

    console.log('addVendorMap response: ', response);

    const results = response.data.createSpkStatementVendorMapTbl;
    console.log('addVendorMap results: ', results);

    return results;
  } catch (err) {
    return ('error addVendorMap: ', [err]);
  }
};

addVendorMap.propTypes = {
  props: PropTypes.shape({
    // Define the structure of "props" based on what is expected by the mutation.
  }).isRequired,
};