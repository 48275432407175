import {
  Drawer,
  DrawerBody,
  // DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const ModuleDrawer = (props) => {

  const {
    onClose,
    isOpen,
    headerContent,
    bodyContent,
    // footerContent,
    size,
  } = props;

  return (
    <>
    <Drawer 
      onClose={onClose} 
      isOpen={isOpen} 
      size={size}>
     <DrawerOverlay />
      <DrawerContent >
        <DrawerHeader>{headerContent}</DrawerHeader>
        <DrawerBody>
          {bodyContent}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
    </>
  )
}

ModuleDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  headerContent: PropTypes.node,
  bodyContent: PropTypes.node,
  footerContent: PropTypes.node,
  size: PropTypes.string,
};