import { uploadData } from 'aws-amplify/storage';
// import { fetchAuthSession } from 'aws-amplify/auth';
import { addFileToUploadedDocuments } from '../../graphqlCompnents/AWS/S3/addFileToUploadedDocuments'
import { v4 as uuidv4 } from 'uuid';
// import { getIdentityId } from './cognitoIdentityService';

export const uploadBlobToS3 = async (props) => {
  if (props) {
    try {
      const fileobj = props.file
      const identityId = props.identityId;
      const filename = props.filename
      const mimeType = props.filetype;
      const bucket = props.bucket
      const fileKey = uuidv4()+':'+filename

      const fileParams = {
        bucket: bucket,
        identityId: identityId, 
        key: fileKey
      }

      // Add the record to the SpkUploadedDocumentsTbl for tracking all files uploaded
      // console.log('--- uploadBlobToS3 --- about to addFileToUploadedDocuments')
      // const uploadedDocumentId = await addFileToUploadedDocuments(fileParams);
      await addFileToUploadedDocuments(fileParams);

      // console.log('got uploadedDocumentId: ', uploadedDocumentId)

      await uploadData({
        path: 'protected/'+identityId+'/'+fileKey,
        data: fileobj,
        options: {
          contentType: mimeType,
          bucket: {
            bucketName: bucket,
            region: 'us-east-1'
          }
        }
      })

      const returnData = {
        fileName: filename,
        identityId: identityId, 
        key: fileKey
      }

      return returnData

    } catch (err) {
      return err
    }
  } else {
    console.log('ERROR: No props sent.')
    return 'ERROR: No props'
  }
  
}
