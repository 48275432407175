import { updatePlaidCreditCardTransaction } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from "aws-amplify/api";
const client = generateClient();

export const updateTransaction = async (props) => {
  if (!props) return;

  console.log('updatePlaidCreditCardTransaction props: ', props);
  
  try {
    const gqlParams = { input: props };
    
    const response = await client.graphql({
      query: updatePlaidCreditCardTransaction,
      variables: gqlParams
    });
    
    console.log('updatePlaidCreditCardTransaction response: ', response);
    
    const results = response.data.updateSpkPlaidCreditCardTransactionTbl.id;
    console.log('updatePlaidCreditCardTransaction results: ', results);
    
    return results;
  } catch (err) {
    return ('error updatePlaidCreditCardTransaction: ', [err]);
  }
};

updateTransaction.propTypes = {
  props: PropTypes.object.isRequired,
};