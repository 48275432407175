import { updateDJTStatusId } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from "aws-amplify/api";
const client = generateClient();

export const updateDJTStatus = async (djtId, statusId, divisionId, clientId, teamMemberId, invoiceNumber) => {
  if (!djtId || !statusId) return null;

  try {
    const params = {
      id: djtId,
      currentStatusId: statusId,
      djtDivisionClientStatus: `${divisionId}#${clientId}#${statusId}`,
      djtDivisionStatus: `${divisionId}#${statusId}`,
      djtDivisionTeamMemberStatus: `${divisionId}#${teamMemberId}#${statusId}`,
      invoiceNumber: invoiceNumber,
    };

    const response = await client.graphql({
      query: updateDJTStatusId,
      variables: { input: params }
    });

    const results = response.data.updateSpkDailyJobTicketTbl.id;
    return results;
  } catch (err) {
    return ('error updateDJTStatus: ', [err]);
  }
};

updateDJTStatus.propTypes = {
  djtId: PropTypes.string.isRequired,
  statusId: PropTypes.string.isRequired,
  divisionId: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  teamMemberId: PropTypes.string.isRequired,
  invoiceNumber: PropTypes.string,
};
