import { capitalOneInstantNotificationsByStatus } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const fetchCapitalOneInstantNotificationsByStatus = async (status, limit = 250) => {
  try {

    const gqlParams = {
      allSort: "all", 
      sortDirection: "ASC", 
      status: {eq: status},
      // filter: {purchaseDate: {ge: "2024-12-01"}},
      limit,
    };

    // console.info('GraphQL Params:', gqlParams);

    const response = await client.graphql({
      query: capitalOneInstantNotificationsByStatus,
      variables: gqlParams,
    });

    // console.info('GraphQL Response:', response);

    // Ensure response is as expected
    if (!response || !response.data || !response.data.capitalOneInstantNotificationByAllSort) {
      console.warn(`No data returned for status ${status}`);
      return null; // Return null if the response structure is unexpected
    }

    const results = response.data.capitalOneInstantNotificationByAllSort.items;

    // If results is not an array or is empty, return null
    if (!Array.isArray(results) || results.length === 0) {
      console.warn('No results found.');
      return null;
    }

    return results; // Return the items array if data is present
  } catch (err) {
    console.error('Error in fetchCapitalOneInstantNotificationsByStatus:', err);
    return null; // Return null on error
  }
};

fetchCapitalOneInstantNotificationsByStatus.propTypes = {
  status: PropTypes.string.isRequired,
  limit: PropTypes.number,
};