export function calcAnniversary(hireDate) {
  let ymdArray = hireDate.split("-");
  let annMonth = ymdArray[1];
  let annDay = ymdArray[2];
  let annYear = new Date().getFullYear();
  let anniversaryDate = annYear+'-'+annMonth+'-'+annDay;
  return anniversaryDate;
}

export function calcYearsOfService(hireDate) {
  let ymdArray = hireDate.split("-");
  let hiredYear = ymdArray[0];
  let annYear = new Date().getFullYear();
  let yearsOfervice = Number(annYear) - Number(hiredYear)
  return yearsOfervice;
}

export function isTimeExceeded(createdAt, timeInSeconds) {
  // Convert the createdAt string to a Date object
  const createdDate = new Date(createdAt);

  // Get the current time as a Date object
  const currentDate = new Date();

  // Calculate the difference in milliseconds
  const differenceInMilliseconds = currentDate - createdDate;

  // Convert timeInSeconds to milliseconds
  const timeInMilliseconds = timeInSeconds * 1000;

  // Check if the difference is greater than timeInSeconds
  return differenceInMilliseconds > timeInMilliseconds;
}

export function todaysDateYMD() {
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, '0');
  let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  let yyyy = today.getFullYear();

  today = yyyy + '-' + mm + '-' + dd;
  return today;
}

export function getPreviousDate(days = 30) {
  // Create a new date instance for today's date
  const today = new Date();

  // Subtract the specified number of days
  today.setDate(today.getDate() - days);

  // Format the date to yyyy-mm-dd
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-indexed month
  const day = String(today.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

// Example usage:
// console.log(getPreviousDate()); // Returns the date 30 days ago in yyyy-mm-dd format
// console.log(getPreviousDate(10)); // Returns the date 10 days ago in yyyy-mm-dd format

export function isWeekend(dateString) {
  // Regular expression to validate date format YYYY-MM-DD
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

  // Check if the date string matches the format
  if (!dateRegex.test(dateString)) {
    console.error('Invalid date format. Please use YYYY-MM-DD.');
    return false;
  }

  // Split the date string into components
  const [year, month, day] = dateString.split('-').map(Number);

  // Create a new Date object with the date components
  const date = new Date(year, month - 1, day);

  // Check if the date is valid (e.g., not "Invalid Date")
  if (isNaN(date.getTime())) {
    console.error('Invalid date. Please provide a valid date.');
    return false;
  }

  // Get the day of the week (0 = Sunday, 6 = Saturday)
  const dayOfWeek = date.getDay();

  // Check if the day is Saturday (6) or Sunday (0)
  return dayOfWeek === 0 || dayOfWeek === 6;
}

export function awsFormatUnknownDate(dateString) {
  const formats = [
    /^(\d{1,2})[/.-](\d{1,2})[/.-](\d{4})$/,    // dd/mm/yyyy or mm/dd/yyyy
    /^(\d{4})[/.-](\d{1,2})[/.-](\d{1,2})$/,    // yyyy-mm-dd or yyyy.mm.dd
    /^(\d{1,2})[/.-](\d{1,2})[/.-](\d{2})$/,    // dd/mm/yy or mm/dd/yy
    /^([A-Za-z]{3}) (\d{1,2}),? (\d{4})$/,      // MMM dd, yyyy or MMM dd yyyy
    /^(\d{1,2})-([A-Za-z]{3})-(\d{4})$/,        // dd-MMM-yyyy
  ];

  // Remove all whitespace characters including carriage returns and line feeds
  // const cleanedString = dateString.replace(/[\s\r\n]+/g, ''); 
  const cleanedString = dateString.replace(/[\r\n]+/g, ''); 

  // Extract the date part from the cleaned string, ignoring any trailing characters
  const dateMatch = cleanedString.match(/^\d{4}[/.-]\d{1,2}[/.-]\d{1,2}|^\d{1,2}[/.-]\d{1,2}[/.-]\d{2,4}|^[A-Za-z]{3} \d{1,2},? \d{4}|^\d{1,2}-[A-Za-z]{3}-\d{4}/);
  if (!dateMatch) {
    // console.warn('No valid date part found in:', dateString);
    return null;
  }
  const datePart = dateMatch[0];

  for (let i = 0; i < formats.length; i++) {
    const match = datePart.match(formats[i]);
    if (match) {
      let year, month, day;
      if (i === 0) {
        month = match[1];
        day = match[2];
        year = match[3];
      } else if (i === 1) {
        year = match[1];
        month = match[2];
        day = match[3];
      } else if (i === 2) {
        month = match[1];
        day = match[2];
        year = match[3].length === 2 ? `20${match[3]}` : `19${match[3]}`;
      } else if (i === 3) {
        month = getMonthFromString(match[1]);
        day = match[2];
        year = match[3];
      } else if (i === 4) {
        day = match[1];
        month = getMonthFromString(match[2]);
        year = match[3];
      }

      const formattedDate = `${year}-${padZero(month)}-${padZero(day)}`;
      const date = new Date(formattedDate);
      if (isValidCustomDate(date)) {
        return formattedDate;
      }
    }
  }

  console.warn('Invalid date format:', dateString);
  return null;
}

// Helper function to pad single digit numbers with a leading zero
function padZero(num) {
  return num.toString().padStart(2, '0');
}

// Helper function to check if a date is valid
function isValidCustomDate(date) {
  return date instanceof Date && !isNaN(date);
}

// Helper function to convert month string to number
function getMonthFromString(mon) {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  return months.indexOf(mon) + 1;
}

// export function nextDayYMD(date) {
//   if (!date) return
//   const fixedDate = date.replace(/-/g, '/')
//   const prevDate = new Date(fixedDate)
//   const nextDay = new Date(prevDate)
//   nextDay.setDate(nextDay.getDate() + 1)
//   const nextDayFormatted = formatDateFn(nextDay)
//   return nextDayFormatted
// }

// export function nextDayYMD(dateString) {
//   console.warn('dateString: ', dateString)
//   if (!dateString) return
//   // Parse the date string and create a Date object
//   const parts = dateString.split('-');
//   const year = parseInt(parts[2], 10);
//   const month = parseInt(parts[0], 10) - 1; // Adjust for zero-indexed months
//   const day = parseInt(parts[1], 10);
//   const date = new Date(year, month, day);

//   // Move to the next day
//   date.setDate(date.getDate() + 1);
//   console.warn('date: ', date)
//   // Format the date as 'YYYY/MM/DD'
//   const nextDay = [
//     date.getFullYear(),
//     ('0' + (date.getMonth() + 1)).slice(-2), // Ensure two digits for month
//     ('0' + date.getDate()).slice(-2) // Ensure two digits for day
//   ].join('-');

  
//   console.warn('nextDay: ', nextDay)

//   return nextDay;
// }

export function nextDayYMD(date) {
  if (!date) return;
  const fixedDate = date.replace(/-/g, '/');
  const prevDate = new Date(fixedDate);
  prevDate.setDate(prevDate.getDate() + 1);
  const nextDayFormatted = `${prevDate.getFullYear()}-${('0' + (prevDate.getMonth() + 1)).slice(-2)}-${('0' + prevDate.getDate()).slice(-2)}`;
  return nextDayFormatted;
}


// export function nextDayAsYMD(date) {
//   if (!date) return
//   const nextDay = new Date(date + 'T00:00:00')
//   nextDay.setDate(nextDay.getDate() + 1)
//   const nextDayFormatted = formatDateFn(nextDay)
//   console.warn('nextDayFormatted: ', nextDayFormatted)
//   return nextDayFormatted
// }

// export function futureDayYMD(days) {
//   if (!days) return
//   const todayDate = todaysDateYMD();
//   const fixedDate = todayDate.replace(/-/g, '/')
//   const prevDate = new Date(fixedDate)
//   const futureDay = new Date(prevDate)
//   futureDay.setDate(futureDay.getDate() + days)
//   const futureDayFormatted = formatDateFn(futureDay)
//   return futureDayFormatted
// }

export function todaysDateMDY() {
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, '0');
  let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  let yyyy = today.getFullYear();

  today = mm + '/' + dd + '/' + yyyy;
  return today;
}

export const stripReversDate = (dateStr) => {
  if (!dateStr) return "";
  const parts = dateStr.split('-');  // Split the date by hyphen
  if (parts.length === 3) {
      const [year, month, day] = parts;
      return month + day + year;  // Concatenate in the desired order
  } else {
      return dateStr;  // Return the original string if it doesn't match expected format
  }
}

export const formatDateMDY = (value) => {
  if (!value) return "";

  // Strip all non-digits
  let numbers = value.replace(/[^\d]/g, '').substring(0,8);

  // if (numbers.length > 8) numbers = numbers.substring(0, 8);

  // Limit string length to 8 (for mmddyyyy)
  const sliced = numbers.slice(0, 8);

  // Break the string into parts and join with '/'
  const parts = [];
  if (sliced.length > 2) {
    parts.push(sliced.substring(0, 2));
    if (sliced.length > 4) {
      parts.push(sliced.substring(2, 4));
      parts.push(sliced.substring(4, 8));
    } else {
      parts.push(sliced.substring(2));
    }
    // console.log('parts: ', parts)
  } else {
    // console.log('sliced: ', sliced)
    return sliced;
  }

  return parts.join('/');
};

export const convertStandardDateToAWSDate = (dateStr) => {
  // Check if the input is non-empty and properly formatted
  if (!dateStr || !/^\d{2}\/\d{2}\/\d{4}$/.test(dateStr)) {
    return 'Invalid date format'; // or throw an error, based on your error handling strategy
  }

  // Split the date into components
  const parts = dateStr.split('/');
  const month = parts[0];
  const day = parts[1];
  const year = parts[2];

  // Reassemble into the desired format
  return `${year}-${month}-${day}`;
};

export function formatDateFn(date, order, separator) {
  if (!date) return null;
  
  // Assuming date is already a Date object
  let dd = String(date.getDate()).padStart(2, '0');
  let mm = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
  let yyyy = date.getFullYear();

  let returnValue = null;
  if (order && separator) {
    if (order === 'mdy') {
      returnValue = `${mm}${separator}${dd}${separator}${yyyy}`;
    } else {
      returnValue = `${yyyy}${separator}${mm}${separator}${dd}`;
    }
  } else {
    returnValue = `${yyyy}-${mm}-${dd}`;
  }
  
  return returnValue;
}

export function net30FromToday() {
  let today = new Date();
  today.setDate(today.getDate() + 30);
  let dd = String(today.getDate()).padStart(2, '0');
  let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  let yyyy = today.getFullYear();

  today = yyyy + '-' + mm + '-' + dd;
  return today;
}

export function todaysDatePlusNDaysYMD(plusDays, separator) {
  let sep = (separator) ? separator : '/';
  let today = new Date();
  today.setDate(today.getDate() + Number(plusDays));
  let dd = String(today.getDate()).padStart(2, '0');
  let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  let yyyy = today.getFullYear();

  today = yyyy + sep + mm + sep + dd;
  return today;
}

export function netDateDueMutator(startDate, plusDays, separator = '/') {
  // Parameter validation
  if (!startDate) {
    throw new Error('startDate is required');
  }
  if (plusDays === undefined || plusDays === null || isNaN(plusDays)) {
    throw new Error('plusDays is required and must be a number');
  }

  let daysToAdd = Number(plusDays);
  // Assuming you want to ensure daysToAdd is at least 1; otherwise, consider removing this.
  daysToAdd = daysToAdd === 0 ? 1 : daysToAdd;

  // Converting startDate to a Date object and adding days
  const date = new Date(startDate);
  if (isNaN(date.getTime())) {
    throw new Error('Invalid startDate format');
  }
  date.setDate(date.getDate() + daysToAdd);

  // Formatting the date
  const formattedDate = [
    date.getFullYear(),
    String(date.getMonth() + 1).padStart(2, '0'), // January is 0!
    String(date.getDate()).padStart(2, '0')
  ].join(separator);

  return formattedDate;
}

// export function netDateDueMutator(startDate, plusDays, separator = '/') {
//   console.warn('netDateDueMutator: ', {startDate, plusDays, separator})
//   // First, we check if plusDays is provided. If not, we return; no date manipulation today!
//   // if (!plusDays || !startDate) return;
//   let daysToAdd = Number(plusDays)
//   if (daysToAdd === 0) daysToAdd = 1

//   console.warn('daysToAdd: ', daysToAdd)
//   // Let's turn the start date into a Date object, ready for some action!
//   const date = new Date(startDate);
//   date.setDate(date.getDate() + daysToAdd);
//   console.warn('date: ', date)
//   // Formatting the date, making it look nice and pretty.
//   const dd = String(date.getDate()).padStart(2, '0');
//   const mm = String(date.getMonth() + 1).padStart(2, '0'); // January is still 0, no surprises here!
//   const yyyy = date.getFullYear();

//   // Combining everything into one string to rule them all.
//   return `${yyyy}${separator}${mm}${separator}${dd}`;
// }


export function differenceInDays(date1, date2) {
  // The one and only Date object, our gateway to temporal calculations.
  const firstDate = new Date(date1);
  const secondDate = new Date(date2);

  // Calculating the difference in milliseconds between the two dates.
  const differenceInMilliseconds = secondDate - firstDate;

  // Magic number time! There are 86,400,000 milliseconds in a day (24h*60m*60s*1000ms).
  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

  // Rounding off to the nearest whole number because we live in a civilized society.
  return Math.round(differenceInDays);
}

export function isValidDate(dateString) {
  var regEx = /^\d{4}-\d{2}-\d{2}$/;
  if(!dateString.match(regEx)) return false;  // Invalid format
  var d = new Date(dateString);
  var dNum = d.getTime();
  if(!dNum && dNum !== 0) return false; // NaN value, Invalid date
  return d.toISOString().slice(0,10) === dateString;
}

export function currentLocalTime() {
  var d = new Date();
  var now = d.toLocaleTimeString();
  return now
}

export function awsDateTimeNow() {
  var nowDT = new Date().toISOString()
  return nowDT
}

export function getTaxableQuarter(dateString) {
  if (!dateString) return null;
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // getMonth() returns 0-11, so we add 1

  let quarter;
  if (month >= 1 && month <= 3) {
    quarter = 1;
  } else if (month >= 4 && month <= 6) {
    quarter = 2;
  } else if (month >= 7 && month <= 9) {
    quarter = 3;
  } else if (month >= 10 && month <= 12) {
    quarter = 4;
  } else {
    throw new Error("Invalid month value");
  }

  return `${year}#${quarter}`;
}

export function dateTimeToAWSDate(dateTimeString) {
  if (!dateTimeString) return null
  const date = new Date(dateTimeString);
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const day = String(date.getUTCDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate; // Output: 2024-06-25
}

// ----- TIMECO FUNCTIONS -----

// import moment from 'moment-timezone';

// export function convertTimeCoAPIDate(apiDate) {
//   if (!apiDate) return null

//   console.info('apiDate: ', apiDate)
//   // Extract the timestamp
//   // const timestamp = parseInt(apiDate.match(/\/Date\((\d+)/)[1], 10);
//   const timestamp = parseInt(apiDate.match(/\/Date\((\d+)([+-]\d+)\)\//)[1], 10);
//   if (!timestamp) return null

//   // var date = new Date();
//   // var zone = 'Europe/Paris';
//   // var dateZone = moment.tz(date, zone).format();

//   // Use moment-timezone to convert the timestamp to a moment object
//   let date = moment(timestamp).tz('America/Chicago'); // Chicago is in the CST time zone

//   console.info('date: ', date)
//   console.info('date.format(): ', date.format())
//   return date.format();
// }

// export function convertToCSTWithOffset(dateString) {
//   // Extract the timestamp and the timezone offset
//   const timestamp = parseInt(dateString.match(/\/Date\((\d+)/)[1], 10);
//   const offset = dateString.match(/-([0-9]{4})\)/)[1];
//   const offsetHours = parseInt(offset.substring(0, 2), 10);
//   const offsetMinutes = parseInt(offset.substring(2), 10);

//   // Convert the timestamp to a moment object in UTC
//   const momentDate = moment.utc(timestamp);

//   // Apply the offset to get the time in the provided timezone
//   const providedTime = momentDate.subtract(offsetHours, 'hours').subtract(offsetMinutes, 'minutes');

//   return providedTime.format('YYYY-MM-DDTHH:mm:ss');
// }

export function timecoDateTimeToJsDateTimeObj(apiDate) {
  // apiDate looks like "/Date(1705402500000-0500)/"
  // Extract the timestamp and timezone offset
  const matches = /\/Date\((\d+)([+-]\d+)\)\//.exec(apiDate);
  if (!matches) return null;

  const timestamp = parseInt(matches[1], 10);
  const offset = matches[2];

  // Create a date object from the timestamp
  let date = new Date(timestamp);

  // Convert the offset to minutes and adjust the date object
  const offsetMinutes = parseInt(offset.slice(0, 3), 10) * 60 + parseInt(offset.slice(3), 10);
  date.setMinutes(date.getMinutes() - offsetMinutes);

  // Assuming CST is always -6 hours from UTC (adjust if needed for daylight saving)
  const cstOffset = -360;
  date.setMinutes(date.getMinutes() - cstOffset);

  return date;
}

// ----- TIMECO FUNCTIONS -----

// IS THIS BEING USED?
export function dateToLong(date) {
  const dayOfWeek = new Date(date).getDay();    
  return dayOfWeek;
}

// remove preceeding 0 on day
export function spellOutDate(dateString, format) {
  
  // Check if dateString is not provided or is not a string
  if (typeof dateString !== 'string' || !dateString) {
    return '';  // Return empty string if the input is not a valid string
  }
  
  let ymdArray = dateString.split("-");
  let year = ymdArray[0]
  let month = ymdArray[1];
  let day = ymdArray[2];
  

  const date = new Date(year, month-1, day);

  let weekdaysLong = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  let weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

  const dayOfWeek = weekdays[date.getDay()]
  const dayOfWeekLong = weekdaysLong[date.getDay()]

  // const monthsLong = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const monthOfYear = months[date.getMonth()];
  // const monthOfYearLong = monthsLong[date.getMonth()];

  // original style
  if (!format) return(`${dayOfWeek} ${monthOfYear} ${day}, ${year}`)
  // apple style date
  if (format==='apple') return(`${dayOfWeek} ${monthOfYear} ${day} '${year.slice(-2)}`)
  // apple style date with full year
  if (format==='apple-fy') return(`${dayOfWeek} ${monthOfYear} ${day} ${year}`)
  // apple - without year
  if (format==='apple-ny') return(`${dayOfWeek} ${monthOfYear} ${day}`)
  // Thursday, Nov 30
  if (format==='phone-tight') return(`${dayOfWeekLong}, ${monthOfYear} ${day}`)
}

export function timeDifference(startTime, endTime) {
  // Validate inputs
  if (!(startTime instanceof Date) || !(endTime instanceof Date)) {
      throw new Error("Please provide valid Date objects for start and end times.");
  }

  // Check if start time is after end time
  if (startTime > endTime) {
      throw new Error("Start time cannot be after end time. Time travel is not yet supported!");
  }

  // Calculate the difference in milliseconds
  const difference = endTime - startTime;

  // Convert milliseconds to minutes and seconds
  const minutes = Math.floor(difference / 60000);
  const seconds = Math.floor((difference % 60000) / 1000);

  // Pad single digits with zero
  const paddedMinutes = String(minutes).padStart(2, '0');
  const paddedSeconds = String(seconds).padStart(2, '0');

  // Return the formatted string
  return `${paddedMinutes}:${paddedSeconds}`;
}

export function awsDateTimeToTime(awsDateTime) {
  const date = new Date(awsDateTime)
  const time = date.toLocaleTimeString()
  return time
}

export function adjustDate(date, days) {
  let result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function adjustAndFormatDate(date, days) {
  let result = new Date(date);
  result.setDate(result.getDate() + days);

  let year = result.getFullYear();
  let month = result.getMonth() + 1; // Because getMonth() is zero-based
  let day = result.getDate();

  // Ensuring two digits for month and day
  month = month < 10 ? `0${month}` : month;
  day = day < 10 ? `0${day}` : day;

  return `${year}-${month}-${day}`;
}

export function convertMilitaryToStandard(time) {
  // Split the time string into hours and minutes
  const [hours, minutes] = time.split(":");

  // Convert the hours part to an integer
  const hoursInt = parseInt(hours, 10);

  // Determine if it's AM or PM
  const period = hoursInt >= 12 ? "PM" : "AM";

  // Convert the hours to standard time format
  const standardHours = ((hoursInt + 11) % 12) + 1;

  // Return the formatted time string
  return `${standardHours}:${minutes} ${period}`;
}
