import  { useState, useCallback, useEffect, useRef, useContext } from 'react';
import Webcam from 'react-webcam';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Flex,
  Image as ChakraImage,  // Rename the Chakra UI Image import
  Spacer,
  VStack,
  Text,
  Container,
  useDisclosure,
  useBreakpointValue,
  Input,
} from "@chakra-ui/react";

import confetti from 'canvas-confetti';

import { GlobalWorkerOptions } from 'pdfjs-dist/build/pdf';
import * as pdfjsLib from 'pdfjs-dist';

import PropTypes from 'prop-types';

// uuid
import { v4 as uuid } from 'uuid';

// react-hook-form
import { useForm } from 'react-hook-form';

// form components
import { TextInput } from '../Form/Input/TextInput.jsx';
import { FormSelectSimple } from '../Form/Select/FormSelectSimple.jsx';
import { TextareaInput } from '../Form/Input/TextareaInput.jsx';
import { FormSelectMulti } from '../Form/Select/FormSelectMulti.jsx';
import { stateOptions } from '../Form/Select/stateOptions.jsx';
import { ButtonQuaternaryWithIcon } from '../Form/Button/ButtonQuaternaryWithIcon.jsx';

// form buttons
import { ButtonPrimaryPlain } from '../Form/Button/ButtonPrimaryPlain.jsx';
import { ButtonSeptenaryWithIconOnly } from '../Form/Button/ButtonSeptenaryWithIconOnly.jsx';
import { ButtonNonaryWithIconOnly } from '../Form/Button/ButtonNonaryWithIconOnly.jsx';
import { ButtonDuodenaryPlain } from '../Form/Button/ButtonDuodenaryPlain.jsx';
import { ButtonQuaternaryPlain } from '../Form/Button/ButtonQuaternaryPlain.jsx';
import { ResponsiveCancelCloseButton } from '../Form/Button/ResponsiveCancelCloseButton.jsx';

// graphql and external components
import { uploadFileToS3 } from '../AWS/S3/uploadFileToS3.jsx';
import { fetchDivisionOptions } from '../graphqlCompnents/Statement/fetchDivisionOptions.jsx';
import { fetchContactCompaniesByDivision } from '../graphqlCompnents/DJT/fetchContactCompaniesByDivision.jsx';
import { fetchAllUnits } from '../graphqlCompnents/Unit/fetchAllUnits.jsx';
import { fetchQuickBooksExpenseCategories } from '../graphqlCompnents/Statement/fetchQuickBooksExpenseCategories.jsx';
import { addIFTAFuelTracking } from '../graphqlCompnents/Statement/addIFTAFuelTracking.jsx';
import { fetchQuickTransaction } from '../graphqlCompnents/Statement/fetchQuickTransaction.jsx';
import { fetchManualTransaction } from '../graphqlCompnents/Statement/fetchManualTransaction.jsx';
import { fetchAccountsByTeamMember } from '../graphqlCompnents/Statement/fetchAccountsByTeamMember.jsx';
import { createCapitalOneReceipt } from '../graphqlCompnents/Statement/createCapitalOneReceipt.jsx';
// import { updateCapitalOneReceipt } from '../graphqlCompnents/Statement/updateCapitalOneReceipt.jsx';
import { updateCreditCardTransactionReceipt } from '../graphqlCompnents/Statement/updateCreditCardTransactionReceipt.jsx';
import { addStatementDocumentUnit } from '../graphqlCompnents/Statement/addStatementDocumentUnit.jsx';
import { fetchCapitalOneReceiptByInstantNotificationId } from '../graphqlCompnents/Statement/fetchCapitalOneReceiptByInstantNotificationId.jsx';
import { updateInstantNotification } from '../graphqlCompnents/Statement/updateInstantNotification.jsx';
import { addStatementDocumentLink } from '../graphqlCompnents/Statement/addStatementDocumentLink.jsx';
import { updateTransaction } from '../graphqlCompnents/Statement/updateTransaction.jsx';

// generic funcitons
import { stripToNumbersAndDecimal } from '../functions/number.jsx';

// globals
import { AppContext } from '../AppContext.jsx';

// modal
import { CenteredSingleButtonConfirmationModal } from '../Modal/CenteredSingleButtonConfirmationModal.jsx';
import { delay } from '../functions/generic.jsx';

// Helper function for delay
// const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
import { spellOutDate } from '../functions/dateTime.jsx';

// parse query for possible id
function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

export const QuickCameraUploadStatement = (props) => {

  const {
    id,
    selectedActionType='upload',
    attachReceiptParams,
    onClose
  } = props;

  // grab global context values
  const { store } = useContext(AppContext);

  const cardHolderName = store?.userData?.name||'';

  const isMobile = store?.userIsMobile;
  // console.info('isMobile: ', isMobile)

  // get the current environment sandbox || production
  const qbEnv = store?.qbEnvironment || 'sandbox'

  // set the current users team member id
  const currentTeamMemberId = store?.userData?.id

  // current users default division
  const usersDivisionId = store?.userData?.divisionId

  // current user groups
  const userGroups = store?.userData?.groups

  // const isSupervisor = userGroups?.includes('supervisor')

  const query = useQueryParams();
  console.warn('props: ', props)
  console.warn('attachReceiptParams: ', attachReceiptParams)
  let hasInstantNotification = attachReceiptParams?.hasInstantNotification||false;
  let externallyOpened = false;
  let transactionId = id||null;

  // console.warn('TRANSACTION ID: ', transactionId)
  
  if (!transactionId) {
    // console.warn('GETTING TRANSACTION ID FROM QUERY')
    transactionId = query.get('id')||null
    externallyOpened = true;
    hasInstantNotification = true;
  }

  let instantNotificationId = hasInstantNotification ? attachReceiptParams?.id||transactionId : null;

  // console.info('SETTING TRANSACTION ID: ', transactionId)

  // const hasInstantNotification = attachReceiptParams?.hasInstantNotification||false;
  // const instantNotificationId = hasInstantNotification ? attachReceiptParams?.id : null;
  const creditCardTransactionId = hasInstantNotification ? null : attachReceiptParams?.id||props.id;
  // console.warn('creditCardTransactionId: ', creditCardTransactionId)
  // console.info('hasInstantNotification: ', hasInstantNotification)
  // console.info('instantNotificationId: ', instantNotificationId)
  // console.info('creditCardTransactionId: ', creditCardTransactionId)
  // console.info('attachReceiptParams: ', attachReceiptParams)
  const plaidCreditCardTransactionId = attachReceiptParams?.id||null;

  // modal
  const { isOpen: isConfirmModalOpen , onOpen: onConfirmModalOpen, onClose: onConfirmModalClose } = useDisclosure()

  const [ receiptHasIssue, setReceiptHasIssue ] = useState(false);
  const [ showRetakePhotoWithIssue, setShowRetakePhotoWithIssue ] = useState(false);
  const [ receiptIssue, setReceiptIssue ] = useState('');
  const [ showReceiptUploadOptions, setShowReceiptUploadOptions ] = useState(true);
  const [ editModeIsLocked, setEditModeIsLocked ] = useState(false);
  const [ isNotificationExpired, setIsNotificationExpired ] = useState(false);
  const [ isReceiptUploaded, setIsReceiptUploaded ] = useState(false);
  const [ effectiveActionType, setEffectiveActionType ] = useState(selectedActionType);
  const [ receiptCheckComplete, setReceiptCheckComplete ] = useState(false);
  const [ confirmationModalContent, setConfirmationModalContent ] = useState(null);
  const [ confirmationModalHeaderText, setConfirmationModalHeaderText ] = useState(null);
  const [ showConfirmationModal, setShowConfirmationModal ] = useState(false);
  const [ isAlreadyUploaded, setIsAlreadyUploaded ] = useState(null);
  const [ isEditMode, setIsEditMode ] = useState(false);
  const [ uploadingNewReceipt, setUploadingNewReceipt ] = useState(false);
  const [selectedClient, setSelectedClient] = useState('');
  const [clients, setClients] = useState([])
  const [combinedImageFile, setCombinedImageFile] = useState(null);
  const [ file, setFile ] = useState(null)
  const [ images, setImages ] = useState([]);
  const [ streaming, setStreaming ] = useState(false);
  const [ previewMode, setPreviewMode] = useState(false);
  const [ mode, setMode] = useState('Single');  // Track user selection of Single or Combine and now File
  const [ showUploadForm, setShowUploadForm] = useState(false);
  const [ showUploadFormRest, setShowUploadFormRest ] = useState(false);
  // const [ isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [ selectedUnits, setSelectedUnits] = useState([]);
  const [ unitOptions, setUnitOptions] = useState([])
  const [ iftaUnitOptions, setIFTAUnitOptions ] = useState([])
  const [ selectedIFTAUnitOption, setSelectedIFTAUnitOption ] = useState([])
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [initializationAttempts, setInitializationAttempts] = useState(0);
  const MAX_RETRY_ATTEMPTS = 3;
  const RETRY_DELAY = 500; // 500ms between retries

  // Helper function for delay
  const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  useEffect(() => {
    const checkTransactions = async () => {
      if (!transactionId) {
        setReceiptCheckComplete(true);
        return;
      }
      try {
        console.warn(' --------- checking transactions for: ', transactionId);
        const transactions = await fetchCapitalOneReceiptByInstantNotificationId(transactionId);
        const transactionsCount = transactions.length;
        
        if (transactionsCount > 0) {
          setIsAlreadyUploaded(true);
          let transaction = transactions[0];
          if (transaction?.hasIssue === true) {
            // setReceiptHasIssue(true);
            // setReceiptIssue(transaction?.issue);
            // setIsEditMode(true);
            // setShowReceiptUploadOptions(false);
          } else {
            setConfirmationModalHeaderText('Receipt Already Uploaded');
            setConfirmationModalContent('This receipt has already been uploaded.');
            setShowConfirmationModal(true);
          }
        } else {
          setIsAlreadyUploaded(false);
          setIsEditMode(false);
        }
        setReceiptCheckComplete(true);
      } catch (error) {
        console.error('Error checking for existing receipts:', error);
        setReceiptCheckComplete(true);
      }
    }
    
    if (selectedActionType !== 'upload-with-transaction') {
      checkTransactions();
    } else {
      setIsAlreadyUploaded(false);
      setIsEditMode(false);
      setReceiptCheckComplete(true);
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[transactionId, selectedActionType])

  useEffect(() => {
    if (attachReceiptParams?.hasIssue && clients?.length>0 && iftaUnitOptions?.length>0) {
      console.warn('SHOULD SEE FIX ISSUE')
      setIsAlreadyUploaded(true);
      setReceiptHasIssue(true);
      setReceiptIssue(attachReceiptParams?.issue||'');
      setIsEditMode(true);
      setValue('addedNotes', attachReceiptParams?.addedNotes||'');
      setValue('odometer', attachReceiptParams?.odometer||'');
      setValue('gallons', attachReceiptParams?.gallons||'');
      (attachReceiptParams?.iftaFuelTaxTrackId ) && updateSelectedState({ value: attachReceiptParams?.stateTwoChar });
      (attachReceiptParams?.clientId) && updateSelectedClient({ value: attachReceiptParams?.clientId });
      (attachReceiptParams?.iftaFuelTaxTrackId ) && handleIFTAUnitChange({ value: attachReceiptParams?.unitId });
      // setValue('selectClient', attachReceiptParams?.clientId||'');
      // setValue('selectClient', attachReceiptParams?.clientId||'');

      setShowReceiptUploadOptions(false);
    }
  }, [attachReceiptParams, clients, iftaUnitOptions])

  const [ receiptIssueLog, setReceiptIssueLog ] = useState(attachReceiptParams?.issue||'');
  
  const handleUpdateReceiptIssue = (issue) => {
    // add admin name to the issue test with a colon and a hard return
    const issues = receiptIssueLog + cardHolderName + ': ' + issue + '::cr::';
    setReceiptIssue(issues);
    setValue('updateReceiptIssue', issues);
  }

  async function handleConfirmModalClosed() {
    // either redirect to user receipts or close the modal
    if (isNotificationExpired) {
      if (isReceiptUploaded) {
        onConfirmModalClose()
        await delay(500)
        if (externallyOpened) {
          handleRedirect();
        } else {
          handleClose();
          // onClose()
        }
      } else {
        onConfirmModalClose()
      }

    } else {
      onConfirmModalClose()
      await delay(500)
      if (externallyOpened) {
        handleRedirect();
      } else {
        handleClose();
        // onClose()
      }
    }
  }

  const canvasRef = useRef(null);
  const videoRef = useRef(null);
  const myConfetti = useRef(null); // Store confetti instance in a ref

  const defaults = {
    spread: 360,
    ticks: 50,
    gravity: 0,
    decay: 0.94,
    startVelocity: 30,
    colors: ['#FFE400', '#FFBD00', '#E89400', '#FFCA6C', '#FDFFB8']
  };

  useEffect(() => {
    if (canvasRef.current && !myConfetti.current) {
      myConfetti.current = confetti.create(canvasRef.current, {
        resize: true,
        useWorker: true
      });
    }
  }, [canvasRef.current]);

  const shoot = () => {
    if (myConfetti.current) {
      // Star confetti
      myConfetti.current({
        ...defaults,
        particleCount: 40,
        scalar: 1.2,
        shapes: ['star']
      });

      // Small circle confetti for contrast
      myConfetti.current({
        ...defaults,
        particleCount: 10,
        scalar: 0.75,
        shapes: ['circle']
      });
    }
  };

  const triggerConfetti = () => {
    shoot(); // First shot
    setTimeout(shoot, 100); // Second shot
    setTimeout(shoot, 200); // Third shot
  };

  useEffect(() => {
    // Set the workerSrc to the file in the public folder
    GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs';
  }, []);

  const handleShowReplaceReceipt = (show) => () => {
    setShowReceiptUploadOptions(show);
    setEditModeIsLocked(true);
    if (show===true) {
      console.log('showing the replace receipt options')
    } else {
      console.log('hiding the replace receipt options and setting showUploadForm to true')
      setShowUploadForm(true);
      setShowUploadFormRest(true);
    }
  }

  useEffect(() => {
    if (showConfirmationModal && confirmationModalContent && confirmationModalHeaderText) {
      if (isNotificationExpired && !isReceiptUploaded) {
        onConfirmModalOpen();
      } else {
        triggerConfetti();
        onConfirmModalOpen();
      }
      
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[showConfirmationModal, confirmationModalContent, confirmationModalHeaderText])

  const [ transactionData, setTransactionData ] = useState([]);
  useEffect(() => {
    console.log('receiptCheckComplete: ', receiptCheckComplete)
    console.log('isAlreadyUploaded: ', isAlreadyUploaded)
    // if (!receiptCheckComplete || isAlreadyUploaded) {
    //   return;
    // }
    if (!receiptCheckComplete) return;
    
    const getTeamMemberAccounts = async (tmId, env) => {
      if (!tmId) {
        console.error('No team member id provided.');
        return;
      }
      try {
        const accounts = await fetchAccountsByTeamMember(env, tmId);
        console.log('accounts: ', accounts)

        // Extract cardLastFour values
        const cards = accounts.map(account => account.cardLastFour);
        console.log('cards: ', cards)

        // setTeamMemberCards(cards)
        return cards;
      } catch (error) {
        console.error('Failed to fetch team member accounts:', error);
      }
    }

    const fetchTransaction = async (id) => {
      if (!id) {
        console.error('No transaction id provided.');
        return;
      }
      try {
        console.warn('effectiveActionType: ', effectiveActionType)
        console.warn('hasInstantNotification: ', hasInstantNotification)
        let transaction = {};
        if (effectiveActionType === 'upload-with-transaction') {
          console.log('with transaction');
          transaction = attachReceiptParams;
        } else if (effectiveActionType === 'upload' && hasInstantNotification) {
          console.warn('fetching transaction for instant notification id: ', id);
          transaction = await fetchQuickTransaction(id);
        } else if (effectiveActionType === 'fix-issue') {
          console.warn('loading fix-issue with params: ', attachReceiptParams);
          transaction = attachReceiptParams;
        } else {
          console.warn('fetching transaction for manual transaction id: ', id);
          transaction = await fetchManualTransaction(id);
    
          if (!transaction || Object.keys(transaction).length === 0) {
            console.warn('No transaction found, generating a new ID.');
            transaction = { id: uuid() };
          }
        }
        console.info('new transaction data: ', transaction);
        setTransactionData(transaction);
        return transaction;
      } catch (error) {
        console.error('Failed to fetch transaction:', error);
        if (!isNotificationExpired) {
          // alert('Failed to fetch transaction.');
          handleRedirect();
        }
      }
    };

    const verifyCardHolder = async (id, env) => {
      try {
        console.log('id: ', id);
        const transaction = await fetchTransaction(id);
        console.info('transaction: ', transaction)
        const usersCards = await getTeamMemberAccounts(currentTeamMemberId, env);
        console.info('usersCards: ', usersCards)
        const cardMatch = usersCards.includes(transaction.accountNumber);
        console.info('transaction.accountNumber: ', transaction.accountNumber);
        console.info('cardMatch: ', cardMatch);
        // setIsUserCardVerified(cardMatch);
      } catch (error) {
        // alert('Failed to verify card holder.');
        console.error('Failed to verify card holder:', error);
        handleRedirect();
      }

    }

    if (transactionId && currentTeamMemberId && selectedActionType) {
      console.info('verifying card holder: ', transactionId)
      verifyCardHolder(transactionId, qbEnv)
    } else {
      console.error('Missing required data to verify card holder.');
    }
    
  },[
    receiptCheckComplete, 
    isAlreadyUploaded, 
    transactionId, 
    currentTeamMemberId, 
    qbEnv, 
    effectiveActionType, 
    hasInstantNotification,
    isNotificationExpired,
    selectedActionType,
    attachReceiptParams
  ])

  const fileInputRef = useRef(null);
  const containerRef = useRef(null);

  // --- REACT-HOOK-FORM ---
  const { 
    setError, 
    control, 
    register, 
    handleSubmit, 
    clearErrors,
    setValue, 
    formState: { 
      isSubmitting,
      errors
    }} = useForm({
      mode: 'onSubmit',
      // reValidateMode: 'onChange',
      reValidateMode: 'onBlur',
      defaultValues: {
        odometer: '',
        gallons: '',
        addedNotes: '',
        selectDivision: '',
        selectClient: '',
        selectExpenseCategory: '',
        selectState: '',
        selectOTRUnit: ''
      }
  });

  const onError = (errors, e) => console.log(errors, e);

  useEffect(() => {
    const fetchAndSetUnits = async () => {
      try {
        const units = await fetchAllUnits();
        // console.log('units: ', units)
        units.sort((a, b) => a.codeSortable.localeCompare(b.codeSortable));

        // create IFTA unit options
        const iftaUnits = units.filter(unit => unit.irpiftaRegistered === true && unit.isActive === true);
        
        const iftaUnitOptions = iftaUnits.map(unit => ({
          value: unit.id,
          label: `${unit.code} - ${unit.type.name} / ${unit.subType.name}`
        }));

        setIFTAUnitOptions(iftaUnitOptions)

        // reduce down to active units
        const activeUnits = units.filter(unit => unit.isActive === true);
        // Create unitOptions
        const options = activeUnits.map(unit => ({
          value: unit.id,
          label: `${unit.code} - ${unit.type.name} / ${unit.subType.name}`
        }));
        
        // console.log('units: ', units)
        setUnitOptions(options)

      } catch (error) {
        console.error('Failed to fetch units:', error);
      }
    };

    fetchAndSetUnits();

  }, []); 

  const [ selectedDivision, setSelectedDivision ] = useState('');
  const [ divisionOptions, setDivisionOptions ] = useState([])
  useEffect(() => {
    const fetchDivisions = async () => {
      try {
        // Fetch divisions for 'all'
        const responseAll = await fetchDivisionOptions('all');
        const optionsAll = responseAll.map(division => ({
          value: division.id,
          label: division.prettyname,
        }));
  
        // Fetch divisions for 'special'
        const responseSpecial = await fetchDivisionOptions('special');
        const optionsSpecial = responseSpecial.map(division => ({
          value: division.id,
          label: division.prettyname,
        }));
  
        // Combine and sort options
        const combinedOptions = [...optionsAll, ...optionsSpecial].sort((a, b) => 
          a.label.localeCompare(b.label)
        );
        setDivisionOptions(combinedOptions);
  
        // Find the division that matches usersDivisionId
        const matchingDivision = combinedOptions.find(option => option.value === usersDivisionId);
  
        // Set selectedDivision if a match is found
        if (matchingDivision) {
          setSelectedDivision(matchingDivision);
          setValue('selectDivision', matchingDivision.value);
        }
  
      } catch (error) {
        console.error('Failed to fetch divisions:', error);
      }
    };
  
    fetchDivisions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersDivisionId]); // Add usersDivisionId to the dependency array to rerun if it changes

  // Fetch clients whenever selectedDivisionId changes
  useEffect(() => {
    const fetchClients = async () => {
      if (selectedDivision?.value) {
        try {
          const response = await fetchContactCompaniesByDivision(selectedDivision.value);
          const clientOptions = response.map(client => ({
            value: client.value,
            label: client.label,
          }));

          // Add the ' -- None --' option at the beginning
          clientOptions.unshift({ value: '', label: ' - None -' });
        
          setClients(clientOptions);
          setSelectedClient(clientOptions[0])
          setValue('selectClient', clientOptions[0])
        } catch (error) {
          console.error('Failed to fetch clients:', error);
        }
      }
    };

    fetchClients();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDivision]); // Add selectedDivisionId to the dependency array

  // if there is a selected client in the transactions data we need to prepopulate the selected client
  useEffect(() => {
    const getClient = (id) => {
      const client = clients.find((client) => client.value === id);

      // If no client is found, return null or a default value
      if (!client) return '';

      // Return the formatted object based on the found client
      const clientOption = {
        value: client.value,
        label: client.label,
      };
      setSelectedClient(clientOption);
    };

    (transactionData?.capitalOneCreditCardTransactionReceipt?.clientId && clients.length) && getClient(transactionData?.capitalOneCreditCardTransactionReceipt?.clientId);
  },[clients, transactionData])

  //fetchQuickBooksExpenseCategories
  const [ expenseCategories, setExpenseCategories ] = useState([])
  const [ selectedExpenseCategory, setSelectedExpenseCategory ] = useState('')
  const [ isIFTATracked, setIsIFTATracked ] = useState(false)
  useEffect(() => {
    const fetchAllExpenseCategories = async () => {
      try {
        const response = await fetchQuickBooksExpenseCategories(qbEnv);
        console.warn('response: ', response)
        const categories = response.map(expense => ({
          value: expense.qbId,
          label: expense.appName,
          isIFTATracked: (expense?.isIFTATracked===true) ? true : false,
        }));

        // Add the ' -- None --' option at the beginning
        // categories.unshift({ value: '', label: ' - None -' });

        console.log('categories: ', categories)

        setExpenseCategories(categories);
        // setSelectedExpenseCategory(categories[0])
        // setValue('selectExpenseCategory', categories[0])
      } catch (error) {
        console.error('Failed to fetch expense categories:', error);
      }
    };

    fetchAllExpenseCategories();
  }, [qbEnv]);

  const handleRedirect = () => {
    const { hostname, port } = window.location;

    console.log('hostname: ', hostname);
    console.log('port: ', port);

    // Define a base URL depending on environment
    let baseUrl;

    // Check if running locally
    if (hostname === 'localhost' || hostname === '192.168.68.69') {
      // Use the specified port if in local development
      baseUrl = `https://${hostname}:${port}/user-receipts`;
    } else {
      // For non-localhost environments, use the production base URL without port
      baseUrl = `https://${hostname}/user-receipts`;
    }

    console.log('baseUrl: ', baseUrl);
    // Perform the redirect
    window.location.href = baseUrl;

    // window.location.href = 'https://brain.spikeenterprise.io';
    // let hostname = window.location.hostname;
    // window.location.href = hostname + '/user-receipts';
    // window.location.href = `https://${hostname}/user-receipts`;
  }

  const [ selectedStatementType, setSelectedStatementType ] = useState('');
  const [ statementTypeOptions, setStatementTypeOptions ] = useState([
    { value: 'receipt', label: 'Receipt' },
    { value: 'credit', label: 'Credit note/Refund' },
    { value: 'invoice', label: 'Invoice' },
  ]);

  useEffect(() => {
    if (statementTypeOptions?.length>0) {
      const statementType = statementTypeOptions.find(option => option.value === 'receipt');
      setSelectedStatementType(statementType);
      setValue('selectStatementType', statementType.value)
    }
  },[statementTypeOptions])

  const updateSelectedStatementType = (event) => {
    setSelectedStatementType(event);
    setValue('selectStatementType', event.value)
  };

  const updateSelectedExpenseCategory = (event) => {
    console.log('event: ', event)
    setIsIFTATracked(event?.isIFTATracked===true ? true : false)
    setSelectedExpenseCategory(event);
    setValue('selectExpenseCategory', event.value)
    clearErrors('selectExpenseCategory')

    setShowUploadFormRest(true)
  }

  // if we are in edit mode we need to prepopulate the selected expense category
  useEffect(() => {
    const getExpenseCategory = (id) => {
      if (!id) return;
      console.info('expenseCategories: ', expenseCategories)
      const expenseObj = expenseCategories.find((category) => category.value === id);
      console.info('expenseObj: ', expenseObj);
      // If no expense is found, return null or a default value
      if (!expenseObj) return '';
  
      // Return the formatted object based on the found expense
      const expenseCategory = {
        value: expenseObj.value,
        label: expenseObj.label,
        isIFTATracked: expenseObj?.isIFTATracked === true ? true : false,
      };
      updateSelectedExpenseCategory(expenseCategory)
      console.log('expenseCategory: ', expenseCategory);
      // setSelectedExpenseCategory(expenseCategory);
    };

    if (expenseCategories?.length>0 && transactionData) {
      console.info('transactionData: ', transactionData)
      let expenseCategoryId = null;
      if (transactionData?.capitalOneCreditCardTransactionReceipt?.expenseCategoryId) {
        expenseCategoryId = transactionData.capitalOneCreditCardTransactionReceipt.expenseCategoryId;
      }
      if (transactionData?.expenseCategoryId) {
        expenseCategoryId = transactionData.expenseCategoryId;
      }
      console.info('expenseCategoryId: ', expenseCategoryId);
      getExpenseCategory(expenseCategoryId);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[expenseCategories, transactionData])

  const updateSelectedDivision = (event) => {
    setSelectedDivision(event);
  };

  const updateSelectedClient = (event) => {
    console.warn('clients: ', clients)
    if (!event.label) {
      const clientSelected = clients.find(client => client.value === event.value);
      event.label = clientSelected.label;
    }
    console.warn('SETTING CLIENT TO EVENT: ', event)
    setSelectedClient(event);
    setValue('selectClient', event.value)
    console.warn('Setting clientId to: ', event.value)
  };

  // we need to see if there are any selected units in the transaction data and prepopulate the selectedUnits based on those if they exist
  useEffect(() => {
    const getUnits = async (units) => {
      const selectedUnits = units?.map(unit => {
        // Find the matching unit option where the `value` matches the `unitId`
        const matchedOption = unitOptions.find(option => option.value === unit.unitId);
        
        // Return the matched option (or null if not found)
        return matchedOption || null;
      }).filter(option => option !== null);  // Filter out any nulls if no matches are found
      
      console.log('unit options: ', selectedUnits);
      setSelectedUnits(selectedUnits);

    }
    
    if (unitOptions?.length>0) {
      if (transactionData?.units?.length>0 || transactionData?.capitalOneCreditCardTransactionReceipt?.units?.items?.length>0) {
        let unitsArray = transactionData?.units?.length>0 ? transactionData.units : transactionData?.capitalOneCreditCardTransactionReceipt?.units?.items;
        getUnits(unitsArray);
      }
    }

    // ((transactionData?.units?.length>0 || transactionData?.capitalOneCreditCardTransactionReceipt?.units?.items?.length>0) && unitOptions.length) && getUnits(transactionData?.capitalOneCreditCardTransactionReceipt?.units?.items);
  },[transactionData, unitOptions])

  const handleUnitsChange = (event, action) => {
    if (action?.action === 'select-option') {
      const addedOption = { value: action?.option?.value, label: action?.option?.label, key: action?.option?.value };
      setSelectedUnits([...selectedUnits, addedOption]);
    }
    if (action?.action === 'remove-value') {
      const removedOption = { value: action?.removedValue?.value, label: action?.removedValue?.label, key: action?.removedValue?.value };
      const newUnits = selectedUnits.filter((unit) => unit.value !== removedOption.value);
      setSelectedUnits(newUnits);
    }
  };

  const [ selectedState, setSelectedState ] = useState('')
  const updateSelectedState = (event) => {
    if (event.value==='') {
      setSelectedState(event)
      setValue('selectState', event)
      setError('selectState', {
        type: 'preemptive',
        message: 'State is required'
      })
    } else {
      if (!event.label) {
        const stateSelected = stateOptions.find(state => state.value === event.value);
        event.label = stateSelected.label;
      }
      setSelectedState(event)
      setValue('selectState', event)
      clearErrors('selectState')
    }
  }

  const handleIFTAUnitChange = (event) => {
    if (!event.label) {
      const unitSelected = iftaUnitOptions.find(unit => unit.value === event.value);
      event.label = unitSelected.label;
    }
    setSelectedIFTAUnitOption(event);
    setValue('selectOTRUnit', event.value)
    clearErrors('selectOTRUnit')
  };

  const [ modeIsLocked, setModeIsLocked ] = useState(false);
  const [ showMultiPictureButtons, setShowMultiPictureButtons ] = useState(false);
  const webcamRef = useRef(null);

  const [imageSrc, setImageSrc] = useState(null); // To hold the captured image
  const [deviceId, setDeviceId] = useState(null); // To handle camera switching
  const [devices, setDevices] = useState([]); // To store available cameras

  const handleDevices = useCallback(
    (mediaDevices) =>
      setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),
    []
  );

  useEffect(() => {
    const updateDevices = () => {
      navigator.mediaDevices
        .enumerateDevices()
        .then(handleDevices)
        .catch((err) => console.error("Error accessing media devices:", err));
    };

    navigator.mediaDevices.addEventListener("devicechange", updateDevices);
    updateDevices(); // Fetch initial devices

    return () => {
      navigator.mediaDevices.removeEventListener("devicechange", updateDevices);
    };
  }, [handleDevices]);

  const videoConstraints = {
    deviceId: deviceId || undefined, // Use selected camera or default
    facingMode: "environment", // Prefer the back camera
  };

  useEffect(() => {
    let mounted = true;
    let stream = null;
  
    const initializeCamera = async (retryCount = 0) => {
      if (!mounted) return;
  
      try {
        // Clean up any existing streams first
        if (videoRef.current?.srcObject) {
          const tracks = videoRef.current.srcObject.getTracks();
          tracks.forEach(track => track.stop());
          videoRef.current.srcObject = null;
        }
  
        if (!isDrawerOpen || mode === 'File') {
          setStreaming(false);
          return;
        }
  
        // Request new camera access
        const newStream = await navigator.mediaDevices.getUserMedia({
          video: {
            facingMode: 'environment',
            width: { ideal: 1920 },
            height: { ideal: 1080 },
            deviceId: deviceId ? { exact: deviceId } : undefined
          }
        });
  
        // Double check component is still mounted
        if (!mounted) {
          newStream.getTracks().forEach(track => track.stop());
          return;
        }
  
        if (videoRef.current) {
          videoRef.current.srcObject = newStream;
          stream = newStream;
          setStreaming(true);
          setInitializationAttempts(0); // Reset attempts on success
        } else if (retryCount < MAX_RETRY_ATTEMPTS) {
          // If videoRef isn't ready yet, wait and retry
          await wait(RETRY_DELAY);
          return initializeCamera(retryCount + 1);
        }
      } catch (err) {
        console.error("Error accessing camera:", err);
        if (retryCount < MAX_RETRY_ATTEMPTS) {
          console.log(`Retrying camera initialization (${retryCount + 1}/${MAX_RETRY_ATTEMPTS})`);
          await wait(RETRY_DELAY);
          return initializeCamera(retryCount + 1);
        }
        setStreaming(false);
      }
    };
  
    // Initialize camera
    if (isDrawerOpen && mode !== 'File') {
      initializeCamera();
    }
  
    // Cleanup function
    return () => {
      mounted = false;
      if (stream) {
        stream.getTracks().forEach(track => track.stop());
      }
      if (videoRef.current?.srcObject) {
        const tracks = videoRef.current.srcObject.getTracks();
        tracks.forEach(track => track.stop());
      }
      setStreaming(false);
    };
  }, [isDrawerOpen, mode, deviceId]);

  useEffect(() => {
    if (initializationAttempts >= MAX_RETRY_ATTEMPTS) {
      console.error('Failed to initialize camera after maximum retry attempts');
      // You could add additional error handling here
    }
  }, [initializationAttempts]);

  // Separate useEffect for handling mode changes
  useEffect(() => {
    if (mode === 'File' && videoRef.current?.srcObject) {
      // Clean up camera when switching to file mode
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach(track => track.stop());
      videoRef.current.srcObject = null;
      setStreaming(false);
    } else if (mode !== '' && mode !== 'File' && !streaming) {
      // Reinitialize camera when switching back from file mode
      navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: 'environment',
          width: { ideal: 1920 },
          height: { ideal: 1080 },
          deviceId: deviceId ? { exact: deviceId } : undefined
        }
      })
      .then((stream) => {
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          setStreaming(true);
        }
      })
      .catch((err) => {
        console.error("Error accessing camera:", err);
        setStreaming(false);
      });
    }
  }, [mode, deviceId, streaming]);

  const capturePhoto = () => {
    setModeIsLocked(true);
    const canvas = canvasRef.current;
    const video = videoRef.current;
    const context = canvas.getContext('2d');
  
    // Set canvas dimensions to match the video feed
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
  
    // Draw the current video frame onto the canvas
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
  
    // Save the captured image
    const capturedImage = canvas.toDataURL('image/png');
    setImageSrc(capturedImage);
    setImages([...images, capturedImage]);
    
    // Set preview mode without stopping the stream
    setPreviewMode(true);
    if (mode === 'Combine') {
      setShowMultiPictureButtons(true);
    }
  };

  // const capturePhoto = (webcamRef) => {
  //   setModeIsLocked(true);

  //   const image = webcamRef.current.getScreenshot();
  //   console.log(' ----- camera debug w: ', webcamRef.current.video.videoWidth);
  //   console.log(' ----- camera debug h: ', webcamRef.current.video.videoHeight);
  //   console.log(` ----- Captured Image: ${image}`);
  //   console.log(` ----- Captured Image Dimensions: ${image.width}x${image.height}`);
  //   setImageSrc(image); // Save the captured image
  //   setImages([...images, image]);
  //   setPreviewMode(true);
  //   if (mode === 'Combine') {
  //     setShowMultiPictureButtons(true);
  //   }
  // };

  const handleRetakeWithIssue = () => {
    setShowRetakePhotoWithIssue(true);
    setPreviewMode(false);
    setImages([]);
    setImageSrc(null); 
  }

  const handleRetake = async () => {
    // Reset states without stopping the stream
    setPreviewMode(false);
    setImages(images.slice(0, -1));
    setImageSrc(null);
  
    // Ensure we have an active stream
    if (!videoRef.current?.srcObject || !streaming) {
      try {
        const newStream = await navigator.mediaDevices.getUserMedia({
          video: {
            facingMode: 'environment',
            width: { ideal: 1920 },
            height: { ideal: 1080 },
            deviceId: deviceId ? { exact: deviceId } : undefined
          }
        });
  
        if (videoRef.current) {
          videoRef.current.srcObject = newStream;
          setStreaming(true);
        }
      } catch (err) {
        console.error("Error restarting camera:", err);
        setStreaming(false);
      }
    }
  };

  const handleKeep = () => {
    setPreviewMode(false);
    if (mode === 'Single') {
      handleDone();
    }
  };

  const handleDone = async () => {
    if (images.length === 0) {
      // alert("No images to combine.");
      return;
    }
  
    const combinedImage = await combineImages(images);
    setImages([combinedImage]); // Replace multiple images with the combined image
    const file = dataURLtoFile(combinedImage, `${uuid()}.png`);
    setCombinedImageFile(file);
    setShowUploadForm(true);
    setUploadingNewReceipt(true);
  };
  
  const combineImages = async (images) => {
    // Load all images
    const loadedImages = await Promise.all(
      images.map((src) =>
        new Promise((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = () => resolve(img);
          img.onerror = reject;
        })
      )
    );
  
    // Calculate canvas dimensions
    const totalHeight = loadedImages.reduce((sum, img) => sum + img.height, 0);
    const maxWidth = Math.max(...loadedImages.map((img) => img.width));
  
    // Create canvas and draw images
    const canvas = document.createElement("canvas");
    canvas.width = maxWidth;
    canvas.height = totalHeight;
    const context = canvas.getContext("2d");

    console.log(` ----- Canvas Dimensions: ${canvas.width}x${canvas.height}`);
  
    let yOffset = 0;
    loadedImages.forEach((img) => {
      context.drawImage(img, 0, yOffset);
      yOffset += img.height;
    });
  
    return canvas.toDataURL("image/png"); // Return the combined image as a dataURL
  };

  const dataURLtoFile = (dataURL, filename) => {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const resetForm = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }

    setFiles([]);
    setPreviews([]);
    setShowUploadForm(false);
    // setImage(null)
    // setMode('Single');
    // setMode('');
    // setFile(null)
    // setImages([]);
    // setStreaming(true);
    // setDevices([]);
    // setSelectedDevice('');
    // setSelectedCamera('');
    // setPreviewMode(false);
    // setShowUploadForm(false);
    setSelectedUnits([]);
    setSelectedDivision('');
    setSelectedClient('');
    setValue('odometer', '');
    setValue('gallons', '');
    setSelectedIFTAUnitOption('');
    setSelectedExpenseCategory('');
    clearErrors();
  };

  const handleOdometerKeyPress = (e) => {
    // Regular expression for allowed characters (digits and period)
    const allowedChars = /^[0-9.]$/;
    
    if (!allowedChars.test(e.key)) {
      e.preventDefault();
    }
  }

  const handleOdometerChange = (e) => {
    if (e.target.value==='') return
    const miles = stripToNumbersAndDecimal(e.target.value);
    setValue('odometer', miles);
    clearErrors('odometer');
  };

  const handleGallonsKeyPress = (e) => {
    // Regular expression for allowed characters (digits and period)
    const allowedChars = /^[0-9.]$/;
    
    if (!allowedChars.test(e.key)) {
      e.preventDefault();
    }
  }

  const handleGallonsChange = (e) => {
    if (e.target.value==='') return
    const gallons = stripToNumbersAndDecimal(e.target.value);
    setValue('gallons', gallons);
    clearErrors('gallons');
  };

  const formSubmit = async (values) => {

    // if selectedActionType is preemptive we need to handle for different transaction data fields
    // this can be done before any plaid transaction data is received
    let isManualUpload = false;
    if (effectiveActionType === 'preemptive' || isNotificationExpired) {
      isManualUpload = true;
    }

    //hasTransactionId if hasTransactionId=true and externallyOpened=false we need to update the transaction
    //externallyOpened

    // if (selectedActionType==='preemptive') {
    //   isManualUpload = true;
    // }

    // if (window.navigator && window.navigator.vibrate) {
    //   window.navigator.vibrate([50]); // Simple haptic feedback on supported devices
    // }
    // if (qbEnv!=='sandbox') return;
    if (!values.selectDivision) return
    console.log('Form Values:', values);
    console.log('currentTeamMemberId: ', currentTeamMemberId)
    console.log('selectedExpenseCategory: ', selectedExpenseCategory)
    console.log('selectedClient: ', selectedClient)
    console.log('selectedUnits: ', selectedUnits)

    console.log('transactionData should be empty if instant notification: ', transactionData)

    // return;
    // update the SpkIFTAFuelTaxTrackingTbl table
    // allSort, satementDate, statementId, yearQuarter, unitId, teamMemberId, stateTwoChar, gallons, odometer
    // selectOTRUnit, odometer, gallons

    // if they chose to keep the existing uploaded receipt image, we need to just update everything but the uploaded documents

    if (combinedImageFile || files || isEditMode) {
      console.log('receipt files: ', combinedImageFile||files);

      

      // use existing for update or generate new one for create
      console.warn('attachReceiptParams: ', attachReceiptParams)
      const receiptId = isEditMode ? transactionData.capitalOneCreditCardTransactionReceiptId : uuid();

      // clientId, expenseCategory, units
      const statementId = receiptId;
      const statementParams = {
        id: statementId,
        allSort: "all",
        isReceiptUploaded: true,
        addedNotes: values?.addedNotes,
        // divisionId: selectedDivision?.value,
        divisionId: values.selectDivision,
        // ...(selectedClient?.value && { clientId: selectedClient.value }),
        ...((values.selectClient !== "" && values.selectClient?.value !== "") && { clientId: values.selectClient }),
        teamMemberId: currentTeamMemberId,
        // ...(selectedExpenseCategory?.value && { expenseCategoryId: selectedExpenseCategory.value }),
        ...(values.selectExpenseCategory && { expenseCategoryId: values?.selectExpenseCategory }),
        transactionType: values.selectStatementType||'receipt',
      };

      if (effectiveActionType === 'upload-with-transaction') {
        statementParams.matchStatus = "ready-for-approval";
      } else if (effectiveActionType === 'fix-issue') {
        statementParams.matchStatus = "fixed-issue";
        statementParams.issue = values?.updateReceiptIssue;
      } else if (!isManualUpload) {
        statementParams.matchStatus = "with-notification";
      } else {
        statementParams.matchStatus = "preemptive";
      }

      if (hasInstantNotification) {
        statementParams.capitalOneInstantNotificationId = instantNotificationId;
      }

      if (plaidCreditCardTransactionId) {
        statementParams.plaidCreditCardTransactionId = plaidCreditCardTransactionId;
      }

      console.log('statementParams: ', statementParams);
  
      try {

        // if this is diesel for OTR, update the iftaTracking table
        // this is going to have to be updated th reference the new transaction table
        if (isIFTATracked) {
          const iftaTrackingJSON = {
            allSort: 'all',
            transactionId: statementId,
            unitId: selectedIFTAUnitOption?.value,
            teamMemberId: currentTeamMemberId,
            stateTwoChar: values?.selectState?.value,
            gallons: values?.gallons,
            odometer: values?.odometer,
          }
  
          // update iftaTracking table
          // console.log('iftaTrackingJSON: ', iftaTrackingJSON)
          const iftaFuelTaxTrackId = await addIFTAFuelTracking(iftaTrackingJSON)
          console.log('iftaFuelTaxTrackId: ', iftaFuelTaxTrackId)
          statementParams.iftaFuelTaxTrackId = iftaFuelTaxTrackId
          // statementParams.unitId = selectedIFTAUnitOption?.value
        }

        let receiptId = ''
        if (isEditMode && !isManualUpload) {
          console.warn('is edit mode and not manual upload')
          if (creditCardTransactionId) {
            console.warn(' abou tto update transaction creditCardTransactionId: ', creditCardTransactionId)
            const params = {
              id: creditCardTransactionId,
              capitalOneCreditCardTransactionReceiptId: statementId
            }
            if (instantNotificationId) {
              params.capitalOneInstantNotificationId = instantNotificationId
            }
            console.warn('params: ', params)
            const updateTransactionresults = await updateTransaction(params)
            console.warn('updateTransactionresults: ', updateTransactionresults)
          }
          console.warn('statementParams: ', statementParams)
          const updateReceiptResultId = await updateCreditCardTransactionReceipt(statementParams)
          console.log('updateReceiptResultId: ', updateReceiptResultId)
          receiptId = updateReceiptResultId
          if (uploadingNewReceipt) {
            if (combinedImageFile) {
              // Upload the combined image file
              // const uploadedDocument = await uploadStatement(combinedImageFile, transactionData.id);
              const uploadedDocument = await uploadStatement(combinedImageFile, transactionId);
              console.log('Uploaded combined image file: ', uploadedDocument);
  
              // Link the combined uploaded document to the receipt
              const createLinkId = await addStatementDocumentLink({
                receiptId: updateReceiptResultId,
                uploadedDocumentId: uploadedDocument?.documentId
              });
              console.log('createLinkId for combined file: ', createLinkId);
            } else if (files && files.length > 0) {
              // If individual files exist, upload each one
              const uploadedDocuments = await Promise.all(
                // files.map(file => uploadStatement(file, transactionData.id))
                files.map(file => uploadStatement(file, transactionId))
              );
              console.log('Uploaded individual files: ', uploadedDocuments);
  
              // Link each uploaded document to the receipt
              for (const uploadedDocument of uploadedDocuments) {
                const createLinkId = await addStatementDocumentLink({
                  receiptId: updateReceiptResultId,
                  uploadedDocumentId: uploadedDocument?.documentId
                });
                console.log('createLinkId for individual file: ', createLinkId);
              }
            }
          }
        } else {

          console.warn('statementParams: ', statementParams)
          // statementParams.capitalOneCreditCardTransactionReceiptId = 
          // const receiptId = await createCapitalOneReceipt(statementParams)
          receiptId = await createCapitalOneReceipt(statementParams)
          console.warn('createCapitalOneReceipt receiptId: ', receiptId)

          if (creditCardTransactionId) {
            console.warn(' abou to update transaction creditCardTransactionId: ', creditCardTransactionId)
            const params = {
              id: creditCardTransactionId,
              capitalOneCreditCardTransactionReceiptId: receiptId,
            }
            if (instantNotificationId) {
              params.capitalOneInstantNotificationId = instantNotificationId
            }
            console.warn('params: ', params)
            const updateTransactionresults = await updateTransaction(params)
            console.warn('updateTransactionresults: ', updateTransactionresults)
          }
          
          // If a combined image file exists, upload it, otherwise upload the individual files
          if (combinedImageFile) {
            // Upload the combined image file
            // const uploadedDocument = await uploadStatement(combinedImageFile, transactionData.id);
            const uploadedDocument = await uploadStatement(combinedImageFile, transactionId);
            console.log('Uploaded combined image file: ', uploadedDocument);

            // Link the combined uploaded document to the receipt
            const createLinkId = await addStatementDocumentLink({
              receiptId: receiptId,
              uploadedDocumentId: uploadedDocument?.documentId
            });
            console.log('createLinkId for combined file: ', createLinkId);
          } else if (files && files.length > 0) {
            // If individual files exist, upload each one
            const uploadedDocuments = await Promise.all(
              // files.map(file => uploadStatement(file, transactionData.id))
              files.map(file => uploadStatement(file, transactionId))
            );
            console.log('Uploaded individual files: ', uploadedDocuments);

            // Link each uploaded document to the receipt
            for (const uploadedDocument of uploadedDocuments) {
              const createLinkId = await addStatementDocumentLink({
                receiptId: receiptId,
                uploadedDocumentId: uploadedDocument?.documentId
              });
              console.log('createLinkId for individual file: ', createLinkId);
            }
          } else {
            throw new Error('No image or file to upload.');
          }
        }

        if (!isManualUpload && externallyOpened) {
          console.warn('We do not want to be here is this is an upload button click.')
          console.warn('We DO want to be here if this is an instant notification link.')
          const updateParams = {
            // id: transactionData.id,
            id: transactionId,
            allSort: 'all',
            capitalOneCreditCardTransactionReceiptId: receiptId,
            isSubmitted: true,
            // status: 'has-receipt',
            status: receiptHasIssue ? "fixed-issue" : "has-receipt",
          }
          console.log('updating instant notifications with id: ', transactionId)
          console.log('Setting receipt id to: ', receiptId)
          const updateSubmitted = await updateInstantNotification(updateParams)
          console.log('updateSubmitted: ', updateSubmitted)
        } else {
          console.warn('SHOULD BE UPDATING TRANSACTIO HERE')
        }
        
        // Create an array of promises for inserting unit records if this is NOT ifta
        const unitPromises = selectedUnits.map(unit => {
          const unitParams = {
            unitId: unit.value,
            // transactionId: transactionData.id,
            transactionId: statementId,

          };
          return addStatementDocumentUnit(unitParams);
        });
  
        // Wait for all unit insertions to complete
        await Promise.all(unitPromises);
  
        console.log('Upload and database operations completed successfully.');
        setConfirmationModalHeaderText('Success!');
        setConfirmationModalContent('Your receipt has been uploaded. Thank you!');
      } catch (error) {
        console.error('Error during form submission:', error);
        setConfirmationModalHeaderText('Error');
        setConfirmationModalContent(error.message);
        // alert('There was an error processing your request. Please try again.');
      }
    } else {
      // alert('No image to upload.');
    }
    setIsReceiptUploaded(true);
    setShowConfirmationModal(true);
  };
  
  async function uploadStatement(file) {
    // Referernce for when multi-file receipt upload is defined
    // https://medium.com/@steven_creates/uploading-multiple-files-to-aws-s3-using-react-js-hooks-react-aws-s3-1dd29221c91c
    // It might work better to sack the images into one image and THEN process that image
    // https://github.com/lukechilds/merge-images

    // setIsUploadStatementDisabled(true)
    // console.log('uploaded file info: ', file)
    // This needs to be a UUID not TM id, we don't want to overwrite what is already there.
    // const imgKey = teamMemberId + '-' + file.name;
    // const imgKey = uuid() + ':' + currentTeamMemberId + ':' + file.name + ':' + statementDocumentId;
    
    // let credentials = await Auth.currentCredentials();
    // console.log("imgKey: ",imgKey)
    // let identityId = credentials.identityId;
    const bucketVersion = qbEnv === 'sandbox' ? 'dev' : 'prod';
    let userBucket = `analyze-expense-statements-${bucketVersion}`;
    // const params = {
    //   bucket: userBucket,
    //   identityId: identityId, 
    //   key: imgKey
    // }

    // 1. add the document to SpkUploadedDocumentsTbl, return the documentId
    // 2. upload to 'analyze-expense-statements'
    // 3. duplicate file to 'textract-analyzexpense-sourcebucket-1rors02oulldp' for textract

    // const uploadData = await uploadFileToS3({ file: file, bucket: userBucket, key: imgKey })
    const uploadData = await uploadFileToS3({ file: file, bucket: userBucket })
    console.log('uploadData: ', uploadData)

    // const duplicateFile = await Storage.put(imgKey, file, {
    //   bucket: 'textract-analyzexpense-sourcebucket-1rors02oulldp',
    //   contentType: file.type
    // })
    // console.log('File has been dropped in source bucket for processing: ', duplicateFile)

    return uploadData

    // At this point:
    // 1.) The uploaded file has been given a unique key as uuid:teamMemberId:fileName
    // AND a record has been created in SpkDocumentUploadsTbl with the key for reference
    // 2.) The file has been saved to protected S3 for owner/admin access
    // 3.) The file has ALSO been put in the S3 bucket 'textract-analyzexpense-sourcebucket-1rors02oulldp'
    // for processing
  }
  
  const handleModeChange = (newMode) => {
    setMode(newMode);
  
    if (newMode === 'File') {
      // Try multiple scroll approaches
      if (containerRef.current) {
        containerRef.current.scrollTop = 0;
      }
      
      // Also try scrolling the parent elements
      const drawer = document.querySelector('.chakra-modal__content');
      if (drawer) {
        drawer.scrollTop = 0;
      }

      // Force scroll after a small delay to ensure DOM is updated
      setTimeout(() => {
        window.scrollTo(0, 0);
        // Focus the file input
        if (fileInputRef.current) {
          fileInputRef.current.focus();
        }
      }, 100);
    }
  };

  useEffect(() => {
    if (mode !== '' && mode !== 'File') {
      console.log('Starting camera mode...');
      setPreviewMode(false); // Reset preview if switching to camera mode
      setImageSrc(null); // Clear any previously captured image
    } else if (mode === 'File') {
      console.log('Switching to file upload mode...');
      // Clean up webcam if switching to file mode
      const video = webcamRef.current;
      if (video && video.srcObject) {
        video.srcObject.getTracks().forEach(track => track.stop());
        video.srcObject = null;
      }
    }
  }, [mode]);

  const [files, setFiles] = useState([]);
  const [previews, setPreviews] = useState([]);  // Array to hold preview images

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files); // Convert FileList to array
    setFiles(selectedFiles);

    // Generate previews for each selected file
    const previewPromises = selectedFiles.map(file => generatePreview(file));

    // Wait for all previews to be generated
    Promise.all(previewPromises).then(previewImages => {
      setPreviews(previewImages);  // Update state with all previews
    });
    if (selectedFiles.length > 0) {
      setShowUploadForm(true);
    }
  };

  const generatePreview = async (file) => {
    if (file.type.startsWith("image/")) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);  // Resolve with the image URL
        };
        reader.readAsDataURL(file);
      });
    } else if (file.type === "application/pdf") {
      return generatePdfPreview(file);
    }
    return null;
  };

  const generatePdfPreview = async (pdfFile) => {
    const fileReader = new FileReader();
    return new Promise((resolve) => {
      fileReader.onload = async function () {
        const typedarray = new Uint8Array(this.result);

        const loadingTask = pdfjsLib.getDocument(typedarray);
        const pdf = await loadingTask.promise;

        // Get the first page of the PDF
        const page = await pdf.getPage(1);

        // Set up a canvas for rendering
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        const viewport = page.getViewport({ scale: 0.5 });

        canvas.height = viewport.height;
        canvas.width = viewport.width;

        await page.render({
          canvasContext: context,
          viewport: viewport,
        }).promise;

        // Convert canvas to data URL and resolve it
        resolve(canvas.toDataURL());
      };

      fileReader.readAsArrayBuffer(pdfFile);
    });
  };

  const handleClose = async () => {
    setIsDrawerOpen(false);
    
    // Stop video tracks with a small delay to ensure proper cleanup
    if (videoRef.current?.srcObject) {
      const tracks = videoRef.current.srcObject.getTracks();
      await Promise.all(tracks.map(track => {
        return new Promise(resolve => {
          track.stop();
          resolve();
        });
      }));
      videoRef.current.srcObject = null;
    }
  
    // Reset all states
    setStreaming(false);
    setInitializationAttempts(0);
    setMode('');
    setImages([]);
    setPreviewMode(false);
    setShowUploadForm(false);
    setDeviceId(null);
    setImageSrc(null);
    setShowMultiPictureButtons(false);
    setModeIsLocked(false);
    setFiles(null);
    setPreviews([]);
    setCombinedImageFile(null);
    setUnitOptions([]);
    setIFTAUnitOptions([]);
    setSelectedIFTAUnitOption([]);
    setSelectedUnits([]);
    setSelectedDivision('');
    setSelectedClient('');
    setValue('odometer', '');
    setValue('gallons', '');
    setSelectedExpenseCategory('');
    clearErrors();
    
    onClose();
  };
  
  // Add useEffect to handle drawer opening
  useEffect(() => {
    setIsDrawerOpen(true);
  }, []); // Run once on mount to set initial state

  const startCamera = () => {
    setShowMultiPictureButtons(false);
    setImageSrc(null)
  }

  return (

<>
      <Container ref={containerRef} as="form" onSubmit={handleSubmit(formSubmit, onError)}>

        <div>
          {/* Button to trigger confetti */}
          {/* <button type="button" onClick={triggerConfetti}>Launch Drawer Confetti</button> */}
          
          {/* Drawer with canvas for confetti */}
          <div>
            <canvas
              ref={canvasRef}
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 1000,
                pointerEvents: 'none',
              }}
            />
          </div>
        </div>

        {(isAlreadyUploaded===false || isEditMode===true) && (
          <>
            <Flex
              direction="column"
              maxW="800px"
              mx="auto"
              color="white"
              minH="100vh"
            >
              <Box>
                <Flex direction="row" alignItems="flex-start" justifyContent="space-between">
                  {/* Left Container */}
                  <Box>
                    <Text as="span" textStyle="heading-1">{receiptHasIssue ? "Edit Receipt" : "Upload Receipt"}</Text>
                  </Box>

                  {/* Spacer between Left and Right Content */}
                  <Spacer />

                  {/* Right Container - Button */}
                  <Box>
                    {!externallyOpened && (
                      <ResponsiveCancelCloseButton 
                        iconSize={['22px', '26px']}
                        isMobile={isMobile}
                        value="Cancel"
                        onClick={handleClose} />
                    )}
                  </Box>
                </Flex>

                {receiptHasIssue && (<>

                  <Box paddingY={'25px'}  display="flex" justifyContent="center" alignItems="center">
                    <ButtonQuaternaryPlain
                      type='button'
                      name='retake-with-issue'
                      value='Retake Receipt Photo'
                      onClick={handleRetakeWithIssue}
                      w='220px'
                    />
                  </Box>

                  <Box>
                    <Text as="span" textStyle="label-2" display="block" mt="4px" marginRight={'10px'}>Reason for return: </Text>
                    <Text
                      paddingLeft="25px"
                      as="span"
                      textStyle="label-2"
                      display="block"
                      mt="4px"
                    >
                      {receiptIssueLog.split('::cr::').map((line, index) => (
                        <Box key={index}>
                          {line}
                          {index < receiptIssueLog.split('::cr::').length - 1 && <br />}
                        </Box>
                      ))}
                    </Text>
                  </Box>
                        
                  <Box pl={'25px'} pt={'25px'}>
                    <TextareaInput
                      fieldname="receiptIssue"
                      prettyname="Receipt Issue"
                      register={register}
                      errors={errors}
                      placeholder="Response to the issue is required."
                      isRequired={true}
                      isReadOnly={false}
                      rows={2}
                      onChange={(e) => {
                        handleUpdateReceiptIssue(e.target.value);
                      }}
                    />
                  </Box>

                  </>
                )}

                {(!receiptHasIssue && selectedActionType==='upload-with-transaction') && (<>
                  <Text as="span" textStyle="label-2" display="block" mt="4px">
                    {transactionData?.merchantEntityName}
                  </Text>
                  <Text as="span" textStyle="label-2" display="block" mt="4px">
                    {(transactionData?.date) && spellOutDate(transactionData?.date, 'apple-fy')}
                  </Text>
                  <Text as="span" textStyle="label-2" display="block" mt="4px">
                    {transactionData?.amount}
                  </Text></>
                )}
                {(!receiptHasIssue && selectedActionType==='preemptive') && (
                  <Text as="span" textStyle="label-2" display="block" mt="4px">
                    Add receipt for missing transaction
                  </Text>
                )}
                {(!receiptHasIssue && transactionData?.purchaseMerchant && selectedActionType!=='preemptive') && (
                  <Text as="span" textStyle="label-2" display="block" mt="4px">
                    {transactionData?.purchaseMerchant} ${transactionData?.purchaseAmount}
                  </Text>
                )}
                {(!receiptHasIssue && !transactionData.purchaseMerchant && attachReceiptParams?.name && selectedActionType!=='preemptive') && (
                  <Text as="span" textStyle="label-2" display="block" mt="4px">
                    {attachReceiptParams?.name} ${attachReceiptParams?.amount}
                  </Text>
                )}


                {((showRetakePhotoWithIssue && receiptHasIssue) || !receiptHasIssue) && <Box>
                  <VStack spacing={4}>
                    <>
                      {/* {(isEditMode && !editModeIsLocked) && (<>
                        <ButtonPrimaryPlain
                          type='button'
                          name='replaceReceipt'
                          value='Replace Receipt'
                          onClick={handleShowReplaceReceipt(true)}
                        />
                        <ButtonPrimaryPlain
                          type='button'
                          name='keepReceipt'
                          value='Edit Details'
                          onClick={handleShowReplaceReceipt(false)}
                        />
                      </>)} */}
                      
                      {images?.length > 0 && (
                        <Box>
                          {images.map((image, index) => (
                            <ChakraImage key={index} src={image} alt={`Receipt ${index + 1}`} />
                          ))}
                          {images.length > 0 && previewMode && (
                            <Flex direction="column" align="center" w="100%">
                              <Flex 
                                justify="space-between"
                                align="center"
                                w={'90%'}
                                my={'25px'}
                                mx={'auto'}
                              >
                                <ButtonQuaternaryPlain
                                  type='button'
                                  name='retake'
                                  value='Retake'
                                  onClick={handleRetake}
                                  w='45%'
                                />
                                <ButtonPrimaryPlain
                                  // mx={'20px'}
                                  type="button"
                                  onClick={handleKeep}
                                  name="keep"
                                  value="Keep"
                                  w='45%'
                                />
                              </Flex>
                            </Flex>
                          )}
                        </Box>
                      )}

                      {/* If selectedActionType = preemptive we need to allow them to select an image from the 
                          receipt uploads that did not have an instant notification. */}

                      {(mode==='File') && (
                        <>
                          <Flex wrap="wrap" mt={4}>
                            {previews.map((preview, index) => (
                              <Box key={index} m={2}>
                                <img src={preview} alt={`Preview ${index + 1}`} width="200px" />
                              </Box>
                            ))}
                          </Flex>
                          <Box>
                            <Text as="span" textStyle='label-2'>Upload PDF or Image</Text>
                            {/* <Input type="file" accept="image/*" capture="environment"></Input> */}
                            <Input 
                              name='fileUpload'
                              id='fileUpload'
                              paddingTop={'6px'} 
                              paddingLeft={'8px'} 
                              placeholder='Choose File(s)' 
                              type="file" 
                              accept="image/jpeg, image/jpg, image/png, application/pdf"
                              multiple
                              onChange={handleFileChange}
                              // capture="environment"
                              ref={fileInputRef} />
                          </Box>
                        </>
                      )}

                      {showMultiPictureButtons && 
                        <>
                          {images.length > 0 && !previewMode && mode === 'Combine' && !showUploadForm && (
                            <ButtonPrimaryPlain
                              // mx={'20px'}
                              type="button"
                              // onClick={startCamera}
                              onClick={startCamera}
                              name="continue"
                              value="Take another picture"
                            />
                          )}
                          {showMultiPictureButtons && !previewMode && !showUploadForm && (
                            <ButtonQuaternaryPlain
                              type='button'
                              name='done'
                              value='I am done taking pictures'
                              onClick={handleDone}
                            />
                          )}
                        </>
                      }

                      {(!showUploadForm && mode!=='File') && <>
                        <Box display="flex" flexDirection="column" alignItems="center" width="100%">

                          {(!previewMode && !showMultiPictureButtons) && (
                            <>
                              {/* Camera Selection Dropdown */}
                              {/* {devices.length > 1 && ( */}
                              <Box mb={4} width="100%">
                                <FormSelectSimple
                                  register={register}
                                  control={control}
                                  errors={errors}
                                  onChange={(e) => {
                                    console.log(devices);
                                    setDeviceId(e.value);
                                  }}
                                  selectedoption={devices.find(device => device.deviceId === deviceId)}
                                  optionsArray={devices?.map(( device, index ) => ({
                                    label: device.label || `Camera ${index + 1}`,
                                    value: device.deviceId,
                                    key: device.deviceId
                                  }))}
                                  fieldname='seletCamera'
                                  prettyname='Select Camera'
                                  fieldlabel={`Select Camera (${devices.length})`}
                                  placeholder={'Select camera'}
                                  issearchable={false}
                                />
                              </Box>
                              {/* )} */}

                              {/* Video Display */}
                              <Box width="100%" position="relative">
                                <Box>
                                  <video
                                    ref={videoRef}
                                    autoPlay
                                    playsInline
                                    style={{ maxWidth: '100%', height: 'auto' }} // Responsive styling
                                  />
                                  
                                  {/* Hidden canvas used for capturing images */}
                                  <canvas ref={canvasRef} style={{ display: 'none' }} />
                                  {/* <Webcam
                                    audio={false}
                                    ref={webcamRef}
                                    screenshotQuality={1}
                                    // screenshotFormat="image/jpeg"
                                    screenshotFormat="image/png"
                                    videoConstraints={videoConstraints}
                                    style={mode !== 'File' ? { width: '100%', height: 'auto' } : { width: '0px', height: '0px' }}
                                    // onLoadedMetadata={() => setIsVideoLoaded(true)}
                                  /> */}
                                </Box>
                              </Box>
                            </>
                          )}

                          {(!previewMode && !showUploadForm && mode!=='File') && (
                            <>
                              <Flex direction="column" align="center" w="100%">
                              {!previewMode && (
                                <Flex justify="space-between" align="center" w={'90%'} my={'25px'}>
                                  {/* Left-aligned upload button isSupervisor */}
                                  <Box w='40px'>
                                    {/* {(!isMobile || (isMobile && isSupervisor)) && (  */}
                                      <ButtonNonaryWithIconOnly
                                        h='24px'
                                        w='24px'
                                        name={'uploadFile'}
                                        icon='uploadFile'
                                        iconsize='24px'
                                        onClick={() => { handleModeChange('File'); }}
                                        isDisabled={modeIsLocked}
                                      />
                                    {/* )} */}
                                  </Box>
                                  
                                  {/* Center-aligned SINGLE and COMBINE buttons */}
                                  {/* {(mode!=='File') &&  */}
                                  <Flex justify="center" align="center" flex="1" paddingLeft={'12px'}>
                                    <ButtonDuodenaryPlain
                                      w='105px'
                                      name='single'
                                      value='SINGLE'
                                      onClick={() => { handleModeChange('Single'); }}
                                      isDisabled={modeIsLocked}
                                      state={mode === 'Single' ? 'selected' : 'default'}
                                    />
                                    <ButtonDuodenaryPlain
                                      w='120px'
                                      name='combine'
                                      value='COMBINE'
                                      onClick={() => { handleModeChange('Combine'); }}
                                      isDisabled={modeIsLocked}
                                      state={mode === 'Combine' ? 'selected' : 'default'}
                                    />
                                  </Flex>
                                  {/* } */}

                                  <Box w='40px' />
                                </Flex>
                              )}

                              {/* Second row - centered button */}
                              <Flex justify="center" w="90%">
                                {!showMultiPictureButtons && (
                                  <ButtonSeptenaryWithIconOnly
                                    h='64px'
                                    w='64px'
                                    mt='5px'
                                    name='capture'
                                    icon='capture'
                                    iconsize='40px'
                                    // onClick={capturePhoto}
                                    onClick={() => capturePhoto(webcamRef)}
                                  />
                                )}
                              </Flex>
                            </Flex>
                          </>
                        )}
                      </Box>
                      <canvas ref={canvasRef} width={640} height={480} style={{ display: 'none' }}></canvas>
                      </>}
                    </>
                  </VStack>
                </Box>}
              </Box>

              {(showUploadForm || receiptHasIssue) && (
                <Box mt={'25px'}>

                  <Box paddingTop={'25px'}>
                    <FormSelectSimple
                      register={register}
                      control={control}
                      errors={errors}
                      isRequired={true}
                      rules={{ required: "Statement Type is required" }}
                      onChange={e => updateSelectedStatementType(e)}
                      selectedoption={selectedStatementType||''}
                      optionsArray={statementTypeOptions}
                      fieldname='selectStatementType'
                      prettyname='Type'
                      fieldlabel="Type"
                      placeholder={'Select type'}
                      issearchable={false}
                    />
                  </Box>

                  <Box paddingTop={'25px'}>
                    <FormSelectSimple
                      register={register}
                      control={control}
                      errors={errors}
                      isRequired={true}
                      rules={{ required: "Expense Category is required" }}
                      onChange={e => updateSelectedExpenseCategory(e)}
                      selectedoption={selectedExpenseCategory||''}
                      optionsArray={expenseCategories}
                      fieldname='selectExpenseCategory'
                      prettyname='Expense Category'
                      fieldlabel="Expense Category"
                      placeholder={'Select expense category'}
                      issearchable={false}
                    />
                  </Box>

                  {((showUploadForm && showUploadFormRest) || receiptHasIssue) && (<>
                    <Box paddingTop={'25px'}>
                      <TextareaInput
                        register={register}
                        errors={errors}
                        // rules={{ required: "Required" }}
                        fieldname="addedNotes"
                        fieldlabel="Expense Notes"
                        prettyname="Expense Notes"
                        placeholder="Enter any notes about this expense"
                        // defaultvalue={isEditMode ? attachReceiptParams?.addedNotes||transactionData?.capitalOneCreditCardTransactionReceipt?.addedNotes : ''}
                        // onChange={handleJobDescriptionChange}
                        // onBlur={handleJobDescriptionChange}
                        // isRequired
                      />
                    </Box>
                    
                    <Box paddingTop={'25px'}>
                      <FormSelectSimple
                        key='Division'
                        register={register}
                        control={control}
                        errors={errors}
                        isRequired={true}
                        rules={{ required: "Division is required" }}
                        fieldname="selectDivision"
                        fieldlabel="Division"
                        placeholder={'Select division'}
                        optionsArray={divisionOptions?.map((division, index) => ({
                          key: index,
                          value: division.value,
                          label: division.label
                        }))}
                        onChange={(e) => updateSelectedDivision(e)}
                        selectedoption={selectedDivision||''}
                        issearchable={false}
                      />
                    </Box>

                    <Box paddingTop={'25px'}>
                      <FormSelectSimple
                        key='Client'
                        register={register}
                        control={control}
                        errors={errors}
                        isRequired={true}
                        fieldname="selectClient"
                        fieldlabel="Client"
                        placeholder={'Select client'}
                        optionsArray={clients}
                        onChange={(e) => updateSelectedClient(e)}
                        selectedoption={selectedClient||''}
                        issearchable={false}
                      />
                    </Box>

                    {isIFTATracked ? (<>
                      <Box paddingTop={'25px'}>
                        <FormSelectSimple
                          register={register}
                          control={control}
                          errors={errors}
                          isRequired={true}
                          rules={{ required: "OTR Unit is required" }}
                          onChange={(event, action) => handleIFTAUnitChange(event, action)}
                          selectedoption={selectedIFTAUnitOption}
                          optionsArray={iftaUnitOptions.map(unit => ({
                            value: unit.value,
                            label: unit.label
                          }))}
                          fieldname="selectOTRUnit"
                          placeholder={'Select OTR unit'}
                          fieldlabel="OTR Unit"
                          issearchable={false}
                        />
                      </Box>

                      <Box paddingTop={'25px'}>
                        <FormSelectSimple
                          register={register}
                          control={control}
                          errors={errors}
                          isRequired={true}
                          rules={{ required: "State is required" }}
                          onChange={e => updateSelectedState(e)}
                          selectedoption={selectedState||''}
                          optionsArray={stateOptions}
                          fieldname='selectState'
                          prettyname='State'
                          fieldlabel="State"
                          placeholder={'Select state'}
                        />
                      </Box>

                      <Box paddingTop={'25px'}>
                        <TextInput
                          register={register}
                          errors={errors}
                          // fieldtype="tel"
                          fieldtype="number" step="any"
                          fieldname="odometer"
                          fieldlabel="Odometer"
                          prettyname="Odometer"
                          placeholder="Enter odometer miles"
                          onChange={handleOdometerChange}
                          onKeyPress={handleOdometerKeyPress}
                          isRequired
                        />
                      </Box>

                      <Box paddingTop={'25px'}>
                        <TextInput
                          register={register}
                          errors={errors}
                          fieldtype="number"
                          fieldname="gallons"
                          fieldlabel="Gallons"
                          prettyname="Gallons"
                          placeholder="Enter gallons purchased"
                          onChange={handleGallonsChange}
                          onKeyPress={handleGallonsKeyPress}
                          isRequired
                        />
                      </Box>

                      </>
                    ) : 
                      <Box paddingTop={'25px'}>
                        <FormSelectMulti
                          register={register}
                          control={control}
                          errors={errors}
                          onChange={(event, action) => handleUnitsChange(event, action)}
                          selectedoption={selectedUnits}
                          optionsArray={unitOptions.map((unit) => ({
                            value: unit.value,
                            label: unit.label
                          }))}
                          fieldname="units"
                          placeholder={'Select units if applicable'}
                          fieldlabel="Units"
                          issearchable={false}
                          isMulti
                        />
                      </Box>
                    }
                  </>)}

                  <Box paddingTop={'50px'}>
                    <Flex gap={4} justifyContent="center" width="100%">
                    
                      {/* <Button 
                        onClick={resetForm} 
                        isDisabled={isSubmitting}
                        colorScheme="red" 
                        width="100%"
                        >Reset</Button> */}
                      <ButtonQuaternaryPlain
                        type="button"
                        // onClick={handleSubmit(formSubmit, onError)}
                        onClick={resetForm} 
                        width="100%"
                        name="reset"
                        value={'Reset'}
                        isDisabled={isSubmitting}
                      />

                      <ButtonPrimaryPlain
                        type="submit"
                        onClick={handleSubmit(formSubmit, onError)}
                        width="100%"
                        name="submit"
                        value={isSubmitting ? "Uploading..." : "Upload"}
                        isDisabled={selectedExpenseCategory==='' || isSubmitting}
                      />
                      
                    </Flex>
                  </Box>

                </Box>
                
              )}
            </Flex>
            <Box h={'300px'}></Box>
          </>
        )}

      </Container>

      <CenteredSingleButtonConfirmationModal
        isModalOpen={isConfirmModalOpen}
        onModalClose={onConfirmModalClose}
        headerIcon='outlinecheckcircle'
        headerIconColor='var(--success-green)'
        headerText={confirmationModalHeaderText}
        bodyContent={confirmationModalContent}
        closeButtonDisabled={false}
        handleSubmitModalClosed={handleConfirmModalClosed}
      />
    </>

  );
};

QuickCameraUploadStatement.propTypes = {
  id: PropTypes.string,
  selectedActionType: PropTypes.string,
  attachReceiptParams: PropTypes.shape({
    accountNumber: PropTypes.string,
    addedNotes: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    amount: PropTypes.string,
    date: PropTypes.string,
    hasInstantNotification: PropTypes.bool,
    hasIssue: PropTypes.bool,
    issue: PropTypes.string,
    merchantEntityName: PropTypes.string,
    unitId: PropTypes.string,
    units: PropTypes.array,
    expenseCategoryId: PropTypes.string,
    clientId: PropTypes.string,
    odometer: PropTypes.string,
    gallons: PropTypes.string,
    stateTwoChar: PropTypes.string,
    iftaFuelTaxTrackId: PropTypes.string,
  }),
  onClose: PropTypes.func,
};