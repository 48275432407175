import { useState, useEffect, useContext, forwardRef } from 'react';
import { ModuleBox } from '../components/Structural/ModuleBox.jsx';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuthenticator, View } from '@aws-amplify/ui-react';
import { AppContext } from './AppContext'
import {
  Container,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
  MenuDivider,
  Button,
  Box,
  Text,
  useDisclosure
} from '@chakra-ui/react';

import { useForm } from 'react-hook-form';
import { ChevronDownIcon } from '@chakra-ui/icons';
// import { getTeamMemberAvatar } from './reactComponents/TeamMember'
import { fetchTeamMemberDataByLoginEmail } from './graphqlCompnents/TeamMember/fetchTeamMemberDataByLoginEmail'
import { getTeamMemberTopicId } from './graphqlCompnents/PubSub/getTeamMemberTopicId'
// import { CustomToast } from "./toast/CustomToast"


// import { fetchContactCompaniesByDivision } from './graphqlCompnents/DJT/fetchContactCompaniesByDivision'

import { searchDjtsByDivision } from './graphqlCompnents/DJT/searchDjtsByDivision';
import { isValidDate } from './functions/dateTime'

// import { useImmer } from 'use-immer';
// import { useOnlineStatus } from '@withvoid/melting-pot';


import { ButtonPrimaryPlain } from './Form/Button/ButtonPrimaryPlain'
import { ButtonPrimaryWithIcon } from './Form/Button/ButtonPrimaryWithIcon';
import { ButtonSecondaryPlain } from './Form/Button/ButtonSecondaryPlain'
import { ButtonSecondaryWithIcon } from './Form/Button/ButtonSecondaryWithIcon';
import { ButtonTertiaryPlain } from './Form/Button/ButtonTertiaryPlain';
import { ButtonTertiaryWithIcon } from './Form/Button/ButtonTertiaryWithIcon';
import { ButtonQuaternaryPlain } from './Form/Button/ButtonQuaternaryPlain';
import { ButtonQuaternaryWithIcon } from './Form/Button/ButtonQuaternaryWithIcon';
import { ButtonQuinaryPlain } from './Form/Button/ButtonQuinaryPlain';
import { ButtonQuinaryWithIcon } from './Form/Button/ButtonQuinaryWithIcon';
import { ButtonSenaryPlain } from './Form/Button/ButtonSenaryPlain';
import { ButtonSenaryWithIcon } from './Form/Button/ButtonSenaryWithIcon';
import { ButtonSenaryWithIconOnly } from './Form/Button/ButtonSenaryWithIconOnly';
import { ButtonOctonaryPlain } from './Form/Button/ButtonOctonaryPlain';
import { ButtonOctonaryWithIcon } from './Form/Button/ButtonOctonaryWithIcon';
// import { ButtonOctonaryWithIconOnly } from './Form/Button/ButtonOctonaryWithIconOnly';
import { ButtonDenaryPlain } from './Form/Button/ButtonDenaryPlain';

import { TextInput } from './Form/Input/TextInput.jsx'

export function Layout() {

  // const [onlineList, setOnline] = useImmer([]);
  // const {store} = useContext(AppContext);
  // const { online } = useOnlineStatus();

  // const [ userEmail, setUserEmail ] = useState(null)


  const [ clientIdSelected, setClientIdSelected ] = useState(null)
  const [ djtStartDate, setDjtStartDate ] = useState('')
  const [ djtEndDate, setDjtEndDate ] = useState('')

 

  // const [ currentDivisionId, setCurrentDivisionId ] = useState(()=> {
    // return(store?.userData?.divisionId||null)
  // })


  // useEffect(() => {
  //   (currentDivisionId!==undefined) && getCustomers(currentDivisionId);
  // }, [currentDivisionId]);  

  // const getUserGroups = async (user) => {

  //   // const user =  await Auth.currentAuthenticatedUser();
  //   // console.log('user object: ', user)
  //   if (user!==null) {
  //     const userGroups = user.signInUserSession.accessToken.payload["cognito:groups"]
  //     const isInGroupIssuedCreditCard = userGroups.includes("issued_credit_card");
  //     const isInGroupTeamMember = userGroups.includes("team_member");
  //     const isInGroupExecAdmin = userGroups.includes("exec_admin");
  //     const isInGroupExecAdminReadOnly = userGroups.includes("exec_admin_readonly");
  //     const isInGroupSuperVisor = userGroups.includes("supervisor");

  //     const userEmailAddress = await user.attributes.email
  //     window.$user_email = userEmailAddress;
  //     setUserEmail(userEmailAddress)
      
  //     window.$member_of_issued_credit_card = isInGroupIssuedCreditCard;
  //     window.$member_of_team_member = isInGroupTeamMember;
  //     window.$member_of_exec_admin = isInGroupExecAdmin;
  //     window.$member_of_exec_admin_readonly = isInGroupExecAdminReadOnly;
  //     window.$member_of_supervisor = isInGroupSuperVisor;
  //     window.$user_groups = userGroups;

  //     const teamMemberObj = await fetchTeamMemberDataByLoginEmail(userEmailAddress)
  //     localStorage.setItem('teamMemberId', teamMemberObj?.id)
  //     localStorage.setItem('teamMemberEmail', teamMemberObj?.loginEmail)
      
  //     const topicId = await getTeamMemberTopicId(teamMemberObj?.id)
  //     localStorage.setItem('teamMemberTopicId', topicId)
  //   }
  // }

  // const getAvatarComponent = async (emailAddress) => {
  //   // console.log('getAvatarComponent emailAddress: ', emailAddress)
  //   const response = await getTeamMemberAvatar(emailAddress)
  //   // console.log('getAvatarComponent response: ',response)
  //   setAvatarCompnent(response)
  // }

  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  
  const { route, signOut, user } = useAuthenticator((context) => [
    context.route,
    context.signOut,
    context.user
  ]);
  const navigate = useNavigate();

  function logOut() {
    window.$user_email = null;
    window.$member_of_issued_credit_card = null;
    window.$member_of_team_member = null;
    window.$member_of_exec_admin = null;
    window.$member_of_exec_admin_readonly = null;
    window.$member_of_supervisor = null;
    window.$user_groups = null;
    signOut();
    navigate('/login');
  }

  const { setFocus, setError, control, register, handleSubmit, setValue, getValues, trigger, watch, formState: { errors, isSubmitting, isLoading }, reset } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {}
  });

  const onError = (errors, e) => console.log(errors, e);

  const formSubmit = async (values, event) => {

  }

  const searchLimit = 10

  const onCustomerChanged = async (event, action) => {
    // console.log('event: ', event)
    // console.log('action: ', action)
    // if the start date is populated
    // if the end date is populated for date range


    if (event===null) {
      // console.log('CLEARED CLIENT: ', {divisionId: currentDivisionId, djtClientId: '', djtDate: djtStartDate, djtEndDate: djtEndDate, limit: searchLimit})
      setClientSelected(null)
      setClientIdSelected(null)
      // setValue('selectCustomer', '')
      searchDjts({divisionId: currentDivisionId, djtClientId: '', djtDate: djtStartDate, djtEndDate: djtEndDate, limit: searchLimit})
        .catch(console.error);;

    } else {
      // console.log('CHANGED CUSTOMER: ', {divisionId: currentDivisionId, djtClientId: event.value, djtDate: djtStartDate, djtEndDate: djtEndDate, limit: searchLimit})
      //{label: "Boomer Environmental - Jason Martin", value: "d72cf271-841c-4f1c-b1db-1bd99d80a74b"}
      setClientSelected({label: event.label, value: event.value})
      setClientIdSelected(event.value)
      // console.log('clientSelected: ', {label: event.label, value: event.value})

      // now search djts for customer
      searchDjts({divisionId: currentDivisionId, djtClientId: event.value, djtDate: djtStartDate, djtEndDate: djtEndDate, limit: searchLimit})
        .catch(console.error);;

    }
    
  }


  const onStartDateChanged = async (date) => {

    // console.log('date event: ', date)
    

    // if we have an end date

    // if we have a client selected

    //setStartDate
    
    // console.log('startDate: ', event.target.value)
    if (date===null) {
      setEndDateDisabled(true)
      setDjtStartDate(null)

      searchDjts({divisionId: currentDivisionId, djtClientId: clientIdSelected, djtDate: '', djtEndDate: '', limit: searchLimit})
        .catch(console.error);;

    } else {
      const startDate = date
    
      if (isValidDate(date)) {
        setEndDateDisabled(false)
        setDjtStartDate(startDate)

        searchDjts({divisionId: currentDivisionId, djtClientId: clientIdSelected, djtDate: startDate, djtEndDate: djtEndDate, limit: searchLimit})
          .catch(console.error);;
      }
    }
  }

  const onEndDateChanged = async (date) => {

    console.log('got end date value: ', date)

    if (date===null) {
      // setEndDateDisabled(true)
      setDjtEndDate(null)

      searchDjts({divisionId: currentDivisionId, djtClientId: clientIdSelected, djtDate: djtStartDate, djtEndDate: '', limit: searchLimit})
          .catch(console.error);;

    } else {
      const endDate = date
    
      if (isValidDate(endDate)) {
        setEndDateDisabled(false)
        setDjtEndDate(endDate)

        searchDjts({divisionId: currentDivisionId, djtClientId: clientIdSelected, djtDate: djtStartDate, djtEndDate: endDate, limit: searchLimit})
          .catch(console.error);;
      }
    }
  }


  // useEffect(() => {
  //   (currentDivisionId) && searchDjts(currentDivisionId)
  // }, [currentDivisionId, searchDjts])

  // async function searchDjts(props) {
  //   console.log('divisionId: ', store?.userData?.divisionId)
  //   //divisionId, djtClientId, djtDate, djtStartDate, djtEndDate, limit
  //   setDjtSearchResults(null)
  // }

  // const searchDjts = useCallback(async (props) => {
  //   if (currentDivisionId) {
  //     console.log('---props: ', props)
  //     // const props = { divisionId: currentDivisionId}
  //     const data = await searchDjtsByDivision(props)
  //     setDjtSearchResults(data)
  //     console.log('returned djts: ', data)
  //   }
  // }, [currentDivisionId])

  // const searchDjts = async (props) => {
  //   // setDjtSearchResults(null)
  //   // console.log('---props: ', props)
  //   // const props = { divisionId: currentDivisionId}
  //   const data = await searchDjtsByDivision(props)
  //   setDjtSearchResults(data)
  //   // console.log('returned djts: ', data)
  // }
  // useEffect(() => {
  //   setValue('searchDate', null)
  //   setValue('endDate', null)
  //   setValue('selectCustomer', null)
  //   searchDjts({divisionId: currentDivisionId, djtClientId: '', djtDate: '', djtEndDate: '', limit: searchLimit})
  //     .catch(console.error);;
  // }, [currentDivisionId])

  //
  // const onInputFocused = async (event) => {
  //   console.log('event: ', event)
  // }
  // const onInputBlurred = async (event) => {
  //   console.log('event: ', event)
  // }
  // const onInputChanged = async (event) => {
  //   console.log('event: ', event)
  // }
  // const onInputClicked = async (event) => {
  //   console.log('event: ', event)
  // }

  const [ searchDateType, setSearchDateType ] = useState(true)
  const handleDateTextChange = () => setSearchDateType(!searchDateType)
  
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  // const handleDateChange = (event) => {
  //   console.log('date: ', event.target)
  //   setSelectedDate(event.value);
  // };

  // const CustomDateButton = forwardRef(({ value, onClick, isDisabled }, ref) => (
  //   <Button 
  //     isDisabled={isDisabled}
  //     variant={'calendarbutton'} 
  //     onClick={onClick} 
  //     ref={ref}>
  //       {value}
  //   </Button>
  // ));

  const formatDateFn = (date) => {
    const selectedDate = new Date(date)
    let dd = String(selectedDate.getDate()).padStart(2, '0');
    let mm = String(selectedDate.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = selectedDate.getFullYear();

    return  yyyy + '-' + mm + '-' + dd;
  } 



  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleOpenDrawer = (id, type) => {
    // id of djt to open
    // type: new, duplicate, view
    onOpen()
  }

  const handleClearLocalStorage = () => {
    const keysToClear = ['showAttachUploadModal', 'showReceiptInfoModal']; // localStorage keys to clear

    keysToClear.forEach((key) => {
      localStorage.removeItem(key);
      console.log(`${key} removed from localStorage!`);
    });
  
    console.log('All specified keys have been cleared from localStorage!');
  }

  return (
    
    <Container as="form" onSubmit={handleSubmit(formSubmit, onError)}>

    <ModuleBox>
    
      {/* <ul id="online"> {online ? 'You are Online' : 'You are Offline'} <hr/> </ul> */}
      <Box>
        <ul>
          <li><Text as="span" textStyle='label-1'>Text Style label-1</Text></li>
          <li><Text as="span" textStyle='label-2'>Text Style label-2</Text></li>
          <li><Text as="span" textStyle='title-circle-1'>Text Style title-circle-1</Text></li>
          <li><Text as="span" textStyle='inner-circle-1'>Text Style inner-circle-1</Text></li>
          <li><Text as="span" textStyle='heading-1'>Text Style heading-1</Text></li>
          <li><Text as="span" textStyle='heading-2'>Text Style heading-2</Text></li>
          <li><Text as="span" textStyle='heading-3'>Text Style heading-3</Text></li>
          <li><Text as="span" textStyle='heading-4'>Text Style heading-4</Text></li>
          <li><Text as="span" textStyle='dark-heading-3'>Text Style dark-heading-3</Text></li>
          <li><Text as="span" textStyle='dark-heading-4'>Text Style dark-heading-4</Text></li>
          <li><Text as="span" textStyle='text-1'>Text Style text-1</Text></li>
          <li><Text as="span" textStyle='text-2'>Text Style text-2</Text></li>
          <li><Text as="span" textStyle='text-3'>Text Style text-3</Text></li>
          <li><Text as="span" textStyle='text-4'>Text Style text-4</Text></li>
          <li><Text as="span" textStyle='text-5'>Text Style text-5</Text></li>
          <li><Text as="span" textStyle='text-6'>Text Style text-6</Text></li>
          <li><Text as="span" textStyle='text-7'>Text Style text-7</Text></li>
          <li><Text as="span" textStyle='text-8'>Text Style text-8</Text></li>
          <li><Text as="span" textStyle='text-9'>Text Style text-9</Text></li>
          <li><Text as="span" textStyle='text-10'>Text Style text-10</Text></li>
          <li><Text as="span" textStyle='alert-1'>Text Style alert-1</Text></li>
          <li><Text as="span" textStyle='text-2-grey-1'>Text Style text-2-grey-1</Text></li>
          <li><Text as="span" textStyle='modal-content-header'>Text Style modal-content-header</Text></li>
          <li><Text as="span" textStyle='manual-form-element-error'>Text Style manual-form-element-error</Text></li>
        </ul>
      </Box>

      <Box paddingTop={'25px'}>
        <TextInput
          register={register}
          errors={errors}
          // fieldtype="text"
          // fieldtype="tel"
          fieldtype="number" step="any"
          fieldname="odometer"
          fieldlabel="Odometer"
          prettyname="Odometer"
          placeholder="Enter odometer miles"
          // onChange={handleOdometerChange}
          // onKeyPress={handleOdometerKeyPress}
          // isRequired
        />
      </Box>

      <Box paddingTop={'25px'}>
      <input 
  type="text" 
  // inputMode="decimal" 
  // pattern="\d*\.?\d*" 
  placeholder="Enter a number"
/>
      </Box>

      <Text>Primary Button Plain</Text>
      <Box my={'5px'}>
        <ButtonPrimaryPlain
          name='new'
          icon='new'
          iconsize='22px'
          value='Create New Ticket'
        />
      </Box>

      <Text>Primary Button with Icon</Text>
      <Box my={'5px'}>
        <ButtonPrimaryWithIcon
          name='new'
          leftIcon='new'
          iconsize='22px'
          value='Create New Ticket'
        />
      </Box>

      <Text>Secondary Button with Icon</Text>
      <Box my={'5px'}>
        <ButtonSecondaryWithIcon 
          name='exportAsPDF'
          leftIcon='download'
          iconsize='20px'
          value='Export Summary'
        />
      </Box>
      
      <Text>Secondary Button Plain</Text>
      <Box my={'5px'}>
        <ButtonSecondaryPlain 
          name='cancel'
          value='Cancel'
        />
      </Box>
      
      <Text>Tertiary Button with Icon</Text>
      <Box my={'5px'}>
        <ButtonTertiaryWithIcon 
          name='exportAsPDF'
          leftIcon='download'
          iconsize='20px'
          value='Export Summary'
        />
      </Box>
      
      <Text>Tertiary Button Plain</Text>
      <Box my={'5px'}>
        <ButtonTertiaryPlain 
          name='cancel'
          value='Cancel'
          // onClick={console.log('cancel')}
        />
      </Box>
      
      <Text>Quaternary Button with Icon</Text>
      <Box my={'5px'}>
        <ButtonQuaternaryWithIcon 
          name='exportAsPDF'
          leftIcon='close'
          iconsize='20px'
          value='Cancel new job ticket'
        />
      </Box>
      
      <Text>Quaternary Button Plain</Text>
      <Box my={'5px'}>
        <ButtonQuaternaryPlain 
          name='cancel'
          value='Cancel'
          // onClick={console.log('cancel')}
        />
      </Box>

      <Text>Quinary Button Plain</Text>
      <Box my={'5px'}>
        <ButtonQuinaryPlain
          name='new'
          icon='new'
          iconsize='22px'
          value='Plain Quniary'
          // onClick={console.log('new')}
        />
      </Box>

      <Text>Quinary Button with Icon</Text>
      <Box my={'5px'}>
        <ButtonQuinaryWithIcon
          name='new'
          rightIcon='plus'
          iconsize='22px'
          value='Dalton Goucher'
          // onClick={console.log('new')}
        />
      </Box>
      
      <Text>Senary Button Plain</Text>
      <Box my={'5px'}>
        <ButtonSenaryPlain
          name='check'
          icon='check'
          iconsize='22px'
          value='Plain Senary'
          // onClick={console.log('new')}
        />
      </Box>

      <Text>Senary Button with Icon</Text>
      <Box my={'5px'}>
        <ButtonSenaryWithIcon
          name='plus'
          rightIcon='plus'
          iconsize='22px'
          value='Dalton Goucher'
          // onClick={console.log('new')}
        />
      </Box>
      
      <Text>Senary Button with Icon Only</Text>
      <Box my={'5px'}>
        <ButtonSenaryWithIconOnly
          name='delete'
          icon='delete'
          iconsize='22px'
          onClick={handleClearLocalStorage}
          // onClick={console.log('new')}
        />
      </Box>

      <Text>Octonary Button Plain</Text>
      <Box my={'5px'}>
        <ButtonOctonaryPlain
          value='Plain Octonary'
          // onClick={console.log('new')}
        />
      </Box>

      <Text>Octonary Button with Icon</Text>
      <Box my={'5px'}>
        <ButtonOctonaryWithIcon
          name='plus'
          rightIcon='plus'
          iconsize='22px'
          value='Dalton Goucher'
          // onClick={console.log('new')}
        />
      </Box>
    
      <Text>Denary Button Plain</Text>
      <Box my={'5px'}>
        <ButtonDenaryPlain
          value='Dalton Goucher'
          // onClick={console.log('new')}
        />
      </Box>

        {/* <Button onClick={() => navigate('/')} colorScheme='blue'>Home</Button> */}
        {/* <Button onClick={() => console.log('--- customersSearch:', customersSearch)}>DEBUG</Button> */}

        {/* <Button type="button"
          onClick={() => {
            reset();
            setEndDateDisabled(true)
            setDjtStartDate('')
            setDjtEndDate('')
            setClientSelected(null)
          }}>Reset Form</Button> */}

          <br/><br/>
        <Menu>
        
          <MenuButton
            px={4}
            py={2}
            transition='all 0.2s'
            borderRadius='md'
            borderWidth='1px'
            _hover={{ bg: 'gray.400' }}
            _expanded={{ bg: 'blue.400' }}
            _focus={{ boxShadow: 'outline' }}
          >
            Dev Menu <ChevronDownIcon />
          </MenuButton>
          <MenuList minWidth='240px'>
            <MenuGroup defaultValue='1' title='Forms'>
            <MenuItem value='x0' onClick={() => navigate('/app/upload-unit-images')}>Upload Unit Images</MenuItem>
            <MenuItem value='x1' onClick={() => navigate('/app/bulk-import')}>Bulk Import</MenuItem>
            <MenuItem value='f9' onClick={() => navigate('/app/teammemberhome')}>TM Dashboard Home</MenuItem>
            <MenuItem value='f44' onClick={() => navigate('/app/dashboardx')}>Dashboard Testing</MenuItem>
            <MenuItem value='qr2' onClick={() => navigate('/app/scan-qr-code-api')}>Scan QR Code API</MenuItem>
            <MenuDivider />
            <MenuItem value='f0' onClick={() => navigate('/app/layout/addteammember')}>Add Team Member</MenuItem>
            <MenuItem value='f8' onClick={() => navigate('/app/layout/existingteammemberform')}>Existing Team Member Form</MenuItem>
            <MenuItem value='f14' onClick={() => navigate('/app/assignedcredentialsform')}>Assigned Credentials Form</MenuItem>
            <MenuItem value='fx5' onClick={() => navigate('/divisionform')}>Division Form</MenuItem>
            <MenuItem value='f33' onClick={() => navigate('/statementsform')}>Statements Form</MenuItem>
            <MenuDivider />
            {/* <MenuItem value='f1' onClick={() => navigate('/credentialissuerform')}>Credential Issuer Form</MenuItem>
            <MenuItem value='f2' onClick={() => navigate('/credentialtypeform')}>Credential Type Form</MenuItem>
            <MenuItem value='f3' onClick={() => navigate('/credentialgroupform')}>Credential Group Form</MenuItem>
            <MenuItem value='f6' onClick={() => navigate('/divisioncredentialform')}>Edit/Add Credential Form</MenuItem>
            <MenuItem value='f17' onClick={() => navigate('/credentialsbyteammember')}>Credentials By Team Member Form</MenuItem>
            <MenuDivider />
            <MenuItem value='f4' onClick={() => navigate('/positionform')}>Position Form</MenuItem>
            <MenuItem value='f14' onClick={() => navigate('/contactform')}>Contact Form</MenuItem>
            <MenuItem value='f15' onClick={() => navigate('/unitform')}>Unit Form</MenuItem>
            <MenuItem value='f16' onClick={() => navigate('/unitmarriageform')}>Unit Marrieage Form</MenuItem>
            <MenuDivider />
            <MenuItem value='f5' onClick={() => navigate('/divisionform')}>Division Form</MenuItem>
            <MenuItem value='f7' onClick={() => navigate('/divisionrequiredcredentialsform')}>Division Required Credentials Form</MenuItem>
            <MenuItem value='f10' onClick={() => navigate('/mockcredentialprocess')}>Mock Credential Processing</MenuItem>
            <MenuDivider />
            <MenuItem value='f11' onClick={() => navigate('/requesttransfer')}>Initiate Transfer Request</MenuItem>
            <MenuItem value='f12' onClick={() => navigate('/transferrequests')}>Transfer Requests Form</MenuItem>
            <MenuDivider />
            <MenuItem value='f13' onClick={() => navigate('/statementsform')}>Statements Form</MenuItem>
            <MenuDivider />
            <MenuItem value='f18' onClick={() => navigate('/bulkcredentials')}>Bulk TeamMember Credentials</MenuItem>
            <MenuItem value='f19' onClick={() => navigate('/bulkunits')}>Bulk Units</MenuItem> */}
            <MenuDivider />
            {/* <MenuItem value='f20' onClick={() => navigate('/createdjtform')}>Create DJT</MenuItem> */}
            <MenuItem value='f20' onClick={() => navigate('/app/layout/createdjtformufa')}>Create DJT UFA</MenuItem>
            <MenuItem value='f21' onClick={() => navigate('/app/djtmain')}>DJT Main</MenuItem>
            <MenuItem value='f22' onClick={() => navigate('/app/viewdjt')}>View DJT</MenuItem>
            <MenuItem value='f23' onClick={() => navigate('/app/inspectunitform')}>Inspect Unit</MenuItem>
            <MenuItem value='f24' onClick={() => navigate('/app/createqbinvoicefromdjt')}>Create QB Invoice</MenuItem>
            <MenuItem value='f25' onClick={() => navigate('/app/createpdffromdjt')}>Create PDF From DJT</MenuItem>
            <MenuItem value='f26' onClick={() => navigate('/app/createpdffromtemplate')}>Create PDF From Template</MenuItem>
            <MenuItem value='f27' onClick={() => navigate('/app/approvedjt')}>Approve Individual DJTs</MenuItem>
            <MenuItem value='f14' onClick={() => navigate('/app/layout/contactform')}>Contact Form</MenuItem>
            <MenuDivider />
            <MenuItem value='f28' onClick={() => navigate('/app/bulkpubsubtopic')}>Bulk PubSub Topic</MenuItem>
            </MenuGroup>
            <MenuDivider />
            <MenuGroup defaultValue='1' title='-- Mutations -- DEV ONLY --'>
              {/* DISABLE FOR NOW - USE NEWER VERSION <MenuItem value='1' onClick={() => navigate('/createteammember')}>Create Team Member</MenuItem> */}
              {/* <MenuItem value='2' onClick={() => navigate('/createuserfull')}>Full Create Team Member</MenuItem> */}
              {/* <MenuItem value='4'>Create Division</MenuItem>
              <MenuItem value='5'>Create Labor Position</MenuItem>
              <MenuItem value='6'>Create Unit</MenuItem> */}
              <MenuItem value='7' onClick={() => navigate('/app/createcredential')}>Create Credential</MenuItem>
              {/* <MenuItem value='8'>Create Test Transaction</MenuItem>
              <MenuItem value='9'>Create Receipt Record</MenuItem>
              <MenuItem value='10'>Update Unit Position</MenuItem>
              <MenuItem value='11'>Update TM Position</MenuItem> */}
              <MenuItem value='12' onClick={() => navigate('/app/uploadavatar')}>Upload Avatar</MenuItem>
              <MenuItem value='13' onClick={() => navigate('/app/avatarupload')}>Avatar Upload</MenuItem>
            </MenuGroup>
            <MenuDivider />
            <MenuGroup defaultValue='1' title='-- Queries -- DEV ONLY --'>
              {/* <MenuItem value='1' onClick={() => navigate('/getpositions')}>Positions Dropdown</MenuItem>
              <MenuItem value='2'>List Position Data</MenuItem>
              <MenuItem value='3' onClick={() => navigate('/getdivisions')}>Divisions Dropdown</MenuItem>
              <MenuItem value='4'>List Division Data</MenuItem>
              <MenuItem value='5'>Get Unit by QR Scan</MenuItem>
              <MenuItem value='6'>Unit, TM Map</MenuItem>
              <MenuItem value='7' onClick={() => navigate('/getcredentialtypes')}>Credential Types Dropdown</MenuItem>
              <MenuItem value='8' onClick={() => navigate('/getcredentialissuers')}>Credential Issuers Dropdown</MenuItem>
              <MenuItem value='9' onClick={() => navigate('/getcognitousers')}>Get Cognito Users</MenuItem> */}
              
            </MenuGroup>
            {/* <MenuItem value='x' onClick={() => navigate('/reusableformtest')}>Reusable Form Components Test</MenuItem> */}
          </MenuList>
        </Menu>
        {/* <Button onClick={() => navigate('/getdivisions')}>Divisions</Button>
        <Button onClick={() => navigate('/getpositions')}>Positions</Button> */}
        {/* <Button onClick={() => navigate('/createuser')}>Create TM</Button> */}
        {/* <Button onClick={() => navigate('/geo')}>Geo</Button>
        <Button onClick={() => navigate('/qr')}>QR</Button> */}
        {/* <Button onClick={() => navigate('/getusers')}>Users</Button>
        <Button onClick={() => navigate('/userexists')}>Check Email Exists</Button>
        <Button onClick={() => navigate('/lambdagetusers')}>LambdaGetUsers</Button>
        <Button onClick={() => navigate('/lambdacreateuser')}>LambdaCreateUser</Button>
        <Button onClick={() => navigate('/lambdaaddusertogroup')}>LambdaAddUserTogroup</Button>
        <Button onClick={() => navigate('/lambdagetemployeesextended')}>LambdaGetEmployeesExtended</Button> */}
        <br/><br/>
        {route !== 'authenticated' ? (
          <Button onClick={() => navigate('/login')}>Login - {authStatus} - {route}</Button>
        ) : (
          <Button onClick={() => logOut()}>Logout - {authStatus} - {route}</Button>
        )}
      {/* <Heading level={1}>Default heading</Heading> */}
      <View data-html2canvas-ignore="true">
      {route === 'authenticated' ? 'group privelages: '+window.$user_groups : 'You are logged out, please login! '+authStatus+' : '+route}
      {authStatus === 'authenticated' ? ' authStatus is set' : ' authStatus is still not set! '+authStatus+' : '+route}
      </View>

      {/* This is the outlet for the nav dropdown dev menu */}
      <Outlet />

    </ModuleBox>
    {/* <Container variant='module'><Text>This is a second test container for targeting</Text>
      <Text>This is only used for testing</Text>
    </Container> */}

    {/* stacked button right side test */}
    {/* <HStack spacing={'0px'} alignItems={'start'}>
      <Box 
        // border='1px solid white'
        borderRadius='6px'
        padding='10px 20px 10px 20px'
        marginTop='25px'
        marginLeft='25px'
        height='90px'
        // minWidth='400px'
        // maxWidth='500px'
        bg='#1e1e1f'>

        <HStack 
          spacing={'0px'} 
          // border='1px solid red' 
          height='70px'>
          <Box 
            // border={'1px solid green'}
            mr={'10px'}
            ><Text textStyle='moduleaction'>DJT by Client:</Text></Box>
          <Box 
            w={'450px'}
            // border={'1px solid blue'}
            >
          <FormSelect
            placeholder='XXXXX'
            // key={`clientSelected__${clientSelected}`}
            // value={clientSelected || ''}
            isDisabled={false}
            optionsArray={
              customersSearch?.map((options) => ({ 
                label: options.label, value: options.value
              }))
            }
            onChange={onCustomerChanged}
            control={control}
            fieldname="selectCustomer"
            register={register}
          />
          </Box>
        </HStack>
        
      </Box>
      <VStack 
        spacing={'0px'}
        alignItems={'start'}>
        <Box><Button variant='stackedbytwotop' leftIcon={<FaRegCalendarAlt />} >Add New Unit</Button></Box>
        <Box><Button variant='stackedbytwobottom' leftIcon={<FaRegCalendarAlt />} >Move Unit to Scrapped or Sold</Button></Box>
      </VStack>
    </HStack> */}

    {/* search daily job tickets prototype */}
    
    
    </Container>
  );
}

// <Button colorScheme="red" leftIcon={<Icon color="#FFF" as={FaHome}/>} />

// https://github.com/aws-amplify/amplify-ui/issues/1977
// We've identified a bug where if you use Auth.* outside of the Authenticator, the authStatus and route may not be in sync.

