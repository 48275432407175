import { createSpkCapitalOneCreditCardTransactionReceipt } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from "aws-amplify/api";
const client = generateClient();

export const createCapitalOneReceipt = async (props) => {
  if (!props) return;

  console.log('createCapitalOneReceipt props: ', props);
  
  try {
    const gqlParams = { input: props };

    console.log('createCapitalOneReceipt gqlParams: ', gqlParams);
    
    const response = await client.graphql({
      query: createSpkCapitalOneCreditCardTransactionReceipt,
      variables: gqlParams
    });
    
    console.log('createCapitalOneReceipt response: ', response);
    
    const results = response.data.createSpkCapitalOneCreditCardTransactionReceiptTbl.id;
    console.log('createCapitalOneReceipt results: ', results);
    
    return results;
  } catch (err) {
    return ('error createCapitalOneReceipt: ', [err]);
  }
};

createCapitalOneReceipt.propTypes = {
  props: PropTypes.shape({
    allSort: PropTypes.string,
    id: PropTypes.string.isRequired,
    plaidCreditCardTransactionId: PropTypes.string,
    capitalOneInstantNotificationId: PropTypes.string,
    teamMemberId: PropTypes.string,
    divisionId: PropTypes.string,
    iftaFuelTaxTrackId: PropTypes.string,
    matchStatus: PropTypes.string,
    qbTransactionEntityId: PropTypes.string,
    isReceiptUploaded: PropTypes.bool,
    quickbooksExpenseId: PropTypes.string,
    clientId: PropTypes.string,
    expenseCategoryId: PropTypes.string,
    unitId: PropTypes.string,
    addedNotes: PropTypes.string,
    hasIssue: PropTypes.bool,
    issue: PropTypes.string,
  }).isRequired,
};


      

      