import { stripToNumbersAndDecimal } from '../functions/number.jsx'

export const pushStatementToQuickBooks = async (purchaseObject) => {
  const {
      files,
      token,
      identityId,
      qbenv,
      accountId,
      accountRefId,
      vendorId,
      txnAmount,
      expensCatId,
      classId,
      memo,
      txnDate,
      customerId,
  } = purchaseObject;

  // Don't stringify files again since it's already a string
  const params = new URLSearchParams({
      files, // Already a JSON string
      token,
      identityid: identityId,
      qbenv,
      accountId,
      accountRefId,
      vendorId,
      txnAmount: stripToNumbersAndDecimal(txnAmount),
      expensCatId,
      classId: classId.toString(), // Convert to string if it's a number
      memo,
      txnDate,
      customerId: customerId||'',
  });

  const url = `https://pix3sl32l626ltjbwmnqoek5ki0epgnz.lambda-url.us-east-1.on.aws/?${params}`;
  
  try {
      console.log('Request URL:', url); // For debugging
      console.log('Request params:', Object.fromEntries(params)); // For debugging

      const response = await fetch(url, {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/x-www-form-urlencoded',
              'Accept': 'application/json'
          }
      });

      if (!response.ok) {
          // Log the response details
          console.error('Response status:', response.status);
          console.error('Response headers:', Object.fromEntries(response.headers));
          const errorText = await response.text();
          console.error('Response body:', errorText);
          throw new Error(`HTTP error! status: ${response.status}`);
      }

      const dataJSON = await response.json();
      return dataJSON?.purchaseId;
  } catch (error) {
      console.error('Full error:', error);
      throw error;
  }
};