import { ButtonSenaryWithIconOnly } from "./ButtonSenaryWithIconOnly";
import { ButtonQuaternaryWithIcon } from "./ButtonQuaternaryWithIcon";
import { PropTypes } from 'prop-types';

export const ResponsiveCancelCloseButton = (props) => {
  const {
    onClick = () => {},
    iconSize = ["22px", "26px"],
    value = "Cancel",
    isMobile = false,
  } = props;

  if (isMobile) {
    return (
      <ButtonSenaryWithIconOnly
        name="closeCancelButton"
        icon="close"
        iconsize={iconSize[0]}
        onClick={onClick}
      />
    );
  } else {
    return (
      <ButtonQuaternaryWithIcon 
        name="closeCancelButton"
        iconsize={iconSize[1]}
        leftIcon="close"
        value={value}
        onClick={onClick}
      />
    );
  }
}

// Prop validation
ResponsiveCancelCloseButton.propTypes = {
  onClick: PropTypes.func, // onClick is a function
  iconSize: PropTypes.array, // iconSize is an array
  value: PropTypes.string, // value is a string
  isMobile: PropTypes.bool, // isMobile is a boolean
};