import { generateClient } from 'aws-amplify/api';
// import PropTypes from 'prop-types';
import { listAllDJTClientOptions } from '../../../graphql/custom_queries';

const client = generateClient();

export const fetchClientsForAllDivisions = async () => {
    try {
      const response = await client.graphql({
        query: listAllDJTClientOptions,
        variables: {
          allSort: "all",
          filter: { isActive: { eq: true } },
        },
      });

      const results = response.data.allDjtClients.items
        .sort((a, b) => (a.contactCompany > b.contactCompany ? 1 : -1))
        .map((data) => ({
          value: data.id,
          label: data.contactCompany,
          staleAfterDate: data.staleAfterDate,
          divisionId: data.divisionId,
          qbId: data.qbId,
          // value: data.id, label: data.contactCompany + ' - ' + data.contactName
        }));
      return results;
    } catch (err) {
      return 'error fetchClientsForAllDivisions: ' + err;
    }

};

// fetchAllContactCompanies.propTypes = {
//   divisionId: PropTypes.string.isRequired,
// };
