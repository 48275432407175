// import AWS from 'aws-sdk';
import { addQuickBooksVendor } from '../graphqlCompnents/Statement/addQuickBooksVendor';
import { fetchAllQuickBooksVendorNamesIds } from '../graphqlCompnents/Statement/fetchAllQuickBooksVendorNamesIds';
import { getVendors } from './getVendors';
import { createQBVendor } from '../QuickBooks/createQBVendor.jsx';

// const dynamodb = new AWS.DynamoDB();

// export const syncQBVendors = async (props) => {
//   const { token, environment } = props;
//   if (!token) {
//     console.warn('NO TOKEN in syncQBVendors');
//     return null;
//   }

//   // Function to fetch vendors with pagination from QuickBooks
//   const getVendorsFromQB = async (startPosition = 1) => {
//     const maxResults = 1000;
//     const response = await getVendors(token, environment, startPosition, maxResults);
//     const dataJSON = response;
//     console.log('dataJSON: ', dataJSON);

//     if (dataJSON.fault?.error?.length > 0) {
//       console.warn('GOT AN ERROR in syncQBVendors: ', dataJSON.fault?.error);
//       return null;
//     }

//     const vendors = dataJSON?.Vendor || [];
//     console.log('Vendors: ', vendors);

//     return vendors;
//   };

//   // Function to fetch all vendors from QuickBooks using pagination
//   const getAllVendors = async () => {
//     let allVendors = [];
//     let startPosition = 1;
//     let moreRecords = true;

//     while (moreRecords) {
//       // console.log('Fetching vendors from QuickBooks...');
//       const vendors = await getVendorsFromQB(startPosition);
//       if (vendors.length < 1000) {
//         moreRecords = false;
//       }
//       allVendors = allVendors.concat(vendors);
//       startPosition += 1000;
//     }

//     return allVendors;
//   };

//   const getCurrentDynamoVendors = async () => {
//     let currentVendors = [];
//     const vendors = await fetchAllQuickBooksVendorNamesIds({startsWith: '', limit: 5000, nextToken: ''});
//     // console.log('fetchAllQuickBooksVendorNamesIds: ', vendors);
//     // Map over the results to extract only the vendor IDs
//     const vendorIds = vendors.items.map(vendor => vendor.qbId);
//     // console.log('Vendor IDs: ', vendorIds);
//     currentVendors = currentVendors.concat(vendorIds);
//     // console.log('Current Vendors: ', currentVendors);
//     return currentVendors;
//   }

//   // Step 1: Fetch all vendors from QuickBooks
//   const qbVendors = await getAllVendors();
//   console.warn(' ----- qbVendors: ', qbVendors);

//   // Step 2: Fetch current vendors from DynamoDB using the index
//   const dynamoVendors = await getCurrentDynamoVendors();
//   console.warn(' ----- dynamoVendors: ', dynamoVendors);

//   // Step 3: Find new vendors to add to DynamoDB using the addQuickBooksVendor function
//   const newVendors = qbVendors.filter(vendor => !dynamoVendors.includes(vendor.Id));
//   console.warn(' ----- newVendors: ', newVendors);

//   // Step 4: Add new vendors using the addQuickBooksVendor function
//   for (const vendor of newVendors) {
//     try {
//       const vendorProps = {
//         id: vendor.Id,
//         name: vendor.DisplayName,
//       };
//       await addQuickBooksVendor(vendorProps); // Use the existing function to add vendor
//       console.log(`Successfully added vendor ${vendor.DisplayName} to DynamoDB using GraphQL.`);
//     } catch (err) {
//       console.error(`Failed to add vendor ${vendor.DisplayName}: `, err);
//     }
//   }

//   console.log('Sync completed successfully!');
//   return newVendors;
// };

// export const syncQBVendors = async (props) => {
//   const { token, environment } = props;

//   if (!token) {
//     console.warn('No token provided in syncQBVendors');
//     return null;
//   }

//   // Fetch vendors from QuickBooks in paginated chunks
//   const getVendorsFromQB = async (startPosition = 1, maxResults = 1000) => {
//     try {
//       const response = await getVendors(token, environment, startPosition, maxResults);
//       const vendors = response?.Vendor || [];
//       console.log(`Fetched ${vendors.length} vendors from QuickBooks starting at position ${startPosition}`);
//       return vendors;
//     } catch (error) {
//       console.error('Error fetching vendors from QuickBooks:', error);
//       throw error;
//     }
//   };

//   // Fetch all vendors from QuickBooks
//   const getAllQBVendors = async () => {
//     let allVendors = [];
//     let startPosition = 1;
//     const maxResults = 1000;
//     let moreRecords = true;

//     while (moreRecords) {
//       const vendors = await getVendorsFromQB(startPosition, maxResults);
//       allVendors = allVendors.concat(vendors);

//       if (vendors.length < maxResults) {
//         moreRecords = false;
//       } else {
//         startPosition += maxResults;
//       }
//     }

//     console.log(`Total vendors fetched from QuickBooks: ${allVendors.length}`);
//     return allVendors.map(vendor => ({ id: vendor.Id, name: vendor.DisplayName }));
//   };

//   // Fetch vendor IDs from DynamoDB
//   const getDynamoVendorIds = async () => {
//     try {
//       const result = await fetchAllQuickBooksVendorNamesIds({ startsWith: '', limit: 5000, nextToken: '' });
//       const vendorIds = result.items.map(vendor => vendor.qbId);
//       console.log(`Fetched ${vendorIds.length} vendor IDs from DynamoDB`);
//       return new Set(vendorIds); // Use a Set for fast lookup
//     } catch (error) {
//       console.error('Error fetching vendors from DynamoDB:', error);
//       throw error;
//     }
//   };

//   // Sync vendors
//   try {
//     // Step 1: Fetch vendors from QuickBooks
//     const qbVendors = await getAllQBVendors();

//     // Step 2: Fetch vendor IDs from DynamoDB
//     const dynamoVendorIds = await getDynamoVendorIds();

//     // Step 3: Identify new vendors to add
//     const newVendors = qbVendors.filter(vendor => !dynamoVendorIds.has(vendor.id));
//     console.log(`Found ${newVendors.length} new vendors to add`);

//     // Step 4: Add new vendors to DynamoDB
//     for (const vendor of newVendors) {
//       try {
//         await addQuickBooksVendor(vendor); // Use GraphQL to add vendor
//         console.log(`Added vendor ${vendor.name} (ID: ${vendor.id}) to DynamoDB`);
//       } catch (error) {
//         console.error(`Failed to add vendor ${vendor.name} (ID: ${vendor.id}):`, error);
//       }
//     }

//     console.log('Vendor sync completed successfully');
//     return newVendors; // Return the new vendors for reference
//   } catch (error) {
//     console.error('Error during vendor sync:', error);
//     throw error; // Propagate error for the caller to handle
//   }
// };

export const syncQBVendors = async (props) => {
  const { token, environment } = props;

  if (!token) {
    console.warn('No token provided in syncQBVendors');
    return null;
  }

  // Fetch vendors from QuickBooks
  const getVendorsFromQB = async (startPosition = 1, maxResults = 1000) => {
    const response = await getVendors(token, environment, startPosition, maxResults);
    const vendors = response?.Vendor || [];
    return vendors.map(vendor => ({ id: vendor.Id, name: vendor.DisplayName }));
  };

  const getAllQBVendors = async () => {
    let allVendors = [];
    let startPosition = 1;
    let moreRecords = true;

    while (moreRecords) {
      const vendors = await getVendorsFromQB(startPosition);
      // console.warn('qb vendors: ', vendors);
      allVendors = allVendors.concat(vendors);

      if (vendors.length < 1000) {
        moreRecords = false;
      } else {
        startPosition += 1000;
      }
    }

    return allVendors;
  };

  // Fetch vendors from DynamoDB
  const getAllDynamoVendors = async () => {
    const result = await fetchAllQuickBooksVendorNamesIds({ startsWith: '', limit: 5000, nextToken: '' });
    return result.items.map(vendor => ({ id: vendor.qbId, name: vendor.name }));
  };

  // Add a vendor to QuickBooks
  const addVendorToQB = async (vendor) => {
    try {
      // console.log(' ----- vendor: ', vendor);
      // console.log(' ----- token: ', token);
      const response = await createQBVendor(token, vendor.name, environment);
      // const response = await createVendorInQuickBooks(token, environment, vendor); // Implement createVendorInQuickBooks
      // console.log(`Successfully added vendor to QBO: ${vendor.name}`);
      return response;
    } catch (error) {
      console.error(`Failed to add vendor to QBO: ${vendor.name}`, error);
    }
  };

  // Sync logic
  try {
    // Step 1: Fetch all vendors
    const qbVendors = await getAllQBVendors();
    const dynamoVendors = await getAllDynamoVendors();

    console.log(`Fetched ${qbVendors.length} vendors from QBO`);
    console.log(`Fetched ${dynamoVendors.length} vendors from DynamoDB`);

    // Step 2: Identify missing vendors in DynamoDB
    const dynamoVendorIds = new Set(dynamoVendors.map(vendor => vendor.id));
    const newVendorsForDynamo = qbVendors.filter(vendor => !dynamoVendorIds.has(vendor.id));

    // Step 3: Add missing vendors to DynamoDB
    for (const vendor of newVendorsForDynamo) {
      try {
        await addQuickBooksVendor({ id: vendor.id, name: vendor.name });
        console.log(`Added vendor to DynamoDB: ${vendor.name}`);
      } catch (error) {
        console.error(`Failed to add vendor to DynamoDB: ${vendor.name}`, error);
      }
    }

    // Step 4: Identify missing vendors in QBO
    const qbVendorIds = new Set(qbVendors.map(vendor => vendor.id));
    const newVendorsForQB = dynamoVendors.filter(vendor => !qbVendorIds.has(vendor.id));

    // Step 5: Add missing vendors to QBO
    for (const vendor of newVendorsForQB) {
      const results = await addVendorToQB(vendor);
      console.log(' ----- results: ', results);
    }

    console.log('Two-way sync completed successfully');
    return { newVendorsForDynamo, newVendorsForQB }; // Return results for reference
  } catch (error) {
    console.error('Error during vendor sync:', error);
    throw error;
  }
};