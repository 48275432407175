import { deactivateIFTAFuelTaxTracking } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const updateIFTAActiveStatus = async (props) => {
  if (!props) return;
  
  console.log('updateIFTAActiveStatus props: ', props);
  
  try {
    const response = await client.graphql({
      query: deactivateIFTAFuelTaxTracking,
      variables: { input: props },
    });

    console.log('updateIFTAActiveStatus response: ', response);

    const results = response.data.updateSpkIFTAFuelTaxTrackingTbl.id;
    console.log('updateIFTAActiveStatus results: ', results);

    return results;
  } catch (err) {
    return ('error updateIFTAActiveStatus: ', [err]);
  }
};

updateIFTAActiveStatus.propTypes = {
  props: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
  }).isRequired,
};
