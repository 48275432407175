import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Center,
  Box,
  Text,
  Image,
  IconButton,
  HStack,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
// import { ButtonQuaternaryWithIcon } from "../Form/Button/ButtonQuaternaryWithIcon";
import PropTypes from 'prop-types';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { AvailableIcons } from "../Icons/AvailableIcons";

export const ReceiptImageViewerModal = (props) => {
  const {
    isModalOpen,
    onModalClose,
    images = [],
    transaction = {},
  } = props;

  console.warn('transaction: ', transaction)

  const [currentIndex, setCurrentIndex] = useState(0);
  const [ headerText, setHeaderText ] = useState('');

  useEffect(() => {
    if(transaction?.date){
      
      const headerDate = transaction?.date;
      const headerSupplier = transaction?.supplier;
      const headerAmount = transaction?.amount;
      const header = <Box
        display="flex"           // Enables flexbox layout
        flexDirection="row"      // Default for flexbox, but explicitly sets children in a horizontal line
        alignItems="center"      // Vertically centers the text (optional, depending on your needs)
        justifyContent="space-between" // Spreads out items (optional, adjust as needed)
      >
        <Text as="span" textStyle="heading-3" paddingRight="20px">
          {headerDate}
        </Text>
        <Text as="span" textStyle="heading-3">
          {headerSupplier}
        </Text>
        <Text as="span" textStyle="heading-3" paddingLeft="20px">
          {headerAmount}
        </Text>
      </Box>
      setHeaderText(header);
    }
  },[transaction])

  // Navigate to the previous image
  const handlePrevImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  // Navigate to the next image
  const handleNextImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Set the current image when a thumbnail is clicked
  const handleThumbnailClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <Modal isOpen={isModalOpen} onClose={onModalClose}>
      <ModalOverlay />
      {/* <ModalContent bg="black" position="relative"> */}
      <ModalContent
        bg="black"
        position="relative"
        maxWidth="80vw"           // Set width to 80% of viewport
        maxHeight="80vh"          // Set height to 80% of viewport
        margin="auto"             // Center modal on screen
      >
        
        {/* Custom close button */}
        <Box position="absolute" top="25px" right="25px">
          {/* <ButtonQuaternaryWithIcon 
            name="closeModal"
            iconsize="26px"
            leftIcon="close"
            value="Close"
            onClick={onModalClose}
          /> */}
          <IconButton 
            // h={'36px'}
            // w={'36px'}
            variant='iconOnlyTertiary' 
            icon={<AvailableIcons boxSize={'26px'} iconRef={'close'} />}
            onClick={onModalClose}
          />
        </Box>

        <ModalBody p="25px" pt="50px">
          <Box position="relative" bg="black" p="25px" borderRadius="md" mt="50px">
            
            {/* Left navigation button */}
            <IconButton
              icon={<ArrowBackIcon />}
              onClick={handlePrevImage}
              position="absolute"
              left="5px"
              top="50%"
              transform="translateY(-50%)"
              zIndex="1"
              aria-label="Previous image"
              variant="ghost"
            />
            
            {/* Display current image */}
            {/* Display current image with size constraints */}
            {images[currentIndex] && (
              <Box
                display="flex"         // Enables flexbox layout
                flexDirection="column" // Stacks children vertically
                alignItems="center"    // Centers items horizontally
                justifyContent="center"// Centers items vertically
              >
               {headerText}
                <Image
                  src={images[currentIndex]}
                  alt="Receipt Image"
                  maxWidth="100%"         // Constrain width to modal width
                  maxHeight="80vh"         // Constrain height to 80% of viewport height
                  objectFit="contain"      // Scale down if needed, but keep original aspect ratio
                  m="auto"                 // Center image horizontally
                  paddingTop='10px'
                  // paddingX={'10px'}
                />
              </Box>
            )}
            
            {/* Right navigation button */}
            <IconButton
              icon={<ArrowForwardIcon />}
              onClick={handleNextImage}
              position="absolute"
              right="5px"
              top="50%"
              transform="translateY(-50%)"
              zIndex="1"
              aria-label="Next image"
              variant="ghost"
            />
          </Box>
        </ModalBody>
        
        <ModalFooter>
          <Center width="100%">
            <HStack
              spacing="10px"
              overflowX="auto"  // Enable horizontal scrolling if too many thumbnails
              padding="10px"
            >
              {images.map((thumbnail, index) => (
                <Box
                  key={index}
                  border={index === currentIndex ? "2px solid white" : "none"} // Highlight selected thumbnail
                  borderRadius="md"
                  cursor="pointer"
                  onClick={() => handleThumbnailClick(index)}
                >
                  <Image
                    src={thumbnail}
                    alt={`Thumbnail ${index + 1}`}
                    boxSize="50px"
                    objectFit="cover"
                    borderRadius="md"
                  />
                </Box>
              ))}
            </HStack>
          </Center>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

ReceiptImageViewerModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  images: PropTypes.arrayOf(PropTypes.string),
  transaction: PropTypes.object,
};