/* eslint-disable react/prop-types */
import { useEffect, useState, useContext, Fragment, useRef } from 'react';
import { useForm } from 'react-hook-form';
import {
  Container,
  Box,
  Text,
  Flex,
  // Spacer,
  Spinner,
  Center,
  Table,
  Tbody,
  Tr,
  Td,
  // HStack,
  useDisclosure,
  // useBreakpointValue,
  TableContainer,
  Tabs, 
  TabList, 
  TabPanels, 
  Tab, 
  TabPanel,
  // Badge,
  Button,
  // Image,
  IconButton,
  Stack,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  HStack,
  Checkbox,
  Modal, 
  ModalOverlay, 
  ModalContent, 
  ModalHeader, 
  ModalBody, 
  ModalFooter
} from "@chakra-ui/react";

// screensize
import { useScreenSize } from '../custom-hooks/useScreenSize.jsx';
// uuid
import { v4 as uuid } from 'uuid';

// globals
import { AppContext } from '../AppContext.jsx';

// generic functions
import { spellOutDate, awsFormatUnknownDate,
  // todaysDateYMD, getPreviousDate 
} from '../functions/dateTime.jsx';
import { truncateString } from '../functions/strings.jsx';

// icons
import { AvailableIcons } from '../Icons/AvailableIcons.jsx';

// decorations
import { Divider } from '../Divider.jsx';

// Buttons
import { ButtonUndenaryPlain } from '../Form/Button/ButtonUndenaryPlain.jsx';
import { ButtonTridenaryPlain } from '../Form/Button/ButtonTridenaryPlain.jsx';
import { ButtonTridenaryPlainRed } from '../Form/Button/ButtonTridenaryPlainRed.jsx';
import { ButtonSecondaryPlain } from '../Form/Button/ButtonSecondaryPlain.jsx';
import { ButtonOctonaryRed } from '../Form/Button/ButtonOctonaryRed.jsx';
import { ButtonPrimaryPlain } from '../Form/Button/ButtonPrimaryPlain.jsx';
import { ButtonSecondaryWithIcon } from '../Form/Button/ButtonSecondaryWithIcon.jsx';

// form components
import { TextInputGroup } from '../Form/Input/TextInputGroup.jsx';

// structural components
import { ModuleBox } from '../Structural/ModuleBox.jsx';
import { ModuleDrawer } from '../Structural/ModuleDrawer.jsx';

// imported components
import { QuickCameraUploadStatement } from './QuickCameraUploadStatement.jsx';
import { AttachReceipt } from './AttachReceipt.jsx';
import { ViewReceipt } from './ViewReceipt.jsx';

// graphql
import { fetchAccountsByTeamMember } from '../graphqlCompnents/Statement/fetchAccountsByTeamMember.jsx';
import { fetchInstantNotificationsByLastFour } from '../graphqlCompnents/Statement/fetchInstantNotificationsByLastFour.jsx';
import { fetchPlaidCreditCardTransactionByAccountNumberByDate } from '../graphqlCompnents/Statement/fetchPlaidCreditCardTransactionByAccountNumberByDate.jsx';
import { fetchUploadedReceiptsByTeamMemberByStatus } from '../graphqlCompnents/Statement/fetchUploadedReceiptsByTeamMemberByStatus.jsx';
import { updateIFTAActiveStatus } from '../graphqlCompnents/Statement/updateIFTAActiveStatus.jsx';
import { updateCreditCardTransactionReceipt } from '../graphqlCompnents/Statement/updateCreditCardTransactionReceipt.jsx';

// custom hooks
import { getSignedUrlForFile } from '../custom-hooks/useSignedUrlForFile.jsx';

export const UserReceipts = () => {
  // if phone, show the small version
  const screenSize = useScreenSize();
  const currentScreenWidth = screenSize.width
  // console.log('currentScreenWidth: ', currentScreenWidth)

  const { 
    isOpen: isAttachUploadModalOpen, 
    onOpen: openAttachUploadModal, 
    onClose: closeAttachUploadModal 
  } = useDisclosure();
  const [showAttachUploadModal, setShowAttachUploadModal] = useState(true);

  const { 
    isOpen: isReceiptInfoModalOpen, 
    onOpen: openReceiptInfoModal, 
    onClose: closeReceiptInfoModal 
  } = useDisclosure();
  const [showReceiptInfoModal, setShowReceiptInfoModal] = useState(false);

  const confirmDeleteCancelRef = useRef()
  const { 
    isOpen: isConfirmDeleteReceiptOpen, 
    onOpen: onConfirmDeleteReceiptOpen, 
    onClose: onConfirmDeleteReceiptClose 
  } = useDisclosure()

  // TEMPORARY - ONLY FOR TESTING - REMOVE FOR PRODUCTION
  // useEffect(() => {
  //   localStorage.setItem('showAttachUploadModal', 'true');
  //   localStorage.setItem('showReceiptInfoModal', 'true');
  // },[])
  
  const [doNotShowAttachUploadAgain, setDoNotShowAttachUploadAgain] = useState(false);
  const [doNotShowReceiptInfoAgain, setDoNotShowReceiptInfoAgain] = useState(false);
  const [modalAttachUploadCheckboxDisabled, setModalAttachUploadCheckboxDisabled] = useState(false);
  const [modalReceiptInfoCheckboxDisabled, setModalReceiptInfoCheckboxDisabled] = useState(false);

  useEffect(() => {
    // Check localStorage for the user's preference
    const attachUploadPreference = localStorage.getItem('showAttachUploadModal');
    console.log('attach upload preference: ', attachUploadPreference);
    setShowAttachUploadModal(attachUploadPreference !== 'false'); // Default to showing the modal if not set
    console.log('showing: ', attachUploadPreference !== 'false')
    const receiptInfoPreference = localStorage.getItem('showReceiptInfoModal');
    console.log('show receipt info preference: ', receiptInfoPreference);
    setShowReceiptInfoModal(receiptInfoPreference !== 'false'); // Default to showing the modal if not set
    console.log('showing: ', receiptInfoPreference !== 'false')
  }, []);

  const handleDoNotShowReceiptInfoAgain = (e) => {
    const isChecked = e.target.checked;
    setDoNotShowReceiptInfoAgain(isChecked);

    // Update localStorage based on the checkbox state
    if (isChecked) {
        setShowReceiptInfoModal(false);
        localStorage.setItem('showReceiptInfoModal', 'false'); // Persist preference
    } else {
        localStorage.setItem('showReceiptInfoModal', 'true'); // Reset preference
    }
  };

  const handleDoNotShowAttachUploadAgain = (e) => {
    const isChecked = e.target.checked;
    setDoNotShowAttachUploadAgain(isChecked);

    if (isChecked) {
      setShowAttachUploadModal(false);
      localStorage.setItem('showAttachUploadModal', 'false'); // Persist preference in localStorage
    } else {
      localStorage.setItem('showAttachUploadModal', 'true'); // Reset preference
    }
  };

  // useEffect(() => {
  //   if (showReceiptInfoModal) {
  //     setModalReceiptInfoCheckboxDisabled(true);
  //     const timer = setTimeout(() => setModalReceiptInfoCheckboxDisabled(false), 3500);

  //     openReceiptInfoModal();

  //     return () => clearTimeout(timer); // Cleanup timer on unmount
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[showReceiptInfoModal])

  const { 
    isOpen: isCameraUploadStatementOpen, 
    onOpen: onCameraUploadStatementOpen, 
    onClose: uploadReceiptClose 
  } = useDisclosure()

  const { 
    isOpen: isAttachReceiptOpen, 
    onOpen: onAttachReceiptOpen, 
    onClose: attachReceiptClose 
  } = useDisclosure()

  const { 
    isOpen: isViewReceiptOpen, 
    onOpen: onViewReceiptOpen, 
    onClose: viewReceiptClose 
  } = useDisclosure()

  // const { 
  //   isOpen: isReceiptInfoOpen, 
  //   onOpen: onReceiptInfoOpen, 
  //   onClose: receiptInfoClose 
  // } = useDisclosure()

  // const { 
  //   isOpen: isStatementDetailOpen , 
  //   onOpen: onStatementDetailOpen, 
  //   onClose: statementDetailClose 
  // } = useDisclosure()

  const onCameraUploadStatementClose = () => {
    // Clean up any existing video tracks first
    if (navigator.mediaDevices) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(stream => {
          stream.getTracks().forEach(track => track.stop());
        })
        .catch(() => {
          // Ignore errors here as we're just cleaning up
        });
    }

    setSelectedTransactionId(null)
    setSelectedActionType(null)
    handleRefresh()
    uploadReceiptClose()
  }
  // const onStatementDetailClose = () => {
  //   handleRefresh()
  //   // statementDetailClose()
  // }
  const onAttachReceiptClose = () => {
    setSelectedTransactionId(null)
    setSelectedActionType(null)
    handleRefresh()
    attachReceiptClose()
  }

  const onViewReceiptClose = () => {
    setSelectedTransactionId(null)
    setSelectedActionType(null)
    handleRefresh()
    viewReceiptClose()
  }

  const handleConfirmDeleteReceiptClosed = () => {
    onConfirmDeleteReceiptClose();
    setHasConfirmedDelete(true);
  }

  const [ hasConfirmedDelete , setHasConfirmedDelete ] = useState(false)
  const [ attachReceiptParams, setAttachReceiptParams ] = useState(null)
  const [ viewReceiptParams, setViewReceiptParams ] = useState(null)
  const [ editReceiptParams, setEditReceiptParams ] = useState(null)
  const [deleteReceiptParams, setDeleteReceiptParams] = useState(null)

  useEffect(() => {
    if (hasConfirmedDelete===true && deleteReceiptParams!==null) {
      console.log('deleteReceiptParams: ', deleteReceiptParams)
      handleDeleteReceipt(deleteReceiptParams)
      setHasConfirmedDelete(false)
      setDeleteReceiptParams(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[hasConfirmedDelete, deleteReceiptParams])

  const handleDeleteReceipt = async (props) => {
    console.log('DELETING SAVED RECEIPT WITH THESE PROPS: ', props)
    if (props.iftaFuelTaxTrackId) {
      const iftaProps = {
        id: props.iftaFuelTaxTrackId,
        isActive: false
      }
      console.log('IFTA PROPS: ', iftaProps)
      const iftaResult = await updateIFTAActiveStatus(iftaProps)
      console.log('IFTA RESULT: ', iftaResult)
    }
    const updateReceiptProps = {
      id: props.id,
      matchStatus: 'deleted'
    }
    console.log('updateReceiptProps: ', updateReceiptProps)
    const updateReceiptResults = await updateCreditCardTransactionReceipt(updateReceiptProps);
    console.log('updateReceiptResults: ', updateReceiptResults);
    handleRefresh()
  }


  const [ refreshIndex, setRefreshIndex ] = useState(0);
  const handleRefresh = () => {

    setSearchInputText('')
    setNextToken('')
    setNextNextToken('')
    
    setRefreshIndex(prev => prev + 1); // Increment to trigger useEffect
    setValue('searchInput', '')
    console.log(`Tab clicked, refreshing...`);
  };

  const [ selectedTransactionId, setSelectedTransactionId ] = useState(null)
  const [ selectedActionType, setSelectedActionType ] = useState(null)
  
  const handleOpenDrawer = (type, id, attachParams) => {
    console.log('type: ', type)
    console.log('id: ', id)
    console.log('attachParams: ', attachParams)

    // Clean up any existing video tracks first
    if (navigator.mediaDevices) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(stream => {
          stream.getTracks().forEach(track => track.stop());
        })
        .catch(() => {
          // Ignore errors here as we're just cleaning up
        });
    }

    setSelectedTransactionId(id)
    setSelectedActionType(type)
    // selectedActionType==='attach' && setAttachReceiptParams(attachParams);
    // selectedActionType==='view' && setViewReceiptParams(attachParams);
    // selectedActionType==='delete' && setDeleteReceiptParams(attachParams);
  }

  useEffect(() => {
    if (selectedTransactionId && selectedActionType) {
      console.log('selectedTransactionId: ', selectedTransactionId)
      console.log('selectedActionType: ', selectedActionType)

      if (
        selectedActionType==='upload-with-transaction' 
        || selectedActionType==='upload' 
        || selectedActionType==='preemptive' 
        || selectedActionType==='fix-issue'
      ) {
        onCameraUploadStatementOpen()
      }

      if (selectedActionType==='attach' && attachReceiptParams) {
        console.warn('attachReceiptParams are now: ', attachReceiptParams)
        onAttachReceiptOpen();
        // setStatementId(id, onStatementDetailOpen())
        // setSelectedStatement(statementObject, onStatementDetailOpen())
      }

      if (selectedActionType==='view' && viewReceiptParams) {
        console.log('OPENING DRAWER FOR VIEW RECEIPT')
        onViewReceiptOpen();
      }

      if (selectedActionType==='delete' && deleteReceiptParams) {
        console.log('OPENING MODAL FOR DELETE RECEIPT')
        onConfirmDeleteReceiptOpen();
      }

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedTransactionId, selectedActionType, 
    attachReceiptParams, viewReceiptParams, deleteReceiptParams])

  const { register, handleSubmit, setValue, formState: { errors } } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    // reValidateMode: 'onBlur',
    defaultValues: {}
  });
  
  const onError = (errors, e) => console.log(errors, e);

  // grab global context values
  const { store } = useContext(AppContext);

  // const currentTeamMemberName = store?.userData?.name
  // set the current users team member id
  const currentTeamMemberId = store?.userData?.id
  // console.warn(' ----- currentTeamMemberId: ', currentTeamMemberId)
  const currentUserIsGlobalAdmin = store?.userData?.isGlobalAdmin||false

  // QuickBooks environment
  // const qbEnv = store?.qbEnvironment;
  const plaidEnv = 'production';

  const userIsMobile = store?.userIsMobile;

  // const [ userData, SetUserData ] = useState({})
  // useEffect(() => {
  //   if (currentTeamMemberName && currentTeamMemberId && currentUserIsGlobalAdmin) {
  //     SetUserData({
  //       name: currentTeamMemberName,
  //       id: currentTeamMemberId,
  //       isGlobalAdmin: currentUserIsGlobalAdmin
  //     })
  //   }
  // },[currentTeamMemberName, currentTeamMemberId, currentUserIsGlobalAdmin]);

  // const [ showResultsSpinner, setShowResultsSpinner ] = useState(false)  

  const [searchInputText, setSearchInputText] = useState('');
  const searchIconCancel = (
    <IconButton
      isDisabled={searchInputText?.length === 0}
      variant="withIconQuaternary"
      bg="var(--dark-module-bg)"
      h="24px"
      icon={
        <AvailableIcons
          boxSize="24px"
          iconRef="searchReset"
          color="var(--dark-text-grey-1)"
        />
      }
      onClick={() => {
        handleRefresh(); // Trigger the same refresh logic
      }}
    />
  );

  const searchIcon = <AvailableIcons boxSize={'24px'} iconRef={'searchMagnify'} color={'var(--dark-text-grey-1)'} />

  const showResultsSpinner = false;
  // const [ currentTab, setCurrentTab ] = useState(0)
  const [ currentTabName, setCurrentTabName ] = useState('Ready')
  const [ previousTabName, setPreviousTabName ] = useState('Ready')

  const getActiveTabName = (index) => {
    console.log('tab index: ', index);
    const tabMap = [
      'Ready',
      'My Receipts',
      'Archive',
      ...(showIssuesTab ? ['Returned'] : [])
    ];
    return tabMap[index] || null;
  };

  const handleTabChange = async (index) => {

    const activeTab = getActiveTabName(index);
    console.log(`Active Tab: ${activeTab}`);

    setSearchInputText('');
    handleRefresh();

    // switch (activeTab) {
    //   case 'Ready':
    //     // Handle Ready logic
    //     break;
    //   case 'My Receipts':
    //     // Handle My Receipts logic
    //     break;
    //   case 'Archive':
    //     // Handle Archive logic
    //     break;
    //   case 'Returned':
    //     // Handle Returned logic
    //     break;
    //   default:
    //     console.error('Invalid tab selected');
    // }
    
    // setCurrentTab(tab)
    // let currentTabNames = ['Ready', 'My Receipts', 'Archive', 'Pending Approvals']
    // setCurrentTabName(currentTabNames[tab])
    setCurrentTabName(activeTab)
    // console.log('currentTabName: ', currentTabNames[tab])

    // if (currentTabNames[tab] !== previousTabName) {
    if (activeTab !== previousTabName) {
        // console.log('Tab changed, resetting...')
      setNextToken('')
      setNextNextToken('')
      setPreviousTabName(activeTab)
    }
  }

  // const searchHeaderTextClass = useBreakpointValue({
  //   sm: 'dark-sfpro-heading-2',
  //   md: 'dark-sfpro-heading-2',
  //   lg: 'dark-sfpro-heading-1',
  //   xl: 'dark-sfpro-heading-1',
  //   '2xl': 'dark-sfpro-heading-1'
  // })

  const [ userAccounts, setUserAccounts ] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      const accounts = await fetchAccountsByTeamMember(plaidEnv, currentTeamMemberId);
      console.warn('cards of team member: ', accounts);
      setUserAccounts(accounts);
    }
    if (currentTeamMemberId) {
      fetchData()
    }
  },[currentTeamMemberId, plaidEnv])

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [userTransactions, setUserTransactions] = useState([]);
  
  // need a way to fetch transactions from posted transactions that never had an instant notification
  // fetchPlaidCreditCardTransactionByAccountNumberByDate = async (lastFour, startDate, limit = 300)
  const [postedPendingMatchTransactions, setPostedPendingMatchTransactions] = useState([]);

  const [ transactionsWithIssue, setFetchedTransactionsWithIssue ] = useState([]);
  useEffect(() => {
    const fetchPostedTransactionsByAccount = async (accounts, limit) => {
      try {
        const promises = accounts.map(async (account) => {
          const lastFour = account.cardLastFour;

          // Dynamically calculate start date
          const startDate = new Date();
          startDate.setDate(startDate.getDate() - 30);

          // Ensure the start date is no earlier than 2025-01-02
          const minStartDate = new Date('2025-01-02');
          const finalStartDate = startDate < minStartDate ? minStartDate : startDate;

          // Format the final start date
          const formattedDate = finalStartDate.toISOString().split('T')[0];

          // const startDate = new Date();
          // startDate.setDate(startDate.getDate() - 30);
          // const formattedDate = startDate.toISOString().split('T')[0];
          
          const transactions = await fetchPlaidCreditCardTransactionByAccountNumberByDate(
            lastFour,
            formattedDate,
            limit
          );

          console.warn('all transactions: ', transactions)
  
          // Separate transactions with issues
          const transactionsWithIssues = transactions.filter(
            (transaction) =>
              transaction?.capitalOneCreditCardTransactionReceipt?.hasIssue === true
          );
  
          // Filter out and return only records where status = "pending_match"
          // const unsubmittedTransactions = transactions.filter(
          //   (transaction) =>
          //     transaction?.capitalOneCreditCardTransactionReceiptId === null &&
          //     transaction.status === "pending_match"
          // );
  
          const unsubmittedTransactions = transactions.filter(
            (transaction) =>
              (!transaction.capitalOneCreditCardTransactionReceiptId || 
                transaction.capitalOneCreditCardTransactionReceiptId === "") &&
              transaction.status === "pending_match"
          );

          return { unsubmittedTransactions, transactionsWithIssues };
        });
  
        const results = await Promise.allSettled(promises);
  
        // Extract fulfilled results
        const transactions = results
          .filter((result) => result.status === "fulfilled" && result.value)
          .reduce(
            (acc, result) => {
              acc.unsubmitted.push(...result.value.unsubmittedTransactions);
              acc.issues.push(...result.value.transactionsWithIssues);
              return acc;
            },
            { unsubmitted: [], issues: [] }
          );
  
        return transactions;
      } catch (error) {
        console.error("Error fetching transactions:", error);
        return { unsubmitted: [], issues: [] };
      }
    };
  
    // DEVELOPER NOTE: This is where we fetch unsubmitted transactions, these get compared to uploaded receipts with notification
    if (userAccounts?.length > 0) {
      (async () => {
        const { unsubmitted, issues } = await fetchPostedTransactionsByAccount(
          userAccounts,
          300
        );
        console.warn("Fetched transactions with issues: ", issues);
        console.warn("Unsubmitted transactions: ", unsubmitted);
        setPostedPendingMatchTransactions(unsubmitted); // Update state with filtered transactions
        setFetchedTransactionsWithIssue(issues); // Update state with transactions with issues
      })();
    }
  }, [userAccounts, refreshIndex]);

  const [ uploadedReceipts, setUploadedReceipts ] = useState([]);
  const [ savedReceipts, setSavedReceipts ] = useState([]);
  const [ archivedReceipts, setArchivedReceipts ] = useState([]);

  useEffect(() => {
    const fetchUploadedReceipts = async () => {
      try {
        // Fetch results for both statuses concurrently
        const [preemptiveResults, 
          withNotificationResults, 
          readyForApprovalResults
        ] = await Promise.all([
          fetchUploadedReceiptsByTeamMemberByStatus({
            status: 'preemptive',
            teamMemberId: currentTeamMemberId,
            limit: 250,
          }),
          fetchUploadedReceiptsByTeamMemberByStatus({
            status: 'with-notification',
            teamMemberId: currentTeamMemberId,
            limit: 250,
          }),
          fetchUploadedReceiptsByTeamMemberByStatus({
            status: 'ready-for-approval',
            teamMemberId: currentTeamMemberId,
            limit: 250,
          }),
        ]);
  
        // Merge both results into a single array
        const combinedResults = [
          ...(preemptiveResults || []), 
          ...(withNotificationResults || []), 
          ...(readyForApprovalResults || [])
        ];
  
        console.info('COMBINED RESULTS: ', combinedResults);

        // DEVELOPER NOTE: take these results (with notification only for now) and see what matches to transactions.
  
        // Iterate through combined results to get signed URLs for each item
        const resultsWithSignedUrls = await Promise.all(
          combinedResults.map(async (item) => {
            const uploadedDocuments = item?.uploadedDocuments?.items || [];
            // Fetch signed URLs for each document in parallel
            const signedUrls = await Promise.all(
              uploadedDocuments.map(async (doc) => {
                const { identityId, key, bucket } = doc?.uploadedDocument || {};
                if (identityId && key && bucket) {
                  try {
                    const url =
                      (await getSignedUrlForFile({
                        bucketName: bucket,
                        identityId: identityId,
                        fileName: key,
                        fileSecurity: 'protected',
                      })) || null;
                    return url;
                  } catch (error) {
                    console.error(`Error fetching signed URL for document ${key}:`, error);
                    return null; // Skip if the URL fetch fails
                  }
                }
                return null; // Skip if any value is missing
              })
            );
  
            // Attach signed URLs to the current item, filtering out any null results
            return {
              ...item,
              signedUrls: signedUrls.filter(Boolean),
            };
          })
        );
  
        // Store processed data with signed URLs in state
        console.info('resultsWithSignedUrls:', resultsWithSignedUrls);
        const saved = resultsWithSignedUrls.filter(
          (result) => result.matchStatus === 'preemptive' || result.matchStatus === 'with-notification'
        );
        const archived = resultsWithSignedUrls.filter(
          (result) => result.matchStatus === 'ready-for-approval'
        );
        setArchivedReceipts(archived);
        setSavedReceipts(saved);
        console.warn(' ----- saved: ', saved)
        console.warn(' ----- archived: ', archived)
        setUploadedReceipts(resultsWithSignedUrls);
      } catch (error) {
        console.error('Error fetching uploaded receipts or signed URLs:', error);
      }
    };
  
    if (currentTeamMemberId) {
      fetchUploadedReceipts();
    }
  }, [currentTeamMemberId, refreshIndex]);

  useEffect(() => {
    const fetchTransactions = async () => {
      if (!userAccounts || userAccounts.length === 0) return;
  
      try {
        // Filter out accounts where cardLastFour is null or undefined
        const validAccounts = userAccounts.filter(account => account.cardLastFour);
  
        const transactionPromises = validAccounts.map(async (account) => {
          const cardNumber = account.cardLastFour;
  
          const [trueTransactions, falseTransactions] = await Promise.allSettled([
            fetchInstantNotificationsByLastFour(cardNumber, true, 500),
            fetchInstantNotificationsByLastFour(cardNumber, false, 500),
          ]);
  
          const transactions = [];
          if (trueTransactions.status === 'fulfilled' && trueTransactions.value) {
            transactions.push(...trueTransactions.value);
          }
          if (falseTransactions.status === 'fulfilled' && falseTransactions.value) {
            transactions.push(...falseTransactions.value);
          }
  
          return transactions;
        });
  
        const allTransactionResults = await Promise.allSettled(transactionPromises);

        console.warn('allTransactionResults: ', allTransactionResults);
  
        const successfulTransactions = allTransactionResults
          .filter(result => result.status === 'fulfilled' && result.value.length > 0)
          .map(result => result.value);
  
        const flattenedTransactions = successfulTransactions.flat();

        // Separate transactions into two groups: those with isSubmitted === null and others
        // const [nullSubmitted, otherSubmitted] = flattenedTransactions.reduce(
        //   (acc, transaction) => {
        //     transaction.isSubmitted === null ? acc[0].push(transaction) : acc[1].push(transaction);
        //     return acc;
        //   },
        //   [[], []]
        // );
  
        // // Sort both groups by purchaseDate in ascending order
        // nullSubmitted.sort((a, b) => new Date(a.purchaseDate) - new Date(b.purchaseDate));
        // otherSubmitted.sort((a, b) => new Date(a.purchaseDate) - new Date(b.purchaseDate));
  
        // console.warn(' ----- nullSubmitted: ', nullSubmitted);
        // console.warn(' ----- otherSubmitted: ', otherSubmitted);
        // // Concatenate the two groups, with nullSubmitted transactions first
        // let sortedTransactions = [...nullSubmitted, ...otherSubmitted];

        let sortedTransactions = flattenedTransactions.filter(
          transaction => transaction.isSubmitted === true
        )

        console.warn('sortedTransactions: ', sortedTransactions);
        console.warn('postedPendingMatchTransactions: ', postedPendingMatchTransactions);
  
        const unmatchedTransactions = postedPendingMatchTransactions.filter(
          (pendingTransaction) =>
            !sortedTransactions.some(
              (transaction) =>
                transaction.id === pendingTransaction.id ||
                transaction.accountNumber === pendingTransaction.accountNumber
            )
        );
  
        console.warn('Unmatched Transactions:', unmatchedTransactions);
  
        // Add unmatched transactions to sortedTransactions
        sortedTransactions = [...sortedTransactions, ...unmatchedTransactions];
  
        console.log('Updated Sorted Transactions:', sortedTransactions);
  
        // Update the state with the updated transactions
        setUserTransactions(sortedTransactions);
  
        setIsDataLoaded(true);
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };
  
    fetchTransactions();
  }, [userAccounts, refreshIndex, postedPendingMatchTransactions]);

  const [ nextToken, setNextToken ] = useState('')
  const [ nextNextToken, setNextNextToken ] = useState('')
  // const searchLimit = 25;
  // const [ statements, setStatements ] = useState([])

  const handleScroll = (e) => {
    if (!nextToken) return;
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight) {
      console.log('reached the bottom of the scrollbox: ', nextToken);
      setNextNextToken(nextToken)
    }
  };

  useEffect(() => {
    if (currentTeamMemberId) {
      if (currentUserIsGlobalAdmin) {
        if (currentTabName === 'Ready' || currentTabName === 'Archive') {
          
          // fetchAndProcessStatements(() => fetchStatementsByStatusTab(currentTabName, searchLimit, nextNextToken), setStatements, setFilteredData);
          
        }
      } else {

        // no longer using statements as the precursor to statement tracking
        // fetchAndProcessStatements(() => fetchStatementsByTeamMember(currentTeamMemberId), setStatements, setFilteredData);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTeamMemberId, refreshIndex, currentUserIsGlobalAdmin, nextNextToken, currentTabName]);

  // this needs to take in ALL statements from both inbox and archive
  // then flag the archive version as already saved so they can delete the new one.
  // const groupStatementsByKeys = (statements) => {
  //   console.info('statements:', statements);
  //   const grouped = new Map();
  
  //   statements.forEach(statement => {
  //     const key = `${statement.vendorName}-${statement.purchaseAmount}-${statement.purchaseDate}`;
  //     if (!grouped.has(key)) {
  //       grouped.set(key, []);
  //     }
  //     grouped.get(key).push(statement);
  //   });
  
  //   return grouped;
  // };

  const handleReceiptAction = (id, status, attachParams='') => {
    console.warn('status: ', status);
    console.warn('handleReceiptAction: ', attachParams);

    switch (status) {
      case 'upload':
        handleSavedAction(id, status, attachParams);
        break;
      case 'upload-with-transaction':
        setAttachReceiptParams(attachParams);
        handleUploadWithTransaction(id, status, attachParams);
        break;
        case 'attach':
        setAttachReceiptParams(attachParams);
        handleSavedAction(id, status, attachParams);
        break;
      case 'fix-issue':
        setAttachReceiptParams(attachParams);
        handleSavedAction(id, status, attachParams);
        break;
      default:
        handleSavedAction(id, status, attachParams);
        break;
    }

    // if (status==='attach') {
    //   setAttachReceiptParams(attachParams);
    //   handleOpenDrawer('attach', id, attachParams);
    // } else {
    //   handleOpenDrawer('upload', id, attachParams);
    // }
  };

  const handleSavedAction = (id, status, attachParams) => {
    console.log('id: ', id)
    console.log('status: ', status)
    console.log('attachParams: ', attachParams)
    setDeleteReceiptParams(attachParams);
    setViewReceiptParams(attachParams);
    setEditReceiptParams(attachParams);
    handleOpenDrawer(status, id, attachParams);
  };

  const handleUploadWithTransaction = (id, status, attachParams) => {
    handleOpenDrawer(status, id, attachParams);
  }

  const handlePreemptiveUpload = () => {
    // open modal if they have not checked to do not show again.
    setAttachReceiptParams(null);
    if (showAttachUploadModal) {
      setModalAttachUploadCheckboxDisabled(true);
      const timer = setTimeout(() => setModalAttachUploadCheckboxDisabled(false), 3500);
      openAttachUploadModal(); // Show modal first if the user hasn't disabled it
      return () => clearTimeout(timer); // Cleanup timer on unmount
    } else {
      let id = uuid();
      handleOpenDrawer('preemptive', id);
    }
  };

  const handleAttachUploadContinue = () => {
    if (doNotShowAttachUploadAgain) {
      localStorage.setItem('showAttachUploadModal', 'false'); // Persist preference in localStorage
    }
    closeAttachUploadModal();
    let id = uuid();
    handleOpenDrawer('preemptive', id);
  };

  const handleReceiptInfoContinue = () => {
    if (doNotShowReceiptInfoAgain) {
      localStorage.setItem('showReceiptInfoModal', 'false'); // Persist preference in localStorage
    }
    closeReceiptInfoModal();
    // let id = uuid();
    // handleOpenDrawer('preemptive', id);
  };

  const [showIssuesTab, setShowIssuesTab] = useState(false);
  const [issueCounts, setIssueCounts] = useState({
    hasIssueCount: 0,
    hasFixedIssueCount: 0,
  });

  useEffect(() => {
    // This useEffect calculates issue counts whenever transactions change
    let newHasIssueCount = 0;
    let newHasFixedIssueCount = 0;
    // let totalActiveIssues = 0;

    console.log('transactionsWithIssue: ', transactionsWithIssue)

    transactionsWithIssue?.forEach((item) => {
      console.log('issues item: ', item);
      const receiptHasIssue = (item?.capitalOneCreditCardTransactionReceipt?.hasIssue && item?.status === 'fix-issue') || false;
      if (receiptHasIssue) {
        newHasIssueCount++;
      }

      // const receiptHasFixedIssue = item?.status;
      // if (receiptHasFixedIssue) {
      //   newHasFixedIssueCount++;
      // }

      // totalActiveIssues = newHasIssueCount - newHasFixedIssueCount;
    });

    setIssueCounts({
      hasIssueCount: newHasIssueCount,
      hasFixedIssueCount: newHasFixedIssueCount,
    });

    // Determine if the Issues tab should be shown
    // setShowIssuesTab(newHasIssueCount > 0 || newHasFixedIssueCount > 0);
    setShowIssuesTab(newHasIssueCount > 0);
  }, [transactionsWithIssue]);

  const generateRows = (transactions, isMobile, tab) => {
    // console.log('TABNAME: ', tab)
    // console.log('TRANSACTIONS: ', transactions)
    if (!transactions || transactions.length === 0) {
      return <Fragment><Tr><Td><p>No transactions available.</p></Td></Tr></Fragment>
    }
  
    const rows = [];
    // Map through each transaction and return a simple row with the ID
    transactions.forEach((item, index) => {
      // console.info('item: ', item);
      // if status: "has-receipt" then show in Archived as submitted but waiting for approval
      // is status: "published" then show in Archived as approved/published
      const transactionHasReceipt = item?.status === 'has-receipt' ? true : false;
      const transactionIsPublished = item?.status === 'published' ? true : false;

      // THis transaction didn't get matched when the instant notification came in but is getting matched now.
      // const isManualMatch = item?.status === 'pending_match';
      // if (item.purchaseAmount === '59.86') console.log('item: ', item)
      const receiptHasIssue = item?.capitalOneCreditCardTransactionReceipt?.hasIssue||false;      
      const receiptHasFixedIssue = item?.capitalOneCreditCardTransactionReceipt?.matchStatus==='fixed-issue' ? true : false;
      const receiptIssue = item?.capitalOneCreditCardTransactionReceipt?.issue||'';
      const isSubmitted = (item.isSubmitted===true) ? true : false;
      // let showActionButtons = true
      let actionButtonType = '';
      let merchantName = '';
      let store = item.store||'';
      let transactionDate = '';
      let purchaseAmount = '';
      let hasInstantNotification = true;

      if (tab==='Ready') {
        // console.info('item:', item);
        actionButtonType = 'ready';
        // showActionButtons = true;
        // Filter out rows so we only show transactions ready to be attached or uploaded
        if (isSubmitted || receiptHasIssue || receiptHasFixedIssue || item.status==="has-receipt") {
            return; // Skip this iteration if any of these conditions are true
        } else {
          // console.info('item:', item);
          hasInstantNotification = false;
          transactionDate = item.date;
          purchaseAmount = item.amount;
          if (isMobile) {
            merchantName = truncateString(item.merchantEntityName, 15)
          } else {
            if (userAccounts.length > 0) {
              merchantName = `${item.merchantEntityName} ${item.accountNumber}`
            } else {
              merchantName = `${item.merchantEntityName}`
            }
          }
        }
      }

      if (tab==='Returned') {
        actionButtonType = 'returned';
        if (receiptHasIssue && !receiptHasFixedIssue) {
          // console.log('SHOWING THIS ITEM: ', item)
          transactionDate = item.date;
          purchaseAmount = item.amount;
          if (isMobile) {
            merchantName = truncateString(item.merchantEntityName, 15)
          } else {
            if (userAccounts.length > 0) {
              merchantName = `${item.merchantEntityName} ${item.accountNumber}`
            } else {
              merchantName = `${item.merchantEntityName}`
            }
          }
        } else {
          return; // Skip this iteration if any of these conditions are true
        }
      }

      const formattedPurchaseAmount = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      }).format(parseFloat(purchaseAmount))
      
      const attachParams = {
        id: item.id,
        name: item.merchantEntityName||item.purchaseMerchant||'Unknown',
        amount: purchaseAmount||'Unknown',
        date: spellOutDate((awsFormatUnknownDate(transactionDate || '')), 'apple-fy')||'Unknown',
        hasInstantNotification: hasInstantNotification,
        // notes, url
      }

      if (actionButtonType === 'archive') {
        // console.info('item: ', item);
        attachParams.url = item.signedUrls?.[0];
        attachParams.notes = item?.addedNotes;
      }

      // console.log('attachParams: ', attachParams)
      if (receiptHasIssue) {
        attachParams.capitalOneCreditCardTransactionReceiptId = item.capitalOneCreditCardTransactionReceiptId;
        attachParams.issue = receiptIssue;
        attachParams.hasIssue = true;
        attachParams.expenseCategoryId = item.capitalOneCreditCardTransactionReceipt?.expenseCategoryId;
        attachParams.divisionId = item.capitalOneCreditCardTransactionReceipt?.divisionId;
        attachParams.clientId = item.capitalOneCreditCardTransactionReceipt?.clientId;
        attachParams.unitId = item.capitalOneCreditCardTransactionReceipt?.unitId;
        attachParams.addedNotes = item.capitalOneCreditCardTransactionReceipt?.addedNotes;
        attachParams.iftaFuelTaxTrackId = item.capitalOneCreditCardTransactionReceipt?.iftaFuelTaxTrackId;
        attachParams.units = item.capitalOneCreditCardTransactionReceipt?.units?.items;
        attachParams.accountNumber = item?.accountNumber;
        attachParams.gallons = item.capitalOneCreditCardTransactionReceipt?.iftaFuelTaxTrack?.gallons;
        attachParams.odometer = item.capitalOneCreditCardTransactionReceipt?.iftaFuelTaxTrack?.odometer;
        attachParams.stateTwoChar = item.capitalOneCreditCardTransactionReceipt?.iftaFuelTaxTrack?.stateTwoChar;
        attachParams.unitId = item.capitalOneCreditCardTransactionReceipt?.iftaFuelTaxTrack?.unitId;

        // console.log('item: ', item?.capitalOneCreditCardTransactionReceipt)
      }

      rows.push(
        <Fragment key={`frag_${index}_${item.id}`}>
          {/* Table Row */}
          <Tr key={`tr_${item.id}`} >
            <Td>
              <Box py={'15px'}>
                {/* Vendor, Date, and Amount Section */}
                <Flex direction="row" justify="space-between" align="flex-start">
                  <Box maxW="70%" wordBreak="break-word">
                    <Text as="span" textStyle="text-6" display="block" mt={1} isTruncated>
                      {merchantName}
                    </Text>
                    <Text as="span" textStyle="text-6" display="block" mt={1} isTruncated>
                      {store}
                    </Text>
                    <Text as="span" textStyle="text-6" display="block" mt={1}>
                      {(transactionDate) ? spellOutDate((awsFormatUnknownDate(transactionDate || '')), 'apple-fy') : 'Empty Date'}
                    </Text>
                    <Text as="span" textStyle="text-6" display="block" mt={1}>
                      {formattedPurchaseAmount}
                    </Text>
                  </Box>
                  
                  <Box>
                    {actionButtonType==='ready' && (
                      <ButtonTridenaryPlain
                        w={'100px'}
                        type='button'
                        name='attachReceipt'
                        value='Attach'
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleReceiptAction(item.id, 'attach', {
                            id: item.id,
                            name: item.merchantEntityName||item.purchaseMerchant||'Unknown',
                            amount: purchaseAmount||'Unknown',
                            date: spellOutDate((awsFormatUnknownDate(transactionDate || '')), 'apple-fy')||'Unknown',
                            hasInstantNotification: hasInstantNotification,
                          });
                        }}
                        isDisabled={savedReceipts.length===0}
                      />
                    )}

                    {actionButtonType==='returned' && (
                      <ButtonTridenaryPlain
                        w={'100px'}
                        type='button'
                        name='fixReceipt'
                        value='Edit'
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleReceiptAction(item.id, 'fix-issue', attachParams);
                        }}
                        isDisabled={false}
                      />
                    )}
                  </Box>
                </Flex>
              </Box>
            </Td>
          </Tr>
      
          {/* Divider */}
          <Tr key={`divider_${item.id}`}>
            <Td borderBottom={'1px solid var(--dark-module-divider)'}></Td>
          </Tr>
        </Fragment>
      );
    });

    return rows;
  };

  const generateSavedRows = (uploadedReceipts, isMobile) => {
    if (!uploadedReceipts || uploadedReceipts.length === 0) {
      return <Fragment><Tr><Td><p>No saved receipts.</p></Td></Tr></Fragment>
    }

    // filter out any records with matchStatus === 'ready-for-approval"
    const rows = [];
    uploadedReceipts.forEach((item, index) => {
      // console.info('item: ', item);
      const savedId = item.id;
      const savedImgUrl = item.signedUrls[0] || '';
      const dateSplit = item.createdAt.split('T')[0]||'';
      const savedDate = spellOutDate((awsFormatUnknownDate(dateSplit)), 'apple-fy')||'';
      let savedNotes = '';
      if (isMobile) {
        savedNotes = truncateString(item?.addedNotes, 25)||'';
      } else {
        savedNotes = item?.addedNotes || '';
      }
      const uploadedVia = (item?.matchStatus === "preemptive") ? 'Manually' : 'Instant Notification';

      let purchaseMerchant = null;
      let purchaseAmount = null;
      if (item.capitalOneInstantNotification) {
        purchaseMerchant = item.capitalOneInstantNotification?.purchaseMerchant||null;
        purchaseAmount = item.capitalOneInstantNotification?.purchaseAmount||null;
      }
      const savedParams = {
        id: savedId,
        url: savedImgUrl,
        date: savedDate,
        notes: item?.addedNotes||'',
      }

      if (item.iftaFuelTaxTrackId) {
        savedParams.iftaFuelTaxTrackId = item.iftaFuelTaxTrackId;
      }

      rows.push(
        <Fragment key={`frag_${index}_${item.id}`}>
          <Tr key={`tr_${item.id}`}>
            <Td>
              <Box py={'15px'}>
                {/* Date, View, Delete */}
                <Flex direction="row" justify="space-between" align="flex-start">
                  <Box maxW="70%" wordBreak="break-word">
                      {/* <Text as="span" textStyle="text-6" display="block" mt={1} isTruncated> */}
                      <Text as="span" textStyle='heading-3'>{spellOutDate((awsFormatUnknownDate(item.createdAt.split('T')[0] || '')), 'apple-fy')}</Text>

                      <Text
                        as="span"
                        textStyle="text-6"
                        display="block"
                        mt={1}
                      >
                        Notes: {savedNotes.slice(0, 18)}...
                      </Text>
                      <Text as="span" textStyle="text-6" display="block" mt={1}>Uploaded: {uploadedVia}</Text>
                    {purchaseMerchant && 
                      <Text as="span" textStyle="text-6" display="block" mt={1}>Merchant: {purchaseMerchant}</Text>
                    }
                    {purchaseAmount && 
                      <Text as="span" textStyle="text-6" display="block" mt={1}>Amount: {purchaseAmount}</Text>
                    }
                  </Box>

                  <Box>
                    <Flex direction="column" align="flex-end">
                      <ButtonUndenaryPlain
                        w={'100px'}
                        type='button'
                        name='viewReceipt'
                        value='View'
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleSavedAction(item.id, 'view', savedParams);
                          // handleReceiptAction(item.id, 'attach', attachParams); // Call function to handle the click and pass the ID
                        }}
                      />
                      <Box mt={'15px'}></Box>
                      <ButtonTridenaryPlainRed
                        w={'100px'}
                        type='button'
                        name='deleteReceipt'
                        value='Delete'
                        onClick={(e) => {
                          e.preventDefault();  // Prevent default browser behavior
                          e.stopPropagation(); // Stop event propagation to parent elements
                          handleSavedAction(item.id, 'delete', savedParams);
                          // handleReceiptAction(item.id, 'upload', attachParams); // Call function to handle the click and pass the ID
                        }}
                      />
                    </Flex>
                  </Box>
                </Flex>
              </Box>
            </Td>
          </Tr>

          {/* Divider */}
          <Tr key={`divider_${item.id}`}>
            <Td borderBottom={'1px solid var(--dark-module-divider)'}></Td>
          </Tr> 
        </Fragment>
      );
    });

    return rows;
  };

  const generateArchiveRows = (uploadedReceipts, isMobile) => {
    if (!uploadedReceipts || uploadedReceipts.length === 0) {
      return <Fragment><Tr><Td><p>No archived receipts.</p></Td></Tr></Fragment>
    }

    // on delete make sure ifta tax tracking is deactivated and uploaded documents are deactivated.

    const rows = [];
    // Map through each transaction and return a simple row with the ID
    uploadedReceipts.forEach((item, index) => {


      // transactionDate = item.purchaseDate;
      // purchaseAmount = item.purchaseAmount;
      // if (isMobile) {
      //   merchantName = truncateString(item.purchaseMerchant, 15)
      // } else {
      //   merchantName = `${item.purchaseMerchant} ${item.lastFour}`
      // }
      // console.log('transactionHasReceipt: ', transactionHasReceipt)
      // console.log('transactionIsPublished: ', transactionIsPublished)

      const currentStatus = (item.matchStatus === "ready-for-approval") ? 'Pending' : 'Approved';

      // console.info('item: ', item);
      // const archivedId = item.id;
      const archivedImgUrl = item.signedUrls[0] || '';
      // const dateSplit = item.createdAt.split('T')[0]||'';
      // const archivedDate = spellOutDate((awsFormatUnknownDate(dateSplit)), 'apple-fy')||'';
      let archivedNotes = '';
      if (isMobile) {
        archivedNotes = truncateString(item?.addedNotes, 25)||'';
      } else {
        archivedNotes = item?.addedNotes || '';
      }

      let purchaseMerchant = null;
      let purchaseAmount = null;
      if (item.capitalOneInstantNotification) {
        purchaseMerchant = item.capitalOneInstantNotification?.purchaseMerchant||null;
        purchaseAmount = item.capitalOneInstantNotification?.purchaseAmount||null;
      }
      if (item.plaidCreditCardTransaction) {
        purchaseMerchant = item.plaidCreditCardTransaction?.merchantEntityName||null;
        purchaseAmount = item.plaidCreditCardTransaction?.amount||null;
      }


      rows.push(
        <Fragment key={`frag_${index}_${item.id}`}>
          <Tr key={`tr_${item.id}`}>
            <Td>
              <Box py={'15px'}>
                {/* Date, View, Delete */}
                <Flex justify="space-between" alignItems="flex-start">
                  <Box pb={'25px'}>
                    <Box pb={'15px'}>
                      <Text as="span" textStyle='heading-3'>{spellOutDate((awsFormatUnknownDate(item.createdAt.split('T')[0] || '')), 'apple-fy')}</Text>
                    </Box>
                    {/* <Box w={'33%'}>
                      <Image w={'33%'} src={item.signedUrls[0]} alt="Uploaded Receipt" />
                    </Box> */}
                    <Box w={'100%'}>
                      <Text as="span" textStyle="text-6" display="block" mt={1} whiteSpace="normal" wordWrap="break-word">Notes: {archivedNotes}</Text>
                    </Box>
                    {/* <Box w={'100%'}>
                      <Text as="span" textStyle="text-6" display="block" mt={1}>Uploaded: {uploadedVia}</Text>
                    </Box> */}
                    {purchaseMerchant && <Box w={'100%'}>
                      <Text as="span" textStyle="text-6" display="block" mt={1}>Merchant: {purchaseMerchant}</Text>
                    </Box>}
                    {purchaseAmount && <Box w={'100%'}>
                      <Text as="span" textStyle="text-6" display="block" mt={1}>Amount: {purchaseAmount}</Text>
                    </Box>}
                    <Box w={'100%'}>
                      <Text as="span" textStyle="text-6" display="block" mt={1}>Status: {currentStatus}</Text>
                    </Box>
                  </Box>

                  <Box>
                    <ButtonUndenaryPlain
                      w={'100px'}
                      type='button'
                      name='fixReceipt'
                      value='View'
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        // handleReceiptAction(item.id, 'view-receipt', attachParams);
                        handleSavedAction(item.id, 'view', item);
                      }}
                      isDisabled={uploadedReceipts.length===0}
                      // isDisabled={true} //disabled for now, need more information to develop this out
                    />
                    </Box>
                </Flex>
              </Box>
            </Td>
          </Tr>

          {/* Divider */}
          <Tr key={`divider_${item.id}`}>
            <Td borderBottom={'1px solid var(--dark-module-divider)'}></Td>
          </Tr> 
        </Fragment>
      );
    });

    return rows;
  };

  const formSubmit = async () => {
    // console.log('event: ', event)
    // if (!data.selectDivision) return
    // console.log('Form Values:', data);
  }

  const handleInputChange = (e) => {
    (e.target.value.length === 0) && handleRefresh(); // Reset the data if the input is empty
    setSearchInputText(e.target.value||'');
    filterTransactionData(e); // Call the filtering function
  };

  const filterTransactionData = (event) => {
    if (!event) {
      return userTransactions;
    }
    
    const searchTerm = event?.target?.value
    const lowercasedTerm = searchTerm.toLowerCase();
    const tempData =  userTransactions.filter(item =>
      item?.purchaseMerchant?.toLowerCase().includes(lowercasedTerm) ||
      item?.merchantEntityName?.toLowerCase().includes(lowercasedTerm) ||
      item?.purchaseAmount?.toLowerCase().includes(lowercasedTerm) || 
      item?.lastFour?.toLowerCase().includes(lowercasedTerm)
    );
    console.log("tempData: ", tempData)
    setUserTransactions(tempData);
  };

  // let leftPadding = (currentScreenWidth>460) ? '15px' : '5px';
  let mobilePaddingX = '15px';

  // Calculate number of transactions where isSubmitted is not true
  // const unsubmittedCount = userTransactions.filter(item => item.isSubmitted !== true).length;
  // console.log('unsubmittedCount: ', unsubmittedCount)
  let adjustedBoxWidth = (currentScreenWidth>460) ? currentScreenWidth-220 : '100%';

  const [confirmIsChecked, setConfirmIsChecked] = useState(false);
  const handleCheckboxToggle = async (state) => {
    if (state === true) {
      setConfirmIsChecked(true);
    } else {
      setConfirmIsChecked(false);
    }
  };

  return (
    <>
      <Container maxW="1600" mb={'50px'} as="form" onSubmit={handleSubmit(formSubmit, onError)}>
        {userIsMobile ?
          <>
            <Box mt={'25px'} w={adjustedBoxWidth} paddingBottom={'25px'}>
              <Box w={'100%'} position="relative">
                {/* Top Box (aligned left) */}
                <Box px={mobilePaddingX} w={'100%'} mt={'5px'} position="relative">
                  <TextInputGroup
                    register={register}
                    errors={errors}
                    fieldname="searchInput"
                    fieldlabel="Search Transactions"
                    fieldvalue={searchInputText || ''} // This dynamically controls the input's value
                    prettyname="Search"
                    placeholder="Search vendor or amount"
                    onChange={handleInputChange}
                    leftButtonElement={searchIcon}
                    rightButtonElement={searchIconCancel}
                    buttonDisabled={!searchInputText || searchInputText.trim().length === 0} // Disable button only when input is empty
                    buttonElementOnClick={() => {
                      console.log('Button clicked');
                      handleRefresh();
                    }}
                  />
                </Box> 
              </Box>
            </Box>

            <Box w={adjustedBoxWidth}>
              <Tabs 
                w={adjustedBoxWidth}
                borderColor='var(--dark-module-divider)' 
                variant='enclosed' 
                colorScheme='spikedarktabs' 
                onChange={(index) => handleTabChange(index)}>
                <TabList> 
                  <Tab><Text fontSize="var(--font-size-sm)">{(showIssuesTab) ? 'Ready' : `Ready ${postedPendingMatchTransactions?.length||0}`} </Text></Tab>
                  <Tab><Text fontSize="var(--font-size-sm)">{(showIssuesTab) ? 'Receipts' : `My Receipts ${savedReceipts.length}`}</Text></Tab>
                  <Tab><Text fontSize="var(--font-size-sm)">Archive</Text></Tab>
                  {showIssuesTab && <Tab><Box mr={'2px'}><AvailableIcons boxSize={'13px'} iconRef={'fillcircle'} color={'var(--alert-red)'} /></Box><Text fontSize="var(--font-size-sm)">{`Returned`}</Text></Tab>}

                  {/* <Tab><Text fontSize="var(--font-size-sm)">Ready {postedPendingMatchTransactions?.length||0}</Text></Tab>
                  {savedReceipts?.length>0 && <Tab><Text fontSize="var(--font-size-sm)">Receipts {savedReceipts.length}</Text></Tab>}
                  <Tab><Text fontSize="var(--font-size-sm)">Archive</Text></Tab>
                  {showIssuesTab && <Tab><Box mr={'2px'}><AvailableIcons boxSize={'13px'} iconRef={'fillcircle'} color={'var(--alert-red)'} /></Box><Text fontSize="var(--font-size-sm)">{`Returned`}</Text></Tab>} */}
                </TabList>
                <TabPanels>

                  {/* Ready */}
                  <TabPanel>
                    <TableContainer>
                      <Table variant={'compact'} size={'compact'} >
                        <Tbody>
                          {/* {(showResultsSpinner===true) && <Tr><Td py={'25px'} borderBottom={'1px solid var(--dark-module-divider)'}><Center><Spinner color='var(--progress-bar-primary)' /></Center></Td></Tr>}
                          {(userTransactions?.length===0 && showResultsSpinner===false) && <Tr><Td py='6px' colSpan={4}><Center>No results found</Center></Td></Tr>} */}
                          
                          {isDataLoaded && generateRows(userTransactions, true, currentTabName)}
                          
                          <Tr><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
                        </Tbody> 
                      </Table>
                    </TableContainer>
                  </TabPanel>

                  {/* My Receipts */}
                  <TabPanel>
                    <Box
                      display="flex"
                      flexDirection="column" // Stack elements vertically
                      alignItems="flex-start" // Align everything to the left
                      marginLeft="15px"
                      my="25px"
                    >
                      <Text textStyle="text-6" mb="10px"> 
                        Add receipt if no notification was received.
                      </Text>
                      <ButtonSecondaryWithIcon
                        type="button"
                        name="manualReceipt"
                        value="Add receipt"
                        onClick={handlePreemptiveUpload}
                        leftIcon="plus"
                        iconsize="20px"
                      />
                    </Box>
                    <Divider mt='25px' mb='25px' h={'1px'} bg={'var(--dark-module-divider)'} />

                    <TableContainer>
                      <Table variant={'compact'} size={'compact'} >
                        <Tbody>

                          {isDataLoaded && generateSavedRows(savedReceipts, true)}

                          <Tr><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </TabPanel>

                  {/* Archive */}
                  <TabPanel>
                    <TableContainer>
                      <Table variant={'compact'} size={'compact'} >
                        <Tbody>
                          {/* {(showResultsSpinner===true) && <Tr><Td py={'25px'} borderBottom={'1px solid var(--dark-module-divider)'}><Center><Spinner color='var(--progress-bar-primary)' /></Center></Td></Tr>}
                          {(userTransactions?.length===0 && showResultsSpinner===false) && <Tr><Td py='6px' colSpan={4}><Center>No results found</Center></Td></Tr>} */}
                          
                          {/* {isDataLoaded && generateRows(userTransactions, true, currentTabName)} */}
                          {isDataLoaded && generateArchiveRows(archivedReceipts, true)}
                          
                          <Tr><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
                        </Tbody> 
                      </Table>
                    </TableContainer>
                  </TabPanel>

                  {/* Returned */}
                  {showIssuesTab && <TabPanel>
                    <TableContainer>
                      <Table variant={'compact'} size={'compact'} >
                        <Tbody>
                          {/* {(showResultsSpinner===true) && <Tr><Td py={'25px'} borderBottom={'1px solid var(--dark-module-divider)'}><Center><Spinner color='var(--progress-bar-primary)' /></Center></Td></Tr>}
                          {(userTransactions?.length===0 && showResultsSpinner===false) && <Tr><Td py='6px' colSpan={4}><Center>No results found</Center></Td></Tr>} */}
                          
                          {isDataLoaded && generateRows(transactionsWithIssue, true, currentTabName)}
                          
                          <Tr><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
                        </Tbody> 
                      </Table>
                    </TableContainer>
                  </TabPanel>}

                </TabPanels>
              </Tabs>
            </Box>
          </>
        :
          <> 
            <ModuleBox>
              <Box w={'100%'} position="relative">
                {/* Top Box (aligned left) */}
                <Box w={'400px'} position="relative">
                  <TextInputGroup
                    register={register}
                    errors={errors}
                    fieldname="searchInput"
                    fieldlabel="Search Transactions"
                    fieldvalue={searchInputText} // This dynamically controls the input's value
                    prettyname="Search"
                    placeholder="Search vendor or amount"
                    onChange={handleInputChange}
                    leftButtonElement={searchIcon}
                    rightButtonElement={searchIconCancel}
                    buttonDisabled={!searchInputText || searchInputText.trim().length === 0} // Disable button only when input is empty
                    buttonElementOnClick={() => {
                      console.log('Button clicked');
                      handleRefresh();
                    }}
                  />
                </Box>

                {/* Bottom Box (aligned left and below) */}
                {/* <Box w={'400px'} display="flex" justifyContent="flex-start" mt="25px">
                  <ButtonSecondaryPlain
                    type="button"
                    name="manualReceipt"
                    value="+ Save Receipt"
                    onClick={handlePreemptiveUpload}
                  />
                </Box> */}
              </Box>
            </ModuleBox>

            <ModuleBox>
              <Tabs 
                borderColor='var(--dark-module-divider)' 
                variant='enclosed' 
                colorScheme='spikedarktabs' 
                onChange={(index) => handleTabChange(index)}>
                <TabList>
                  <Tab><Text>Ready ({postedPendingMatchTransactions?.length||0})</Text></Tab>
                  <Tab><Text>My Receipts ({savedReceipts.length})</Text></Tab>
                  <Tab><Text>Archive</Text></Tab>
                  {showIssuesTab && <Tab><Box mr={'2px'}><AvailableIcons boxSize={'13px'} iconRef={'fillcircle'} color={'var(--alert-red)'} /></Box><Text>{`Returned (${issueCounts.hasIssueCount})`}</Text></Tab>}
                </TabList>
                <TabPanels>

                  {/* Ready */}
                  <TabPanel>
                    <TableContainer>
                      <Box
                        height={'600px'}
                        // width={width||'100%'}
                        overflowY="scroll"
                        border="1px solid"
                        borderColor="var(-dark-component-border)"
                        borderRadius="6px"
                        p={2}
                        onScroll={handleScroll}
                      >
                        <Table variant={'compact'} size={'compact'} >
                          <Tbody>
                            {(showResultsSpinner===true) && <Tr><Td py={'25px'} borderBottom={'1px solid var(--dark-module-divider)'}><Center><Spinner color='var(--progress-bar-primary)' /></Center></Td></Tr>}
                            {(userTransactions?.length===0 && showResultsSpinner===false) && <Tr><Td py='6px' colSpan={4}><Center>No results found</Center></Td></Tr>}
                            
                            {isDataLoaded && generateRows(userTransactions, false, currentTabName)}
                            
                            <Tr><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
                          </Tbody>
                        </Table>
                      </Box>
                    </TableContainer>
                  </TabPanel>

                  {/* My Receipts */}
                  <TabPanel>
                    <Box
                      display="flex"
                      flexDirection="column" // Stack elements vertically
                      alignItems="flex-start" // Align everything to the left
                      marginLeft="15px"
                      my="25px"
                    >
                      <Text textStyle="text-6" mb="10px"> 
                        Add receipt if no notification was received.
                      </Text>
                      <ButtonSecondaryWithIcon
                        type="button"
                        name="manualReceipt"
                        value="Add receipt"
                        onClick={handlePreemptiveUpload}
                        leftIcon="plus"
                        iconsize="20px"
                      />
                    </Box>

                    <Divider mt='25px' mb='25px' h={'1px'} bg={'var(--dark-module-divider)'} />

                    <TableContainer>
                      <Box
                        height={'600px'}
                        // width={width||'100%'}
                        overflowY="scroll"
                        border="1px solid"
                        borderColor="var(-dark-component-border)"
                        borderRadius="6px"
                        p={2}
                        onScroll={handleScroll}>
                        <Table variant={'compact'} size={'compact'} >
                          <Tbody>
                            {generateSavedRows(savedReceipts, false)}
                            <Tr><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
                          </Tbody>
                        </Table>
                      </Box>
                    </TableContainer>
                  </TabPanel>

                  {/* Archive */}
                  <TabPanel>
                    <TableContainer>
                      <Box
                        height={'600px'}
                        // width={width||'100%'}
                        overflowY="scroll"
                        border="1px solid"
                        borderColor="var(-dark-component-border)"
                        borderRadius="6px"
                        p={2}
                        onScroll={handleScroll}
                      >
                        <Table variant={'compact'} size={'compact'} >
                          <Tbody>
                            {isDataLoaded && generateArchiveRows(archivedReceipts, false)}
                            <Tr><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
                          </Tbody>
                        </Table>
                      </Box>
                    </TableContainer>
                  </TabPanel>

                  {/* Returned */}
                  {showIssuesTab && <TabPanel>
                    <TableContainer>
                      <Table variant={'compact'} size={'compact'} >
                        <Tbody>
                          {/* {(showResultsSpinner===true) && <Tr><Td py={'25px'} borderBottom={'1px solid var(--dark-module-divider)'}><Center><Spinner color='var(--progress-bar-primary)' /></Center></Td></Tr>}
                          {(userTransactions?.length===0 && showResultsSpinner===false) && <Tr><Td py='6px' colSpan={4}><Center>No results found</Center></Td></Tr>} */}
                          
                          {isDataLoaded && generateRows(transactionsWithIssue, true, currentTabName)}
                          
                          <Tr><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
                        </Tbody> 
                      </Table>
                    </TableContainer>
                  </TabPanel>}

                </TabPanels>
              </Tabs>
            </ModuleBox>
          </>
        }
        
      <ModuleDrawer
        onClose={onCameraUploadStatementClose}
        isOpen={isCameraUploadStatementOpen}
        bodyContent={<QuickCameraUploadStatement 
          selectedActionType={selectedActionType} 
          screenWidth={currentScreenWidth} 
          id={selectedTransactionId} 
          attachReceiptParams={attachReceiptParams}
          onClose={onCameraUploadStatementClose}/>}
        size={'full'}
      />

      <ModuleDrawer
        onClose={onAttachReceiptClose}
        isOpen={isAttachReceiptOpen}
        bodyContent={<AttachReceipt 
          // uploadedReceipts={uploadedReceipts} 
          uploadedReceipts={savedReceipts} 
          selectedActionType={selectedActionType} 
          screenWidth={currentScreenWidth} 
          transaction={attachReceiptParams} 
          onClose={onAttachReceiptClose}/>}
        size={'full'}
      />

      <ModuleDrawer
        onClose={onViewReceiptClose}
        isOpen={isViewReceiptOpen}
        bodyContent={<ViewReceipt 
          screenWidth={currentScreenWidth} 
          viewReceiptParams={viewReceiptParams}
          onClose={onViewReceiptClose}/>}
        size={'full'}
      />
    {/* 
      <CenteredConfirmDeleteReceipt
      isAlertOpen={isConfirmDeleteReceiptOpen}
      onAlertClose={onConfirmDeleteReceiptClose}
      cancelRef={confirmDeleteCancelRef}
      // closeButtonDisabled={confirmDeleteReceiptCloseDisabled}
      handleConfirmDeleteAlertClosed={handleConfirmDeleteReceiptClosed}
      // deleteReceiptParams={deleteReceiptParams}
    /> */}

      <AlertDialog
        isOpen={isConfirmDeleteReceiptOpen}
        onClose={onConfirmDeleteReceiptClose}
        blockScrollOnMount={true}
        closeOnOverlayClick={false}
        isCentered
      >
        <AlertDialogOverlay
          bg="none"
          backdropFilter="auto"
          backdropBrightness="60%"
          backdropBlur="2px"
        >
          <AlertDialogContent>
            <AlertDialogHeader>
              <Center>
                <Box h={'50px'} mt={'10px'}>
                  <AvailableIcons boxSize={'60px'} iconRef={'alerttriangle'} color={'var(--error-red)'} />
                </Box>
              </Center>
            </AlertDialogHeader>

            <AlertDialogBody mt={'0px'}>
              <Center>
                <Text textStyle={'modal-content-header'}>Delete this receipt?</Text>
              </Center>
              <Box border={'1px solid var(--dark-divider)'} p={'25px'} m={'20px'}>
                This receipt will be permanently deleted from your saved receipts.
              </Box>
              <HStack ml={'35px'} mt={'15px'} mb={'10px'}>
                <Box verticalAlign="top" height="15px">
                  <Checkbox
                    sx={{
                      ".chakra-checkbox__control": {
                        borderColor: "var(--dark-gold-primary)", // Border color
                        _checked: {
                          bg: "var(--dark-gold-primary)", // Background color when checked
                          borderColor: "var(--dark-gold-primary)", // Border color when checked
                        },
                        _hover: {
                          borderColor: "var(--dark-gold-primary)", // Border color on hover
                        },
                        _active: {
                          borderColor: "var(--dark-gold-primary)", // Border color on hover
                        },
                      },
                    }}
                    onChange={(e) => handleCheckboxToggle(e.target.checked)}
                    // onChange={handleCheckboxToggle}
                  />
                </Box>
                <Box>Yes, I confirm.</Box>
              </HStack>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Box width={'500px'}>
                <Center>
                  <HStack spacing="25px">
                    <ButtonOctonaryRed
                      width="148px"
                      name="confirmDeleteAlert"
                      value="Delete"
                      onClick={() => {
                        setConfirmIsChecked(false);
                        handleConfirmDeleteReceiptClosed();
                      }}
                      mr={'40px'}
                      isdisabled={confirmIsChecked ? false : true}
                    />

                    <ButtonPrimaryPlain
                      reference={confirmDeleteCancelRef}
                      width="148px"
                      name="cancelDeleteAlert"
                      value="Cancel"
                      onClick={() => {
                        setConfirmIsChecked(false);
                        onConfirmDeleteReceiptClose();
                      }}
                    />
                  </HStack>
                </Center>
              </Box>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      
      {/* AttachUpload Modal */}
      <Modal isOpen={isAttachUploadModalOpen} onClose={closeAttachUploadModal} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader><Center><Text textStyle={'modal-content-header'}>Add Receipt for Missing Transaction</Text></Center></ModalHeader>
          <ModalBody>
            <Center>
              <Text textStyle={'modal-content-text'} mt={0}>
                If you don’t receive a notification within a few minutes of your transaction, you can upload and save your receipt here. You can attach it to the transaction when it appears in your inbox within a few days.
              </Text>
            </Center>
            <Checkbox
              mt={4}
              isChecked={doNotShowAttachUploadAgain}
              onChange={(e) => handleDoNotShowAttachUploadAgain(e)}
              isDisabled={modalAttachUploadCheckboxDisabled}
              sx={{
                ".chakra-checkbox__control": {
                  borderColor: "var(--dark-gold-primary)", // Border color
                  _checked: {
                    bg: "var(--dark-gold-primary)", // Background color when checked
                    borderColor: "var(--dark-gold-primary)", // Border color when checked
                  },
                  _hover: {
                    borderColor: "var(--dark-gold-primary)", // Border color on hover
                  },
                  _active: {
                    borderColor: "var(--dark-gold-primary)", // Border color on hover
                  },
                },
              }}
            >
              <Text textStyle={'modal-content-text'} mt={0}>I understand. Don’t show again.</Text>
            </Checkbox>
          </ModalBody>
          <ModalFooter>
            <Center>
              <Button colorScheme="blue" onClick={handleAttachUploadContinue}>
                Continue
              </Button>
            </Center>
          </ModalFooter>
        </ModalContent>
      </Modal>
          
      {/* AttachUpload Modal */}
      <Modal isOpen={isReceiptInfoModalOpen} onClose={closeReceiptInfoModal} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader><Center><Text textStyle={'modal-content-header'}>How to Manage Your Receipts</Text></Center></ModalHeader>
          <ModalBody>
            <Center>
              <Stack textAlign="left">
                <Stack spacing={1}>
                  <Text textStyle={'modal-content-semibold'}>Upload</Text>
                  <Text textStyle={'modal-content-text'} mt={0}>
                    Use “Upload” to scan and submit a receipt for a specific transaction.
                  </Text>
                </Stack>

                {/* Attach Section */}
                <Stack spacing={1}>
                  <Text textStyle={'modal-content-semibold'}>Attach</Text>
                  <Text textStyle={'modal-content-text'} mt={0}>
                    Use “Attach” to link a saved receipt to a specific transaction.
                  </Text>
                </Stack>

                {/* Attach Section */}
                {/* <Stack spacing={1}>
                  <Text textStyle={'modal-content-semibold'}>Add receipt for missing transaction</Text>
                  <Text textStyle={'modal-content-text'} mt={0}>
                    If you don’t receive a notification within a few minutes of your transaction, you can upload and save your receipt here. You can match it to the transaction when it appears in your inbox within a few days.
                  </Text>
                </Stack> */}

                {/* My Receipts Section */}
                <Stack spacing={1}>
                  <Text textStyle={'modal-content-semibold'}>My Receipts Tab</Text>
                  <Text textStyle={'modal-content-text'} mt={0}>
                    View receipts you’ve saved for missing transactions.
                  </Text>
                </Stack>
              </Stack>
            </Center>
            <Checkbox
              mt={4}
              isChecked={doNotShowReceiptInfoAgain}
              onChange={(e) => handleDoNotShowReceiptInfoAgain(e)}
              isDisabled={modalReceiptInfoCheckboxDisabled}
              sx={{
                ".chakra-checkbox__control": {
                  borderColor: "var(--dark-gold-primary)", // Border color
                  _checked: {
                    bg: "var(--dark-gold-primary)", // Background color when checked
                    borderColor: "var(--dark-gold-primary)", // Border color when checked
                  },
                  _hover: {
                    borderColor: "var(--dark-gold-primary)", // Border color on hover
                  },
                  _active: {
                    borderColor: "var(--dark-gold-primary)", // Border color on hover
                  },
                },
              }}
            >
              <Text textStyle={'modal-content-text'}>Got it. Don’t show again.</Text>
            </Checkbox>
          </ModalBody>
          <ModalFooter>
            <Center>
              <Button colorScheme="blue" onClick={handleReceiptInfoContinue}>
                Continue
              </Button>
            </Center>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* <ModuleDrawer
        onClose={onStatementDetailClose}
        isOpen={isStatementDetailOpen}
        bodyContent={<StatementDetail qbEnv={qbEnv} screenWidth={currentScreenWidth} selectedStatement={selectedStatement} userData={userData} onClose={onStatementDetailClose}/>}
        size={'full'}
      /> */}
          
      </Container>
    </> 

  );
};
