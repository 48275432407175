import { useState, useRef, useEffect, useContext } from 'react';
import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  Container,
  // useDisclosure,
  // Center,
  // Stack,
  IconButton,
} from "@chakra-ui/react";

import { AddIcon, MinusIcon, RepeatIcon } from '@chakra-ui/icons';
import { AvailableIcons } from '../Icons/AvailableIcons.jsx';

import PropTypes from 'prop-types';

// uuid
// import { v4 as uuid } from 'uuid';

// form buttons
// import { ButtonPrimaryPlain } from '../Form/Button/ButtonPrimaryPlain.jsx';
import { ResponsiveCancelCloseButton } from '../Form/Button/ResponsiveCancelCloseButton.jsx';

// generic funcitons
// import { stripToNumbersAndDecimal } from '../functions/number.jsx';
import { spellOutDate } from '../functions/dateTime.jsx';

// globals
import { AppContext } from '../AppContext.jsx';

// pdf preview
import { GlobalWorkerOptions } from 'pdfjs-dist/build/pdf';
import * as pdfjsLib from 'pdfjs-dist';

export const ViewReceipt = (props) => {

  // grab global context values
  const { store } = useContext(AppContext);

  const userIsMobile = store?.userIsMobile;

  const {
    screenWidth,
    viewReceiptParams,
    onClose,
  } = props;

  useEffect(() => {
    // Set the workerSrc to the file in the public folder
    GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs';
  }, []);

  const [scale, setScale] = useState(1); // Track zoom level
  
  const handleZoom = (zoomIn) => {
    setScale((prevScale) => {
      const newScale = zoomIn ? prevScale + 0.2 : prevScale - 0.2;
      return Math.max(1, Math.min(newScale, 3)); // Limit scale between 1x and 3x
    });
  };

  const resetZoom = () => {
    setScale(1); // Reset zoom level to default
  };

  const [ previewImage, setPreviewImage ] = useState('');
  const [ signedUrl, setSignedUrl ] = useState('');

  console.warn('viewReceiptParams: ', props.viewReceiptParams);

  let displayDate = '';
  let displayNotes = '';
  let displayImageUrl = '';
  if (viewReceiptParams?.plaidCreditCardTransaction?.addedNotes) {
    displayNotes = viewReceiptParams.plaidCreditCardTransaction.addedNotes;
  }
  if (viewReceiptParams?.notes) {
    displayNotes = viewReceiptParams.notes;
  }
  if (viewReceiptParams?.plaidCreditCardTransaction?.date) {
    displayDate  = spellOutDate(viewReceiptParams.plaidCreditCardTransaction.date, 'apple-fy'); 
  }
  if (viewReceiptParams?.date) {
    displayDate = viewReceiptParams.date;
  }
  // if (viewReceiptParams?.signedUrls?.length>0) {
  //   displayImageUrl  = viewReceiptParams?.signedUrls[0];
  //   setSignedUrl(viewReceiptParams?.signedUrls[0]);
  // }
  // if (viewReceiptParams?.url) {
  //   displayImageUrl = viewReceiptParams.url;
  //   setSignedUrl(viewReceiptParams.url);
  // }

  useEffect(() => {
    if (viewReceiptParams?.signedUrls?.length > 0) {
      setSignedUrl(viewReceiptParams.signedUrls[0]);
    } else if (viewReceiptParams?.url) {
      setSignedUrl(viewReceiptParams.url);
    }
  }, [viewReceiptParams]);

  useEffect(() => {
    // generatePreview function
    const generatePreview = async (signedURL) => {
      function extractFileTypeFromUrl(url) {
        try {
          // Extract the portion of the URL before the query parameters
          const urlWithoutParams = url.split('?')[0];
          
          // Extract the file extension using regex or by splitting on "."
          const fileType = urlWithoutParams.split('.').pop();
          
          // Validate that the file type is meaningful (e.g., not empty)
          if (fileType && fileType.length > 0) {
            return fileType;
          } else {
            throw new Error("File type could not be determined");
          }
        } catch (error) {
          console.error("Error extracting file type:", error.message);
          return null;
        }
      }
      const fileType = extractFileTypeFromUrl(signedURL);

      if (fileType === 'pdf') {
        const pdfPreview = await generatePdfPreview(signedURL);
        console.log('pdfPreview: ', pdfPreview);
        setPreviewImage(pdfPreview);  // Set the preview in the
        return 
      } else if (['jpg', 'jpeg', 'png'].includes(fileType.toLowerCase())) {
        const imagePreview = signedURL;
        console.log('imagePreview: ', imagePreview);
        setPreviewImage(imagePreview);  // Set the preview in the
        return
      } else {
        throw new Error('Unsupported file type for preview generation');
      }
    };
    
    // generatePdfPreview function
    const generatePdfPreview = async (pdfUrl) => {
      const pdf = await pdfjsLib.getDocument(pdfUrl).promise;
      const page = await pdf.getPage(1);
    
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const viewport = page.getViewport({ scale: 1.0 });
    
      canvas.height = viewport.height;
      canvas.width = viewport.width;
    
      await page.render({ canvasContext: context, viewport }).promise;
      
      return canvas.toDataURL();  // Return the preview as a data URL
    };
    // Generate a preview for the file (either an image or a PDF)
    (signedUrl?.length>1) && generatePreview(signedUrl);  // Generate preview
  },[signedUrl])

  console.info('displayImageUrl: ', displayImageUrl);
  
  return (
    <>
    <Container 
      paddingBottom={'50px'}
      width="100%" 
      maxW="800px"
      // maxW="100%"
      >
      <Flex
        direction="column"
        width="100%" // Ensure it stretches to fill the available space
        mx="auto"
        color="white"
        minH="100vh"
        // border={'1px solid red'}
      >
        <Flex direction="column" align="center" position="relative">
          {/* Close button positioned at the top right */}
          <Box position="absolute" top="0" right="0">
            <ResponsiveCancelCloseButton 
              iconSize={['22px', '26px']}
              isMobile={userIsMobile}
              value="Cancel"
              onClick={onClose} />
          </Box>

          <Box w={'100%'}>
            <Text
              as="span"
              color={'var(--dark-text-grey-1)'}
              textStyle="text-2"
              display="block"
              mb="6px"
            >
              Receipt Image Date
            </Text>
            <Text
              as="span"
              color={'var(--dark-text-grey-1)'}
              textStyle="heading-4"
              display="block"
              fontWeight="bold"
              mb="6px"
            >
              {displayDate}
            </Text>
            {displayNotes && <><Text
              as="span"
              color={'var(--dark-text-grey-1)'}
              textStyle="text-2"
              display="block"
              mb="6px"
            >
              Notes
            </Text>
            <Text
              as="span"
              color={'var(--dark-text-grey-1)'}
              textStyle="text-2"
              display="block"
            >
              {displayNotes}
            </Text></>}

            <Box mt="25px" width="100%" maxW="800px" mx="auto" position="relative">
              {/* Scrollable Zoomable Box */}
              <Box
                overflow="auto"
                border="1px solid #ccc"
                width="100%"
                maxW="800px" // Set maxWidth to 800px
                height="100%" // Adjust height as needed
                maxHeight={screenWidth > 800 ? "800px" : "600px"} // Limit height on smaller screens
                mx="auto" // Center the box horizontally
                position="relative"
                bg="gray.50"
              >
                <Box
                  style={{
                    transform: `scale(${scale})`,
                    transformOrigin: "top left",
                    display: "inline-block",
                  }}
                  width={`${100 * scale}%`}
                >
                  <Image
                    src={previewImage}
                    alt="Uploaded Receipt"
                    draggable="false"
                    width="100%"
                    height="auto"
                  />
                </Box>
              </Box>

              {/* Floating Zoom Buttons at the Top-Right */}
              <Flex
                position="absolute"
                top="5px"
                right="5px"
                flexDirection="column"
                gap="5px"
                // bg="var(dark-module-bg)" // Set the background color
                bg={'var(--dark-module-bg)'} // Set the background color
                color={'var(--dark-gold-primary)'}
                borderRadius="md"
                paddingX="5px"
                paddingY="10px"
                zIndex="10"
                // boxShadow="lg" // Optional: Add shadow for a floating effect
              >
                <IconButton
                  aria-label="Zoom In"
                  icon={<AvailableIcons boxSize={'25px'} iconRef={'plus'} color={'var(--dark-gold-primary)'} />}
                  onClick={() => handleZoom(true)}
                  isDisabled={scale === 3}
                  color="dark-gold-primary" // Set icon color
                  bg="transparent" // Background transparent to match dark-module-bg
                  _hover={{ bg: "rgba(255, 255, 255, 0.1)" }} // Slight hover effect
                  _active={{ bg: "rgba(255, 255, 255, 0.2)" }} // Active effect
                  _disabled={{ color: "gray.500" }} // Disabled state styling
                  size="lg" // Adjust button size to match the design
                />
                <IconButton
                  aria-label="Zoom Out"
                  icon={<AvailableIcons boxSize={'25px'} iconRef={'minus'}  />}
                  onClick={() => handleZoom(false)}
                  isDisabled={scale === 1}
                  color="dark-gold-primary"
                  bg="transparent"
                  _hover={{ bg: "rgba(255, 255, 255, 0.1)" }}
                  _active={{ bg: "rgba(255, 255, 255, 0.2)" }}
                  _disabled={{ color: "gray.500" }}
                  size="lg"
                />
                <IconButton
                  aria-label="Reset Zoom"
                  icon={<AvailableIcons boxSize={'25px'} iconRef={'revert'} color={'var(--dark-gold-primary)'} />}
                  onClick={resetZoom}
                  color="dark-gold-primary"
                  bg="transparent"
                  _hover={{ bg: "rgba(255, 255, 255, 0.1)" }}
                  _active={{ bg: "rgba(255, 255, 255, 0.2)" }}
                  size="lg"
                />
              </Flex>
            </Box>

          </Box>
        </Flex>

      </Flex>
    </Container>
    </>
  );

}

ViewReceipt.propTypes = {
  viewReceiptParams: PropTypes.shape({
    id: PropTypes.string,
    notes: PropTypes.string,
    url: PropTypes.string,
    date: PropTypes.string,
    signedUrls: PropTypes.array,
    plaidCreditCardTransaction: PropTypes.shape({
      date: PropTypes.string,
      addedNotes: PropTypes.string,
    }),
  }),
  screenWidth: PropTypes.number,
  onClose: PropTypes.func,
};