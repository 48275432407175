import { Box, IconButton, Tooltip } from '@chakra-ui/react';
// import { tooltip } from 'aws-amplify';

// icons
import { AvailableIcons } from '../../Icons/AvailableIcons';

export const NotificationsBadge = (props) => {
    // set 9 as max displayable message count, append '+' sign after 9
    const { msgCount } = props
    let numMessages = (msgCount>=10) ? '9+' : msgCount;

    return (
        <Tooltip 
            openDelay={1000} 
            closeDelay={100} 
            arrowSize={15} 
            placement='top' 
            // hasArrow 
            // label='Notifications'
            wrapper="span">
            <IconButton 
                variant={'iconbutton'}
                aria-label={'Notifications'}
                size={'lg'}
                icon={<>
                    <AvailableIcons 
                        boxSize={'28px'} 
                        iconRef={'notifications'} />
                        <Box 
                            as={'span'} 
                            color={'white'} 
                            position={'absolute'} 
                            top={'6px'} 
                            right={'4px'} 
                            fontSize={'0.8rem'}
                            bgColor={'red'} 
                            // zIndex={tooltip} 
                            borderRadius={'10px'} 
                            w={'20px'} 
                            m={'1px'} 
                            padding={'1px'}>{numMessages||0}</Box>
            </>}
        /></Tooltip>
    );
}

