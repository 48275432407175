import { listPlaidCreditCardTransactionsByStatusByDateRange } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const fetchTransactionsByDateRange = async (status, startPostedDate, endPostedDate, limit=1500) => {
  try {
    if (!startPostedDate || !endPostedDate) {
      console.warn('No date range provided to fetchTransactionsByDateRange');
      return null;
    }

    const gqlParams = {
      status: status,
      startDate: {
        between: [startPostedDate, endPostedDate]
      },
      limit: limit
    };
    
    console.info('GraphQL Params:', gqlParams);

    const response = await client.graphql({
      query: listPlaidCreditCardTransactionsByStatusByDateRange,
      variables: gqlParams,
    });

    console.info('GraphQL Response:', response);

    // Ensure response is as expected
    if (!response || !response.data || !response.data.plaidCreditCardTransactionByStatus) {
      console.warn(`No data returned for status ${status}`);
      return null; // Return null if the response structure is unexpected
    }

    const results = response.data.plaidCreditCardTransactionByStatus.items;

    // If results is not an array or is empty, return null
    if (!Array.isArray(results) || results.length === 0) {
      console.warn(`No transactions found for date range ${startPostedDate} to ${endPostedDate}`);
      return null;
    }

    return results; // Return the items array if data is present
  } catch (err) {
    console.error('Error in fetchTransactionsByStatus:', err);
    return null; // Return null on error
  }
};

fetchTransactionsByDateRange.propTypes = {
  status: PropTypes.string.isRequired,
  startPostedDate: PropTypes.string.isRequired,
  endPostedDate: PropTypes.string.isRequired,
  limit: PropTypes.number,
};